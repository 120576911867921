var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-request-offer", class: { active: _vm.open } },
    [
      _c("MoleculeDynamicModal"),
      _c(
        "form",
        {
          ref: "request-offer-form",
          attrs: { action: "javascript:void(0);", id: "request-offer" },
          on: { submit: _vm.submitForm }
        },
        [
          _c("MoleculeRequestOffer", [
            _c("div", { staticClass: "row row-header" }, [
              _c("h3", [_vm._v("Request Offer")]),
              _c(
                "div",
                { staticClass: "close-i", on: { click: _vm.handleClose } },
                [
                  _vm._v("\n          Close\n          "),
                  _c(
                    "span",
                    { staticClass: "close-button" },
                    [_c("AtomIcon", { attrs: { icon: "xmark" } })],
                    1
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "row row-steps align-items-center" }, [
              _c("div", { staticClass: "col col-adaptive" }, [
                _c(
                  "span",
                  {
                    staticClass: "step-number",
                    class: {
                      "step-active": _vm.activeStep === "Step 1",
                      "step-done": _vm.activeStep === "Step 2"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeStep === "Step 1",
                            expression: "activeStep === 'Step 1'"
                          }
                        ]
                      },
                      [_vm._v("1")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.activeStep === "Step 2",
                            expression: "activeStep === 'Step 2'"
                          }
                        ]
                      },
                      [_c("AtomIcon", { attrs: { icon: "ok" } })],
                      1
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "col col-8" }, [
                _c("span", { staticClass: "line" })
              ]),
              _c("div", { staticClass: "col col-adaptive" }, [
                _c(
                  "span",
                  {
                    staticClass: "step-number",
                    class: { "step-active": _vm.activeStep === "Step 2" }
                  },
                  [_c("span", [_vm._v("2")])]
                )
              ])
            ]),
            _c("div", { staticClass: "row row-title-steps" }, [
              _c("div", { staticClass: "col col-xs-12" }, [
                _c("div", { staticClass: "title-and-description" }, [
                  _c("span", { staticClass: "step" }, [
                    _vm._v(_vm._s(_vm.activeStep))
                  ]),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === "Step 1",
                          expression: "activeStep === 'Step 1'"
                        }
                      ],
                      staticClass: "title"
                    },
                    [_vm._v("\n              Items requested\n            ")]
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === "Step 2",
                          expression: "activeStep === 'Step 2'"
                        }
                      ],
                      staticClass: "title"
                    },
                    [_vm._v("\n              Who is interested\n            ")]
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === "Step 2",
                          expression: "activeStep === 'Step 2'"
                        }
                      ],
                      staticClass: "subtitle"
                    },
                    [
                      _vm._v(
                        "\n              Please complete the fields below before sending\n            "
                      )
                    ]
                  )
                ])
              ])
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStep === "Step 1",
                    expression: "activeStep === 'Step 1'"
                  }
                ],
                staticClass: "row row-packages"
              },
              [
                _c(
                  "div",
                  { staticClass: "col col-12 scroll-y" },
                  [
                    _c("OrganismRequestOfferPackages", {
                      ref: "organismRequestOfferPackages"
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeStep === "Step 2",
                    expression: "activeStep === 'Step 2'"
                  }
                ],
                staticClass: "row row-form"
              },
              [
                _c("div", { staticClass: "col col-12 scroll-y" }, [
                  _c("div", { staticClass: "organism-request-offer-form" }, [
                    _c(
                      "div",
                      { staticClass: "contact-details" },
                      [
                        _c("h3", [_vm._v("Contact Details")]),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.fullName,
                            label: "Full Name (Required)",
                            placeholder: "Enter your first and last name",
                            error: _vm.errors.fullName
                          },
                          on: {
                            input: function($event) {
                              _vm.fullName = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.companyName,
                            label: "Company Name (Required)",
                            placeholder: "Enter company name",
                            error: _vm.errors.companyName
                          },
                          on: {
                            input: function($event) {
                              _vm.companyName = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXT,
                            value: _vm.email,
                            label: "Email address (Required)",
                            placeholder: "Enter email address",
                            error: _vm.errors.email
                          },
                          on: {
                            input: function($event) {
                              _vm.email = $event
                            }
                          }
                        }),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEL,
                            value: _vm.phone,
                            label: "Phone Number (Required)",
                            placeholder: "Enter phone number",
                            error: _vm.errors.phone
                          },
                          on: {
                            input: function($event) {
                              _vm.phone = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "specific-details" },
                      [
                        _c("h3", [
                          _vm._v(
                            "Any specific details regarding your personalized quote?"
                          )
                        ]),
                        _c("AtomInput", {
                          attrs: {
                            type: _vm.inputTypes.TEXTAREA,
                            value: _vm.moreDetails,
                            label: "More details or leave us a message:"
                          },
                          on: {
                            input: function($event) {
                              _vm.moreDetails = $event
                            }
                          }
                        }),
                        _c("AtomCheck", {
                          ref: "check",
                          staticClass: "row-checkbox",
                          attrs: {
                            id: "checkbox-contact",
                            label:
                              "I consent to Iulius Mall using my personal data for the purpose of delivering products and services and understand that I can withdraw my consent at any time.*",
                            error: _vm.errors.agree
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "buttons-bottom" }, [
              _c("div", { staticClass: "buttons-left" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === "Step 1",
                        expression: "activeStep === 'Step 1'"
                      }
                    ],
                    staticClass: "btn-general btn-gray",
                    on: { click: _vm.handleClose }
                  },
                  [_c("span", [_vm._v("Cancel")])]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === "Step 2",
                        expression: "activeStep === 'Step 2'"
                      }
                    ],
                    staticClass: "btn-general btn-gray",
                    on: {
                      click: function($event) {
                        return _vm.handleStepsChange("Step 1")
                      }
                    }
                  },
                  [_c("span", [_vm._v("Back")])]
                )
              ]),
              _c("div", { staticClass: "buttons-right" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === "Step 1",
                        expression: "activeStep === 'Step 1'"
                      }
                    ],
                    staticClass: "btn-general btn-black",
                    on: {
                      click: function($event) {
                        return _vm.handleStepsChange("Step 2")
                      }
                    }
                  },
                  [_c("span", [_vm._v("Next Step")])]
                ),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep === "Step 2",
                        expression: "activeStep === 'Step 2'"
                      }
                    ],
                    staticClass: "btn-general btn-black",
                    attrs: { type: "submit", value: "Request Offer" }
                  },
                  [_c("span", [_vm._v("Request Offer")])]
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }