var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-shortlist-drawer" },
    [
      _c(
        "OrganismDrawer",
        {
          ref: "drawer",
          attrs: { "default-open": _vm.defaultOpen },
          on: {
            "drawer:close": function($event) {
              return _vm.$emit("drawer:close")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("div", { staticClass: "title" }, [
                _c("h3", [_vm._v("Your Shortlist")]),
                _vm.shortlistItemsLength > 0
                  ? _c("span", { staticClass: "shortlist-length" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.shortlistItemsLength) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]),
              _c("AtomButton", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shortlistItemsLength > 0,
                    expression: "shortlistItemsLength > 0"
                  }
                ],
                staticClass: "rounded-button",
                attrs: { label: "Clear All", "on-click": _vm.clearAll }
              })
            ],
            1
          ),
          _vm.shortlistItemsLength > 0
            ? _c(
                "div",
                { staticClass: "body" },
                [_c("MoleculeSidebarShortlistItems")],
                1
              )
            : _c(
                "div",
                [
                  _c("MoleculeShortlistPlaceholder", {
                    attrs: {
                      "trigger-open-availability": _vm.triggerOpenAvailability
                    }
                  })
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }