<template>
  <div id="owl-custom-dots" class="molecule-image-thumb-slider" ref="container">
      <AtomImageThumbSlider v-for="(sliderImage, index) in thumbImages" :slider-image="sliderImage" :active-thumb="activeThumb" :key="index" @click="handleThumbChange" />
  </div>
</template>

<script>
import AtomImageThumbSlider from "../../atoms/common/slider/AtomImageThumbSlider";
import $ from "jquery";

export default {
  name: "MoleculeImageThumbSlider",
  components: {AtomImageThumbSlider},
  props: {
    thumbnails: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
    typeOf: {
      required: false,
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      thumbImages: [],
      activeThumb: 0,
    }
  },
  watch: {
    thumbnails: {
      deep: true,
      handler: function (value, old) {
        this.thumbImages = [...value];
      }
    }
  },
  mounted() {
    this.thumbImages = this.thumbnails;
  },
  methods: {
    changeActiveThumb(index) {
      if (isNaN(index)) index = 0;
      this.activeThumb = index;

      this.pauseVideoOnThumbChange();
    },
    handleThumbChange(index) {
      this.changeActiveThumb(index);
      this.$emit("thumb-change", index);
    },
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    pauseVideoOnThumbChange(){
      if(this.typeOf === 'video') {
        console.log('pauseVideoOnThumbChange');
        const currentSlider = $('.owl-item.active'); // it is easy to use jquery in this context instead of refs.
        if(!currentSlider) return;
        let activePlayer = currentSlider.find('iframe').get(0);
        if(activePlayer){ // activePlayer will be the iframe of youtube player
          console.log('activePlayer', activePlayer);
          activePlayer.contentWindow.postMessage(JSON.stringify({
            'event': 'command',
            'func': 'pauseVideo'
          }), "*"); // we pause the video when user clicks on another video thumb card
        } else {
          activePlayer = currentSlider.find('video'); // activePlayer will be the html video player
          if(activePlayer){
            activePlayer.trigger('pause'); // we trigger a pause action on the video when use clicks on another video thumb card
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.molecule-image-thumb-slider {
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 0.125rem;
  @media only screen and (max-width: 767px) {
    padding: 0 2rem 0.125rem;
  }
}
</style>