var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-contact": true,
          "section-contact-v2": true
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("OrganismContactForm"),
      !_vm.data.contactFormOnly
        ? _c("div", { staticClass: "row contacts-wrapper" }, [
            _c("div", { staticClass: "col col-xs-12 col-6 column-left-info" }, [
              _c("div", {
                staticClass: "title",
                domProps: { innerHTML: _vm._s(_vm.data.title) }
              }),
              _c("div", {
                staticClass: "subtitle",
                domProps: { innerHTML: _vm._s(_vm.data.subtitle) }
              }),
              _c("ul", { staticClass: "info-general" }, [
                _vm.data.email
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "email",
                          attrs: { href: _vm.data.email }
                        },
                        [
                          _c("AtomIcon", { attrs: { icon: "email-custom" } }),
                          _vm._v("  " + _vm._s(_vm.data.email))
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.data.phone
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "phone",
                          attrs: { href: _vm.data.phone }
                        },
                        [
                          _c("AtomIcon", { attrs: { icon: "phone-custom" } }),
                          _vm._v(" " + _vm._s(_vm.data.phone))
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.data.address
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "phone",
                          attrs: { href: _vm.data.address }
                        },
                        [
                          _c("AtomIcon", {
                            attrs: { icon: "location-custom" }
                          }),
                          _vm._v(" " + _vm._s(_vm.data.address))
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _c(
              "div",
              { staticClass: "col col-xs-12 col-6 column-right-info" },
              [
                _c("div", { staticClass: "text-right" }, [
                  _c("div", { staticClass: "social" }, [
                    _vm.data.socialTitle
                      ? _c("div", {
                          staticClass: "title",
                          domProps: { innerHTML: _vm._s(_vm.data.socialTitle) }
                        })
                      : _vm._e(),
                    _vm.data.instagramUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.data.instagramUrl,
                              target: "_blank"
                            }
                          },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "instagram",
                                size: 40,
                                color: "#FFF"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.linkedinUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.data.linkedinUrl,
                              target: "_blank"
                            }
                          },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "linkedin-squared",
                                size: 40,
                                color: "#FFF"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.facebookUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.data.facebookUrl,
                              target: "_blank"
                            }
                          },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "facebook-squared",
                                size: 40,
                                color: "#FFF"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.data.youtubeUrl
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.data.youtubeUrl,
                              target: "_blank"
                            }
                          },
                          [
                            _c("AtomIcon", {
                              attrs: {
                                icon: "youtube-play",
                                size: 40,
                                color: "#FFF"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }