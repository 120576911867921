<template>
  <div
    class="section section-background-image-title"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      class="row background-image"
      :style="`background-image: url(${resourceUrl(data.image)})`"
    >
      <div class="col col-12">
        <div class="content-header">
          <div class="gradient-background"></div>
          <AtomTitle :title="data.title" :subtitle="data.subtitle" />
          <AtomList :lines="data.lines" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../components/atoms/common/AtomTitle";
import AtomList from "../../components/atoms/common/AtomList";
export default {
  name: "SectionBackgroundImageTitle",
  components: { AtomList, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-background-image-title {
  .background-image {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    & > .col {
      position: static;
    }
    .gradient-background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(25, 73, 95, 0.6);
    }
    .content-header {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      min-height: 35rem;
      @media only screen and (max-width: 767px) {
        align-items: initial;
      }
    }
    .atom-title {
      position: relative;
      z-index: 5;
      margin-bottom: 1.5rem;
      @media only screen and (max-width: 767px) {
        padding: 0 2rem;
        margin-bottom: 4rem;
      }
      h2 {
        text-transform: none;
        letter-spacing: -0.08rem;
        @include font-main(4rem, $white !important, $bold, normal);
        @media only screen and (max-width: 767px) {
          text-align: center;
          @include font-main(3.25rem, $white !important, $bold, normal);
        }
      }
      h3 {
        color: $white !important;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
}
</style>
