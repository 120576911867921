<template>
  <div class="molecule-sidebar-header">
    <div class="menu-items sm">
      <slot v-if="!['Digital Items', 'Physical Items'].length"></slot>
      <AtomMenuItem
        v-else
        v-for="(tab, index) in [
          { label: 'Digital Items', tabId: 'digital' },
          { label: 'Physical Supports', tabId: 'physical' },
        ]"
        :key="index"
        :label="tab.label"
        :icon="tab.icon"
        :tab-id="tab.tabId"
        :on-click="handleTabClick"
        :class="{ active: selectedTab === tab.tabId }"
      />
    </div>
  </div>
</template>

<script>
import AtomMenuItem from "@/components/atoms/project/building/AtomMenuItem.vue";
export default {
  name: "MoleculeSidebarHeader",
  components: { AtomMenuItem },
  props: {
    selectedOption: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    let defaultTab = "";
    if (this.selectedOption !== "") {
      defaultTab = this.selectedOption;
    }
    return {
      selectedTab: defaultTab,
      buildingOptions: [],
      floorOptions: [],
      surfaceOptions: [],
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
  },
  watch: {
    selectedOption(newVal) {
      let defaultTab = "";
      if (this.selectedOption !== "") {
        defaultTab = this.selectedOption;
      }
      this.selectedTab = defaultTab;
    },
  },
  methods: {
    handleFilterChange(e, type) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_FILTER_STATE
        ),
        { value: e, type: type }
      );
    },
    handleTabClick(tabId) {
      this.selectedTab = tabId;
      this.$emit("tab-change", tabId);
    },
  },
};
</script>

<style lang="scss">
.molecule-sidebar-header {
  position: relative;
  padding: 1.313rem 1.25rem;
  @media only screen and (max-width: 767px) {
    padding: 0 1rem;
  }
  .title-and-info {
    position: relative;
    padding-right: 3.75rem;
    margin-bottom: 1rem;
  }
  .btnShowElements {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -1rem;
  }
  .filters {
    margin-bottom: 0.375rem;
    .select-style {
      background: #eaeaea;
      width: 100%;
      border-radius: 0.25rem;
      margin-bottom: 0.5rem;
      label {
        display: block;
        height: auto;
        min-width: 0;
        border: none;
        background: transparent;
        letter-spacing: 0;
        text-transform: initial;
        padding: 0.375rem 0.75rem;
        margin: 0;
        font-size: 0.875rem;
        color: $black;
        font-weight: $regular;
        line-height: 1.063rem;
        @media only screen and (max-width: 1200px) {
          font-size: 0.813rem;
        }
      }
      .select-inline-block {
        display: block;
      }
    }
  }

  .menu-item-atom {
    padding: 0.125rem 0.5rem !important;
    border-radius: 3px !important;
    height: auto !important;
    @include font-main(0.938rem, $black, $regular, 1.5rem);
    @media only screen and (max-width: 767px) {
      padding: 0.125rem 0.5rem !important;
    }
    span {
      white-space: nowrap;
      @include font-main(0.938rem, $black, $medium, 1.5rem);
    }

    &.router-link-exact-active,
    &.active {
      color: white !important;
      background-color: #020202 !important;
      i {
        color: white !important;
      }
    }

    &:hover {
      background-color: rgba(2, 2, 2, 0.08);
    }
  }
}

.menu-items {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  .menu-item-atom {
    padding: 0.125rem 0.5rem !important;
    margin-right: 0.25rem;
    border-radius: 0.188rem !important;

    @include font-main(0.938rem, $black, 500, 1.5rem);
    span {
      white-space: nowrap;
    }

    &.router-link-exact-active,
    &.active {
      color: white;
      background-color: #020202 !important;
      i {
        color: white !important;
      }
    }

    &:hover {
      background-color: rgba(2, 2, 2, 0.08);
    }
  }

  &.sm {
    .menu-item-atom {
      @media only screen and (max-width: 767px) {
        font-size: 0.875rem;
        span {
          font-size: 0.875rem;
        }
      }
      .icon {
        @media only screen and (max-width: 767px) {
          margin-right: 7px;
          font-size: 0.875rem !important;
        }
      }
    }
  }

  &.no-margin-ends {
    .menu-item-atom {
      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
