<template>
  <div :id="`${id}`" :class="{'atom-bullet-specs': true, 'active': isActive}" :style="{'top': topPostion, 'right': rightPostion, 'bottom': bottomPostion, 'left': leftPostion}" @click="handleClick">
    <span class="bullet"></span>
    <AtomIcon color="#fff" :size="16" icon="plus" />
    <AtomIcon color="#fff" :size="30" icon="minus" />
    <div class="hidden-element" :style="{'top': showTop, 'right': showRight, 'bottom': showBottom, 'left': showLeft, 'transform': showCenter}">
      <div v-if="image" class="image">
        <img :src="resourceUrl(image)" :alt="category">
      </div>
      <div class="content-info">
        <div class="content" v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon"; 
export default {
  name: "AtomBulletSpecsNoCategory",
  components: {AtomIcon},
  props: {
    id: {
      required: false,
      type: String,
      default: ""
    },
    showTop: {
      required: false,
      type: String,
      default: ""
    },
    showBottom: {
      required: false,
      type: String,
      default: ""
    },
    showLeft: {
      required: false,
      type: String,
      default: ""
    },
    showRight: {
      required: false,
      type: String,
      default: ""
    },
    showCenter: {
      required: false,
      type: String,
      default: ""
    },
    text: {
      required: false,
      type: String,
      default: ""
    },
    image: {
      required: false,
      type: String,
      default: ""
    },
    category: {
      required: false,
      type: String,
      default: ""
    },
    topPostion: {
      required: false,
      type: String,
      default: "initial"
    },
    rightPostion: {
      required: false,
      type: String,
      default: "initial"
    },
    bottomPostion: {
      required: false,
      type: String,
      default: "initial"
    },
    leftPostion: {
      required: false,
      type: String,
      default: "initial"
    },
    activeBullet: {
      required: true,
      type: String,
      default: "item-0",
    },
    bulletId: {
      required: true,
      type: String,
      default: ''
    },
  },
  computed: {
    isActive() {
      return this.$props.activeBullet === this.bulletId;
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    handleClick() {
      this.$emit('change', this.bulletId);
    }
  }
}
</script>

<style lang="scss">
  .atom-bullet-specs {
    position: absolute;
    height: 3.375rem;
    width: 3.375rem;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      width: 1.5rem;
      height: 1.5rem;
    }
    .bullet {
      display: block;
      width: 1.613rem;
      height: 1.613rem;
      background: $black;
      border-radius: 3.438rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }

    .icon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      transition: all 0.5s ease-in-out 0s;
      &.icon-plus {
        top: 48%;
        font-size: 1.3rem !important;
        @media only screen and (max-width: 767px) {
          top: 50%;
          font-size: 1rem !important;
        }
      }
      &.icon-minus {
        font-size: 1.875rem !important;
        opacity: 0;
        top: 52%;
        @media only screen and (max-width: 767px) {
          top: 50%;
          font-size: 1rem !important;
        }
      }
    }
    .hidden-element {
      background: $black;
      border-radius: 0.188rem;
      position: absolute;
      width: 0;
      padding: 0;
      opacity: 0;
      z-index: 9;
      transition: all 0.2s ease-in-out 0s;
      &::-webkit-scrollbar-thumb {
        background: $white;
      }
      .content-info {
        overflow: hidden;
        overflow-y: auto;
        max-height: 9.6rem;
      }
      img {
        display: block;
        width: 100%;
        max-width: 100%;
      }
      p {
        font-weight: $regular;
        font-size: 1rem;
        line-height: 1.188rem;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $white;
        opacity: 0.6;
        padding: 0.938rem 1.563rem 0;
      }
      .content {
        font-weight: $regular;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $white;
        width: 18.75rem;
        padding: 0.9rem 1.563rem;
        a {
          color: $second-color;
        }
      }
      @media only screen and (max-width: 767px) {
        display: none !important;
      }
    }
    &:hover, &.active {
      .bullet {
        width: 3.375rem;
        height: 3.375rem;
        @media only screen and (max-width: 767px) {
          width: 1.5rem;
          height: 1.5rem;
          background: $second-color;
        }
      }
      .icon {
        &.icon-plus {
          opacity: 0;
        }
        &.icon-minus {
          opacity: 1;
        }
      }
      .hidden-element {
        opacity: 1;
        width: 18.75rem;
      }
    }
  }
</style>