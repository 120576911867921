<template>
  <div class="molecule-shortlist-placeholder">
    <img :src="url" alt="" />
    <h1>
      At the moment there are <br />
      <span> no promotional items shortlisted</span>
    </h1>
    <span class="molecule-shortlist-placeholder_button">
      <AtomButton
          class="btn-black"
          label="See Promotional Items list"
          :on-click="triggerOpenAvailability"
      />
    </span>
  </div>
</template>

<script>
import AtomButton from "../../atoms/common/AtomButton";
export default {
  name: "MoleculeShortlistPlaceholder",
  components: {AtomButton},
  props: {
    triggerOpenAvailability: {
      required: false,
      type: Function,
      default: () => {
        return;
      },
    },
  },
  computed: {
    url() {
      let base = `${this.$store.getters.cdnBase}/`;
      return `${base}${this.$store.getters.shortlistPlaceholder}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-shortlist-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4rem 2rem;
  gap: 2rem;
  @media only screen and (max-width: 767px) {
    padding: 0 2rem 1rem;
    gap: 1rem;
  }

  h1 {
    font-weight: $regular;
  }
  span {
    font-weight: $bold;
  }
  h1,
  span {
    font-size: 1.75rem;
  }

  &_button {
    margin-top: 1rem;
    width: 100%;
    @media only screen and (max-width: 767px) {
      margin-top: 0;
    }
    button {
      width: 100%;
    }
  }
}
</style>
