<template>
  <div class="organism-request-offer" :class="{ active: open }">
    <MoleculeDynamicModal />
    <form
      @submit="submitForm"
      action="javascript:void(0);"
      id="request-offer"
      ref="request-offer-form"
    >
      <MoleculeRequestOffer>
        <div class="row row-header">
          <h3>Request Offer</h3>
          <div class="close-i" @click="handleClose">
            Close
            <span class="close-button">
              <AtomIcon icon="xmark" />
            </span>
          </div>
        </div>
        <div class="row row-steps align-items-center">
          <div class="col col-adaptive">
            <span
              class="step-number"
              :class="{
                'step-active': activeStep === 'Step 1',
                'step-done': activeStep === 'Step 2',
              }"
            >
              <span v-show="activeStep === 'Step 1'">1</span>
              <span v-show="activeStep === 'Step 2'"
                ><AtomIcon icon="ok"
              /></span>
            </span>
          </div>
          <div class="col col-8">
            <span class="line"></span>
          </div>
          <div class="col col-adaptive">
            <span
              class="step-number"
              :class="{ 'step-active': activeStep === 'Step 2' }"
            >
              <span>2</span>
            </span>
          </div>
        </div>
        <div class="row row-title-steps">
          <div class="col col-xs-12">
            <div class="title-and-description">
              <span class="step">{{ activeStep }}</span>
              <p class="title" v-show="activeStep === 'Step 1'">
                Items requested
              </p>
              <p class="title" v-show="activeStep === 'Step 2'">
                Who is interested
              </p>
              <p class="subtitle" v-show="activeStep === 'Step 2'">
                Please complete the fields below before sending
              </p>
            </div>
          </div>
        </div>
        <div class="row row-packages" v-show="activeStep === 'Step 1'">
          <div class="col col-12 scroll-y">
            <OrganismRequestOfferPackages ref="organismRequestOfferPackages" />
          </div>
        </div>
        <div class="row row-form" v-show="activeStep === 'Step 2'">
          <div class="col col-12 scroll-y">
            <div class="organism-request-offer-form">
              <div class="contact-details">
                <h3>Contact Details</h3>
                <AtomInput
                  :type="inputTypes.TEXT"
                  :value="fullName"
                  @input="fullName = $event"
                  label="Full Name (Required)"
                  placeholder="Enter your first and last name"
                  :error="errors.fullName"
                />
                <AtomInput
                  :type="inputTypes.TEXT"
                  :value="companyName"
                  @input="companyName = $event"
                  label="Company Name (Required)"
                  placeholder="Enter company name"
                  :error="errors.companyName"
                />
                <AtomInput
                  :type="inputTypes.TEXT"
                  :value="email"
                  @input="email = $event"
                  label="Email address (Required)"
                  placeholder="Enter email address"
                  :error="errors.email"
                />
                <AtomInput
                  :type="inputTypes.TEL"
                  :value="phone"
                  @input="phone = $event"
                  label="Phone Number (Required)"
                  placeholder="Enter phone number"
                  :error="errors.phone"
                />
              </div>
              <div class="specific-details">
                <h3>Any specific details regarding your personalized quote?</h3>
                <AtomInput
                  :type="inputTypes.TEXTAREA"
                  :value="moreDetails"
                  @input="moreDetails = $event"
                  label="More details or leave us a message:"
                />
                <AtomCheck
                  id="checkbox-contact"
                  ref="check"
                  class="row-checkbox"
                  label="I consent to Iulius Mall using my personal data for the purpose of delivering products and services and understand that I can withdraw my consent at any time.*"
                  :error="errors.agree"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="buttons-bottom">
          <div class="buttons-left">
            <span
              class="btn-general btn-gray"
              v-show="activeStep === 'Step 1'"
              @click="handleClose"
            >
              <span>Cancel</span>
            </span>
            <span
              class="btn-general btn-gray"
              v-show="activeStep === 'Step 2'"
              @click="handleStepsChange('Step 1')"
            >
              <span>Back</span>
            </span>
          </div>
          <div class="buttons-right">
            <span
              class="btn-general btn-black"
              v-show="activeStep === 'Step 1'"
              @click="handleStepsChange('Step 2')"
            >
              <span>Next Step</span>
            </span>
            <button
              type="submit"
              class="btn-general btn-black"
              value="Request Offer"
              v-show="activeStep === 'Step 2'"
            >
              <span>Request Offer</span>
            </button>
          </div>
        </div>
      </MoleculeRequestOffer>
    </form>
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import MoleculeRequestOffer from "../molecules/MoleculeRequestOffer";
import OrganismRequestOfferPackages from "./OrganismRequestOfferPackages";
import AtomInput, { EnumInputTypes } from "../atoms/common/AtomInput";
import AtomCheck from "../atoms/common/AtomCheck";
import MoleculeDynamicModal from "@/components/molecules/common/MoleculeDynamicModal.vue";

export default {
  name: "OrganismRequestOffer",
  components: {
    MoleculeDynamicModal,
    AtomCheck,
    AtomInput,
    OrganismRequestOfferPackages,
    MoleculeRequestOffer,
    AtomIcon,
  },
  data() {
    return {
      activeStep: "Step 1",
      errors: {},
      fullName: "",
      companyName: "",
      email: "",
      phone: "",
      moreDetails: "",
      inputTypes: EnumInputTypes,
    };
  },
  watch: {
    "$store.state.base.requestOffer.open": function () {
      this.activeStep = "Step 1";
      this.errors = {};
    },
  },
  computed: {
    open() {
      return this.$store.state?.base?.requestOffer?.open || false;
    },
    // @TODO: add type to request offer object
    type() {
      return this.$store.state.project?.requestOfferItems.type || null;
    },
    activeProject() {
      return this.$store.state.project?.activeProject || null;
    },
  },
  methods: {
    handleClose() {
      this.activeStep = "Step 1";
      this.errors = {};
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_REQUEST_OFFER),
        {
          open: !this.open,
        }
      );
    },
    handleStepsChange(step) {
      const requestOffer = this.$store.state.project.requestOfferItems;
      if (
        (requestOffer.type === "digital" &&
          requestOffer.items_ids.length === 0) ||
        (requestOffer.type === "physical" &&
          requestOffer.typesAreas.length === 0)
      ) {
        const modal = this.$store.getters.constants.modal;
        this.$store.dispatch(
          modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
          {
            showModal: true,
            modalType: "dynamic",
            modalData: {
              title: "Warning!",
              text:
                "You don't have any items in your request to move to the next step.",
            },
          }
        );
        return;
      }
      this.activeStep = step;
      this.errors = {};
    },
    async submitForm(e) {
      e.preventDefault();
      if (this.validateInputs() !== null) return;

      let options = { digital: [], physical: [] };
      const requestOffer = this.$store.state.requestOffer;
      const stateBuildings = this.$store.state.project.buildings;
      const projects = this.$store.state.project.projects;

      let payload = {};
      // // if (this.type === "digital") {
      // for (const building of requestOffer.digital.buildings) {
      //   // const buildings = stateBuildings.filter(
      //   //   (building) => building.id === building.id
      //   // );
      //   options.digital.push(building:[...options.digital, ...building.options];
      // }
      // for (const building of requestOffer.physical.buildings) {
      //   // const buildings = stateBuildings.filter(
      //   //   (building) => building.id === building.id
      //   // );
      //   options.physical = [...options.physical, ...building.options];
      // }

      payload = {
        fullName: this.fullName,
        companyName: this.companyName,
        companyAddress: "",
        companyRegNo: "",
        email: this.email,
        phoneNumber: this.phone,
        message: this.moreDetails === "" ? null : this.moreDetails,
        requestOffer,
      };
      // } else {
      //   for (const building of requestOffer.physical.buildings) {
      //     options = [...options, ...building.options];
      //   }
      //   payload = {
      //     fullName: this.fullName,
      //     companyName: this.companyName,
      //     companyAddress: "",
      //     companyRegNo: "",
      //     email: this.email,
      //     phoneNumber: this.phone,
      //     message: this.moreDetails === "" ? null : this.moreDetails,
      //     items: options.map(({ area, items }) => ({
      //       // name: physicalItem.name,
      //       // type: physicalItem.type,
      //       duration: "", // for packs - digital items.
      //       area: area.name, // for physical supports.
      //       // @TODO: insert project id in physical item obj
      //       projectName: "Iulius Mall Cluj",
      //     })),
      //   };
      // }

      const {
        error: serverError,
        result: serverResult,
      } = await this.$request.POST(
        this.$urls.landlord.sendRequestOffer(),
        payload
      );
      if (serverError) {
        alert("Something wrong happened! Please try again!");
        return;
      }
      this.$refs["request-offer-form"].reset();
      this.$refs.check.checked = false;
      this.$refs.organismRequestOfferPackages.$refs.check.checked = false;
      // this.$refs.organismRequestOfferPhysicalItems.$refs.check.checked = false;
      const requestOfferConstants = this.$store.getters.constants.requestOffer;
      const shortlistConstants = this.$store.getters.constants.shortlist;
      await this.$store.dispatch(
        requestOfferConstants.withNamespace(
          requestOfferConstants.action.CLEAR_REQUEST
        ),
        { reset: true }
      );
      await this.$store.dispatch(
        shortlistConstants.withNamespace(
          shortlistConstants.action.CLEAR_SHORTLIST
        )
      );
      Object.assign(this.$data, this.$options.data());

      // show pop-up after request is submitted
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "dynamic",
          modalData: {
            title: "Congratulations!",
            text:
              "Your request has been sent for review. You will be contacted shortly.",
          },
        }
      );
      this.handleClose();
    },
    validateInputs() {
      this.errors = {};
      if (this.fullName === "") {
        this.errors.fullName = "Full Name is required.";
      }
      if (!this.fullName.match(/^[A-Za-z\s]*$/)) {
        this.errors.fullName =
          "Special characters and numbers are not allowed.";
      }
      if (this.companyName === "") {
        this.errors.companyName = "Company name is required.";
      }
      if (!this.companyName.match(/^[A-Za-z\s]*$/)) {
        this.errors.companyName =
          "Special characters and numbers are not allowed.";
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (
        this.phone === "" ||
        !this.phone.length >= 10 ||
        !this.phone.match(/^[+]?\d{10,13}$/)
      ) {
        this.errors.phone = "Phone Number is not valid.";
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = "Please agree to the Terms and Conditions first.";
      }
      if (Object.keys(this.errors).length === 0) {
        return null;
      }
      return this.errors;
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss">
.organism-request-offer {
  position: absolute;
  top: 0;
  bottom: 0;
  background: $white;
  width: 30rem;
  right: -40rem;
  z-index: 99999;
  transition: all 0.5s ease-in-out 0s;
  padding: 2rem 1.5rem;
  box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.05);
  overscroll-behavior: contain;
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 6rem 1.5rem 1rem;
    box-shadow: none;
  }
  &.active {
    right: 0;
  }
  .row-header {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    h3 {
      @include font-main(1.25rem, $black, $regular, 1.5rem);
    }
  }
  .row-steps {
    background: #f5f7f8;
    border-radius: 6.25rem;
    padding: 0.375rem;
    text-align: center;
    margin-bottom: 1.5rem;
    .col {
      align-items: center;
      &:first-child {
        align-items: start;
      }
      &:last-child {
        align-items: end;
      }
    }
    .step-number {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        background: $white;
        @include font-main(1.313rem, $black, $bold, 1.5rem);
        .icon {
          font-size: 1rem !important;
        }
      }
      &.step-active {
        span {
          background: $black;
          color: $white;
        }
      }
      &.step-done {
        span {
          background: #23cd9a;
          .icon {
            color: $white !important;
          }
        }
      }
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 0.063rem;
      background: rgba(0, 0, 0, 0.2);
    }
  }
  .row-title-steps {
    justify-content: center;
    .title-and-description {
      text-align: center;
      .step {
        text-transform: uppercase;
        @include font-main(0.875rem, $black, $bold, 1.063rem);
      }
      .title {
        margin-bottom: 0.5rem;
        @include font-main(2rem, $black, $regular, 2.375rem);
      }
      .subtitle {
        margin-bottom: 1rem;
        @include font-main(1rem, $blackOpacity06, $regular, 1.188rem);
      }
    }
  }
  .row-packages {
    margin: 0 -1.5rem;
    .scroll-y {
      height: calc(100vh - 22rem);
      padding: 0 1.5rem;
      overflow-y: auto;
      overflow-x: hidden;
      overscroll-behavior: contain;
      @media only screen and (max-width: 767px) {
        padding: 0 0.5rem;
        height: calc(100vh - 23rem);
      }
    }
  }
  .row-form {
    margin: 0 -0.625rem;
    .scroll-y {
      height: calc(100vh - 22rem);
      padding: 0 0.625rem;
      overflow-y: auto;
      overscroll-behavior: contain;
      @media only screen and (max-width: 767px) {
        height: calc(100vh - 25rem);
        padding: 0 0.5rem;
      }
    }
    .organism-request-offer-form {
      margin-top: 1rem;
      .contact-details {
        margin-bottom: 1rem;
      }
      h3 {
        margin-bottom: 1.25rem;
        @include font-main(1.25rem, $black, $regular, 1.5rem);
      }
      .input-atom-wrapper {
        margin-bottom: 0.75rem;
      }
    }
  }
  .buttons-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.5rem;
    @media only screen and (max-width: 767px) {
      padding: 1rem;
    }
    .btn-general {
      font-weight: $medium;
    }
  }
  .close-i {
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      position: absolute;
      right: 0;
      top: -4.3rem;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
      @include font-main(1rem, $blackOpacity06, $regular, 1.188rem);
    }
    .close-button {
      display: inline-block;
      vertical-align: middle;
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      line-height: 3.1rem;
      text-align: center;
      background: rgba(2, 2, 2, 0.08);
      margin-left: 1.25rem;
      .icon {
        font-size: 1.125rem !important;
      }
    }
  }
}
</style>
