<template>
  <div class="molecule-multiple-options">
    <div class="header-container">
      <div class="header-title">
        <span>{{ title }}</span>
      </div>
      <div class="header-button">
        <AtomButton
          class="rounded-button"
          v-if="internalValue.length > 0"
          label="Clear All"
          :on-click="handleClearAll"
        />
      </div>
    </div>
    <div class="list-content" v-if="options.length > 0">
      <AtomOption
        ref="atomOptionBtn"
        v-for="(o, idx) in options"
        :key="`option-${idx}`"
        :option="o"
        :value="internalValue.includes(o.id)"
        @input="handleClick($event, o.id)"
      />
    </div>
  </div>
</template>

<script>
import AtomButton from "../../../atoms/common/AtomButton";
import AtomOption from "../../../atoms/common/AtomOption";

export default {
  name: "MoleculeMultipleOptions",
  components: { AtomOption, AtomButton },
  props: {
    title: {
      required: true,
      type: String,
      default: "Title",
    },
    options: {
      required: true,
      type: Array,
      default: () => [],
    },
    value: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      lazyValue: this.value,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue;
      },
      set(val) {
        this.lazyValue = val;
        this.$emit("input", val);
      },
    },
  },
  methods: {
    handleClearAll() {
      this.internalValue = [];
    },
    handleClick(val, opt) {
      let newValue = [...this.lazyValue];
      if (val) {
        newValue.push(opt);
      } else {
        newValue = newValue.filter((v) => v !== opt);
      }

      this.internalValue = newValue;
    },
  },
  watch: {
    value(val) {
      this.lazyValue = val;
    },
  },
};
</script>

<style lang="scss">
.molecule-multiple-options {
  padding: 0.625rem 0;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.625rem;
    min-height: 1.5rem;
    .header-title {
      padding: 0;
      font-weight: $medium;
    }
    .header-button {
      .rounded-button {
        padding: 0.125rem 0.75rem;
        gap: 0.625rem;
        border: none;
        border-radius: 18.75rem;
        background: $darkerWhite;
        transition: all 0.5s ease-in-out;
        span {
          text-transform: none;
          font-weight: $regular;
          color: $black;
        }
        &:hover {
          background: $black;
          span {
            color: $white;
          }
        }
      }
    }
  }
  .list-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
