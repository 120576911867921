<template>
  <div v-if="projects.length > 1">
    <div class="separator"></div>
    <div class="subtitle">Projects</div>
    <a
        v-for="(project, i) in projects" :key="i"
        class="sidebar-link"
        :class="{'disabled': isProjectDisabled(project)}"
        :href="`/project/${project.slug}`"
    >
      {{ project.name}}
    </a>
  </div>
</template>

<script>

export default {
  name: "OrganismProjectLinks",
  components: {},
  data() {
    return {
      isScrolled: false,
    }
  },
  computed: {
    projects() {
      return this.$store.state?.project?.projects || [];
    },
    buildings() {
      return this.$store.state.project?.buildings || [];
    },
  },
  methods: {
    isProjectDisabled(project) {
      let isDisabled = false;
      const projectBuildings = this.buildings.filter((b) => b.project_id === project.id) || [];
      for (const b of projectBuildings) {
        if (!b.is_available) {
          isDisabled = true;
        }
      }
      return isDisabled;
    }
  }
}
</script>

<style lang="scss">

</style>