var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-popover", { on: { show: _vm.handleWidth } }, [
    _c("div", { staticClass: "label-popover" }, [
      _vm._v(_vm._s(_vm.placeholder))
    ]),
    _c(
      "div",
      { ref: "input-show", staticClass: "drop-down-filter-container" },
      [
        _vm.inputVal.length
          ? _c("div", [
              _vm._v("\n      " + _vm._s(_vm.placeholder) + "\n      "),
              _c("span", { staticClass: "indicator" }, [
                _vm._v(_vm._s(_vm.inputVal.length))
              ]),
              _c(
                "span",
                { staticClass: "clear-all", on: { click: _vm.clearFilter } },
                [_c("AtomIcon", { attrs: { icon: "xmark" } })],
                1
              )
            ])
          : _c("span", [_vm._v("\n      All\n    ")]),
        _c("span", { staticClass: "arrow-filter" }, [_c("b")])
      ]
    ),
    _c("div", { attrs: { slot: "popover" }, slot: "popover" }, [
      _c(
        "div",
        { ref: "popover-wrapper", staticClass: "popover-wrapper" },
        _vm._l(_vm.options, function(option) {
          return _c(
            "div",
            { key: option.value, staticClass: "dropdown-option" },
            [
              _c("AtomCheck", {
                attrs: {
                  label: option.text,
                  "is-checked": _vm.inputVal.includes(option.value)
                },
                on: {
                  input: function($event) {
                    return _vm.handleInput(option.value, $event)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }