var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-sidebar-results" }, [
    Object.entries(_vm.shortlistGroupedItems).length > 0
      ? _c(
          "div",
          { staticClass: "bottom-content" },
          _vm._l(Object.entries(_vm.shortlistGroupedItems), function(ref) {
            var parentKey = ref[0]
            var typeOfItems = ref[1]
            return _c(
              "div",
              { key: "type-" + parentKey, staticClass: "package-category" },
              [
                _vm.showCategory(typeOfItems.buildings)
                  ? _c("h2", { staticClass: "center-category" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            parentKey.charAt(0).toUpperCase() +
                              parentKey.slice(1)
                          ) +
                          " items\n      "
                      )
                    ])
                  : _vm._e(),
                _vm.showCategory(typeOfItems.buildings)
                  ? _c(
                      "div",
                      _vm._l(typeOfItems.buildings, function(building, idx) {
                        return _c("AtomCollapsibleItem", {
                          key: "building_" + idx,
                          ref: "building_" + idx,
                          refInFor: true,
                          staticClass: "parent-box",
                          attrs: { "is-default-open": true },
                          scopedSlots: _vm._u(
                            [
                              _vm.getBuildingName(building.id)
                                ? {
                                    key: "title",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "title-box" },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "title-text" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.getBuildingName(
                                                        building.id
                                                      )
                                                    ) +
                                                    "\n              "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null,
                              {
                                key: "content",
                                fn: function() {
                                  return _vm._l(
                                    Object.entries(building.options),
                                    function(ref) {
                                      var optionType = ref[0]
                                      var options = ref[1]
                                      return _c("AtomCollapsibleItem", {
                                        key: "option_" + optionType,
                                        ref: "option_" + optionType,
                                        refInFor: true,
                                        staticClass: "packs",
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "title",
                                              fn: function() {
                                                return [
                                                  _c("div", {}, [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "package-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              optionType
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                optionType.slice(
                                                                  1
                                                                )
                                                            ) +
                                                            "\n                    Package\n                  "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "package-title-length"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              options.length
                                                            ) +
                                                            "\n                    " +
                                                            _vm._s(
                                                              options.length > 1
                                                                ? "Options"
                                                                : "Option"
                                                            ) +
                                                            "\n                  "
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "content",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "location-info"
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          "You can view the locations of the screens by tapping /\n                    clicking on them"
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _vm._l(options, function(
                                                    opt
                                                  ) {
                                                    return _c(
                                                      "AtomCollapsibleItem",
                                                      {
                                                        key:
                                                          "item_" +
                                                          opt.unique_key,
                                                        ref:
                                                          "item_" +
                                                          opt.unique_key,
                                                        refInFor: true,
                                                        staticClass:
                                                          "pack-options",
                                                        attrs: { item: opt },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "title",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "div",
                                                                    {},
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "display-inline"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn-trash-item",
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.removeFromShortlist(
                                                                                    parentKey,
                                                                                    building.id,
                                                                                    opt.unique_key
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "AtomIcon",
                                                                                {
                                                                                  attrs: {
                                                                                    icon:
                                                                                      "trash-can"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      ),
                                                                      opt.duration
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "package-option-title"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                        " +
                                                                                  _vm._s(
                                                                                    opt.duration
                                                                                  ) +
                                                                                  "\n                        Seconds\n                      "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : opt.area &&
                                                                          opt
                                                                            .area
                                                                            .name
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "package-option-title"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                        " +
                                                                                  _vm._s(
                                                                                    opt
                                                                                      .area
                                                                                      .name
                                                                                  ) +
                                                                                  "\n                      "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      opt.duration
                                                                        ? _c(
                                                                            "AtomIcon",
                                                                            {
                                                                              staticClass:
                                                                                "time-icon",
                                                                              attrs: {
                                                                                icon:
                                                                                  "timer-v2"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e(),
                                                                      opt.hourly_broadcasts
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "package-broadcasts"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                        " +
                                                                                  _vm._s(
                                                                                    opt.hourly_broadcasts
                                                                                  ) +
                                                                                  " broadcasts/hour\n                      "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key: "content",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "MoleculeTableItem",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          opt.items,
                                                                        "option-id":
                                                                          opt.unique_key
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "shortlist-remove-item-btn"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "button",
                                                                        {
                                                                          staticClass:
                                                                            "btn-general btn-white hover-red icon-left font-medium",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.removeFromShortlist(
                                                                                parentKey,
                                                                                building.id,
                                                                                opt.unique_key
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "AtomIcon",
                                                                            {
                                                                              attrs: {
                                                                                icon:
                                                                                  "remove-shortlist"
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Remove Item"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      }
                                                    )
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    }
                                  )
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      }),
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "shortlist-request-offer-btn" },
      [
        _c("AtomButton", {
          staticClass: "btn-black icon-left font-bold",
          attrs: {
            label: "Request Offer",
            "custom-label": "Request",
            "on-click": _vm.requestOffer,
            "activate-hover-event": ""
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }