var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MoleculeContentSlider3",
    { attrs: { id: _vm.id } },
    _vm._l(_vm.sliderContentData, function(sliderContent, index) {
      return _c("AtomContentSlider3", { key: index }, [
        _c("div", { staticClass: "row row-slider-info align-items-center" }, [
          _c("div", { staticClass: "col col-xs-12 col-6" }, [
            _c("img", {
              attrs: {
                src: _vm.resourceUrl(sliderContent.image),
                alt: sliderContent.title
              }
            })
          ]),
          _c("div", { staticClass: "col col-xs-12 col-6" }, [
            _c(
              "div",
              { staticClass: "content-slider" },
              [
                sliderContent.title
                  ? _c("p", {
                      staticClass: "title",
                      domProps: { innerHTML: _vm._s(sliderContent.title) }
                    })
                  : _vm._e(),
                sliderContent.lines
                  ? _c(
                      "div",
                      { staticClass: "subtitle-info" },
                      _vm._l(sliderContent.lines, function(line, index) {
                        return _c("p", {
                          key: index,
                          staticClass: "subtitle",
                          domProps: { innerHTML: _vm._s(line) }
                        })
                      }),
                      0
                    )
                  : _vm._e(),
                sliderContent.statisticsLines
                  ? _c(
                      "div",
                      { staticClass: "statistics-info" },
                      _vm._l(sliderContent.statisticsLines, function(
                        statistic,
                        index
                      ) {
                        return _c("AtomNumberAndText", {
                          key: index,
                          attrs: {
                            title: statistic.numberText,
                            subtitle: statistic.text
                          }
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                sliderContent.table
                  ? _c("OrganismTable", {
                      attrs: { "table-data": sliderContent.table }
                    })
                  : _vm._e(),
                _c("div", { staticClass: "buttons-bottom" }, [
                  sliderContent.buttonUrl
                    ? _c(
                        "div",
                        { staticClass: "display-inline" },
                        [
                          _c("AtomButtonLink", {
                            attrs: {
                              label: "" + sliderContent.buttonText,
                              href: "" + sliderContent.buttonUrl
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "div",
                        { staticClass: "display-inline" },
                        [
                          _c("AtomButton", {
                            staticClass: "btn-disabled",
                            attrs: { label: "" + sliderContent.buttonText }
                          })
                        ],
                        1
                      ),
                  sliderContent.buttonBrochureText
                    ? _c(
                        "div",
                        { staticClass: "display-inline" },
                        [
                          _c("AtomButtonLink", {
                            staticClass: "btn-general-all-black",
                            attrs: {
                              label: "" + sliderContent.buttonBrochureText,
                              href: "" + sliderContent.buttonBrochureUrl
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ])
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }