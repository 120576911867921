<template>
  <div class="atom-number-and-text">
    <AtomTitle :title="title" :subtitle="subtitle" />
  </div>
</template>

<script>
import AtomTitle from "@/components/atoms/common/AtomTitle";

export default {
  name: "AtomNumberAndText",
  components: {AtomTitle},
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
  .atom-number-and-text {
    .atom-title {
      h2 {
        font-weight: $medium;
        font-size: 3rem;
        line-height: 3.563rem;
        margin-bottom: 0.25rem;
        color: $second-color;
        @media only screen and (max-width: 767px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
        span {
          font-weight: $regular;
          font-size: 1.5rem;
          line-height: 1.813rem;
          letter-spacing: -0.02em;
          text-transform: none;
          @media only screen and (max-width: 767px) {
            font-size: 1.25rem;
            line-height: 1.5rem;
          }
        }
      }
      h3 {
        font-weight: $regular;
        font-size: 1.125rem;
        line-height: 1.313rem;
        letter-spacing: -0.02em;
        color: $second-color;
        opacity: 0.5;
        @media only screen and (max-width: 767px) {
          font-size: 1.125rem;
          line-height: 1.313rem;
        }
      }
    }
  }
</style>