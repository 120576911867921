<template>
  <div class="loader-element">
    <div class="percent-content">
      <p>Loading 3D assets, please don’t close the page</p>
      <span class="number-percent">{{ percentage }}%</span>
      <span class="line-main-color"></span>
    </div>
    <span class="line-percent" :style="{ width: `${percentage}%` }"></span>
  </div>
</template>

<script>
import buildingStore from "../../organisms/project/building/store";
export default {
  name: "AtomLoaderLoadingBar",
  components: {},
  data() {
    return {
      percentage: 0,
      seconds: 30,
      maxPercentage: 90,
      interval: 0,
      closeInterval: 0,
      closeTimeRemaining: 0,
      startedCountdown: false,
    }
  },
  computed: {
    buildingStore() {
      return buildingStore;
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      const percentageChange = Math.floor(Math.random() * Math.round(this.maxPercentage / this.seconds) + 1);
      let percentage = this.percentage;
      percentage += percentageChange;
      if (percentage > this.maxPercentage) {
        this.percentage = 99;
        clearInterval(this.interval);
        this.interval = 0;
        // this.startCloseCountdown();
        return;
      }
      this.percentage = percentage;
    }, 1000);
    this.buildingStore.subscribe((payload, state) => {
      if (state.finishedLoadingAssets && this.percentage < 100) {
        clearInterval(this.interval);
        this.interval = 0;
        this.percentage = 100;
        this.startCloseCountdown();
      }
    });
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.closeInterval);
  },
  methods: {
    closeLoadingScreen() {
      this.buildingStore.dispatch('changeLoadingScreenStatus', false);
      clearInterval(this.interval);
      clearInterval(this.closeInterval);
    },
    startCloseCountdown() {
      this.closeInterval = setInterval(() => {
        this.closeLoadingScreen();
        clearInterval(this.closeInterval);
        this.closeInterval = 0;
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader-element {
    position: absolute;
    width: 100%;
    height: 100%;
    .percent-content {
      position: absolute;
      top: 6.563rem;
      left: 3.75rem;
      z-index: 5;
      @media only screen and (max-width: 767px) {
        top: 19%;
        left: 0;
        padding: 0 2rem;
      }
      p {
        opacity: 0.6;
        margin-bottom: 0.313rem;
        @include font-main(1rem, $white, $regular, 1.188rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.25rem;
          font-size: 0.75rem;
          line-height: 0.938rem;
        }
      }
      .number-percent {
        display: block;
        margin-bottom: 1rem;
        @include font-main(6.25rem, $white, $bold, 7.438rem);
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.313rem;
          font-size: 2.5rem;
          line-height: 2.813rem;
        }
      }
      .line-main-color {
        display: block;
        width: 6.25rem;
        height: 0.063rem;
        background: $second-color;
        @media only screen and (max-width: 767px) {
          width: 6.25rem;
        }
      }
    }
    .line-percent {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background: $second-color;
      opacity: 0.5;
    }
  }
</style>