import { Vector3 } from "babylonjs";

export enum SCENE_VIEW {
  TWO_DIMENSIONAL,
  DEFAULT,
  FIRST_PERSON,
}

export default {
  ENABLE_PIPELINE: false,
  DO_NOT_SYNC_BB: true,
  MESH_LAYER_MASK: 0x10000000,
  VIRTUAL_TOUR_CAMERA_MASK: 0x20000000,
  CAMERA_3D_LAYER_MASK: 0x30000000,
  MINIMAP_LAYER_MASK: 0x40000000,
  UPPER_BETA_LIMIT: 1.5708,
  PANNING_SENSIBILITY: 40,
  HIGHLIGHT_ALPHA: (3 * Math.PI) / 2,
  VIRTUAL_TOUR_CAMERA_SPEED: 0.5,
  CAMERA_DEFAULT_FOV: 0.45,
  ANGULAR_SENSIBILITY_MOBILE: 1000,
  ANGULAR_SENSIBILITY_DESKTOP: 2000,
  PIN_TEXTURE: "/images/projects/sthlm/360/plus-sign.jpg",
  POINTER_CIRCLE_TEXTURE: "/images/vt-pointer.png",
  POINTER_POSITIONY: 25,
  CONTINUOUS_RENDERING: true,
  MAX_RENDER_TIME: 100,
  ADAPT_TO_DEVICE_RATIO: false,
  POINTER_CIRCLE_SIZE: 0.6,
  WALKABLE_FLOOR_NAME: "iuliuscluj_walkable_floors",
  ENABLE_MINIMAP: false,
  CAMERA_3D_VIEW_DEFAULT_VALUES: {
    alpha: 1,
    beta: 1,
    radius: 100,
    target: Vector3.Zero(),
  },
  FLOOR_LEVEL_Y: [-2, 2, 8.6],
  PIN_POP_UP_OFFSET: [28, 32],
  HIGHLIGHT_ZOOM: 100,
  RENDER_ID_TOP: 3,
  MULTIPLE_CEILINGS: false,
  MIN_Z_FLOOR_ARC_ROTATE: 10,
  MIN_Z_FLOOR_UNIVERSAL: 0,
  CAMERE_YOFFSET_PORTRAIT_MOBILE: 10,
  SPACE_VISIBILITY: 0.25,
  PIN_STYLES: {
    backgroundColor: "rgba(0,0,0,0.5)",
    backgroundColorActive: "rgba(0,0,0,1)",
    color: "white",
    fontFamily: "Effra",
    fontSize: 12,
    fontWeight: "500",
  },
  DEBUG_PINS: false,
  SKYBOX_NAME: "SkyBox",
  PIN_NAME: "Pin",
  MINIMAP_PLANE_NAME: "minimapPlane",
};

export const PIN_ICON = {
  plus: `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.0938 6.875C13.0938 7.28516 12.7715 7.57812 12.3906 7.57812H7.70312V12.2656C7.70312 12.6758 7.38086 12.998 7 12.998C6.58984 12.998 6.29688 12.6758 6.29688 12.2656V7.57812H1.60938C1.19922 7.57812 0.90625 7.28516 0.90625 6.9043C0.90625 6.49414 1.19922 6.17188 1.60938 6.17188H6.29688V1.48438C6.29688 1.10352 6.58984 0.810547 7 0.810547C7.38086 0.810547 7.70312 1.10352 7.70312 1.48438V6.17188H12.3906C12.7715 6.17188 13.0938 6.49414 13.0938 6.875Z" fill="white"/>
        </svg>`,
  line: `<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 27L27 1" stroke="black" stroke-opacity="0.6"/>
        </svg>`,
};
