var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-projects": true }),
        (_obj["section-" + _vm.hash] = true),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("OrganismContentSlider3", {
        attrs: { "slider-content-data": _vm.data.slider, id: _vm.data.id }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }