<template>
  <div
    id="header-project"
    ref="menu"
    :class="{ 'organism-menu-bar': true, scrolled: isScrolled }"
  >
    <div class="row align-items-center menu-bar-content">
      <div class="block-element">
        <div class="inline-elements">
          <MoleculeMenuTrigger />
        </div>
        <div class="inline-elements">
          <div class="logo-header" @click="scrollTopMethod">
            <router-link tag="div" :to="{ name: 'Home' }">
              <AtomLogo />
            </router-link>
          </div>
        </div>
        <div class="inline-elements hidden-xs" v-if="showProjectSelector">
          <AtomDropdown />
        </div>
        <div class="inline-elements hidden-xs" v-if="showProjectSelector">
          <AtomFloorSelector />
        </div>
      </div>
      <div class="block-element">
        <div class="inline-elements">
          <div class="menu">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../helpers/util";
import MoleculeMenuTrigger from "@/components/molecules/MoleculeMenuTrigger";
import AtomDropdown from "../atoms/common/AtomDropdown";
import AtomFloorSelector from "../atoms/project/AtomFloorSelector";

export default {
  name: "OrganismMenuBar",
  components: {
    AtomFloorSelector,
    AtomDropdown,
    MoleculeMenuTrigger,
    AtomLogo,
  },
  data() {
    return {
      isScrolled: false,
    };
  },
  computed: {
    showProjectSelector() {
      const { name } = this.$route;
      return name == "SpecificProject";
    },
  },
  mounted() {
    this.isScrolled = window.scrollY > 40;
    document.addEventListener("scroll", this.initScrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.initScrollListener);
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    },
    scrollTopMethod() {
      const sections = this.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[0];
        const targetString = convertUCWordsOrCamelCaseToDashCase(
          targetSection.type
        );
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar {
  position: fixed;
  top: 1.5rem;
  left: 2rem;
  border-radius: 6.25rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  z-index: 9999;
  pointer-events: none;
  display: flex;
  background: $white;
  @media only screen and (max-width: 767px) {
    padding: 0.188rem 1.25rem 0.188rem 0.188rem;
  }
  .menu-bar-content {
    pointer-events: all;
  }
  .rest-fix {
    pointer-events: none;
    flex: 1;
  }
  &.scrolled {
    background: $white;
    box-shadow: 0 0 0.063rem 0.063rem #f7f7f7;
  }
  .inline-elements {
    display: inline-block;
    vertical-align: middle;
  }
  .atom-logo {
    margin: 0.25rem 1.25rem 0;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0 1.25rem;
    }
    img {
      display: block;
      max-height: 4rem;
      max-width: 9.25rem;
      @media only screen and (max-width: 767px) {
        max-height: 3.25rem;
      }
    }
  }
  .link-atom {
    background: rgba(58, 189, 89, 0.2);
    border-color: transparent;
    padding: 0.875rem 1.25rem;
    margin-right: 0.625rem;
    @media only screen and (max-width: 767px) {
      padding: 0.5rem 0.625rem;
      max-width: 9.063rem;
    }
    span {
      color: #3abd59;
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      @media only screen and (max-width: 767px) {
        font-size: 0.75rem;
        line-height: 0.938rem;
      }
    }
    &:hover {
      background: #3abd59;
      border-color: transparent;
      @media only screen and (max-width: 1200px) {
        background: rgba(58, 189, 89, 0.2);
      }
      span {
        color: $white;
        @media only screen and (max-width: 1200px) {
          color: #3abd59;
        }
      }
    }
    &.active {
      background: #3abd59 !important;
      border-color: transparent !important;
      span {
        color: $white !important;
      }
    }
  }
  .menu {
    margin-top: -0.375rem;
    .sidebar-link {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.063rem;
      letter-spacing: 0.04em;
      color: $black;
      text-decoration: none;
      margin: 0 0.125rem;
      height: 2.5rem;
      padding: 0.5rem;
      transition: all 0.2s ease-in-out 0s;
      border-radius: 0.188rem;
      &:hover,
      &.active {
        color: $white;
        background-color: $black;
      }
    }
  }
}
</style>
