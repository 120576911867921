<template>
  <div
    id="shortlist-rules-popup"
    class="molecule-shortlist-rules"
    v-if="showModal && modalType === 'shortlist-rules'"
  >
    <div class="popup-screen">
      <span class="btn-close-popup" @click="closeModal">
        <AtomIcon icon="xmark" />
      </span>
      <div class="row shortlist-rules">
        <div class="col col-12">
          <div class="scroll-disable">
            <div class="molecule-shortlist-rules-container">
              <AtomIcon icon="warning-icon" />
              <h1 class="molecule-shortlist-rules-title">Shortlist Rules</h1>
              <p>
                At the moment you can only shortlist one type of media: digital
                or physical. You can send multiple request if you are interested
                in both types.
              </p>
              <h2>How do you wish to proceed?</h2>
              <div class="molecule-shortlist-rules-buttons">
                <AtomButton
                    class="btn-white"
                    label="Cancel"
                    :on-click="closeModal"
                />
                <AtomButton
                    class="btn-black"
                    label="Replace"
                    :on-click="replace"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EnumInputTypes } from "../../atoms/common/AtomInput";
import AtomIcon from "../../atoms/common/AtomIcon";
import config from "@/config";
import AtomButton from "../../atoms/common/AtomButton";

export default {
  name: "MoleculeShortlistRulesModal",
  components: {AtomButton, AtomIcon },
  data() {
    return {
      errors: {},
      inputTypes: EnumInputTypes,
    };
  },
  computed: {
    showModal() {
      return this.$store.state.modal.showModal;
    },
    modalType() {
      return this.$store.state.modal.modalType;
    },
    modalData() {
      return this.$store.state.modal.modalData;
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    client() {
      return config.CLIENT;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
  },
  methods: {
    closeModal() {
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(modal.withNamespace(modal.action.CLOSE_MODAL));
      document.body.classList.remove("disable-scroll");
    },
    replace() {
      this.$store.dispatch("project/replaceShortlist", this.modalData);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.molecule-shortlist-rules {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 999990;
  background: rgba(0, 0, 0, 0.5);
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
  }

  &#shortlist-rules-popup {
    display: block;
  }
  .input-atom-wrapper {
    margin-bottom: 0.938rem;
    flex: none;
    input,
    textarea {
      margin-bottom: 0.313rem;
    }
  }
  &-container {
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &-title {
    font-size: 2em;
    font-weight: 700;
  }
  &-buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .popup-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 90%;
    z-index: 999999;
    background: #F4F6F7;
    box-shadow: 0 0 0.625rem $blackOpacity012;
    overflow: hidden;
    padding: 3.313rem 2.5rem 2.25rem;
    width: 41.25rem;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      width: 90%;
    }
    &.small-popup-screen {
      width: 30%;
      height: auto;
      min-height: 20%;
      @media only screen and (max-width: 1200px) {
        min-height: 10%;
      }
      @media only screen and (max-width: 767px) {
        min-height: 0;
        width: 80%;
      }
      .content-center {
        text-align: center;
        margin: 0.875rem 1.25rem;
        h3 {
          font-size: 1rem;
          line-height: 1.25rem;
        }
        .title-subtitle {
          margin-bottom: 1.25rem;
        }
      }
    }
    .btn-close-popup {
      position: absolute;
      right: 2rem;
      top: 2.563rem;
      padding: 0;
      width: 3rem;
      height: 3rem;
      line-height: 3.23rem;
      border-radius: 3rem;
      display: block;
      z-index: 99;
      text-align: center;
      cursor: pointer;
      background: rgba(2, 2, 2, 0.08);
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        right: 0.5rem;
        top: 0.5rem;
      }
      .icon {
        font-size: 1.125rem !important;
      }
      &:hover {
        background: $black;
        .icon {
          color: $white !important;
        }
      }
    }
    .shortlist-rules {
      height: 100%;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      overflow-y: auto;
      .scroll-disable {
        max-width: 100%;
        .col-left-popup {
          padding-right: 2.188rem;
        }
        .col-right-popup {
          padding-left: 2.188rem;
          padding-bottom: 3.438rem;
          .buttons-bottom {
            margin-top: 3.438rem;
            a {
              margin-right: 1.25rem;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .required {
          margin-bottom: 1.25rem;
          @include font-main(0.75rem, #736f6f, $bold, 1rem);
        }
        .spaces-selected-result {
          padding: 0 0.313rem 0 0;
          max-height: 4.063rem;
          min-height: 4.063rem;
          overflow: hidden;
          overflow-y: auto;
          margin-bottom: 1.25rem;
          position: relative;
          width: 100%;
          display: inline-block;
        }
        .space-floor-selected {
          cursor: initial;
          margin-bottom: 0.938rem;
          h6 {
            display: block;
            @include font-main(1rem, $black, $semiBold, 1.188rem);
            margin-bottom: 0.938rem;
            text-transform: uppercase;
          }
          span {
            display: inline-block;
            @include font-main(0.75rem, rgba(0, 0, 0, 0.6), $semiBold, 1rem);
            text-transform: uppercase;
            margin-right: 1.563rem;
            b {
              font-size: 0.75rem;
              line-height: 1rem;
            }
            &:last-child {
              margin-right: 0;
            }
            span {
              margin: -0.063rem 0 0;
              color: $black;
              display: inline-block;
              vertical-align: middle;
            }
          }
          &:hover {
            &:before {
              opacity: 0;
            }
          }
          .hoverButtons {
            display: none;
          }
        }
        .row-floor {
          cursor: initial;
          margin-bottom: 1.25rem;
          h6 {
            display: block;
            @include font-main(1rem, $black, $semiBold, 1.188rem);
            margin-bottom: 0.938rem;
            text-transform: uppercase;
          }
          span {
            display: inline-block;
            @include font-main(0.75rem, rgba(0, 0, 0, 0.6), $semiBold, 1rem);
            text-transform: uppercase;
            margin-right: 1.563rem;
            &:last-child {
              margin-right: 0;
            }
            span {
              margin: 0;
              color: $black;
            }
          }
          &:hover {
            &:before {
              opacity: 0;
            }
          }
          .hoverButtons {
            display: none;
          }
        }
        h6 {
          display: inline-block;
          vertical-align: bottom;
          @include font-main(0.75rem, $black, $bold, 1rem);
        }
        .title-subtitle {
          margin-top: 1.875rem;
          margin-bottom: 1.25rem;
          &.mt-0 {
            margin-top: 0;
          }
          h3 {
            margin-bottom: 0.313rem;
            text-transform: uppercase;
            @include font-main(1rem, $black, $semiBold, 1.188rem);
          }
          p {
            @include font-main(0.813rem, $black, $bold, 1.25rem);
          }
        }
        h4 {
          margin-bottom: 0.938rem;
          @include font-main(0.813rem, $black, $bold, 1.25rem);
        }
        .input-style {
          width: 100%;
          margin-bottom: 1.25rem;
          textarea {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: 7.5rem;
            min-height: 7.5rem;
            max-height: 7.5rem;
            padding: 0.313rem 0.625rem;
          }
        }
        .atom-radio-box {
          display: block;
          margin-bottom: 1.25rem;
        }
        .hide-select {
          display: none;
          padding: 0.313rem 1.375rem 0.625rem;
          &.active {
            display: block;
          }
        }
      }
      .molecule-shortlist-rules-container {
        margin: 0;
        display: block;
        .icon {
          display: block;
          font-size: 1.5rem !important;
          color: #E10000 !important;
          margin-bottom: 0.75rem;
          &:before {
            margin: 0;
          }
        }
        .molecule-shortlist-rules-title {
          margin-bottom: 1.5rem;
          @include font-main(2rem, $black, $bold, 2.375rem);
        }
        p {
          margin-bottom: 0.75rem;
          @include font-main(1.125rem, $black, $regular, 1.625rem);
        }
        h2 {
          margin-bottom: 3.5rem;
          @include font-main(1.375rem, $black, $medium, 1.625rem);
          @media only screen and (max-width: 767px) {
            margin-bottom: 2rem;
          }
        }
      }
      .molecule-shortlist-rules-buttons {
        .btn-general {
          width: 11.875rem;
          padding: 1.18rem 1rem;
          @media only screen and (max-width: 767px) {
            width: auto;
            padding: 0.6rem 1rem;
          }
        }
      }
    }
  }
}
</style>
