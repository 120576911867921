<template>
  <div class="organism-drawer">
    <div :class="{ 'drawer-content': true, open: isOpen }">
      <div class="close-button" @click="$emit('drawer:close')" v-if="isOpen">
        <AtomIcon icon="xmark" color="#fff" :size="20"/>
      </div>
      <div class="drawer-content-wrapper" :style="{'height': `calc(${windowHeight}px - 70px)`}">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

import AtomIcon from "../../atoms/common/AtomIcon";
export default {
  name: "OrganismDrawer",
  components: { AtomIcon },
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      isOpen: this.defaultOpen,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0
    };
  },
  methods: {
    toggleFilter() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
    }
  }
};
</script>

<style lang="scss">
.organism-drawer {
  .close-button {
    display: none;
    cursor: pointer;
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    background: $black;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out 0s;
    z-index: 999;
    .icon {
      font-size: 1rem !important;
    }
    @media only screen and (max-width: 767px) {
      display: flex;
    }
  }
  .drawer-buttons {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 3rem;
    display: flex;
    flex-direction: column;
    transition: 0.5s ease-in-out 0s;
    z-index: 999999;
    &.active {
      right: 31rem;
    }
    .drawer-button {
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      background: $black;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.5s ease-in-out 0s;
      z-index: 999;
      .icon {
        font-size: 0.9rem !important;
        &:before {
          margin: 0;
          width: auto;
        }
        &.icon-xmark {
          display: none;
          font-size: 1.3rem !important;
        }
      }
      &.active {
        right: 23.938rem;
        background: rgba(0, 0, 0, 0.5);
        @media only screen and (max-width: 767px) {
          right: 2rem;
        }
        .icon {
          &.icon-xmark {
            display: block;
          }
          &.icon-filter-list {
            display: none;
          }
        }
      }
      &:hover {
        background: rgba(0, 0, 0, 0.9);
        @media only screen and (max-width: 1200px) {
          background: $black;
        }
        .icon {
          color: $white !important;
          @media only screen and (max-width: 1200px) {
            color: $white !important;
          }
        }
      }
    }
  }

  .drawer-content {
    position: absolute;
    top: 0;
    bottom: 0;
    background: $white;
    width: 30rem;
    right: -40rem;
    z-index: 99999;
    transition: all 0.5s ease-in-out 0s;
    overscroll-behavior: contain;
    @media only screen and (max-width: 767px) {
      right: -101%;
      width: 100%;
      padding-top: 6rem;
    }
    &.open {
      right: 0;
    }
  }
}
</style>