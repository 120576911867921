import {
  AssetsManager,
  CubeTexture,
  Scene,
  MeshBuilder,
  StandardMaterial,
  Texture,
  Color3,
} from "babylonjs";
import "babylonjs-loaders";
import { Utils } from "../builders/Utils";
import Constants from "@/components/organisms/project/building/3D/core/builders/Constants";

export class LoadingManager {
  private manager: AssetsManager;

  constructor(private readonly scene: Scene) {
    this.manager = new AssetsManager(scene);
    this.manager.useDefaultLoadingScreen = false;
  }

  addMeshLoadTask(
    taskName: string,
    meshNames: string,
    rootUrl: string,
    sceneFilename: string,
    onLoad: () => void,
    onError?: () => void
  ) {
    const newTask = this.manager.addMeshTask(
      taskName,
      "",
      rootUrl,
      sceneFilename
    );
    newTask.onSuccess = (task) => {
      //// disable picking on meshes
      for (const mesh of task.loadedMeshes) {
        Utils.setDefaultMeshProps(mesh);
      }
      onLoad();
    };
    newTask.onError = (task, message, exception) => {
      console.error(task + "Task Failed");
      console.error(message);
      console.error(exception);
      onError?.();
    };
  }

  loadHDR(url: string, scene: Scene) {
    const hdrImage = CubeTexture.CreateFromPrefilteredData(url, scene);
    hdrImage.gammaSpace = false;
    scene.environmentTexture = hdrImage;
    return hdrImage;
  }

  loadSkybox(url: string, scene: Scene) {
    const skybox = MeshBuilder.CreateBox(
      Constants.SKYBOX_NAME,
      { size: 1000 },
      scene
    );
    const skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture(url, scene);
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skybox.material = skyboxMaterial;
    Utils.setDefaultMeshProps(skybox);
    skybox.isPickable = true;
    return skybox;
  }

  startLoading() {
    return this.manager.load();
  }

  reset() {
    this.manager.reset();
  }
}
