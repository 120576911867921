var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section-spaces canvas-buttons-wrapper active" },
    [
      _c("div", { staticClass: "col-12 background-gray" }, [
        _c("div", { staticClass: "text-center" }, [
          _c("div", { staticClass: "inline-element text-left select-style" }, [
            _c(
              "label",
              [_c("AtomIcon", { attrs: { icon: "chair-office", size: 14 } })],
              1
            ),
            _c(
              "div",
              { staticClass: "select-inline-block" },
              [
                _c("AtomSelect", {
                  attrs: {
                    id: "fit-out",
                    options: [
                      {
                        text:
                          "Select one of the fit-outs to see how the space will look if fitted in certain ways or none to see it empty.",
                        value: "",
                        disabled: "disabled"
                      },
                      { text: "None", value: "none" }
                    ].concat(
                      _vm.fitoutData.fitoutOptions.map(function(opt) {
                        return { text: opt.label, value: opt.name }
                      })
                    ),
                    "select-props": {
                      search: false,
                      dropdownCssClass: "fitout-dropdown-list",
                      containerCssClass: "fitout-dropdown-container"
                    }
                  },
                  on: { change: _vm.changeFitout }
                }),
                _c("div", { staticClass: "info-fit-out" }, [
                  _c("span", { staticClass: "fit-out-text" }, [
                    _vm._v("Fitout")
                  ]),
                  _c(
                    "span",
                    { staticClass: "select-text hidden-mobile-elements" },
                    [_vm._v("Select")]
                  ),
                  _c("div", { staticClass: "custom-tooltip" }, [
                    _c(
                      "span",
                      {
                        staticClass: "info-icon",
                        attrs: { "data-fitout": "" }
                      },
                      [_c("AtomIcon", { attrs: { icon: "info", size: 16 } })],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "legends legends-space" })
                ])
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }