<template>
  <div class="molecule-filters">
    <MoleculeFilterHeader
        :activeTab="activeTab"
        @toggle-filters="toggleFilters"
        @re-init-filters="handleReInitFilters"
    />
    <MoleculeDigitalFilters
        v-if="activeTabString === 'digital'"
        :showFilters="showFilters"
        :reInitFilters="reInitFilters"
        @filterChange="handleFilterChange"
    />
    <MoleculePhysicalFilters
        v-if="activeTabString === 'physical'"
        :showFilters="showFilters"
        :reInitFilters="reInitFilters"
        @filterChange="handleFilterChange"
    />
    <AtomFilterResult :activeTab="activeTabString"/>
  </div>
</template>

<script>
import MoleculeFilterHeader from "./availability/MoleculeFilterHeader";
import MoleculeDigitalFilters from "./availability/MoleculeDigitalFilters";
import MoleculePhysicalFilters from "./availability/MoleculePhysicalFilters";
import AtomFilterResult from "../../atoms/common/filters/AtomFilterResult";

export default {
  name: "MoleculeFilters",
  components: {
    AtomFilterResult,
    MoleculePhysicalFilters,
    MoleculeDigitalFilters,
    MoleculeFilterHeader
  },
  props: {
    activeTab: {
      type: String,
      required: true,
      default: 'digital'
    }
  },
  data() {
    return {
      showFilters: true,
      reInitFilters: false,
    };
  },
  computed: {
    activeTabString() {
      return this.activeTab;
    },
    filters() {
      return this.$store.state.project?.filters || null;
    },
  },
  methods: {
    toggleFilters(toggleValue) {
      this.showFilters = toggleValue;
    },
    handleReInitFilters(value) {
      this.reInitFilters = value;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    handleFilterChange({ value, type, parentKey }) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE), {
        value,
        type,
        parentKey
      });
    },
  }
}
</script>


<style lang="scss">
.molecule-filters {

  .col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }

  .list-amenities {
    font-weight: $regular;
    color: $black;
    cursor: pointer;
    background: #F5F7F8;
    border-radius: 1px;
    padding: 0.188rem 0.75rem;
    font-size: 0.938rem;
    line-height: 1.125rem;
    transition: none;
    @media only screen and (max-width: 767px) {
      padding: 3px 12px;
      font-size: 15px;
      line-height: 18px;
    }

    .icon {
      font-size: 0.813rem !important;
      margin-right: 0.313rem;
      display: inline-block;
      vertical-align: middle;
      transition: none;
      @media only screen and (max-width: 767px) {
        font-size: 13px !important;
        margin-right: 5px;
      }

      &.icon-minus-simple {
        font-size: 0.25rem !important;
        @media only screen and (max-width: 767px) {
          font-size: 4px !important;
        }
      }

      &:before {
        margin: 0;
        width: auto;
      }
    }

    &:hover, &.active {
      background: $black;
      color: $white;

      .icon {
        color: $white !important;
      }
    }
  }

  .filters-row {
    margin-left: -0.313rem;
    margin-right: -0.313rem;
    @media only screen and (max-width: 767px) {
      margin-left: -5px;
      margin-right: -5px;
    }
  }
}
</style>