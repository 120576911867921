<template>
  <tr class="molecule-table-footer-general">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: "MoleculeTableFooterGeneral",
  components: {},
};
</script>

<style lang="scss" scoped>
.molecule-table-footer-general {
}
</style>
