<template>
  <div class="molecule-building-orientation-bar">
    <div class="display-inline info-mobile-building">
      <div
        class="orientation-button"
        :class="{ active: open }"
        @click="handleInfoTrigger"
      >
        <AtomIcon :icon="open ? 'xmark' : 'info'" :size="20" />
      </div>
    </div>
    <div class="display-inline">
      <span class="vertical-line">|</span>
    </div>
    <div class="display-inline">
      <div class="button-tooltip" ref="button3d" @click="changeViewMode3D">
        <span class="btn-tooltip" :class="{ active: !isActiveVirtualTour }"
          >3D</span
        >
        <p class="hidden-mobile-elements">Select 3D View Mode</p>
      </div>
    </div>
    <div class="display-inline">
      <div
        class="button-tooltip"
        ref="buttonVt"
        @click="changeViewModeFirstPerson"
      >
        <span class="btn-tooltip" :class="{ active: isActiveVirtualTour }">
          <AtomIcon icon="street-view" :size="20" />
        </span>
        <p class="hidden-mobile-elements">Enter Virtual Tour</p>
      </div>
    </div>
    <div class="display-inline">
      <span class="vertical-line">|</span>
    </div>
    <div class="display-inline area-parent" v-if="selectedArea">
      <div
        class="area-button"
        :class="{ active: openArea }"
        @click="handleAreaTrigger"
      >
        <div class="button-arrow">
          <div class="button-arrow-wrapper">
            <AtomIcon icon="arrow-up" />
          </div>
        </div>
        <div class="area-state">
          <h5>Area</h5>
          <p>{{ selectedArea.name }}</p>
        </div>
      </div>
      <OrganismInfoAreaPopup v-show="openArea">
        <h3>All Areas</h3>
        <ul class="list-areas">
          <li
            class="area-name"
            :class="{ active: selectedAreaId === area.id }"
            v-for="area in areas"
            :key="`area_${area.id}`"
            @click="handleAreaSelected(area)"
          >
            {{ area.name }}
          </li>
        </ul>
      </OrganismInfoAreaPopup>
    </div>
    <div
      class="display-inline specs-toggle-parent"
      v-if="pinsDataBuilding.length > 0"
      @click="handleTogglePins"
    >
      <div class="specs-toggle-button" :class="{ active: showPins }">
        <div class="button-specs-toggle">
          <div class="button-general-specs-toggle button-left-specs-toggle">
            <AtomIcon icon="no-specs" />
          </div>
          <span class="circle-black"></span>
          <div class="button-general-specs-toggle button-right-specs-toggle">
            <AtomIcon icon="chair-office" />
          </div>
        </div>
        <div class="specs-toggle-state">
          <h5>Mall Specs</h5>
          <p>
            <span class="active-state">Active</span
            ><span class="none-state">None</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../atoms/common/AtomIcon";
import { FloorViewMode } from "@/components/organisms/project/building/store";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import OrganismInfoAreaPopup from "../organisms/OrganismInfoAreaPopup";

export default {
  name: "MoleculeBuildingOrientationBar",
  components: { OrganismInfoAreaPopup, AtomIcon },
  data() {
    return {
      showPins: true,
      selectedAreaId: 1,
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.project?.activeProject || null;
    },
    activeBuilding() {
      return this.$store.state.project?.activeBuilding || null;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    pinsDataBuilding() {
      if (!this.generalConfig.modelDisplaySettings) return "";
      return (
        this.generalConfig?.modelDisplaySettings?.[this.activeProject].building
          .pinsData || null
      );
    },
    buildingStore() {
      return this.$parent.$refs.building.buildingStore;
    },
    projectData() {
      return this.$store.state.project?.project || [];
    },
    areas() {
      return (
        this.projectData?.buildings.find(
          (building) => building.id === this.activeBuilding
        )?.areas || []
      );
    },
    selectedArea() {
      return (
        this.areas?.find((area) => area.id === this.selectedAreaId) || null
      );
    },
    open() {
      return this.$store.state?.project?.infoBox?.open || false;
    },
    openArea() {
      return this.$store.state?.project?.infoArea?.open || false;
    },
    isActiveVirtualTour() {
      return this.$store.state?.base?.isActiveVirtualTour || false;
    },
  },
  mounted() {
    window.addEventListener("keydown", (evt) => {
      if (evt.key === "Escape") {
        this.changeViewMode3D();
      }
    });
  },
  beforeDestroy() {
    window.addEventListener("keydown", (evt) => {
      if (evt.key === "Escape") {
        this.changeViewMode3D();
      }
    });
  },
  methods: {
    handleInfoTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open,
        }
      );
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.scene.getEngine().getRenderingCanvas().focus();
      }
    },
    handleAreaTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_INFO_AREA
        ),
        {
          open: !this.openArea,
        }
      );
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.scene.getEngine().getRenderingCanvas().focus();
      }
    },
    setVirtualLastFloorClicked(floorNo) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED
        ),
        {
          lastFloorClicked: floorNo,
        }
      );
    },
    changeViewMode3D() {
      this.clearVirtualTourPopupState();
      this.toggleVirtualTour(false);
      this.setVirtualLastItemClicked();
      if (this.viewMode === FloorViewMode.DEFAULT) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.DEFAULT);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.DEFAULT);
      }
    },
    changeViewModeFirstPerson() {
      this.toggleVirtualTour(true);
      if (this.viewMode === FloorViewMode.FIRST_PERSON) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.FIRST_PERSON);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.FIRST_PERSON);
        this.setVirtualLastFloorClicked(manager.getCameraFloor());
      }
    },
    handleTogglePins() {
      this.showPins = !this.showPins;
    },
    handleAreaSelected(area) {
      this.selectedAreaId = area.id;
      if (!this.isActiveVirtualTour) {
        this.changeViewModeFirstPerson();
      }
      this.setVirtualLastItemClicked();
      this.clearVirtualTourPopupState();
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.moveFirstPersonCamera(
          area.position_coordinates,
          area.target_coordinates,
          area.code
        );
        this.handleAreaTrigger();
        setTimeout(() => {
          this.setVirtualLastFloorClicked(manager.getCameraFloor());
        }, 1200);
      }
    },
    toggleVirtualTour(isActive) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_VIRTUAL_TOUR),
        {
          isActive,
        }
      );
    },
    setVirtualLastItemClicked(item = null) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED
        ),
        {
          lastItemClicked: item,
        }
      );
    },
    clearVirtualTourPopupState() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.CLEAR_VIRTUAL_TOUR_POPUP
        )
      );
    },
  },
};
</script>

<style lang="scss">
.molecule-building-orientation-bar {
  background: $white;
  border-radius: 3.75rem;
  padding: 0.25rem 1.75rem 0.25rem 0.25rem;
  @media only screen and (max-width: 767px) {
    padding: 0.25rem 0.625rem 0.25rem 0.25rem;
    flex-direction: row;
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }

  .desktop-hidden {
    display: none;
  }

  @media only screen and (max-width: 767px) {
    .mobile-hidden {
      display: none !important;
    }
    .desktop-hidden {
      display: inline-block;
    }
  }

  .display-inline {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.188rem;
  }

  .specs-toggle-parent {
    position: relative;
    margin-left: 1rem;
    @media only screen and (max-width: 767px) {
      margin-left: 16px;
    }
  }

  .specs-toggle-button {
    .button-specs-toggle {
      padding: 0.125rem;
      width: 5.25rem;
      height: 2.75rem;
      background: #eeeeee;
      border-radius: 62.5rem;
      position: relative;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;

      .button-general-specs-toggle {
        position: absolute;
        top: 0.125rem;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;

        .icon {
          font-size: 1rem !important;
        }

        &.button-left-specs-toggle {
          left: 0.125rem;

          .icon {
            color: $white !important;
          }
        }

        &.button-right-specs-toggle {
          right: 0.125rem;

          .icon {
            color: $black !important;
          }
        }
      }

      .circle-black {
        position: absolute;
        left: 0.125rem;
        top: 0.125rem;
        width: 2.5rem;
        height: 2.5rem;
        background: $black;
        border-radius: 2.5rem;
        display: block;
      }
    }

    .specs-toggle-state {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.813rem;

      h5 {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-bottom: 0.063rem;
      }

      p {
        span {
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.063rem;

          &.active-state {
            display: none;
          }
        }
      }
    }

    &.active {
      .button-specs-toggle {
        .button-general-specs-toggle {
          &.button-left-specs-toggle {
            .icon {
              color: $black !important;
            }
          }

          &.button-right-specs-toggle {
            .icon {
              color: $white !important;
            }
          }
        }

        .circle-black {
          left: 2.625rem;
        }
      }

      .specs-toggle-state {
        p {
          span {
            &.active-state {
              display: block;
            }

            &.none-state {
              display: none;
            }
          }
        }
      }
    }
  }

  .area-parent {
    position: relative;
  }

  .area-button {
    .button-arrow {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      z-index: 5;

      .button-arrow-wrapper {
        background: #eeeeee;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 0.75rem !important;
          color: $black !important;
        }
      }
    }

    .area-state {
      display: inline-block;
      vertical-align: middle;
      margin-left: 0.813rem;

      h5 {
        font-weight: 400;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-bottom: 0.063rem;
      }

      p {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.063rem;
      }
    }

    &:hover {
      .button-arrow {
        .button-arrow-wrapper {
          background: $black;

          .icon {
            color: $white !important;
          }
        }
      }
    }

    &.active {
      .button-arrow {
        .button-arrow-wrapper {
          background: $black;
          transform: rotate(180deg);

          .icon {
            color: $white !important;
          }
        }
      }
    }
  }

  .orientation-button {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.25rem;
    color: $black;
    user-select: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out 0s;

    .icon {
      font-size: 1.25rem !important;
    }

    i {
      color: $black !important;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
      @media only screen and (max-width: 767px) {
        background: transparent;
      }
    }

    &.active-hover {
      background: rgba(0, 0, 0, 0.04);
    }

    &.active {
      background: $black !important;

      i {
        color: $white !important;
      }
    }
  }

  .vertical-line {
    display: inline-block;
    vertical-align: middle;
    font-weight: $medium;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $black;
    margin: -0.1rem 0.313rem 0;
  }

  .button-tooltip {
    .btn-tooltip {
      width: 2.5rem;
      height: 2.5rem;
      font-weight: $bold;
      font-size: 1.125rem;
      line-height: 1.313rem;
      color: $black;
      user-select: none;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease-in-out 0s;

      .icon {
        font-size: 1.25rem !important;
      }

      i {
        color: $black !important;
      }

      &:hover,
      &.active {
        background: $black;
        color: $white;

        i {
          color: $white !important;
        }
      }

      & + p {
        top: initial;
        right: initial;
        left: 50%;
        bottom: 100%;
        transform: translateX(-50%);
        margin: 0 0 0.313rem;
      }
    }
  }

  .info-space {
    display: inline-block;
    vertical-align: middle;

    .ap-nr {
      height: 2.75rem;
      padding: 0 0.375rem;
      background: $black;
      color: $white;
      font-weight: $medium;
      font-size: 0.875rem;
      line-height: 2.75rem;
      margin-right: 0.375rem;
    }

    .info-text {
      margin-right: 0.625rem;

      span {
        font-weight: $regular;
        font-size: 0.875rem;
        line-height: 1.313rem;

        b {
          font-weight: $medium;
          font-size: 0.875rem;
          line-height: 1.313rem;
        }

        &.sold {
          b {
            color: #ef3075;

            span {
              color: $black;
            }
          }
        }
      }

      .circle {
        padding: 0 0.25rem;
      }
    }
  }

  .btn-gray {
    text-align: center;
    padding: 0.625rem 0;
    background: rgba(2, 2, 2, 0.04);
    border-radius: 6.25rem;
    cursor: pointer;
    width: 9.5rem;
    max-width: 100%;

    span {
      font-weight: $medium;
      font-size: 1rem;

      &.hover {
        display: none;
      }
    }

    .icon {
      transition: none;
      display: inline-block;
      vertical-align: middle;
      font-size: 0.75rem !important;
      margin-top: -0.125rem;
      margin-right: 0.25rem;
    }

    &:hover {
      background: #23cd9a;

      span {
        color: $white;

        &.initial {
          display: none;
        }

        &.hover {
          display: inline-block;
        }
      }

      .icon {
        color: $white !important;
      }
    }
  }

  .btn-black {
    display: block;
    text-align: center;
    padding: 0.625rem 0;
    background: $black;
    border-radius: 6.25rem;
    cursor: pointer;
    width: 9.5rem;
    max-width: 100%;
    transition: none;

    span {
      font-weight: $medium;
      font-size: 1rem;
      color: $white;
    }

    .icon {
      transition: none;
      display: none;
      vertical-align: middle;
      font-size: 0.75rem !important;
      color: $white !important;
      margin-top: -0.125rem;
      margin-right: 0.25rem;
    }

    &:hover {
      background: #23cd9a;

      span {
        color: $white;
      }

      .icon {
        display: inline-block;
      }
    }
  }
}
</style>
