<template>
  <div
    class="section section-media-gallery"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <AtomTitleLine :title="data.title" />
    <OrganismMediaInfoPopup />
    <OrganismMediaGallery :slider-gallery-data="data.mediaGallery" />
    <AtomNumberList :lines="data.specs" />
  </div>
</template>

<script>
import OrganismMediaGallery from "../../components/organisms/common/OrganismMediaGallery";
import AtomTitleLine from "../../components/atoms/common/AtomTitleLine";
import AtomNumberList from "../../components/atoms/common/AtomNumberList";
import OrganismMediaInfoPopup from "@/components/organisms/project/OrganismMediaInfoPopup.vue";
export default {
  name: "SectionMediaGallery",
  components: {
    OrganismMediaInfoPopup,
    AtomNumberList,
    AtomTitleLine,
    OrganismMediaGallery,
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-media-gallery {
  position: relative;
  padding: 0 2rem;
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
}
</style>
