<template>
  <div :class="{section: true, 'section-specs-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row title-top">
      <div class="col col-xs-12 col-8">
        <AtomTitle :title="data.title" :subtitle="data.subtitle" />
      </div>
      <div class="col col-xs-12 col-4">
        <div class="text-right">
          <div class="tabs">
            <div class="tab-trigger" v-for="(tab, i) in data.tabs" :class="{active: activeTab === tab.tabId}" @click="handleTabChange(tab.tabId)" :key="i">
              {{tab.tabLabel}}
              <img src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/active-line-specs.svg" alt="Active Line">
            </div>
            <AtomToggle v-model="toggled" />
          </div>
        </div>
      </div>
    </div>
    <div v-for="(tab, i) in data.tabs" :key="i">
      <div v-if="activeTab === tab.tabId">
        <OrganismTabsTechnicalSpecsV2 :sub-tabs="tab.subTabs">
          <a v-if="hasResourcesModal" class="button-atom" :href="`${resourceUrl(data.resources)}`" target="_blank"><span>Download Resources</span></a>
        </OrganismTabsTechnicalSpecsV2>
      </div>
    </div>
  </div>
</template>

<script>
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
import OrganismTabsTechnicalSpecsV2
  from "../../../../components/organisms/project/building/OrganismTabsTechnicalSpecsV2";
import AtomToggle from "../../../../components/atoms/common/AtomToggle";

export default {
  name: "SectionSpecsV2",
  components: {AtomToggle, OrganismTabsTechnicalSpecsV2, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  data() {
    let activeTab = '';
    if (this.data.tabs && this.data.tabs.length) {
      activeTab = this.data.tabs[0].tabId;
    }
    return {
      activeTab,
      toggled: false,
    }
  },
  computed: {
    hasResourcesModal() {
      return false
    }
  },
  beforeUpdate() {
    if (this.data.tabs && this.data.tabs.length && !this.activeTab) {
      this.activeTab = this.data.tabs[0].tabId;
    }
  },
  methods: {
    handleTabChange(tabId) {
      this.activeTab = tabId;
      this.toggled = tabId === 'exterior';
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    }
  },
  watch: {
    toggled: function (value) {
      this.activeTab = value ? 'exterior' : 'interior';
    }
  },
}
</script>

<style lang="scss">
  .section-specs-v2 {
    padding-top: 3.75rem;
    @media only screen and (max-width: 767px) {
      padding: 2.5rem 0 0;
    }
    .title-top {
      padding: 0 3.75rem;
      @media only screen and (max-width: 767px) {
        padding: 0 2rem;
        margin-bottom: 0.625rem;
      }
      .atom-title {
        margin-bottom: 0.5rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.625rem;
        }
        h2 {
          color: $blueDark !important;
          font-weight: $regular;
          font-size: 3.25rem;
          line-height: 3.875rem;
          text-transform: none;
          margin-bottom: 0.5rem;
          @media only screen and (max-width: 767px) {
            font-size: 2.5rem;
            line-height: 3rem;
            margin-bottom: 0;
          }
          b {
            font-size: 3.25rem;
            line-height: 3.875rem;
            @media only screen and (max-width: 767px) {
              font-size: 2.5rem;
              line-height: 3rem;
            }
          }
        }
        h3 {
          color: $blueDark !important;
          font-weight: $regular;
          font-size: 1.375rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 1.125rem;
            line-height: 1.313rem;
            margin-top: 0.375rem;
          }
        }
      }
      .text-right {
        @media only screen and (max-width: 767px) {
          text-align: center;
        }
      }
      .tabs {
        position: relative;
        display: inline-block;
        margin-top: 1rem;
        @media only screen and (max-width: 767px) {
          margin: 0.625rem 0 1.25rem;
        }
        .tab-trigger {
          position: relative;
          display: inline-block;
          vertical-align: middle;
          font-weight: $regular;
          font-size: 1.375rem;
          line-height: 1.625rem;
          color: $blueDark;
          transition: all 0.2s ease-in-out 0s;
          cursor: pointer;
          @media only screen and (max-width: 767px) {
            font-size: 1.125rem;
            line-height: 1.375rem;
          }
          img {
            opacity: 0;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -0.338rem;
            width: 4.625rem;
            max-width: 99999px !important;
            transition: all 0.2s ease-in-out 0s;
            @media only screen and (max-width: 767px) {
              bottom: -0.813rem;
              width: 4.625rem;
            }
          }
          &:first-child {
            margin-right: 6rem;
            @media only screen and (max-width: 767px) {
              margin-right: 6rem;
            }
          }
          &:hover, &.active {
            color: $blue;
            img {
              opacity: 1;
            }
          }
        }
      }
    }
    .tabs-elements {
      position: relative;
      padding: 0 4.375rem;
      border-bottom: 1px solid #ECF5F8;
      @media only screen and (max-width: 767px) {
        margin: 0 0 0.5rem;
        padding: 0 2rem;
      }
      .sub-tabs {
        .sub-tab-trigger {
          cursor: pointer;
          display: inline-block;
          vertical-align: bottom;
          font-weight: $bold;
          font-size: 1.125rem;
          line-height: 1.313rem;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out 0s;
          margin-right: 1.875rem;
          border-bottom: 0.25rem solid transparent;
          padding-bottom: 0.313rem;
          @media only screen and (max-width: 767px) {
            font-size: 1rem;
            line-height: 1.2rem;
            margin-right: 1rem;
          }
          &:hover, &.active {
            color: $blue;
          }
          &.active {
            border-color: $blue;
          }
        }
      }
      .button-atom {
        background: transparent;
        padding: 0.75rem 2rem;
        font-weight: $regular;
        border-color: $blue;
        margin-bottom: 0.313rem;
        @media only screen and (max-width: 767px) {
          padding: 0.5rem 0.938rem;
          position: absolute;
          right: 0;
          top: 0.625rem;
        }
        span {
          color: $black;
          text-transform: none;
        }
        &:hover {
          background: $blue;
          span {
            color: $white;
          }
        }
      }
    }
    .statements {
      position: relative;
      overflow: hidden;
      padding: 2.25rem 4.375rem;
      @media only screen and (max-width: 767px) {
        padding: 1.5rem 2rem;
      }
      & > .col {
        position: static;
      }
      .icon-quotation-mark {
        font-size: 11.75rem !important;
        opacity: 0.04;
        position: absolute;
        left: -4rem;
        top: 0.938rem;
        @media only screen and (max-width: 767px) {
          font-size: 7.5rem !important;
          left: -2.5rem;
          top: 0.25rem;
        }
        &:before {
          width: auto;
          margin: 0;
        }
      }
      .atom-title {
        margin-bottom: 2.5rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.938rem;
        }
        h2 {
          color: $blueDark !important;
          font-weight: $regular;
          font-size: 2.75rem;
          line-height: 3.25rem;
          text-transform: none;
          margin-bottom: 1.25rem;
          @media only screen and (max-width: 767px) {
            font-size: 1.125rem;
            line-height: 1.375rem;
            margin-bottom: 0.625rem;
          }
          b {
            font-size: 2.75rem;
            line-height: 3.25rem;
            @media only screen and (max-width: 767px) {
              font-size: 1.125rem;
              line-height: 1.375rem;
            }
          }
        }
        h3 {
          color: $black !important;
          font-weight: $regular;
          font-size: 1rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 1rem;
            line-height: 1.275rem;
          }
        }
      }
      .person-info {
        h5 {
          font-weight: $bold;
          font-size: 1.375rem;
          line-height: 1.625rem;
          margin-bottom: 0.188rem;
          @media only screen and (max-width: 767px) {
            font-size: 1.125rem;
            line-height: 1.375rem;
          }
        }
        h6 {
          font-weight: $bold;
          font-size: 1rem;
          line-height: 1.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 0.875rem;
            line-height: 1.125rem;
          }
        }
      }
    }
    .image-technical-specs {
      position: relative;
      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>