<template>
  <div :class="{section: true, 'section-map-locations': true, [`section${orderNo}`]: true}" :style="{'min-height': `calc(${windowHeight}px - 1px)`}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismMainLocations :locations-data="data" />
  </div>
</template>

<script>
import OrganismMainLocations from "@/components/organisms/portfolio/OrganismMainLocations";
import {convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId} from "@/helpers/util";

export default {
  name: "SectionMapLocations",
  components: {OrganismMainLocations },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
    }
  }
};
</script>

<style lang="scss">
  .section-map-locations {
    position: relative;
    .navigation-target {
      position: absolute;
      top: -3.563rem;
      left: 0;
    }
    .static-marker {
      padding: 0.313rem 0.625rem;
      border-radius: 6.25rem;
      &:hover, &.active {
        background-color: $main-color;
        &:after {
          border-top-color: $main-color;
        }
      }
      &:after {
        border-width: 0.438rem;
        margin-left: -0.438rem;
      }
      .title-marker {
        font-weight: $regular;
        font-size: 0.875rem;
        line-height: 1.063rem;
        white-space: nowrap;
        b {
          font-weight: $regular;
          font-size: 0.875rem;
          line-height: 1.063rem;
          text-transform: none;
          display: inline-block;
        }
      }
    }
  }
</style>