<template>
  <div :class="{section: true, 'section-features': true, [`section${orderNo}`]: true}" :style="inlineStyles">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title">
      <AtomTitle color2="#fff" :title="data.title" :subtitle="data.subtitle" />
      <AtomVerticalLine/>
    </div>
    <OrganismListCircle :list-circle-data="data.items" />
  </div>
</template>

<script>
import AtomTitle from "../../../components/atoms/common/AtomTitle";
import AtomVerticalLine from "../../../components/atoms/common/AtomVerticalLine";
import OrganismListCircle from "../../../components/organisms/common/OrganismListCircle";
export default {
  name: "SectionFeatures",
  components: {OrganismListCircle, AtomVerticalLine, AtomTitle},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    inlineStyles() {
      let styles = {};
      if (this.data.backgroundImage) {
        styles = {...{'background-image': `url(${this.data.backgroundImage})`}}
      }
      return styles
    }
  }
}
</script>

<style lang="scss">
.section-features {
  padding: 4.375rem 3.75rem 0;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba($main-color, 0.9);
  @media only screen and (max-width: 767px) {
    padding: 30px 20px;
  }
  .top-title {
    margin-bottom: 5.938rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    .atom-title {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
      h2 {
        font-weight: $medium;
        font-size: 0.875rem;
        line-height: 1.063rem;
        letter-spacing: 0.05em;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 17px;
        }
      }
      h3 {
        margin-top: 0.5rem;
        font-weight: $medium;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }
    .atom-vertical-line {
      margin-bottom: 1.875rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
    .atom-content-text {
      max-width: 80rem;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
      p {
        @include font-main(1.125rem, $black, $regular, 1.813rem);
        @media only screen and (max-width: 767px) {
          font-size: 18px;
          line-height: 29px;
        }
      }
    }
  }
  .atom-list-circle {
    h4 {
      font-weight: $medium;
      font-size: 1.375rem;
      line-height: 1.625rem;
      @media only screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 26px;
      }
    }
    p {
      @include font-main(1.125rem, $black, $regular, 1.813rem);
      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 29px;
      }
    }
  }
}
</style>