var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.virtualTourPopupState.isActive
    ? _c(
        "div",
        {
          staticClass: "organism-virtual-tour-popup",
          class: {
            open: _vm.virtualTourPopupState.isActive,
            expand: _vm.expandGallery
          }
        },
        [
          _c("div", { staticClass: "header-element" }, [
            !_vm.expandGallery
              ? _c("h5", [_vm._v("Info")])
              : _c("h5", [_vm._v("Gallery")]),
            _c(
              "div",
              {
                staticClass: "close-element",
                on: { click: _vm.clearVirtualTourPopupState }
              },
              [_c("AtomIcon", { attrs: { icon: "xmark" } })],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "body-element" },
            [
              _vm.virtualTourPopupState.title
                ? _c("h2", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.virtualTourPopupState.title) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              _vm.virtualTourPopupState.assets.length > 0
                ? _c("MoleculeVirtualTourImages", {
                    ref: "virtualTourSliderImages",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "slider-images",
                          fn: function() {
                            return _vm._l(
                              _vm.virtualTourPopupState.assets,
                              function(image, assets) {
                                return _c(
                                  "AtomVirtualTourImages",
                                  { key: assets },
                                  [
                                    _c("div", { staticClass: "image" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.cdnBase +
                                            "/images/projects/" +
                                            _vm.landlordSlug +
                                            "/" +
                                            _vm.activeProjectSlug +
                                            "/images/" +
                                            image.file_name +
                                            ".jpg",
                                          alt:
                                            _vm.virtualTourPopupState.title +
                                            "-" +
                                            image.id
                                        }
                                      })
                                    ])
                                  ]
                                )
                              }
                            )
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2544424247
                    )
                  })
                : _vm._e(),
              _vm.virtualTourPopupState.description && !_vm.expandGallery
                ? _c("p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.virtualTourPopupState.description) +
                        "\n    "
                    )
                  ])
                : _vm._e(),
              !_vm.expandGallery
                ? _c(
                    "a",
                    {
                      staticClass: "btn-general btn-black font-medium",
                      attrs: { href: "javascript:void(0)" }
                    },
                    [_c("span", [_vm._v("Download")])]
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }