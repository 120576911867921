<template>
  <div :class="{section: true, 'section-contact': true, 'section-contact-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContactForm/>
    <div class="row contacts-wrapper" v-if="!data.contactFormOnly">
      <div class="col col-xs-12 col-6 column-left-info">
        <div class="title" v-html="data.title"></div>
        <div class="subtitle" v-html="data.subtitle"></div>
        <ul class="info-general">
          <li v-if="data.email">
            <a class="email" :href="data.email"><AtomIcon icon="email-custom" />  {{ data.email }}</a>
          </li>
          <li v-if="data.phone">
            <a class="phone" :href="data.phone"><AtomIcon icon="phone-custom" /> {{ data.phone }}</a>
          </li>
          <li v-if="data.address">
            <a class="phone" :href="data.address"><AtomIcon icon="location-custom" /> {{ data.address }}</a>
          </li>
        </ul>
      </div>
      <div class="col col-xs-12 col-6 column-right-info">
        <div class="text-right">
          <div class="social">
            <div v-if="data.socialTitle" class="title" v-html="data.socialTitle"></div>
            <a v-if="data.instagramUrl" :href="data.instagramUrl" target="_blank">
              <AtomIcon icon="instagram" :size="40" color="#FFF"/>
            </a>
            <a v-if="data.linkedinUrl" :href="data.linkedinUrl" target="_blank">
              <AtomIcon icon="linkedin-squared" :size="40" color="#FFF"/>
            </a>
            <a v-if="data.facebookUrl" :href="data.facebookUrl" target="_blank">
              <AtomIcon icon="facebook-squared" :size="40" color="#FFF"/>
            </a>
            <a v-if="data.youtubeUrl" :href="data.youtubeUrl" target="_blank">
              <AtomIcon icon="youtube-play" :size="40" color="#FFF"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganismContactForm from "../../../components/organisms/project/OrganismContactForm";
import AtomIcon from "../../../components/atoms/common/AtomIcon";

export default {
  name: "SectionContact",
  components: {AtomIcon, OrganismContactForm},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.general
    },
    instagramUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.facebookUrl;
    },
    youtubeUrl() {
      if (!this.generalConfig.social) return '';
      return this.generalConfig.social.youtubeUrl;
    }
  }
}
</script>

<style lang="scss">
.section-contact-v2 {
  position: relative;
  .navigation-target {
    position: absolute;
    top: -3.563rem;
    left: 0;
  }
  .organism-contact-form {
    padding: 4rem 2rem 3.5rem;
    @media only screen and (max-width: 767px) {
      padding: 2rem;
    }
    .atom-title {
      margin-bottom: 4rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.875rem;
      }
      h2 {
        color: $second-color !important;
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        text-transform: none;
        @media only screen and (max-width: 767px) {
          font-size: 2.5rem;
          line-height: 3rem;
        }
      }
      h3 {
        color: $second-color !important;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.125rem;
          line-height: 1.313rem;
        }
      }
    }
    .button-atom {
      background: transparent;
      padding: 0.75rem 2rem;
      font-weight: 400;
      border-color: $main-color;
      @media only screen and (max-width: 767px) {
        padding: 0.5rem 0.938rem;
      }
      span {
        color: $black;
        text-transform: none;
      }
      &:hover {
        background: $main-color;
        span {
          color: $white;
        }
      }
    }
  }
  .atom-contact {
    margin-bottom: 3.125rem;
    .atom-gradient-background {
      padding: 0 0.375rem;
      background: none;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
      .content-info {
        background: rgba(0,0,0,0.2);
        padding: 1.25rem 1.5rem;
        @media only screen and (max-width: 767px) {
          padding: 0.938rem 1.25rem;
        }
        .col {
          padding: 0;
          margin: 0;
          &.col-7 {
            flex-basis: calc(100% / 12 * 12) !important;
          }
          span {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.375rem;
            display: block;
            width: 100%;
            text-transform: none;
            margin-bottom: 1.125rem;
            @media only screen and (max-width: 767px) {
              font-size: 0.875rem;
              line-height: 1.188rem;
              margin-bottom: 0.938rem;
            }
          }
          .name {
            font-size: 1.375rem;
            line-height: 1.625rem;
            font-weight: 400;
            margin-bottom: 0.25rem;
            max-width: 100%;
            @media only screen and (max-width: 767px) {
              font-size: 1.25rem;
              line-height: 1.5rem;
              margin-bottom: 0.25rem;
            }
          }
          a {
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.188rem;
            @media only screen and (max-width: 767px) {
              font-size: 0.875rem;
              line-height: 1.188rem;
            }
          }
        }
      }
    }
  }

  .contacts-wrapper {
    background-color: $second-color;
    padding: 2.5rem 2rem 7.5rem;
    @media only screen and (max-width: 767px) {
      padding: 2rem;
    }
    .column-left-info {
      color: $white;
      padding: 0 0.375rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
      .title {
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        max-width: 36.375rem;
        margin-bottom: 1.25rem;
        @media only screen and (max-width: 767px) {
          font-size: 2rem;
          line-height: 2.375rem;
          max-width: 100%;
          margin-bottom: 0.938rem;
        }
        span {
          font-weight: 700;
          font-size: 3.25rem;
          line-height: 3.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 2rem;
            line-height: 2.375rem;
          }
        }
      }
      .subtitle {
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        margin-bottom: 4rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.125rem;
          line-height: 1.313rem;
          margin-bottom: 1.875rem;
        }
      }
      .info-general {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          margin-bottom: 1rem;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-weight: 400;
            font-size: 1.375rem;
            line-height: 1.625rem;
            letter-spacing: -0.02em;
            color: $white;
            @media only screen and (max-width: 767px) {
              font-size: 1.125rem;
              line-height: 1.313rem;
            }
            .icon {
              font-size: 1rem !important;
              color: $white !important;
              margin-right: 1.25rem;
              @media only screen and (max-width: 767px) {
                font-size: 1.2rem !important;
                margin-right: 0.5rem;
              }
              &.icon-phone-custom {
                font-size: 1.25rem !important;
                @media only screen and (max-width: 767px) {
                  font-size: 1.45rem !important;
                }
              }
              &.icon-location-custom {
                font-size: 1.375rem !important;
                @media only screen and (max-width: 767px) {
                  font-size: 1.575rem !important;
                }
              }
            }
          }
        }
      }
    }
    .column-right-info {
      color: $white;
      padding: 0 0.375rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
        margin-top: 2rem;
      }
      .text-right {
        @media only screen and (max-width: 767px) {
          text-align: left;
        }
      }
      .title {
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        margin-bottom: 1.5rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.375rem;
          line-height: 1.625rem;
          margin-bottom: 0.938rem;
        }
        span {
          font-weight: 700;
          font-size: 3.25rem;
          line-height: 3.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 1.375rem;
            line-height: 1.625rem;
          }
        }
      }
      .social {
        display: inline-block;
        text-align: left;
        @media only screen and (max-width: 767px) {
          margin-bottom: 2.5rem;
        }
        .title {
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.375rem;
          margin-bottom: 1.875rem;
          @media only screen and (max-width: 767px) {
            margin-bottom: 0.938rem;
          }
          span {
            font-weight: 700;
            font-size: 2rem;
            line-height: 2.375rem;
          }
        }
        a {
          display: inline-block;
          margin-right: 1.5rem;
          @media only screen and (max-width: 767px) {
            margin-right: 0.75rem;
          }
          &:last-child {
            margin-right: 0;
          }
          .icon {
            font-size: 2.5rem !important;
            @media only screen and (max-width: 767px) {
              font-size: 2rem !important;
            }
            &:before {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>