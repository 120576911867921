var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-map-locations": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: { "min-height": "calc(" + _vm.windowHeight + "px - 1px)" }
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("OrganismMainLocations", { attrs: { "locations-data": _vm.data } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }