var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-project-slider" },
    [
      _vm.sliderData.length > 1
        ? _c(
            "MoleculeProjectSliderThumb",
            _vm._l(_vm.sliderData, function(thumb, index) {
              return _c("AtomProjectSliderThumb", {
                key: index,
                attrs: {
                  "active-thumb": _vm.activeThumb,
                  "thumb-id": "item-" + index,
                  thumb: thumb.thumb,
                  sold: thumb.sold,
                  title: thumb.title,
                  subtitle: thumb.subtitle
                },
                on: { change: _vm.handleThumbChange }
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "MoleculeProjectSliderBackground",
        _vm._l(_vm.sliderData, function(image, index) {
          return _c("AtomProjectSliderBackground", {
            key: index,
            attrs: {
              "active-background": _vm.activeThumb,
              "background-id": "item-" + index,
              image: image.image,
              video: image.video
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }