<template>
  <div class="molecule-request-offer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeRequestOffer",
}
</script>

<style lang="scss">

</style>