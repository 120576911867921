import { Mesh, Scene, TransformNode } from "babylonjs";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export class HighlightManager {
  highlightedMeshes: Mesh[] = [];
  addCookieHoverSpace?: () => void;
  addCookieClickSpace?: () => void;
  floorsNodes: Map<string, TransformNode> = new Map();
  caseNodes: Map<string, TransformNode> = new Map();
  sceneManager = BabylonClientManager.getSceneManager();

  showMesh(mesh: Mesh) {
    mesh.isVisible = true;
    mesh.visibility = 1;
    this.highlightedMeshes.push(mesh);
    //// Remove pop messageson first highlight
    if (this.addCookieHoverSpace) {
      this.addCookieHoverSpace();
      this.addCookieHoverSpace = undefined;
    }
  }
  hideMeshes() {
    if (this.highlightedMeshes.length > 0) {
      for (const mesh of this.highlightedMeshes) {
        mesh.visibility = 0;
        mesh.isVisible = false;
      }
      this.highlightedMeshes = [];
    }
  }

  highlightBuildingSpace(
    scene: Scene,
    highlightName: string,
    buildingCode: string
  ) {
    const highlight = Utils.findNode(highlightName, scene);
    const floorsNode = this.floorsNodes.get(buildingCode);
    const caseNode = this.caseNodes.get(buildingCode);
    if (!highlight || !floorsNode || !caseNode) return;
    this.showMesh(highlight as Mesh);
    //// Hide outer case
    caseNode.setEnabled(false);
    //// Hide other floors
    const floorHighlight = highlightName.split("_");
    let selectedFloor;
    for (const floor of floorsNode.getChildren()) {
      if (floor.name != floorHighlight[0] + "_" + floorHighlight[1]) {
        //// Check if it's transform node and set its children as well
        if (floor.getChildren().length > 0) {
          for (const floorChild of floor.getChildren()) {
            (floorChild as Mesh).visibility = 0.15;
          }
        }
        (floor as Mesh).visibility = 0.15;
      } else {
        selectedFloor = floor;
      }
    }
    return selectedFloor;
  }

  resetBuildingVisibility() {
    this.caseNodes.forEach((caseNode) => {
      caseNode.setEnabled(true);
    });
    this.floorsNodes.forEach((floorNode) => {
      for (const floor of floorNode.getChildMeshes()) {
        floor.visibility = 1;
      }
    });
    this.sceneManager?.renderScene(1);
  }
}
