<template>
  <div class="atom-main-locations-padding">
    <div :id="`${projectId}`" class="atom-main-locations">
      <div v-if="image" class="image-location" :style="{'background-image': `url(${resourceUrl(image)})`}">
        <div class="background-transparent"></div>
        <span v-if="imageText" class="image-text" v-html="imageText"></span>
      </div>
      <div class="title-and-button">
        <p v-if="title" class="title" v-html="title"></p>
        <AtomButtonLink
            v-if="buttonUrl"
            :label="`${ buttonText }`"
            :href="`${ buttonUrl }`"
        />
        <AtomButton
            v-else
            class="btn-disabled"
            :label="buttonText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AtomButtonLink from "../common/AtomButtonLink";
import AtomButton from "../common/AtomButton";

export default {
  name: "AtomMainLocations",
  components: {AtomButton, AtomButtonLink},
  props: {
    image: {
      required: false,
      type: String
    },
    title: {
      required: false,
      type: String,
      default: ''
    },
    imageText: {
      required: false,
      type: String,
      default: ''
    },
    buttonText: {
      required: false,
      type: String,
      default: ''
    },
    buttonUrl: {
      required: false,
      type: String,
      default: "javascript:void(0)",
    },
    tabId: {
      required: true,
      type: String,
      default: ''
    },
    projectId: {
      required: true,
      type: String,
      default: ''
    },
    activeProject: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeProject === this.tabId;
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  }
}
</script>

<style lang="scss">
  .atom-main-locations-padding {
    padding: 0 0.625rem;
    .image-location {
      position: relative;
      width: 16rem;
      height: 9.375rem;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      .background-transparent {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: $second-color;
        opacity: 0.2;
      }
      .image-text {
        position: absolute;
        left: 0.5rem;
        bottom: 0.625rem;
        font-weight: $regular;
        font-size: 1rem;
        line-height: 1.188rem;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: $white;
        z-index: 9;
        b {
          font-weight: $bold;
          font-size: 1.25rem;
          line-height: 1.5rem;
          margin-top: -0.125rem;
          margin-right: 0.313rem;
        }
      }
    }
    .title-and-button {
      background: #fff;
      padding: 1rem 7.188rem 1rem 0.5rem;
      position: relative;
      .title {
        font-weight: $regular;
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: -0.02em;
      }
      .btn-general {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        font-weight: $medium;
        font-size: 1rem;
        line-height: 1.125rem;
        padding: 0.5rem 1rem;
        transform: translateY(-50%);
      }
    }
  }
</style>