<template>
  <div
    :class="{
      section: true,
      'section-building': true,
      [`section${orderNo}`]: true,
    }"
  >
    <MoleculeShortlistRulesModal />
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div
      v-if="loadingScreenActive"
      class="organism-loader-background"
      :style="{
        'background-image': `url(${resourceUrl(backgroundLoaderUrl)})`,
      }"
    >
      <OrganismLoader :content-loader="contentLoaderData" />
    </div>
    <OrganismMenuRight :phone-header="phoneNumberHeader" />
    <OrganismBuilding
      ref="building"
      :class="{
        active: drawerAvailabilityOpen || drawerShortlistOpen,
      }"
    />
    <div
      :class="{
        'drawer-buttons': true,
        active: drawerAvailabilityOpen || drawerShortlistOpen,
      }"
    >
      <div
        class="drawer-button drawer-button-black custom-tooltip"
        @click="handleExpandAvailability"
      >
        <AtomIcon
          :icon="drawerAvailabilityOpen ? 'xmark' : 'filter-list'"
          color="#fff"
        />
        <p class="hidden-mobile-elements">
          {{ drawerAvailabilityOpen ? "Close" : "Filters & Portfolio" }}
        </p>
      </div>
      <div
        v-if="shortlistState"
        class="drawer-button drawer-button-white custom-tooltip"
        @click="handleExpandShortlist"
      >
        <AtomIcon
          :icon="drawerShortlistOpen ? 'xmark' : 'shortlist-icon'"
          color="#000"
        />
        <span class="number" v-if="shortlistItemsLength > 0">{{
          shortlistItemsLength
        }}</span>
        <p class="hidden-mobile-elements">
          {{ drawerShortlistOpen ? "Close" : "Shortlist" }}
        </p>
      </div>
    </div>
    <OrganismAvailabilityDrawer
      ref="availability-drawer"
      :default-open="drawerAvailabilityOpen"
      @drawer:close="handleCloseAvailability"
    />
    <OrganismShortlistDrawer
      ref="shortlist-drawer"
      :default-open="drawerShortlistOpen"
      :trigger-open-availability="handleExpandAvailability"
      @toggleAvailability="handleExpandAvailability"
      @drawer:close="handleCloseShortlist"
    />
    <OrganismRequestOffer />
    <OrganismTutorial />
    <OrganismVirtualTourPopup />
    <div class="orientation-nav">
      <MoleculeBuildingOrientationBar />
    </div>
    <a class="footer-bright" href="https://brightspaces.tech/" target="_blank">
      Created by ©BrightSpaces</a
    >
  </div>
</template>

<script>
import OrganismBuilding from "../../../components/organisms/project/building/OrganismBuilding";
import OrganismLoader from "../../../components/organisms/project/OrganismLoader";
import { CurrentView } from "../../../components/organisms/project/building/store";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../../helpers/util";
import MoleculeBuildingOrientationBar from "@/components/molecules/MoleculeBuildingOrientationBar";
import OrganismTutorial from "@/components/organisms/project/OrganismTutorial";
import AtomIcon from "../../../components/atoms/common/AtomIcon";
import OrganismAvailabilityDrawer from "../../../components/organisms/common/availability/OrganismAvailabilityDrawer";
import OrganismShortlistDrawer from "../../../components/organisms/common/availability/OrganismShortlistDrawer";
import OrganismMenuRight from "../../../components/organisms/OrganismMenuRight";
import OrganismRequestOffer from "../../../components/organisms/OrganismRequestOffer";
import MoleculeShortlistRulesModal from "@/components/molecules/project/MoleculeShortlistRulesModal.vue";
import OrganismVirtualTourPopup from "../../../components/organisms/OrganismVirtualTourPopup";
import constants from "../../../store/modules/shortlist/constants";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export default {
  name: "SectionBuilding",
  components: {
    OrganismVirtualTourPopup,
    OrganismRequestOffer,
    OrganismMenuRight,
    OrganismShortlistDrawer,
    OrganismAvailabilityDrawer,
    AtomIcon,
    OrganismTutorial,
    MoleculeBuildingOrientationBar,
    OrganismLoader,
    OrganismBuilding,
    MoleculeShortlistRulesModal,
  },
  props: {
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {
          backgroundLoader: "",
          contentLoader: [],
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFloorView: false,
      loadingScreenActive: true,
      headerHeight: 0,
      drawerAvailabilityOpen: true,
      drawerShortlistOpen: false,
    };
  },
  computed: {
    shortlistItemsLength() {
      return this.$store.state.shortlist.total || 0;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    phoneNumberHeader() {
      if (!this.generalConfig.phoneNumberHeader) return "";
      return this.generalConfig.phoneNumberHeader;
    },
    isMobileMenuOpen() {
      return this.$store.state.base.mobileAvailabilityMenuOpen;
    },
    contentLoaderData() {
      return this.data.contentLoader;
    },
    backgroundLoaderUrl() {
      return this.data.backgroundLoader;
    },
    isEmbedReady() {
      const routePageData = this.$route.meta.page;
      return routePageData.isEmbedReady;
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
    totalSpace() {
      let total = 0;
      this.requestedSpaces.forEach((s) => {
        total += s.sqm;
      });
      return total;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    shortlistState() {
      //unused
      return this.$store.getters?.getShortlist || false;
    },
    activeProjectSlug() {
      return this.$store.state.project?.activeProject || null;
    },
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections;
      const targetSection = sections[this.orderNo];
      const targetString = targetSection.hashAnchor;
      if (sections && sections.length > 1) {
        console.log("targetString", targetString);
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    sidebarHideMobile() {
      if (window.innerWidth < 767) {
        this.drawerAvailabilityOpen = false;
      }
    },
    handleDrawersGeneral() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_REQUEST_OFFER),
        {
          open: false,
        }
      );
    },
    handleExpandAvailability() {
      this.$refs["availability-drawer"]?.toggleDrawer();
      this.drawerAvailabilityOpen = !this.drawerAvailabilityOpen;
      if (this.drawerAvailabilityOpen) {
        this.handleCloseShortlist();
      }
      this.handleDrawersGeneral();

      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        const intVal = setInterval(() => {
          manager.resize();
        }, 10);
        setTimeout(() => {
          clearInterval(intVal);
          manager.scene.getEngine().getRenderingCanvas().focus();
        }, 501);
      }
    },
    handleExpandShortlist() {
      this.$refs["shortlist-drawer"]?.toggleDrawer();
      this.drawerShortlistOpen = !this.drawerShortlistOpen;
      if (this.drawerShortlistOpen) {
        this.handleCloseAvailability();
      }
      this.handleDrawersGeneral();

      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        const intVal = setInterval(() => {
          manager.resize();
        }, 10);
        setTimeout(() => {
          clearInterval(intVal);
          manager.scene.getEngine().getRenderingCanvas().focus();
        }, 501);
      }
    },
    handleCloseAvailability() {
      this.$refs["availability-drawer"]?.closeDrawer();
      this.drawerAvailabilityOpen = false;
      this.handleDrawersGeneral();
    },
    handleCloseShortlist() {
      this.$refs["shortlist-drawer"]?.closeDrawer();
      this.drawerShortlistOpen = false;
      this.handleDrawersGeneral();
    },
    initShortlistStoreWithLocalStorageItems(localStorageItems) {
      const shortlist = this.$store.getters.constants.shortlist;
      this.$store.dispatch(
        shortlist.withNamespace(shortlist.action.LOAD_ITEMS),
        localStorageItems
      );
    },
  },
  beforeMount() {
    this.sidebarHideMobile();
  },
  mounted() {
    this.$refs.building.buildingStore.subscribe((payload, state) => {
      this.isFloorView = state.currentView === CurrentView.FLOOR;
      this.loadingScreenActive = state.loadingScreenActive;
    });
    window.addEventListener("resize", this.resizeListener);
    this.headerHeight = document.getElementById("header-project")
      ? document.getElementById("header-project").clientHeight
      : 0;

    if (this.activeProjectSlug) {
      const cache = JSON.parse(localStorage.getItem(constants.cacheName)) || {};
      if (cache[this.activeProjectSlug]) {
        this.initShortlistStoreWithLocalStorageItems(
          cache[this.activeProjectSlug]
        );
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeListener);
  },
};
</script>

<style lang="scss">
.section-building {
  position: relative;
  overflow: hidden;
  background: #f7f7f7;
  z-index: 9;
  .organism-menu-right {
    right: 5.625rem;
    z-index: 9;
    @media only screen and (max-width: 767px) {
      right: 90px;
      display: none;
    }
  }
  .footer-bright {
    position: fixed;
    bottom: 0;
    left: 0;
    font-weight: $regular;
    padding: 0.313rem 1.25rem;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: $white;
    background: $blackOpacity06;
    z-index: 999;
  }
  .organism-building {
    transition: all 0.5s ease-in-out 0s;
    &.active {
      width: calc(100% - 30rem);
      @media only screen and (max-width: 767px) {
        width: 100% !important;
      }
    }
  }
  .orientation-nav {
    position: absolute;
    bottom: 2.25rem;
    left: 2rem;
    z-index: 9;
    @media only screen and (max-width: 767px) {
      top: initial;
      bottom: 5%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 85%;
      width: 23rem;
      &.nav-space-details-open {
        .mobile-app-info {
          background: #e5eaed;
        }
      }
    }
  }
  .drawer-buttons {
    cursor: pointer;
    position: absolute;
    top: 2.05rem;
    right: 1.25rem;
    display: flex;
    flex-direction: column;
    z-index: 10;
    transition: 0.5s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      top: 1.5rem;
      right: 2rem;
    }
    &.active {
      right: 31rem;
      @media only screen and (max-width: 767px) {
        right: 2rem;
      }
    }
  }
  .drawer-button {
    width: 3rem;
    height: 3rem;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease-in-out 0s;
    z-index: 999;
    position: relative;
    margin-bottom: 1rem;
    @media only screen and (max-width: 767px) {
      &.home-button {
        display: none;
      }
    }
    .number {
      position: absolute;
      top: -0.313rem;
      right: 0;
      transform: translate(25%);
      background: rgba(35, 205, 154, 1);
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.875rem;
    }
    .icon {
      font-size: 0.8rem !important;
      transition: none;
      &:before {
        margin: 0;
        width: auto;
      }
      &.icon-xmark {
        font-size: 1.3rem !important;
      }
    }
    &.drawer-button-black {
      background: $black;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.625rem;
      }
    }
    &.drawer-button-white {
      background: $white;
    }
    &.active {
      background: rgba(0, 0, 0, 0.5);
    }
    &.drawer-button-black:hover {
      background: rgba(0, 0, 0, 0.9);
      @media only screen and (max-width: 1200px) {
        background: $black;
      }
      .icon {
        color: $white !important;
        @media only screen and (max-width: 1200px) {
          color: $white !important;
        }
      }
    }
    &.drawer-button-white:hover {
      background: rgba(255, 255, 255, 0.9);
      @media only screen and (max-width: 1200px) {
        background: $white;
      }
      .icon {
        color: $black !important;
        @media only screen and (max-width: 1200px) {
          color: $black !important;
        }
      }
    }
  }

  &.embed-ready {
    .organism-sidebar-building {
      bottom: 4.688rem;
      top: initial;
      right: 50%;
      border-radius: 0.25rem;
      transform: translateX(50%);
      z-index: 9;
      @media only screen and (max-width: 1200px) {
        width: 21.563rem !important;
      }
      @media only screen and (max-width: 345px) {
        width: 96% !important;
      }
    }
    .organism-cart-spaces-building {
      position: absolute;
      bottom: 4.688rem;
      right: 50%;
      width: 25.938rem;
      transform: translateX(50%);
      @media only screen and (max-width: 1200px) {
        width: 21.563rem;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }
      .cart-spaces {
        position: relative;
        border-radius: 0.25rem !important;
        width: 100% !important;
        bottom: 0;
      }
    }
    .embed-controls {
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto 0.313rem;
      width: 25.938rem;
      display: flex;
      @media only screen and (max-width: 1200px) {
        width: 21.563rem;
      }
      @media only screen and (max-width: 345px) {
        width: 96%;
      }
      .control {
        display: flex;
        flex-direction: row;
        background: $white;
        border-radius: 0.25rem;
        height: 4.063rem;
        &.embed-menu-trigger {
          flex: 1;
          margin-right: 2.5px;
        }
        &.embed-cart-trigger {
          flex: 1;
          margin-left: 2.5px;
        }
        .description {
          position: relative;
          display: block;
          width: 100%;
          padding: 0.625rem;
          cursor: pointer;
          .custom-arrow {
            position: absolute;
            right: 0.625rem;
            top: 0.75rem;
            border-color: transparent transparent $black;
            border-style: solid;
            border-width: 0 0.313rem 0.375rem;
            height: 0;
            width: 0;
            display: inline-block;
            vertical-align: middle;
            transition: all 0.2s ease-in-out 0s;
          }
          p {
            font-size: 0.875rem;
            color: $black;
            font-weight: 400;
            line-height: 1.125rem;
            margin-bottom: 0.438rem;
            &:last-child {
              margin-bottom: 0;
            }
            &.green-color {
              color: #4cc66d !important;
            }
            .icon {
              font-size: 0.938rem !important;
              transition: all 0.2s ease-in-out 0s;
            }
            b {
              font-size: 0.875rem;
              line-height: 1.125rem;
            }
          }
        }
        &:hover {
          .description {
            .custom-arrow {
              border-color: transparent transparent #4cc66d;
            }
          }
        }
        &.active {
          background: $black;
          .description {
            .custom-arrow {
              border-color: $white transparent transparent;
              border-width: 0.375rem 0.313rem 0;
            }
            p {
              color: $white;
              .icon {
                color: $white !important;
              }
            }
          }
        }
      }
    }
  }
  .organism-loader-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>
