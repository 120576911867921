var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "atom-image-title",
      style: { "background-image": "url(" + _vm.image + ")" }
    },
    [_c("div", { staticClass: "background-gradient" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }