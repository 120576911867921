var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-sidebar-campaign" },
    [
      _c("MoleculeSidebarCampaign", { class: { active: _vm.open } }, [
        _c("div", { staticClass: "row row-header" }, [
          _c("h3", [_vm._v("Start a Campaign")]),
          _c(
            "div",
            { staticClass: "close-i", on: { click: _vm.handleClose } },
            [
              _vm._v("\n          Close\n          "),
              _c(
                "span",
                { staticClass: "close-button" },
                [_c("AtomIcon", { attrs: { icon: "xmark" } })],
                1
              )
            ]
          )
        ]),
        _c("div", { staticClass: "row row-steps align-items-center" }, [
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "step-number step-active" }, [
              _vm._v("1")
            ])
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "line" })
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c(
              "span",
              { staticClass: "step-number step-done" },
              [_c("AtomIcon", { attrs: { icon: "ok" } })],
              1
            )
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "line" })
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "step-number" }, [_vm._v("3")])
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "line" })
          ]),
          _c("div", { staticClass: "col col-adaptive" }, [
            _c("span", { staticClass: "step-number" }, [_vm._v("4")])
          ])
        ]),
        _c("div", { staticClass: "row row-title-steps" }, [
          _c("div", { staticClass: "col col-xs-12" }, [
            _c("div", { staticClass: "title-and-description" }, [
              _c("span", { staticClass: "step" }, [_vm._v("Step 1")]),
              _c("p", { staticClass: "title" }, [
                _vm._v("Choose type of campaign")
              ]),
              _c("p", { staticClass: "subtitle" }, [
                _vm._v("You can select more than one")
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }