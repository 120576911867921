<template>
  <div class="organism-contact-form">
    <AtomTitle color="#2F3030" color2="rgba(67,54,135,0.4)" title="Leave us a message" subtitle="We will get back to you as soon as possible" />
    <MoleculeContactForm/>
  </div>
</template>

<script>
import AtomTitle from "../../atoms/common/AtomTitle";
import MoleculeContactForm from "../../molecules/common/MoleculeContactForm";
export default {
  name: "OrganismContactForm",
  components: { MoleculeContactForm, AtomTitle}
}
</script>

<style lang="scss">
  .organism-contact-form {
    padding: 4.063rem 4.375rem;
    @media only screen and (max-width: 767px) {
      padding: 1.875rem 1.25rem;
    }
    .atom-title {
      h2 {
        font-weight: $medium;
        font-size: 1.375rem;
        line-height: 1.625rem;
      }
      h3 {
        font-weight: $regular;
        font-size: 0.75rem;
        line-height: 0.875rem;
        margin-top: 0.375rem;
        text-transform: initial;
      }
    }
    .atom-check-box {
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.875rem;
      }
    }
  }
</style>