var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-tabs-packages" },
    [
      _c("div", { staticClass: "row-tabs" }, [
        _c(
          "div",
          { staticClass: "tabs-wrapper" },
          _vm._l(_vm.tabs, function(tab, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tab-trigger",
                class: { active: _vm.activeTab === tab.tabId },
                on: {
                  click: function($event) {
                    return _vm.handleTabChange(tab.tabId)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(tab.tabLabel) + "\n      ")]
            )
          }),
          0
        )
      ]),
      _vm._l(_vm.tabs, function(tab, index) {
        return _c("div", { key: index }, [
          _vm.activeTab === tab.tabId
            ? _c(
                "div",
                [_c("OrganismPackages", { attrs: { packages: tab.packages } })],
                1
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }