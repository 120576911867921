import {
  Scene,
  TransformNode,
  UniversalCamera,
  Vector3,
  PostProcess,
  Effect,
} from "babylonjs";
import { CameraManager } from "@/components/organisms/project/building/3D/core/managers/CameraManager";
import * as gsap from "gsap";

export class FastTravelManager {
  camera: UniversalCamera;
  timer?: number;
  timerDuration = 1000; //milliseconds
  stairsTravelNodes: TransformNode[] = [];
  cameraManager: CameraManager;
  scene: Scene;
  nodeDistance = 15;
  fastTravelNodes = new Map<string, TransformNode>();
  floorFirstPersonCameraCoords: Array<{ x: number; y: number; z: number }> = [];
  fadeLevel = 1.0;

  constructor(
    camera: UniversalCamera,
    cameraManager: CameraManager,
    scene: Scene
  ) {
    this.camera = camera;
    this.cameraManager = cameraManager;
    this.scene = scene;
  }

  addCameraProximityDetection() {
    this.timer = setInterval(() => {
      const posCheck = this.checkCameraPosition(
        this.stairsTravelNodes,
        this.camera
      );
      if (posCheck.isClose && posCheck.node) {
        this.moveUpOrDown(posCheck.node, this.camera)
          ? console.log("go up?")
          : console.log("go down?");
      }
    }, this.timerDuration);
  }

  checkCameraPosition(
    fastTravelNodes: TransformNode[],
    camera: UniversalCamera
  ) {
    const returnObj: { isClose: boolean; node: null | TransformNode } = {
      isClose: false,
      node: null,
    };
    for (const node of fastTravelNodes) {
      //// adjust for scene transforms
      const adjustedValues = camera.position.clone();
      adjustedValues.x *= -1;
      const distance = Vector3.Distance(adjustedValues, node.position);
      if (distance < this.nodeDistance) {
        returnObj.isClose = true;
        returnObj.node = node;
      }
    }
    return returnObj;
  }

  moveUpOrDown(node: TransformNode, camera: UniversalCamera) {
    let isUp = false;
    for (const childNode of node.getChildren()) {
      if ((childNode as TransformNode).position.y > camera.position.y) {
        isUp = true;
      }
    }
    return isUp;
  }

  moveCamera(
    position: { x: number; y: number; z: number },
    target: { x: number; y: number; z: number }
  ) {
    if (!this.camera || !this.scene) return;
    this.cameraManager.triggerAnimations(
      this.camera,
      this.cameraManager.createMoveAndLookCameraAnimations(position, target, 1),
      4,
      () => {
        this.fade(1, 1);
        this.scene.getEngine().getRenderingCanvas()?.focus();
      }
    );
  }

  dispose() {
    clearInterval(this.timer);
  }

  setCameraCoords(coords: Array<{ x: number; y: number; z: number }>) {
    this.floorFirstPersonCameraCoords = coords;
  }

  moveCameraToFloor(floorNumber: number) {
    //// Save last position on previous floor
    this.floorFirstPersonCameraCoords[this.camera.metadata.floor] = {
      x: this.camera.position.x,
      y: this.camera.position.y,
      z: this.camera.position.z,
    };
    //// Set new position for camera
    this.camera.position.set(
      this.floorFirstPersonCameraCoords[floorNumber].x,
      this.floorFirstPersonCameraCoords[floorNumber].y,
      this.floorFirstPersonCameraCoords[floorNumber].z
    );
    this.camera.metadata.floor = floorNumber;
  }

  initFadePostProcess() {
    Effect.ShadersStore["fadePixelShader"] =
      "precision highp float;" +
      "varying vec2 vUV;" +
      "uniform sampler2D textureSampler; " +
      "uniform float fadeLevel; " +
      "void main(void){" +
      "vec4 baseColor = texture2D(textureSampler, vUV) * fadeLevel;" +
      "baseColor.a = 1.0;" +
      "gl_FragColor = baseColor;" +
      "}";
    const postProcess = new PostProcess(
      "Fade",
      "fade",
      ["fadeLevel"],
      null,
      1.0,
      this.camera
    );
    postProcess.onApply = (effect) => {
      effect.setFloat("fadeLevel", this.fadeLevel);
    };
  }

  fade(fadeDuration: number, fadeIn: number) {
    const promise = new Promise((resolve, reject) => {
      gsap.gsap.to(this, {
        fadeLevel: fadeIn,
        duration: fadeDuration,
        onComplete: () => {
          resolve(true);
        },
      });
    });
    return promise;
  }
}
