var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OrganismInfoPopup",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.open, expression: "open" }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "close-i", on: { click: _vm.handleInfoTrigger } },
        [_c("AtomIcon", { attrs: { icon: "xmark" } })],
        1
      ),
      _c("div", { staticClass: "row info-i" }, [
        _c("div", { staticClass: "col col-12" }, [
          _c("h4", [_vm._v("Easy Experience")])
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "content-info" }, [
            _c("div", { staticClass: "icon-atom-custom" }, [
              _c("img", {
                staticClass: "mouse-i",
                attrs: {
                  src:
                    "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/mouse-i.svg",
                  alt: "Mouse"
                }
              }),
              _c("p", [
                _vm._v(
                  "Press and drag to explore or scroll down for more information"
                )
              ])
            ]),
            _c("div", { staticClass: "icon-atom-custom" }, [
              _c("img", {
                staticClass: "cursor-i",
                attrs: {
                  src:
                    "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/cursor-i.svg",
                  alt: "Cursor"
                }
              }),
              _c("p", [
                _vm._v(
                  "Click and select an Area to navigate to another location"
                )
              ])
            ]),
            _c("div", { staticClass: "icon-atom-custom" }, [
              _c("img", {
                staticClass: "filter-i",
                attrs: {
                  src:
                    "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/filter-i.svg",
                  alt: "Filter"
                }
              }),
              _c("p", [
                _vm._v(
                  "\n            Use filters to filter and explore other digital or physical items\n          "
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "content-info" }, [
            _c("div", { staticClass: "icon-atom-custom" }, [
              _c("img", {
                staticClass: "menu-i",
                attrs: {
                  src:
                    "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/menu-i.svg",
                  alt: "Menu"
                }
              }),
              _c("p", [_vm._v("Use the menu to navigate through projects")])
            ]),
            _c("div", { staticClass: "icon-atom-custom" }, [
              _c("img", {
                staticClass: "esc-i",
                attrs: {
                  src:
                    "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/esc-i.svg",
                  alt: "Esc"
                }
              }),
              _c("p", [_vm._v("Press Escape to exit the selected area")])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }