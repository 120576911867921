<template>
  <div :class="{section: true, 'section-main': true, [`section${orderNo}`]: true,}" :style="{'min-height': `${windowHeight}px`}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="background-image-element" :style="{'background-image': `url(${resourceUrl(data.image)})`}">
      <div class="background-color-element"></div>
    </div>
    <div class="content-center">
      <AtomTitle :title="data.title" :subtitle="data.subtitle" />
      <router-link class="btn-general btn-general-white" tag="a" :to="{name: 'static-page-map'}">
        <span>{{ data.buttonText }}</span>
        <AtomIcon icon="arrow-right" />
      </router-link>
    </div>
    <div class="content-bottom">
      <OrganismMainBoxes :boxes-data="data.boxes" />
    </div>
    <span class="icon-scroll-down" @click="goToNextSection">
      <AtomIcon icon="angle-down" :size="17" color="#fff" />
    </span>
  </div>
</template>

<script>
import AtomTitle from "@/components/atoms/common/AtomTitle";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import OrganismMainBoxes from "@/components/organisms/common/OrganismMainBoxes";
import {convertUCWordsOrCamelCaseToDashCase, smoothScrollToTargetId} from "@/helpers/util";

export default {
  name: "SectionMain",
  components: {OrganismMainBoxes, AtomIcon, AtomTitle },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return [];
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    goToNextSection() {
      const sections = this.$parent.$parent.sections;
      if (sections && sections.length > 1) {
        const targetSection = sections[this.orderNo];
        const targetString = convertUCWordsOrCamelCaseToDashCase(targetSection.type);
        smoothScrollToTargetId(`#${targetString}`);
      }
    },
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
    }
  }
};
</script>

<style lang="scss">
  .section-main {
    .background-image-element {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      .background-color-element {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #194B5B;
        opacity: 0.9;
      }
    }
    .content-center {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 32.813rem;
      transform: translate(-50%, -50%);
      text-align: center;
      @media only screen and (max-width: 767px) {
        top: 35%;
        max-width: 24rem;
        width: 100%;
      }
      .atom-title {
        margin-bottom: 2rem;
        h2 {
          font-weight: $regular;
          font-size: 3.25rem;
          line-height: 3.875rem;
          margin-bottom: 0.75rem;
          letter-spacing: -0.02em;
          color: white;
          text-transform: none;
          b {
            font-weight: $bold;
            font-size: 3.25rem;
            line-height: 3.875rem;
          }
        }
        h3 {
          font-weight: 300;
          font-size: 1.375rem;
          line-height: 1.625rem;
          letter-spacing: -0.02em;
          color: white;
        }
      }
    }
    .content-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 2rem 5rem;
      z-index: 5;
      .organsim-main-boxes {
        @media only screen and (max-width: 767px) {
          text-align: center;
        }
        .row {
          justify-content: center;
          margin: 0 -2.188rem;
          .col {
            padding: 0 2.188rem;
            @media only screen and (max-width: 767px) {
              margin-bottom: 1rem;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        h2 {
          font-size: 3.25rem;
          line-height: 3.875rem;
          color: $white;
          @media only screen and (max-width: 767px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }
          span {
            font-size: 2rem;
            line-height: 2.375rem;
            @media only screen and (max-width: 767px) {
              font-size: 1.5rem;
              line-height: 1.875rem;
            }
          }
        }
        h3 {
          color: $white;
        }
      }
    }
    .icon-scroll-down {
      position: absolute;
      left: 50%;
      bottom: 1.25rem;
      z-index: 7;
      cursor: pointer;
      width: 1.688rem;
      height: 1.688rem;
      line-height: 1.6rem;
      text-align: center;
      border: 0.125rem solid rgba(255,255,255,0.1);
      border-radius: 1.688rem;
      transform: translateX(-50%);
      @media only screen and (max-width: 767px) {
        bottom: 2.1rem;
      }
      .icon {
        font-size: 1.063rem !important;
      }
    }
  }
</style>