var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-table-package" }, [
    _c(
      "div",
      { staticClass: "table-body" },
      [
        [
          _c("div", { staticClass: "row-table-body" }, [
            _c("div", { staticClass: "columns" }, [
              _c(
                "button",
                {
                  staticClass: "btn-trash-item btn-trash-item-physical",
                  on: {
                    click: function($event) {
                      return _vm.removeFromRequestPhysicalByArea(
                        _vm.buildingId,
                        _vm.pkey
                      )
                    }
                  }
                },
                [_c("AtomIcon", { attrs: { icon: "trash-can" } })],
                1
              ),
              _c("span", [_c("b", [_vm._v(_vm._s(_vm.area.name))])])
            ]),
            _c("div", { staticClass: "columns" }, [
              _c("span", [_c("b", [_vm._v(_vm._s(_vm.items.length))])])
            ])
          ])
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }