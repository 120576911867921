<template>
  <div class="molecule-sidebar-results">
    <div class="bottom-content" v-if="activeProject && activeTab === 'digital'">
      <AtomCollapsibleItem
        class="parent-box"
        v-for="building in listData.digitalItems"
        :key="`building_${building.id}`"
        :item="building"
        :ref="`building_${building.id}`"
        :is-default-open="true"
        :show-icon-text="true"
      >
        <template v-slot:title>
          <div class="title-box">
            <p class="title-text">{{ building.name }}</p>
            <span
              v-if="shortlistItemsLength && shortlistType === 'digital'"
              class="building-selected-options-count"
              >{{ shortlistItemsLength }}</span
            >
          </div>
        </template>
        <template v-slot:content>
          <AtomCollapsibleItem
            class="packs"
            v-for="(pack, name) in building.packs"
            :key="`pack_${name}`"
            :ref="`pack_${name}`"
          >
            <template v-slot:title>
              <div class="package-container">
                <span class="package-title">
                  {{ name }}
                  Package
                </span>
                <span class="package-title-length">
                  {{ pack.length }}
                  {{ pack.length > 1 ? "Options" : "Option" }}
                </span>
                <span
                  v-if="countSelectedPacks(pack, shortlistType)"
                  class="package-selected-options-count"
                  >{{ countSelectedPacks(pack, shortlistType) }}</span
                >
              </div>
            </template>
            <template v-slot:content>
              <div class="location-info">
                <span
                  >You can view the locations of the screens by tapping /
                  clicking on them</span
                >
              </div>
              <AtomCollapsibleItem
                class="pack-options"
                v-for="packOption in pack"
                :key="`packOption_${packOption.id}`"
                :item="packOption"
                :ref="`packOption_${packOption.id}`"
              >
                <template v-slot:title>
                  <div class="">
                    <span class="package-option-title">
                      {{ packOption.duration }}
                      Seconds
                    </span>
                    <AtomIcon icon="timer-v2" class="time-icon" />
                    <span class="package-broadcasts">
                      {{ packOption.hourly_broadcasts }} broadcasts/hour
                    </span>
                  </div>
                </template>
                <template v-slot:content>
                  <MoleculeTablePackage
                    :pack-id="packOption.id"
                    :digital-items="building.digitalItems"
                  />
                  <AtomItemsButtons
                    :digital-items="building.digitalItems"
                    :pack-option="packOption"
                    :building-id="building.id"
                    :item-id="packOption.id"
                    :type="activeTab"
                  />
                </template>
              </AtomCollapsibleItem>
            </template>
          </AtomCollapsibleItem>
        </template>
      </AtomCollapsibleItem>
    </div>
    <div
      class="physical-supports"
      v-if="activeProject && activeTab === 'physical'"
    >
      <AtomCollapsibleItem
        class="parent-box"
        v-for="building in listData.physicalItems"
        :key="`building_${building.id}`"
        :item="building"
        :ref="`building_${building.id}`"
        :is-default-open="true"
        :show-icon-text="true"
      >
        <template v-slot:title>
          <div class="title-box">
            <p class="title-text">{{ building.name }}</p>
            <span
              v-if="shortlistItemsLength && shortlistType === 'physical'"
              class="building-selected-options-count"
              >{{ shortlistItemsLength }}</span
            >
          </div>
        </template>
        <template v-slot:content>
          <AtomCollapsibleItem
            class="physical-items"
            v-for="(physicalItem, type) in building.physicalItems"
            :key="`physical_${type}`"
            :item="physicalItem"
            :ref="`physical_${type}`"
          >
            <template v-slot:title>
              <div class="physical-item-container">
                <span class="physical-item-title">
                  {{ type.charAt(0).toUpperCase() + type.slice(1) }}
                </span>
                <span class="physical-item-title-length">
                  {{ physicalItemTotalCount(physicalItem) }}
                </span>
                <span
                  v-if="countSelectedPacks(type, shortlistType)"
                  class="physical-selected-options-count"
                  >{{ countSelectedPacks(type, shortlistType) }}</span
                >
              </div>
            </template>
            <template v-slot:content>
              <AtomCollapsibleItem
                class="area-options"
                v-for="(area, name) in physicalItem"
                :key="`area_${name}`"
                :item="{ area }"
                :ref="`area_${name}`"
              >
                <template v-slot:title>
                  <div class="">
                    <span class="area-option-title">
                      {{ name }}
                    </span>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="margin-05">
                    <div class="area-items">
                      <div
                        class="area-item-option"
                        v-for="item in area"
                        :key="`item_${item.id}`"
                        :class="{
                          active: isActive(item.code),
                        }"
                      >
                        <span
                          class="hover-element"
                          @click="moveCameraToItem(item)"
                          v-show="item.coordinates_id"
                        >
                          <AtomIcon icon="act-ethically" />
                          <span> {{ getButtonText(item.code) }} </span>
                        </span>
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                    <AtomItemsButtons
                      :building-id="building.id"
                      :item-id="`areaId:${area[0].area_id}_type:${area[0].type}`"
                      :physical-item-type="area[0].type"
                      :type="activeTab"
                    />
                  </div>
                </template>
              </AtomCollapsibleItem>
            </template>
          </AtomCollapsibleItem>
        </template>
      </AtomCollapsibleItem>
    </div>
  </div>
</template>

<script>
import AtomCollapsibleItem from "@/components/atoms/common/AtomCollapsibleItem.vue";
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import MoleculeTablePackage from "@/components/molecules/MoleculeTablePackage.vue";
import AtomItemsButtons from "@/components/atoms/project/AtomItemsButtons.vue";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { FloorViewMode } from "@/components/organisms/project/building/store";
import buildingStore from "../../../organisms/project/building/store";

export default {
  name: "MoleculeSidebarResults",
  components: {
    MoleculeTablePackage,
    AtomIcon,
    AtomCollapsibleItem,
    AtomItemsButtons,
  },
  props: {
    activeTab: {
      required: true,
      type: String,
      default: "digital",
    },
  },
  data() {
    return {
      isSpaceListExpanded: false,
      listData: {
        digitalItems: [],
        physicalItems: [],
      },
    };
  },
  computed: {
    projectData() {
      return this.$store.state.project?.project || null;
    },
    projectConstants() {
      return this.$store.getters.constants.project;
    },
    activeProject() {
      return this.$store.state.project?.activeProject || null;
    },
    activeTabString() {
      return this.activeTab;
    },
    filters() {
      return this.$store.state.project?.filters || null;
    },
    buildingStore() {
      return buildingStore;
    },
    shortlistItemsLength() {
      if (this.activeTab === "digital") {
        return (
          this.$store.state.project?.shortlistRegistry?.items_ids?.length || 0
        );
      } else {
        return (
          this.$store.state.project?.shortlistRegistry?.typesAreas?.length || 0
        );
      }
    },
    shortlistType() {
      return this.$store.state.project?.shortlistRegistry?.type || null;
    },
    isActiveVirtualTour() {
      return this.$store.state?.base?.isActiveVirtualTour || false;
    },
    selectedItemView() {
      return this.$store.state?.base?.virtualTourLastItemClicked || null;
    },
  },
  watch: {
    projectData: {
      deep: true,
      handler: function () {
        if (this.activeTab === "digital") {
          this.refreshDigitalItems();
        }
        if (this.activeTab === "physical") {
          this.refreshPhysicalItems();
        }
      },
    },
    "$store.state.project.activeProject": function () {
      if (this.activeTabString === "digital") {
        if (this.activeTab === "digital") {
          this.refreshDigitalItems();
        }
        if (this.activeTab === "physical") {
          this.refreshPhysicalItems();
        }
      }
    },
    "$store.state.project.filters": {
      deep: true,
      handler: function () {
        if (this.activeTab === "digital") {
          this.refreshDigitalItems();
        }
        if (this.activeTab === "physical") {
          this.refreshPhysicalItems();
        }
      },
    },
    activeTab(newVal, oldVal) {
      if (oldVal !== newVal) {
        if (newVal === "digital") {
          this.refreshDigitalItems();
        }
        if (newVal === "physical") {
          this.refreshPhysicalItems();
        }
      }
    },
  },
  methods: {
    isActive(itemCode) {
      return this.selectedItemView?.code === itemCode;
    },
    toggleVirtualTour(isActive) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_VIRTUAL_TOUR),
        {
          isActive,
        }
      );
    },
    setVirtualLastFloorClicked(floorNo) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED
        ),
        {
          lastFloorClicked: floorNo,
        }
      );
    },
    changeViewMode3D() {
      this.clearVirtualTourPopupState();
      this.toggleVirtualTour(false);
      if (this.viewMode === FloorViewMode.DEFAULT) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.DEFAULT);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.DEFAULT);
      }
    },
    moveCameraToItem(item) {
      if (this.selectedItemView?.code === item?.code) {
        this.changeViewMode3D();
        this.setVirtualLastItemClicked();
        return;
      }
      this.toggleVirtualTour(true);
      this.setVirtualTourPopupState(item);
      this.setVirtualLastItemClicked(item);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.FIRST_PERSON);
        manager.moveFirstPersonCamera(
          item.position_coordinates,
          item.target_coordinates,
          item.code
        );
        setTimeout(() => {
          this.setVirtualLastFloorClicked(manager.getCameraFloor());
        }, 1200);
      }
    },
    getButtonText(itemCode) {
      if (this.selectedItemView?.code === itemCode)
        return "click to reset view";
      return "click to view item location";
    },
    setVirtualTourPopupState(item) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.TOGGLE_VIRTUAL_TOUR_POPUP
        ),
        {
          isActive: true,
          assets: item?.gallery?.assets || [],
          description: item?.description,
          title: item.name,
          code: item.code,
        }
      );
    },
    setVirtualLastItemClicked(item = null) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED
        ),
        {
          lastItemClicked: item,
        }
      );
    },
    clearVirtualTourPopupState() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.CLEAR_VIRTUAL_TOUR_POPUP
        )
      );
    },
    physicalItemTotalCount(physicalItem) {
      const count = Object.values(physicalItem).flat().length;
      return count + " " + (count > 1 ? "Options" : "Option");
    },
    countPhysicalItems(items) {
      let count = 0;
      if (Object?.values(items).length === 0) return 0;
      Object?.values(items)
        ?.flat()
        ?.forEach((pItem) => {
          count += Object?.values(pItem)?.flat()?.length || 0;
        });
      return count;
    },
    countPacks(packs) {
      let count = 0;
      if (Object?.values(packs).length === 0) return 0;
      Object?.values(packs)?.forEach((pack) => {
        count += pack?.length || 0;
      });
      return count;
    },
    countSelectedPacks(selected, type) {
      let optionsSelected = null;
      if (type === "digital" && this.activeTab === "digital") {
        optionsSelected = this.$store.state.project?.shortlistRegistry?.items_ids?.filter(
          (item) => selected.find((option) => option.id === item)
        );
      } else if (type === "physical" && this.activeTab === "physical") {
        const selectedTypesAreas = this.$store.state.project?.shortlistRegistry?.typesAreas?.filter(
          (item) => item.includes(selected)
        );
        return selectedTypesAreas.length || 0;
      }
      return optionsSelected?.length || 0;
    },
    refreshDigitalItems() {
      let counter = 0;
      let buildingsMetadata = this.projectData?.buildings || [];
      if (this.filters.digital.areas.length > 0) {
        buildingsMetadata = buildingsMetadata.filter((b) =>
          b.areas.some((a) => this.filters.digital.areas.includes(a.name))
        );
      }
      if (this.filters.digital.typeOfPackage !== "all") {
        buildingsMetadata = buildingsMetadata.map((b) => {
          let packs =
            b.packs?.filter(
              (p) => p.type === this.filters.digital.typeOfPackage
            ) || [];
          return {
            ...b,
            packs,
          };
        });
      }
      if (this.filters.digital.advertisingTiming !== "all") {
        buildingsMetadata = buildingsMetadata.map((b) => {
          let packs =
            b.packs?.filter(
              (p) => p.duration === this.filters.digital.advertisingTiming
            ) || [];
          return {
            ...b,
            packs,
          };
        });
      }
      buildingsMetadata = buildingsMetadata
        .map((b) => {
          let packs = b.packs;
          if (Array.isArray(b.packs)) {
            packs = b.packs.reduce(function (result, pack) {
              (result[pack.name] = result[pack.name] || []).push(pack);
              return result;
            }, {});
          }
          return {
            ...b,
            packs,
          };
        })
        .filter((b) => Object.keys(b.packs).length);

      this.listData.digitalItems = buildingsMetadata;

      Object.entries(this.listData.digitalItems)?.forEach(([key, d]) => {
        counter += this.countPacks(d.packs);
      });

      this.$store.dispatch(
        this.projectConstants.withNamespace(
          this.projectConstants.action.COUNT_RESULTS
        ),
        {
          digital: {
            totalResults: counter,
          },
        }
      );
    },
    refreshPhysicalItems() {
      let counter = 0;
      let buildingsMetadata = this.projectData?.buildings || [];
      if (this.filters.physical.areas.length > 0) {
        buildingsMetadata = buildingsMetadata.map((b) => {
          const filteredPhysicalItems =
            b.physicalItems.filter((p) => {
              if (this.filters.physical.areas.includes(p.area.name)) {
                return p;
              }
            }) || [];
          return {
            ...b,
            physicalItems: filteredPhysicalItems,
          };
        });
      }

      buildingsMetadata = buildingsMetadata.map((b) => {
        let physicalItems = b.physicalItems;
        if (Array.isArray(b.physicalItems)) {
          physicalItems = b.physicalItems.reduce((result, currentValue) => {
            result[currentValue.physicalItem.type] =
              result[currentValue.physicalItem.type] ?? [];
            result[currentValue.physicalItem.type].push(currentValue);
            return result;
          }, {});

          Object.keys(physicalItems).forEach((area) => {
            physicalItems[area] = physicalItems[area].reduce(
              (result, currentValue) => {
                result[currentValue.area.name] =
                  result[currentValue.area.name] ?? [];
                result[currentValue.area.name].push(currentValue.physicalItem);
                return result;
              },
              {}
            );
          });
        }
        return {
          ...b,
          physicalItems,
        };
      });

      if (this.filters.physical.devices.length > 0) {
        buildingsMetadata = buildingsMetadata.map((b) => {
          let physicalItems = {};
          Object.entries(b.physicalItems)?.forEach(([key, p]) => {
            if (this.filters.physical.devices.includes(key)) {
              physicalItems[key] = {};
              physicalItems[key] = p;
            }
          });

          return {
            ...b,
            physicalItems,
          };
        });
      }

      this.listData.physicalItems = buildingsMetadata.filter(
        (b) => Object.keys(b.physicalItems).length
      );

      Object.entries(this.listData.physicalItems)?.forEach(([key, p]) => {
        counter += this.countPhysicalItems(p.physicalItems);
      });

      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.COUNT_RESULTS),
        {
          physical: {
            totalResults: counter,
          },
        }
      );
    },
  },
};
</script>

<style lang="scss">
.molecule-sidebar-results {
  .title-box {
    .building-selected-options-count {
      transform: translate(25%);
      background: rgba(35, 205, 154, 1);
      width: 1.575rem;
      height: 1.575rem;
      border-radius: 100%;
      display: flex;
      margin-left: auto;
      margin-right: 1rem;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.875rem;
    }
  }

  .title-text {
    @include font-main(1.375rem, $black, $regular, 1.625rem);
  }

  &-action-buttons {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;

    span {
      width: 100%;
    }

    &_shortlist {
      background: $white;
    }
  }

  .parent-box > .item-header {
    padding-top: 0.75rem;

    .title-wrapper {
      padding: 0;
    }
  }

  .packs {
    background: $white;
    margin-bottom: 0.375rem;
    padding-bottom: 0.01rem;

    .item-header {
      padding: 0 !important;
    }

    .item-content {
      padding: 0 0 0.01rem !important;
    }

    .package-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .package-title {
      display: inline-block;
      background: $lightGrey;
      border-radius: 0.125rem;
      padding: 0.313rem 0.5rem;
      @include font-main(1rem, $black, $bold, 1.5rem);
    }

    .package-title-length {
      @include font-main(1rem, $black, $medium, 1.5rem);
      margin-left: 0.625rem;
    }

    &.active {
      .package-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 1.5rem);
      }
    }

    .location-info {
      margin-bottom: 0.313rem;
      @include font-main(0.875rem, #708c9d, $regular, 1.313rem);
    }

    .package-selected-options-count {
      transform: translate(25%);
      background: rgba(35, 205, 154, 1);
      width: 1.575rem;
      height: 1.575rem;
      border-radius: 100%;
      display: flex;
      margin-left: auto;
      margin-top: 0.5rem;
      margin-right: 1rem;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.875rem;
    }
  }

  .pack-options {
    background: $darkerWhite;
    margin-bottom: 0.375rem;

    .item-header {
      padding: 0 !important;

      .title-wrapper {
        padding: 0.5rem;
      }
    }

    .item-content {
      margin: 0;
    }

    .package-option-title {
      display: inline-block;
      background: $white;
      border-radius: 0.125rem;
      padding: 0.4rem 0.5rem;
      @include font-main(1rem, $black, $medium, 150%);
    }

    .package-broadcasts {
      @include font-main(1rem, $black, $medium, 150%);
    }

    .time-icon {
      margin-left: 0.625rem;
    }

    .option-info {
      display: flex;
      justify-content: space-between;
      @include font-main(0.875rem, $black, $regular, 130%);
    }

    .molecule-table-package {
      padding: 0 0.75rem 0.563rem;
      @media only screen and (max-width: 767px) {
        padding: 0 0 0.563rem;
      }

      .table-body {
        padding: 0.5rem;
      }

      .table-footer {
        padding: 0 0.75rem 0.75rem;
      }
    }

    .atoms-items-buttons {
      padding: 0 0.75rem 0.563rem;
      @media only screen and (max-width: 767px) {
        padding: 0 0 0.563rem;
      }
    }

    &.active {
      background: $lightGrey;

      .package-option-title {
        background: $black;
        color: $white;
      }

      .molecule-table-package,
      .molecule-table-item {
        background: $lightGrey;

        .table-body {
          background: $darkerWhite;
        }

        .table-footer {
          background: $darkerWhite;
        }
      }
    }
  }

  .physical-items {
    background: $white;
    margin-bottom: 0.375rem;

    .item-header {
      padding: 0 !important;
    }

    .item-content {
      padding: 0 0 0.01rem !important;
      @media only screen and (max-width: 767px) {
        padding: 0 0.5rem 0.01rem !important;
      }
    }

    .physical-item-title {
      background: $lightGrey;
      border-radius: 0.125rem;
      padding: 0.313rem;
      @include font-main(1rem, $black, $bold, 150%);
    }

    .physical-item-title-length {
      @include font-main(1rem, $black, $medium, 150%);
      margin-left: 0.625rem;
    }

    .physical-item-container {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .physical-selected-options-count {
      transform: translate(25%);
      background: rgba(35, 205, 154, 1);
      width: 1.575rem;
      height: 1.575rem;
      border-radius: 100%;
      display: flex;
      margin-left: auto;
      margin-top: 0.5rem;
      margin-right: 1rem;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 0.875rem;
    }

    &.active {
      .physical-item-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 150%);
      }
    }
  }

  .area-options {
    background: $lightGrey;
    margin-bottom: 0.375rem;

    .item-header {
      padding: 0;
    }

    .area-option-title {
      background: $white;
      border-radius: 0.125rem;
      padding: 0.313rem;
      @include font-main(1rem, $black, $medium, 150%);
    }

    &.active {
      .area-option-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 150%);
      }
    }

    .margin-05 {
      margin: 0 -0.5rem;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }
    }

    .area-items {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .area-item-option {
      position: relative;
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;
      background: $darkerWhite;
      border: 1px solid $darkerWhite;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }

      span {
        @include font-main(1rem, $black, $regular, 1.188rem);
      }

      .hover-element {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        background: rgba(233, 237, 239, 0.85);
        opacity: 0;
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;

        .icon {
          font-size: 1rem !important;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.25rem;
          margin-top: 0.125rem;
        }
      }

      &:hover {
        .hover-element {
          opacity: 1;
        }
      }

      &.active {
        cursor: pointer;
        background: #e9edef;

        //.hover-element {
        //  display: none;
        //}

        span {
          font-weight: $bold;
        }
      }
    }
  }
}
</style>
