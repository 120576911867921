var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-building-orientation-bar" }, [
    _c("div", { staticClass: "display-inline info-mobile-building" }, [
      _c(
        "div",
        {
          staticClass: "orientation-button",
          class: { active: _vm.open },
          on: { click: _vm.handleInfoTrigger }
        },
        [
          _c("AtomIcon", {
            attrs: { icon: _vm.open ? "xmark" : "info", size: 20 }
          })
        ],
        1
      )
    ]),
    _vm._m(0),
    _c("div", { staticClass: "display-inline" }, [
      _c(
        "div",
        {
          ref: "button3d",
          staticClass: "button-tooltip",
          on: { click: _vm.changeViewMode3D }
        },
        [
          _c(
            "span",
            {
              staticClass: "btn-tooltip",
              class: { active: !_vm.isActiveVirtualTour }
            },
            [_vm._v("3D")]
          ),
          _c("p", { staticClass: "hidden-mobile-elements" }, [
            _vm._v("Select 3D View Mode")
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "display-inline" }, [
      _c(
        "div",
        {
          ref: "buttonVt",
          staticClass: "button-tooltip",
          on: { click: _vm.changeViewModeFirstPerson }
        },
        [
          _c(
            "span",
            {
              staticClass: "btn-tooltip",
              class: { active: _vm.isActiveVirtualTour }
            },
            [_c("AtomIcon", { attrs: { icon: "street-view", size: 20 } })],
            1
          ),
          _c("p", { staticClass: "hidden-mobile-elements" }, [
            _vm._v("Enter Virtual Tour")
          ])
        ]
      )
    ]),
    _vm._m(1),
    _vm.selectedArea
      ? _c(
          "div",
          { staticClass: "display-inline area-parent" },
          [
            _c(
              "div",
              {
                staticClass: "area-button",
                class: { active: _vm.openArea },
                on: { click: _vm.handleAreaTrigger }
              },
              [
                _c("div", { staticClass: "button-arrow" }, [
                  _c(
                    "div",
                    { staticClass: "button-arrow-wrapper" },
                    [_c("AtomIcon", { attrs: { icon: "arrow-up" } })],
                    1
                  )
                ]),
                _c("div", { staticClass: "area-state" }, [
                  _c("h5", [_vm._v("Area")]),
                  _c("p", [_vm._v(_vm._s(_vm.selectedArea.name))])
                ])
              ]
            ),
            _c(
              "OrganismInfoAreaPopup",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.openArea,
                    expression: "openArea"
                  }
                ]
              },
              [
                _c("h3", [_vm._v("All Areas")]),
                _c(
                  "ul",
                  { staticClass: "list-areas" },
                  _vm._l(_vm.areas, function(area) {
                    return _c(
                      "li",
                      {
                        key: "area_" + area.id,
                        staticClass: "area-name",
                        class: { active: _vm.selectedAreaId === area.id },
                        on: {
                          click: function($event) {
                            return _vm.handleAreaSelected(area)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(area.name) + "\n        "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.pinsDataBuilding.length > 0
      ? _c(
          "div",
          {
            staticClass: "display-inline specs-toggle-parent",
            on: { click: _vm.handleTogglePins }
          },
          [
            _c(
              "div",
              {
                staticClass: "specs-toggle-button",
                class: { active: _vm.showPins }
              },
              [
                _c("div", { staticClass: "button-specs-toggle" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "button-general-specs-toggle button-left-specs-toggle"
                    },
                    [_c("AtomIcon", { attrs: { icon: "no-specs" } })],
                    1
                  ),
                  _c("span", { staticClass: "circle-black" }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "button-general-specs-toggle button-right-specs-toggle"
                    },
                    [_c("AtomIcon", { attrs: { icon: "chair-office" } })],
                    1
                  )
                ]),
                _vm._m(2)
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "display-inline" }, [
      _c("span", { staticClass: "vertical-line" }, [_vm._v("|")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "display-inline" }, [
      _c("span", { staticClass: "vertical-line" }, [_vm._v("|")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "specs-toggle-state" }, [
      _c("h5", [_vm._v("Mall Specs")]),
      _c("p", [
        _c("span", { staticClass: "active-state" }, [_vm._v("Active")]),
        _c("span", { staticClass: "none-state" }, [_vm._v("None")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }