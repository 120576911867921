<template>
  <div :class="{ 'organism-info-area-popup': true, open: isOpen }">
    <slot></slot>
  </div>
</template>
<script>
import { baseConstants } from "@/store/modules";

export default {
  name: "OrganismInfoAreaPopup",
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].infoOpen;
    },
  },
};
</script>

<style lang="scss">
.organism-info-area-popup {
  display: block;
  position: absolute;
  bottom: 100%;
  left: 0;
  background: $white;
  padding: 1.25rem 1.563rem;
  margin-bottom: 0.688rem;
  width: 21.5rem;
  z-index: 9;
  transition: all 0.5s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    left: 0.5rem;
    width: 24rem;
    transform: translateX(-50%);
  }
  &.open {
    display: block;
  }
  h3 {
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 1rem;
    @include font-main(1rem, rgba(0, 0, 0, 0.4), $regular, 1.188rem);
  }
  .list-areas {
    list-style: none;
    .area-name {
      cursor: pointer;
      margin-bottom: 0.25rem;
      @include font-main(1rem, $black, $regular, 1.188rem);
      &:hover,
      &.active {
        color: #23cd9a;
        text-decoration: underline;
      }
    }
  }
}
</style>
