var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page landing" },
    _vm._l(_vm.sections, function(section, key) {
      return _c("SectionSelector", {
        key: key,
        attrs: {
          enabled: section.enabled,
          hash: section.hashAnchor,
          variant: section.variant,
          title: section.title,
          subtitle: section.subtitle,
          data: section.data,
          extra: section.extra || {},
          section: section.type,
          "order-no": section.order_no
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }