var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal && _vm.modalType === "shortlist-rules"
    ? _c(
        "div",
        {
          staticClass: "molecule-shortlist-rules",
          attrs: { id: "shortlist-rules-popup" }
        },
        [
          _c("div", { staticClass: "popup-screen" }, [
            _c(
              "span",
              { staticClass: "btn-close-popup", on: { click: _vm.closeModal } },
              [_c("AtomIcon", { attrs: { icon: "xmark" } })],
              1
            ),
            _c("div", { staticClass: "row shortlist-rules" }, [
              _c("div", { staticClass: "col col-12" }, [
                _c("div", { staticClass: "scroll-disable" }, [
                  _c(
                    "div",
                    { staticClass: "molecule-shortlist-rules-container" },
                    [
                      _c("AtomIcon", { attrs: { icon: "warning-icon" } }),
                      _c(
                        "h1",
                        { staticClass: "molecule-shortlist-rules-title" },
                        [_vm._v("Shortlist Rules")]
                      ),
                      _c("p", [
                        _vm._v(
                          "\n              At the moment you can only shortlist one type of media: digital\n              or physical. You can send multiple request if you are interested\n              in both types.\n            "
                        )
                      ]),
                      _c("h2", [_vm._v("How do you wish to proceed?")]),
                      _c(
                        "div",
                        { staticClass: "molecule-shortlist-rules-buttons" },
                        [
                          _c("AtomButton", {
                            staticClass: "btn-white",
                            attrs: {
                              label: "Cancel",
                              "on-click": _vm.closeModal
                            }
                          }),
                          _c("AtomButton", {
                            staticClass: "btn-black",
                            attrs: { label: "Replace", "on-click": _vm.replace }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }