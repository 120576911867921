var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-video-slider slide" }, [
    _c("div", { staticClass: "image-relative" }, [
      _vm.sliderVideo.image && !_vm.isPlaying
        ? _c(
            "div",
            {
              staticClass: "overlay-play",
              style:
                "background-image: url(" +
                _vm.resourceUrl(_vm.sliderVideo.image) +
                ")"
            },
            [
              !_vm.isHostedVideo
                ? _c(
                    "span",
                    { staticClass: "play-video", on: { click: _vm.play } },
                    [_c("AtomIcon", { attrs: { icon: "play" } })],
                    1
                  )
                : _c(
                    "span",
                    {
                      staticClass: "play-video",
                      on: { click: _vm.playIframe }
                    },
                    [_c("AtomIcon", { attrs: { icon: "play" } })],
                    1
                  )
            ]
          )
        : _vm._e(),
      _vm.isHostedVideo
        ? _c("iframe", {
            ref: "iframe",
            staticClass: "background-image",
            attrs: { src: _vm.assetUrl }
          })
        : _c(
            "video",
            {
              ref: "video",
              staticClass: "background-image video-background",
              attrs: { controls: "" }
            },
            [_c("source", { attrs: { type: "video/mp4", src: _vm.assetUrl } })]
          ),
      _c("img", {
        attrs: {
          src:
            "https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/placeholder-for-full-size-image.png",
          alt: "Placeholder"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }