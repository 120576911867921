var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-brochure": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: { "min-height": _vm.windowHeight + "px" }
    },
    [
      _c(
        "div",
        {
          staticClass: "carousel-content portfolio-carousel",
          style: { "min-height": _vm.windowHeight + "px" }
        },
        [
          _c("div", {
            staticClass: "background-image",
            style: {
              "background-image":
                "url(" + _vm.resourceUrl(_vm.data.backgroundImage) + ")"
            }
          }),
          _c(
            "div",
            { staticClass: "left-slide" },
            [
              _c("div", { staticClass: "background-purple" }),
              _c("h2", [_vm._v(_vm._s(_vm.data.title))]),
              _c(
                "div",
                { staticClass: "certifications" },
                _vm._l(_vm.data.certifications, function(certification, i) {
                  return _c("img", {
                    key: i,
                    attrs: {
                      src: _vm.resourceUrl(certification.image),
                      alt: certification.alt
                    }
                  })
                }),
                0
              ),
              _vm.data.sold
                ? _c("span", { staticClass: "sold-info" }, [_vm._v("Divested")])
                : _vm._e(),
              _vm.data.dataDelivery
                ? _c("span", { staticClass: "data-delivery" }, [
                    _vm._v(_vm._s(_vm.data.dataDelivery))
                  ])
                : _vm._e(),
              _c("div", { staticClass: "horizontally-line" }),
              _c("OrganismContentSliderBrochure", {
                attrs: { "slider-content-data": _vm.data.slider }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "row buttons-bottom-absolute text-center" },
            [
              _vm.data.urlBookMeATour
                ? _c("div", { staticClass: "col col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button-style",
                        attrs: { href: "" + _vm.data.urlBookMeATour }
                      },
                      [_vm._v("Book me a tour")]
                    )
                  ])
                : _vm._e(),
              _vm.data.urlBrochure
                ? _c("div", { staticClass: "col col-auto" }, [
                    _c(
                      "a",
                      {
                        staticClass: "button-style",
                        attrs: {
                          target: "_blank",
                          href: "" + _vm.resourceUrl(_vm.data.urlBrochure)
                        }
                      },
                      [_vm._v("Brochure")]
                    )
                  ])
                : _vm._e(),
              _vm._m(0)
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col col-auto" }, [
      _c(
        "a",
        {
          staticClass: "button-style",
          attrs: { href: "mailto:leasing@one.ro" }
        },
        [_vm._v("Contact")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }