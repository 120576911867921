<template>
  <div :class="{section: true, 'section-location-v2': true, [`section${orderNo}`]: true}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.heading || data.description">
      <AtomTitle :title="data.heading" :subtitle="data.description" />
    </div>
    <OrganismLocation :location-data="data" />
  </div>
</template>

<script>
import OrganismLocation from "../../../../components/organisms/project/OrganismLocation";
import AtomTitle from "../../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionLocationV2",
  components: {AtomTitle, OrganismLocation},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  created() {
  }
}
</script>

<style lang="scss">
  .section-location-v2 {
    padding: 4rem 0 0;
    @media only screen and (max-width: 767px) {
      padding: 2.5rem 0 0;
    }
    .top-title {
      .atom-title {
        padding: 0 3.75rem;
        margin-bottom: 3.125rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 1.938rem;
          padding: 0 2rem;
        }
        h2 {
          color: $blueDark !important;
          font-weight: 400;
          font-size: 3.25rem;
          line-height: 3.875rem;
          text-transform: none;
          @media only screen and (max-width: 767px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }
          b {
            font-size: 3.25rem;
            line-height: 3.875rem;
            @media only screen and (max-width: 767px) {
              font-size: 2.5rem;
              line-height: 3rem;
            }
          }
        }
        h3 {
          color: $blueGreen !important;
          font-weight: 400;
          font-size: 1.375rem;
          line-height: 1.625rem;
          @media only screen and (max-width: 767px) {
            font-size: 1.125rem;
            line-height: 1.313rem;
            margin-top: 0.375rem;
          }
        }
      }
    }
    .organism-location {
      .atom-image-title {
        .background-gradient {
          background: transparent;
          padding: 0 2.188rem 2.188rem;
          @media only screen and (max-width: 767px) {
            padding: 0 2rem 1.25rem;
          }
          .atom-title {
            h2 {
              color: $blueDark !important;
              font-weight: 400;
              font-size: 2rem;
              line-height: 2.375rem;
              text-transform: none;
              margin-bottom: 0.25rem;
              @media only screen and (max-width: 767px) {
                font-size: 2.1rem;
                line-height: 2.6rem;
              }
              b {
                font-size: 2rem;
                line-height: 2.375rem;
                @media only screen and (max-width: 767px) {
                  font-size: 2.1rem;
                  line-height: 2.6rem;
                }
              }
            }
            h3 {
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.188rem;
              color: $blueGreen !important;
              margin: 0;
              @media only screen and (max-width: 767px) {
                font-size: 1.125rem;
                line-height: 1.313rem;
                margin-top: 0.375rem;
              }
            }
          }
        }
      }
      .sidebar {
        top: 6rem;
        height: 88.8%;
        width: 33.5rem;
        @media only screen and (max-width: 767px) {
          top: 9.5rem;
          right: initial;
          left: -110%;
          width: 100%;
          height: 40.938rem;
          &.active {
            left: 0;
          }
        }
        .sidebar-locations {
          height: 28.125rem;
          margin-bottom: 1.25rem;
          @media only screen and (max-width: 767px) {
            height: 26rem;
            margin-bottom: 0.938rem;
          }
          .wrapper-sidebar {
            .atom-location-type {
              background: #F8FAFC;
              .change-info-boxes {
                padding: 1.25rem 9.375rem 1.25rem 1.25rem;
                border-color: transparent;
                font-weight: 700;
                font-size: 1.125rem;
                line-height: 1.313rem;
                text-transform: uppercase;
                @media only screen and (max-width: 767px) {
                  padding: 0.938rem 9rem 0.938rem 2rem;
                  font-size: 0.875rem;
                  line-height: 1.125rem;
                }
                .buttons-tabs {
                  right: 3.75rem;
                  @media only screen and (max-width: 767px) {
                    right: 4.5rem;
                  }
                  span {
                    .icon {
                      color: $blue !important;
                    }
                    &:hover, &.active {
                      background: $blue;
                      .icon {
                        color: $white !important;
                      }
                    }
                  }
                }
                .circle {
                  right: 0.938rem;
                  opacity: 0;
                  transition: all 0.2s ease-in-out 0s;
                  @media only screen and (max-width: 767px) {
                    right: 2rem;
                    opacity: 1;
                  }
                  .icon {
                    color: $black !important;
                  }
                }
              }
              .atom-route {
                padding: 0.75rem 9.688rem 0.75rem 50px;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.188rem;
                border-color: rgba(0,0,0,0.05);
                @media only screen and (max-width: 767px) {
                  padding: 0.625rem 9.5rem 0.625rem 3.5rem;
                  font-size: 0.75rem;
                  line-height: 1rem;
                }
                b {
                  left: 1.25rem;
                  color: $blue;
                  font-weight: 400;
                  transition: all 0.2s ease-in-out 0s;
                  @media only screen and (max-width: 767px) {
                    left: 2rem;
                  }
                }
                .add-time {
                  right: 1.25rem;
                  @media only screen and (max-width: 767px) {
                    right: 2rem;
                  }
                  .icon {
                    color: $blue !important;
                  }
                }
                &:last-child {
                  border: none;
                  border-radius: 0 0 0.625rem 0.625rem;
                  @media only screen and (max-width: 767px) {
                    border-radius: 0;
                  }
                }
                &:hover, &.atom-route-active {
                  background: $blue;
                  b {
                    color: $white;
                  }
                  .add-time {
                    .icon {
                      color: $white !important;
                    }
                  }
                }
              }
              &:hover {
                .change-info-boxes {
                  .circle {
                    opacity: 1;
                  }
                }
              }
              &.active {
                background: #F5F5F5;
                border-radius: 0.625rem;
                margin: 0.25rem 0;
                @media only screen and (max-width: 767px) {
                  border-radius: 0;
                }
                .change-info-boxes {
                  border-color: rgba(0,0,0,0.05);
                  .circle {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>