var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-media-contact-form" },
    [
      _c("MoleculeDynamicModal"),
      _c(
        "form",
        {
          ref: "contactForm",
          attrs: { action: "javascript:void(0);" },
          on: { submit: _vm.submitForm }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col col-12" },
              [
                _c("AtomInput", {
                  attrs: {
                    type: _vm.inputTypes.TEXT,
                    value: _vm.fullName,
                    label: "Full Name*",
                    placeholder: "Enter your first and last name",
                    error: _vm.errors.fullName
                  },
                  on: {
                    input: function($event) {
                      _vm.fullName = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col col-12" },
              [
                _c("AtomInput", {
                  attrs: {
                    type: _vm.inputTypes.TEXT,
                    value: _vm.email,
                    label: "Email address*",
                    placeholder: "Enter email address",
                    error: _vm.errors.email
                  },
                  on: {
                    input: function($event) {
                      _vm.email = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col col-12" },
              [
                _c("AtomInput", {
                  attrs: {
                    type: _vm.inputTypes.TEXT,
                    value: _vm.phone,
                    label: "Phone Number*",
                    placeholder: "Enter phone number",
                    error: _vm.errors.phone
                  },
                  on: {
                    input: function($event) {
                      _vm.phone = $event
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col col-12" },
              [
                _c("AtomButton", {
                  staticClass: "btn-general-second-color",
                  attrs: { label: "Submit" }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }