var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "organsim-table" }, [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.tableData.header, function(header, index) {
          return _c("AtomTableHeader", {
            key: index,
            attrs: { text: header.text }
          })
        }),
        1
      )
    ]),
    _c(
      "tbody",
      _vm._l(_vm.tableData.body, function(rowLines, index) {
        return _c(
          "MoleculeTableBody",
          { key: index },
          _vm._l(rowLines.textes, function(body, index) {
            return _c("AtomTableBody", {
              key: index,
              attrs: { text: body.text }
            })
          }),
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }