<template>
  <div class="row-package-info active">
    <div class="header-info">
      <slot name="header"></slot>
      <AtomIcon icon="ok" />
    </div>
    <div class="body-info">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../atoms/common/AtomIcon";
export default {
  name: "MoleculeCollapsiblePackages",
  components: { AtomIcon },
};
</script>

<style lang="scss" scoped></style>
