import { AnimationBuilder } from "@/components/organisms/project/building/3D/core/builders/AnimationBuilder";
import { FreeCameraKeyboardWalkInput } from "@/components/organisms/project/building/3D/core/builders/FreeCameraKeyboardWalkInput";
import {
  Animation,
  ArcRotateCamera,
  EasingFunction,
  SineEase,
  Vector3,
  FreeCameraKeyboardMoveInput,
  Ray,
} from "babylonjs";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";
import { SCENE_VIEW } from "@/components/organisms/project/building/3D/core/builders/Constants";
import Constants from "../builders/Constants";

export class CameraManager {
  cameraRadiusLimit: number;
  sceneManager;
  constructor() {
    this.cameraRadiusLimit = 0;
    this.sceneManager = BabylonClientManager.getSceneManager();
  }

  createMoveAndLookCameraAnimations(
    position: { x: number; y: number; z: number },
    target: { x: number; y: number; z: number },
    speed = 1
  ) {
    if (!this.sceneManager || !this.sceneManager.cameraFirstPerson) {
      console.error("Camera Manager not defined");
      return [];
    }

    const moveAnimation = AnimationBuilder.createAnimation(
      "position",
      this.sceneManager.cameraFirstPerson.position,
      new Vector3(position.x, position.y, position.z),
      AnimationBuilder.speedRatio / speed,
      Animation.ANIMATIONTYPE_VECTOR3
    );
    const lookAtAnimation = AnimationBuilder.createAnimation(
      "target",
      this.sceneManager.cameraFirstPerson.target,
      new Vector3(target.x, target.y, target.z),
      AnimationBuilder.speedRatio / speed,
      Animation.ANIMATIONTYPE_VECTOR3
    );
    const ease = new SineEase();
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT);
    moveAnimation.setEasingFunction(ease);
    lookAtAnimation.setEasingFunction(ease);

    return [moveAnimation, lookAtAnimation];
  }

  triggerAnimations(
    animationObject: any,
    animations: Animation[],
    speed: number,
    onanimationend?: () => void
  ) {
    this.sceneManager?.scene.beginDirectAnimation(
      animationObject,
      animations,
      AnimationBuilder.fromFrame,
      AnimationBuilder.toFrame,
      AnimationBuilder.isLoop,
      speed,
      onanimationend
    );
  }

  moveArcRotateCamera(
    options: {
      radius: number;
      alpha: number;
      beta: number;
      target: {
        x: number;
        y: number;
        z: number;
      };
    },
    speed: number,
    onAnimationEnd?: () => void
  ) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    this.sceneManager.scene.stopAllAnimations();
    this.sceneManager.camera3DView.animations = AnimationBuilder.createMoveArcRotateCamera(
      this.sceneManager.camera3DView,
      options,
      speed
    );
    this.sceneManager.scene.beginAnimation(
      this.sceneManager.camera3DView,
      AnimationBuilder.fromFrame,
      AnimationBuilder.toFrame,
      AnimationBuilder.isLoop,
      speed,
      onAnimationEnd
    );
  }

  moveCameraTo2D(onAnimationEnd?: () => void) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    const options = {
      radius: this.sceneManager.camera3DView.radius,
      alpha: this.sceneManager.camera3DView.alpha,
      target: this.sceneManager.camera3DView.target,
      beta: 0,
    };
    this.moveArcRotateCamera(options, 1, onAnimationEnd);
  }

  moveCameraTo3D(onAnimationEnd?: () => void) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Camera Manager not defined");
    const options = {
      radius: this.sceneManager.camera3DView.radius,
      alpha: this.sceneManager.camera3DView.alpha,
      target: this.sceneManager.camera3DView.target,
      beta: 1,
    };
    this.moveArcRotateCamera(options, 1, onAnimationEnd);
  }

  zoomCamera(zoomValue: number, camera: ArcRotateCamera, zoomFactor: number) {
    camera.upperRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
    camera.lowerRadiusLimit = this.cameraRadiusLimit - zoomValue * zoomFactor;
  }

  lockCameraAxis(upperBetaLimit: number, lowerBetaLimit: number) {
    if (!this.sceneManager || !this.sceneManager.camera3DView)
      return console.error("Scene Manager not defined");
    this.sceneManager.camera3DView.upperBetaLimit = upperBetaLimit;
    this.sceneManager.camera3DView.lowerBetaLimit = lowerBetaLimit;
  }

  setActiveCamera(cameraType: "ArcRotate" | "Universal") {
    if (!this.sceneManager) return console.error("Scene Manager not defined");
    const canvas = this.sceneManager.scene.getEngine().getRenderingCanvas();
    if (cameraType == "ArcRotate") {
      this.sceneManager.cameraFirstPerson?.detachControl();
      this.sceneManager.camera3DView?.attachControl(canvas);
    } else if (cameraType == "Universal") {
      this.sceneManager.camera3DView?.detachControl();
      this.sceneManager.cameraFirstPerson?.attachControl(canvas);
    }
  }

  switchCamera(view: string) {
    if (
      !this.sceneManager ||
      !this.sceneManager.camera3DView ||
      !this.sceneManager.cameraFirstPerson
    )
      return console.error("Scene Manager is not defined");
    switch (view) {
      case "2D":
        Utils.updateSceneProperties(this.sceneManager.scene, true);
        this.sceneManager.scene.cameraToUseForPointers = this.sceneManager.camera3DView;
        this.sceneManager.pointerCircle?.togglePointer(false);
        this.sceneManager.minimap?.toggle(false);
        this.sceneManager.actualSceneView = SCENE_VIEW.TWO_DIMENSIONAL;
        this.setActiveCamera("ArcRotate");
        this.moveCameraTo2D(() => {
          this.lockCameraAxis(0, 0);
        });
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.camera3DView
        );
        this.sceneManager?.pinManager?.unselectPin();
        break;
      case "default":
        Utils.updateSceneProperties(this.sceneManager.scene, true);
        this.sceneManager.scene.cameraToUseForPointers = this.sceneManager.camera3DView;
        this.sceneManager.pointerCircle?.togglePointer(false);
        this.sceneManager.minimap?.toggle(false);
        this.sceneManager.actualSceneView = SCENE_VIEW.DEFAULT;
        this.setActiveCamera("ArcRotate");
        this.moveCameraTo3D();
        this.lockCameraAxis(1.5708, 0);
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.camera3DView
        );
        this.sceneManager?.pinManager?.unselectPin();
        break;
      case "firstPerson":
        Utils.updateSceneProperties(this.sceneManager.scene, false);
        this.sceneManager.scene.cameraToUseForPointers = this.sceneManager.cameraFirstPerson;
        this.sceneManager.setPointerY();
        this.sceneManager.pointerCircle?.togglePointer(true);
        this.sceneManager.minimap?.toggle(true);
        this.sceneManager.actualSceneView = SCENE_VIEW.FIRST_PERSON;
        this.setActiveCamera("Universal");
        this.sceneManager.scene.activeCameras = [];
        this.sceneManager.scene.activeCameras?.push(
          this.sceneManager.cameraFirstPerson
        );
        this.sceneManager?.pinManager?.unselectPin();
        this.sceneManager?.scene.onAfterRenderObservable.addOnce(() => {
          this.sceneManager?.scene.getEngine().getRenderingCanvas()?.focus();
        });
        break;
      default:
    }
  }
  addVirtualTourControls(options: unknown) {
    if (!this.sceneManager || !this.sceneManager.cameraFirstPerson)
      return console.error("Scene Manager is not defined");

    const camera = this.sceneManager.cameraFirstPerson;
    //// Set camera properties
    Object.assign(camera, options);

    //// Customize keyboard
    const keyboard = camera.inputs.attached
      .keyboard as FreeCameraKeyboardMoveInput;

    keyboard.keysUp = [38, 87];
    keyboard.keysDown = [40, 83];
    keyboard.keysLeft = [81];
    keyboard.keysRight = [69];
    keyboard.keysRotateLeft = [39, 68];
    keyboard.keysRotateRight = [37, 65];

    camera.invertRotation = true;
    const walkInput = new FreeCameraKeyboardWalkInput();
    walkInput.camera = camera;
    camera.inputs.remove(camera.inputs.attached.touch);
    camera.inputs.add(walkInput);

    // After each camera input change render the scene
    camera.onViewMatrixChangedObservable.add(() => {
      if (camera && camera.metadata.checkYPosition) {
        //// y position
        const ray = new Ray(camera.position, new Vector3(0, -1, 0), 10);
        const hit = camera.getScene().pickWithRay(ray);
        let dist = 0;
        if (
          hit &&
          hit.pickedMesh &&
          hit.pickedMesh.parent &&
          hit.pickedMesh.parent.name == Constants.WALKABLE_FLOOR_NAME
        ) {
          dist = hit.distance;
        }

        if (!dist) return console.error("Camera raycast distance undefined");
        if (dist < camera.metadata.y) {
          const dif = camera.metadata.y - dist;
          camera.position.y += dif;
        } else if (dist > camera.metadata.y) {
          const dif = dist - camera.metadata.y;
          camera.position.y -= dif;
        }
        this.sceneManager?.updateCameraFloorStore();
      }
    });
  }
}
