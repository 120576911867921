<template>
  <div class="molecule-sidebar-results">
    <div
      class="bottom-content"
      v-if="Object.entries(shortlistGroupedItems).length > 0"
    >
      <div
        class="package-category"
        v-for="[parentKey, typeOfItems] in Object.entries(
          shortlistGroupedItems
        )"
        :key="`type-${parentKey}`"
      >
        <h2 class="center-category" v-if="showCategory(typeOfItems.buildings)">
          {{ parentKey.charAt(0).toUpperCase() + parentKey.slice(1) }} items
        </h2>
        <div v-if="showCategory(typeOfItems.buildings)">
          <AtomCollapsibleItem
            class="parent-box"
            v-for="(building, idx) in typeOfItems.buildings"
            :key="`building_${idx}`"
            :ref="`building_${idx}`"
            :is-default-open="true"
          >
            <template v-slot:title v-if="getBuildingName(building.id)">
              <div class="title-box">
                <p class="title-text">
                  {{ getBuildingName(building.id) }}
                </p>
              </div>
            </template>
            <template v-slot:content>
              <AtomCollapsibleItem
                class="packs"
                v-for="[optionType, options] in Object.entries(
                  building.options
                )"
                :key="`option_${optionType}`"
                :ref="`option_${optionType}`"
              >
                <template v-slot:title>
                  <div class="">
                    <span class="package-title">
                      {{
                        optionType.charAt(0).toUpperCase() + optionType.slice(1)
                      }}
                      Package
                    </span>
                    <span class="package-title-length">
                      {{ options.length }}
                      {{ options.length > 1 ? "Options" : "Option" }}
                    </span>
                  </div>
                </template>
                <template v-slot:content>
                  <div class="location-info">
                    <span
                      >You can view the locations of the screens by tapping /
                      clicking on them</span
                    >
                  </div>
                  <AtomCollapsibleItem
                    class="pack-options"
                    v-for="opt in options"
                    :key="`item_${opt.unique_key}`"
                    :item="opt"
                    :ref="`item_${opt.unique_key}`"
                  >
                    <template v-slot:title>
                      <div class="">
                        <div class="display-inline">
                          <button
                            class="btn-trash-item"
                            @click="
                              removeFromShortlist(
                                parentKey,
                                building.id,
                                opt.unique_key
                              )
                            "
                          >
                            <AtomIcon icon="trash-can" />
                          </button>
                        </div>
                        <span class="package-option-title" v-if="opt.duration">
                          {{ opt.duration }}
                          Seconds
                        </span>
                        <span
                          class="package-option-title"
                          v-else-if="opt.area && opt.area.name"
                        >
                          {{ opt.area.name }}
                        </span>
                        <AtomIcon
                          icon="timer-v2"
                          class="time-icon"
                          v-if="opt.duration"
                        />
                        <span
                          class="package-broadcasts"
                          v-if="opt.hourly_broadcasts"
                        >
                          {{ opt.hourly_broadcasts }} broadcasts/hour
                        </span>
                      </div>
                    </template>
                    <template v-slot:content>
                      <MoleculeTableItem
                        :items="opt.items"
                        :option-id="opt.unique_key"
                      />
                      <div class="shortlist-remove-item-btn">
                        <button
                          class="btn-general btn-white hover-red icon-left font-medium"
                          @click="
                            removeFromShortlist(
                              parentKey,
                              building.id,
                              opt.unique_key
                            )
                          "
                        >
                          <AtomIcon icon="remove-shortlist" />
                          <span>Remove Item</span>
                        </button>
                      </div>
                    </template>
                  </AtomCollapsibleItem>
                </template>
              </AtomCollapsibleItem>
            </template>
          </AtomCollapsibleItem>
        </div>
      </div>
    </div>
    <div class="shortlist-request-offer-btn">
      <AtomButton
        class="btn-black icon-left font-bold"
        label="Request Offer"
        custom-label="Request"
        :on-click="requestOffer"
        activate-hover-event
      />
    </div>
  </div>
</template>

<script>
import AtomCollapsibleItem from "@/components/atoms/common/AtomCollapsibleItem.vue";
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import AtomButton from "../../atoms/common/AtomButton";
import { ItemTypes } from "@/store/modules/shortlist/types";
import MoleculeTableItem from "./MoleculeTableItem";

export default {
  name: "MoleculeSidebarShortlistItems",
  components: {
    MoleculeTableItem,
    AtomButton,
    AtomIcon,
    AtomCollapsibleItem,
  },
  computed: {
    buildings() {
      return this.$store.state.project?.buildings || [];
    },
    projectConstants() {
      return this.$store.getters.constants.project;
    },
    baseConstants() {
      return this.$store.getters.constants.base;
    },
    activeProjectSlug() {
      return this.$store.state.project?.activeProject || null;
    },
    shortlistItems() {
      return this.$store.state.shortlist || null;
    },
    shortlistType() {
      let shortlistType = null;
      if (this.shortlistItems?.digital?.buildings?.length > 0) {
        shortlistType = ItemTypes.DIGITAL;
      } else if (this.shortlistItems?.physical?.buildings?.length > 0) {
        shortlistType = ItemTypes.PHYSICAL;
      }
      return shortlistType;
    },
    shortlistGroupedItems() {
      const items = {
        digital: {
          buildings: [],
        },
        physical: {
          buildings: [],
        },
      };
      if (!items) return null;
      Object.entries(this.shortlistItems).forEach(([key, shortlistType]) => {
        switch (key) {
          case ItemTypes.DIGITAL: {
            shortlistType.buildings.forEach((b, idx) => {
              items.digital.buildings[idx] = { ...b };
              if (!b.options || b.options.length === 0) return;
              for (const opt of b.options) {
                opt.unique_key = opt.pack_id;
              }
              items.digital.buildings[idx].options = this.groupBy(
                b.options,
                (opt) => opt.type
              );
            });
            break;
          }
          case ItemTypes.PHYSICAL: {
            shortlistType.buildings.forEach((b, idx) => {
              items.physical.buildings[idx] = { ...b };
              if (!b.options || b.options.length === 0) return;
              for (const opt of b.options) {
                opt.unique_key = `areaId:${opt.area.id}_type:${opt.type}`;
              }
              items.physical.buildings[idx].options = this.groupBy(
                b.options,
                (opt) => opt.type
              );
            });
            break;
          }
          default:
            break;
        }
      });
      return items;
    },
    shortlistConstants() {
      return this.$store.getters.constants.shortlist;
    },
  },
  methods: {
    groupBy(arr, keyGetter) {
      const out = {};
      for (let item of arr) {
        const key = keyGetter(item);
        out[key] ??= [];
        out[key].push(item);
      }
      return out;
    },
    showCategory(buildings) {
      return buildings?.flatMap((b) => b?.options || [])?.length || false;
    },
    removeFromShortlist(type, buildingId, itemId) {
      if (type === ItemTypes.DIGITAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_DIGITAL_FROM_SHORTLIST
          ),
          {
            building_id: buildingId,
            item_id: itemId,
          }
        );
      }
      if (type === ItemTypes.PHYSICAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_PHYSICAL_FROM_SHORTLIST
          ),
          {
            building_id: buildingId,
            item_id: itemId,
          }
        );
      }
    },
    physicalItemTotalCount(physicalItem) {
      const count = Object.values(physicalItem).flat().length;
      return count + " " + (count > 1 ? "Options" : "Option");
    },
    requestOffer() {
      const requestOfferConstants = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOfferConstants.withNamespace(
          requestOfferConstants.action.MULTIPLE_REQUEST_OFFER
        )
      );
      this.$store.dispatch(
        requestOfferConstants.withNamespace(
          requestOfferConstants.action.CHANGE_MODAL_REQUEST_TYPE
        ),
        "shortlist"
      );
      this.$store.dispatch(
        this.baseConstants.withNamespace(
          this.baseConstants.action.TOGGLE_REQUEST_OFFER
        ),
        { open: true }
      );
    },
    getBuildingName(buildingId) {
      const fBuilding =
        this.buildings?.find((build) => build.id === buildingId) || null;
      if (!fBuilding) return;
      return fBuilding.name;
    },
  },
};
</script>

<style lang="scss">
.molecule-sidebar-results {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .shortlist-remove-item-btn {
    width: 100%;
    padding: 0 0.75rem 0.5rem;
    @media only screen and (max-width: 767px) {
      padding: 0 0 0.5rem;
    }
    button {
      width: 100%;
    }
  }
  .title-text {
    @include font-main(1.375rem, $black, $regular, 1.625rem);
  }
  .package-category {
    margin: 0 0 1rem;
    .center-category {
      padding: 0.5rem 1.25rem;
      background: $black;
      @include font-main(1rem, $white, $bold, 150%);
    }
  }
  .parent-box > .item-header {
    padding-top: 0.75rem;

    .title-wrapper {
      padding: 0;
    }
  }
  .packs {
    background: $white;
    margin-bottom: 0.375rem;
    padding-bottom: 0.01rem;
    .item-header {
      padding: 0 !important;
    }
    .item-content {
      padding: 0 !important;
      @media only screen and (max-width: 767px) {
        padding: 0 0.5rem !important;
      }
    }
    .package-title {
      background: $lightGrey;
      border-radius: 0.125rem;
      padding: 0.313rem;
      @include font-main(1rem, $black, $bold, 150%);
    }
    .package-title-length {
      @include font-main(1rem, $black, $medium, 150%);
      margin-left: 0.625rem;
    }
    &.active {
      .package-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 150%);
      }
    }
    .location-info {
      @include font-main(0.875rem, #708c9d, $regular, 150%);
    }
  }

  .display-inline {
    display: inline-block;
    vertical-align: middle;
  }

  .atom-collapsible-item {
    .item-header {
      .title-wrapper {
        .btn-trash-item {
          width: 2.125rem;
          height: 2.125rem;
          border-radius: 2.125rem;
          background: transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          margin-top: -0.063rem;
          .icon {
            font-size: 1rem !important;
            margin: 0 !important;
          }

          &:hover {
            background: rgba(239, 48, 117, 0.1);

            .icon {
              color: #ef3075 !important;
            }
          }
        }
      }
    }
  }
  .pack-options {
    position: relative;
    background: $darkerWhite;
    margin-bottom: 0.375rem;
    .item-header {
      padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    }
    .package-option-title {
      background: $white;
      border-radius: 0.125rem;
      padding: 0.4rem 0.5rem;
      @include font-main(1rem, $black, $medium, 150%);
    }
    .package-broadcasts {
      @include font-main(1rem, $black, $medium, 150%);
    }
    .time-icon {
      margin-left: 0.625rem;
    }
    .option-info {
      display: flex;
      justify-content: space-between;
      @include font-main(0.875rem, $black, $regular, 130%);
    }
    &.active {
      background: $lightGrey;

      .package-option-title {
        background: $black;
        @include font-main(1rem, $white, $regular, 150%);
      }
      .molecule-table-item {
        padding: 0 0.75rem 0.563rem;
        @media only screen and (max-width: 767px) {
          padding: 0 0 0.563rem;
        }
      }
      .molecule-table-package,
      .molecule-table-item {
        background: $lightGrey;

        .table-body {
          padding: 0.5rem 0.75rem 0.25rem 0.75rem;
          background: $darkerWhite;
        }

        .table-footer {
          padding: 0 0.75rem 0.75rem;
          background: $darkerWhite;
        }
      }
    }
    .location-info {
      @include font-main(0.875rem, #708c9d, $regular, 150%);
    }
  }
  .physical-items {
    background: $white;
    margin-bottom: 0.375rem;
    .physical-item-title {
      background: $lightGrey;
      border-radius: 0.125rem;
      padding: 0.313rem;
      @include font-main(1rem, $black, $bold, 150%);
    }
    .physical-item-title-length {
      @include font-main(1rem, $black, $medium, 150%);
      margin-left: 0.625rem;
    }
    &.active {
      .physical-item-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 150%);
      }
    }
  }
  .area-options {
    background: $lightGrey;
    margin-bottom: 0.375rem;
    .area-option-title {
      display: inline-block;
      background: $white;
      border-radius: 0.125rem;
      padding: 0.313rem;
      @include font-main(1rem, $black, $medium, 150%);
    }
    &.active {
      .area-option-title {
        background: $black;
        @include font-main(1rem, $white, $bold, 150%);
      }
    }
    .margin-05 {
      margin: 0 -0.5rem;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }

      .shortlist-remove-item-btn {
        padding: 0.5rem;
        @media only screen and (max-width: 767px) {
          padding: 0.5rem 0;
        }
      }
    }
    .area-items {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      @media only screen and (max-width: 767px) {
        gap: 0.5rem;
      }
    }

    .area-item-option {
      position: relative;
      margin: 0 0.5rem;
      padding: 0.5rem 1rem;
      background: $darkerWhite;
      border: 1px solid $darkerWhite;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }

      span {
        @include font-main(1rem, $black, $regular, 1.188rem);
      }

      .hover-element {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        background: rgba(233, 237, 239, 0.85);
        opacity: 0;
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;

        .icon {
          font-size: 1rem !important;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.25rem;
          margin-top: 0.125rem;
        }
      }

      &:hover {
        .hover-element {
          opacity: 1;
        }
      }

      &.active {
        cursor: pointer;
        background: #e9edef;

        //.hover-element {
        //  display: none;
        //}

        span {
          font-weight: $bold;
        }
      }
    }
  }
  .shortlist-request-offer-btn {
    width: 100%;
    padding: 0.5rem;

    .btn-general {
      width: 100%;
    }
  }
}
</style>
