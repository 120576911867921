var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showModal && _vm.modalType === "dynamic"
    ? _c(
        "div",
        {
          staticClass: "molecule-dynamic-modal",
          attrs: { id: "dynamic-modal-popup" }
        },
        [
          _c("div", { staticClass: "popup-screen" }, [
            _c(
              "span",
              { staticClass: "btn-close-popup", on: { click: _vm.closeModal } },
              [_c("AtomIcon", { attrs: { icon: "xmark" } })],
              1
            ),
            _c("div", { staticClass: "row shortlist-rules" }, [
              _c("div", { staticClass: "col col-12" }, [
                _c("div", { staticClass: "scroll-disable" }, [
                  _c(
                    "div",
                    { staticClass: "molecule-dynamic-modal-container" },
                    [
                      _c("AtomIcon", { attrs: { icon: "warning-icon" } }),
                      _c(
                        "h1",
                        { staticClass: "molecule-dynamic-modal-title" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.modalData.title) +
                              "\n            "
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.modalData.text))]),
                      _vm.modalData.question
                        ? _c("h2", [_vm._v(_vm._s(_vm.modalData.question))])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "molecule-dynamic-modal-buttons" },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn-general btn-white molecule-dynamic-modal-btn-cancel",
                              on: { click: _vm.closeModal }
                            },
                            [_vm._v("\n                Close\n              ")]
                          ),
                          _vm.actionButton
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn-general btn-black",
                                  on: { click: _vm.replace }
                                },
                                [
                                  _vm._v(
                                    "\n                Replace\n              "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ])
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }