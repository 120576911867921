var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.section || !_vm.enabled
    ? _c("div")
    : _vm.section === "Building"
    ? _c("SectionBuilding", {
        ref: "buildingSection",
        attrs: {
          hash: _vm.hash,
          data: _vm.activeDataSet,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Features"
    ? _c("SectionFeatures", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Sustainability"
    ? _c("SectionSustainability", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Parking"
    ? _c("SectionParking", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Specs"
    ? _c("SectionSpecs", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Location"
    ? _c("SectionLocation", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Gallery"
    ? _c("SectionGallery", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "Explore"
    ? _c("SectionExploreBuilding", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "Contact"
    ? _c("SectionContact", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm.section === "ImageList"
    ? _c("SectionImageList", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "ImageOrVideo"
    ? _c("SectionImageOrVideo", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          extra: _vm.extra,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "FewFacts"
    ? _c("SectionFewFacts", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "IconsDescriptions"
    ? _c("SectionIconsDescriptions", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm.section === "DiscoverArea"
    ? _c("SectionDiscoverArea", {
        attrs: {
          data: _vm.activeDataSet,
          hash: _vm.hash,
          "order-no": _vm.orderNo
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }