<template>
  <div class="molecule-table-item">
    <div class="table-header">
      <div class="row-table-header">
        <div class="columns">
          <span>Type of item</span>
        </div>
        <div class="columns" v-if="totalDailyBroadcasts !== 0">
          <span>Total No. of broadcasts</span>
        </div>
      </div>
    </div>
    <div class="table-body">
      <template v-for="item in items">
        <div class="row-table-body" :key="`item_${item.id}`">
          <div
            class="row-state-element"
            :class="{ active: isActive(item.code) }"
          >
            <span
              class="hover-element"
              v-show="item.coordinates_id"
              @click="moveCameraToItem(item)"
            >
              <AtomIcon icon="act-ethically" />
              <span> {{ getButtonText(item.code) }} </span></span
            >
            <div class="columns">
              <span>
                <b>{{ item.name }}</b>
              </span>
            </div>
            <div class="columns" v-if="dailyBroadcasts(item)">
              <span>
                <b>{{ dailyBroadcasts(item) }}</b> / day
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="table-footer">
      <div class="row-table-footer">
        <div class="columns">
          <span>Total</span>
        </div>
        <div class="columns" v-if="totalDailyBroadcasts !== 0">
          <span
            ><b>{{ totalDailyBroadcasts }}</b> / day</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buildingStore, {
  FloorViewMode,
} from "@/components/organisms/project/building/store";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import AtomIcon from "../../atoms/common/AtomIcon";

export default {
  name: "MoleculeTableItem",
  components: { AtomIcon },
  props: {
    items: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
    optionId: {
      required: false,
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    totalDailyBroadcasts() {
      if (this.items.length === 0) return 0;
      return this.items.reduce((total, item) => {
        if (item && item.digitalPack) {
          const digitalPack =
            item.digitalPack.find(
              (broadcasts) => broadcasts.pack_id === this.optionId
            ) || null;
          if (!digitalPack) return 0;
          return total + (digitalPack ? digitalPack.daily_broadcasts : 0);
        }
        return 0;
      }, 0);
    },
    buildingStore() {
      return buildingStore;
    },
    selectedItemView() {
      return this.$store.state?.base?.virtualTourLastItemClicked || null;
    },
  },
  methods: {
    isActive(itemCode) {
      return this.selectedItemView?.code === itemCode;
    },
    dailyBroadcasts(item) {
      if (item && item.digitalPack) {
        const digitalPack =
          item.digitalPack.find(
            (broadcasts) => broadcasts.pack_id === this.optionId
          ) || null;
        if (!digitalPack) return null;
        return digitalPack.daily_broadcasts || null;
      }
      return null;
    },
    changeViewMode3D() {
      this.clearVirtualTourPopupState();
      this.toggleVirtualTour(false);
      if (this.viewMode === FloorViewMode.DEFAULT) return;
      this.buildingStore.dispatch("changeViewMode", FloorViewMode.DEFAULT);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.DEFAULT);
      }
    },
    toggleVirtualTour(isActive) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_VIRTUAL_TOUR),
        {
          isActive,
        }
      );
    },
    setVirtualLastFloorClicked(floorNo) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED
        ),
        {
          lastFloorClicked: floorNo,
        }
      );
    },
    moveCameraToItem(item) {
      if (this.selectedItemView?.code === item?.code) {
        this.changeViewMode3D();
        this.setVirtualLastItemClicked();
        return;
      }
      this.toggleVirtualTour(true);
      this.setVirtualTourPopupState(item);
      this.setVirtualLastItemClicked(item);
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.switchCamera(FloorViewMode.FIRST_PERSON);
        manager.moveFirstPersonCamera(
          item.position_coordinates,
          item.target_coordinates,
          item.code
        );
        setTimeout(() => {
          this.setVirtualLastFloorClicked(manager.getCameraFloor());
        }, 1200);
      }
    },
    setVirtualTourPopupState(item) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.TOGGLE_VIRTUAL_TOUR_POPUP
        ),
        {
          isActive: true,
          assets: item?.gallery?.assets || [],
          description: item?.description,
          title: item.name,
          code: item.code,
        }
      );
    },
    setVirtualLastItemClicked(item = null) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED
        ),
        {
          lastItemClicked: item,
        }
      );
    },
    clearVirtualTourPopupState() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.CLEAR_VIRTUAL_TOUR_POPUP
        )
      );
    },
    getButtonText(itemCode) {
      if (this.selectedItemView?.code === itemCode)
        return "click to reset view";
      return "click to view item location";
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-table-item {
  .row-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.188rem;
  }

  .row-table-body {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1);

    &:last-child {
      border-color: $black;
      margin-bottom: 0.813rem;
    }

    .row-state-element {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 0.375rem 0;

      .hover-element {
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        background: rgba(233, 237, 239, 0.85);
        opacity: 0;
        transition: all 0.2s ease-in-out 0s;
        cursor: pointer;

        .icon {
          font-size: 1rem !important;
          display: inline-block;
          vertical-align: middle;
          margin-right: 0.25rem;
          margin-top: 0.125rem;
        }
      }

      &:hover {
        .hover-element {
          opacity: 1;
        }
      }

      &.active {
        cursor: pointer;
        background: #e9edef;

        b {
          font-weight: $bold;
        }
      }
    }
  }

  .row-table-footer {
    display: flex;
    justify-content: space-between;
  }

  span {
    letter-spacing: 0.01em;
    @include font-main(0.875rem, $black, $regular, 1.063rem);

    b {
      @include font-main(1rem, $black, $medium, 1.188rem);
    }
  }
}
</style>
