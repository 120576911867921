var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "molecule-filters" },
    [
      _c("MoleculeFilterHeader", {
        attrs: { activeTab: _vm.activeTab },
        on: {
          "toggle-filters": _vm.toggleFilters,
          "re-init-filters": _vm.handleReInitFilters
        }
      }),
      _vm.activeTabString === "digital"
        ? _c("MoleculeDigitalFilters", {
            attrs: {
              showFilters: _vm.showFilters,
              reInitFilters: _vm.reInitFilters
            },
            on: { filterChange: _vm.handleFilterChange }
          })
        : _vm._e(),
      _vm.activeTabString === "physical"
        ? _c("MoleculePhysicalFilters", {
            attrs: {
              showFilters: _vm.showFilters,
              reInitFilters: _vm.reInitFilters
            },
            on: { filterChange: _vm.handleFilterChange }
          })
        : _vm._e(),
      _c("AtomFilterResult", { attrs: { activeTab: _vm.activeTabString } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }