var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OrganismInfoPopup",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.open, expression: "open" }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "close-i", on: { click: _vm.handleInfoTrigger } },
        [_c("AtomIcon", { attrs: { icon: "xmark" } })],
        1
      ),
      _vm.data.header
        ? _c("p", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.data.header) }
          })
        : _vm._e(),
      _c("OrganismTableGeneral", {
        staticClass: "media-info-table",
        attrs: { "table-data": _vm.data.tableData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }