<template>
  <div class="locations-map">
    <div class="organism-main-locations">
      <MoleculeMainLocations>
        <AtomMainLocations v-for="(location, index) in locationsData.projects"
                              :active-project="activeProject"
                              :tab-id="`item-${index}`"
                              :project-id="location.id"
                              :image="location.image"
                              :title="location.title"
                              :image-text="location.imageText"
                              :button-text="location.buttonText"
                              :button-url="location.buttonUrl"
                              :key="index" />
      </MoleculeMainLocations>
    </div>
    <MoleculeGoogleMap ref="map" :map-config="locationsData.map" />
  </div>
</template>

<script>
import MoleculeGoogleMap from "../../molecules/common/MoleculeGoogleMap";
import MoleculeMainLocations from "@/components/molecules/portfolio/MoleculeMainLocations";
import AtomMainLocations from "@/components/atoms/portfolio/AtomMainLocations";

export default {
  name: "OrganismMainLocations",
  components: {
    AtomMainLocations,
    MoleculeMainLocations, MoleculeGoogleMap},
  props: {
    locationsData: {
      required: true,
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      activeProject: 'item-0',
    }
  }
}
</script>

<style lang="scss">
  .locations-map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .organism-main-locations {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 5;
      width: 100%;
    }
  }
</style>