<template>
  <div class="organism-image-and-table">
    <div
      class="row row-image-and-table align-items-center"
      v-for="(rowElement, index) in contentData"
      :class="{ 'flex-row-reverse': rowElement.reverseColumn }"
      :key="index"
    >
      <div class="col col-xs-12 col-6">
        <img :src="resourceUrl(rowElement.image)" :alt="rowElement.title" />
      </div>
      <div class="col col-xs-12 col-6">
        <div class="content-slider">
          <p
            class="title"
            v-if="rowElement.title"
            v-html="rowElement.title"
          ></p>
          <OrganismTableGeneral
            v-if="rowElement.tableData"
            :table-data="rowElement.tableData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganismTableGeneral from "./table/OrganismTableGeneral";
import PageLanding from "../../../views/portfolio/PageLanding";

export default {
  name: "OrganismImageAndTable",
  components: {
    PageLanding,
    OrganismTableGeneral,
  },
  props: {
    contentData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
    id: {
      required: false,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
};
</script>

<style lang="scss">
.organism-image-and-table {
  .row-image-and-table {
    margin-bottom: 5rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 3rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.flex-row-reverse {
      flex-direction: row-reverse !important;
    }
    & > .col {
      padding: 0 2.25rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
    img {
      display: block;
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }
    }
    .content-slider {
      margin: 0 auto;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }
      .title {
        margin-bottom: 1.5rem;
        letter-spacing: -0.065rem;
        @include font-main(3.25rem, $main-color, $light, normal);
        @media only screen and (max-width: 767px) {
          @include font-main(2.5rem, $main-color, $light, normal);
        }
        b {
          @include font-main(3.25rem, $main-color, $bold, normal);
          @media only screen and (max-width: 767px) {
            @include font-main(2.5rem, $main-color, $bold, normal);
          }
        }
        span {
          display: block;
          opacity: 0.3;
          @include font-main(2rem, $main-color, $bold, normal);
          @media only screen and (max-width: 767px) {
            @include font-main(1.7rem, $main-color, $bold, normal);
          }
        }
      }
      .organism-table-general {
        width: 40rem;
        max-width: 100%;
        border-spacing: 0;
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
        tr {
          th,
          td {
            padding: 0 0.625rem;
            background: transparent !important;
            &:first-child {
              text-align: left;
              padding-left: 0;
            }
            &:last-child {
              text-align: right;
              padding-right: 0;
            }
          }
          th {
            padding-bottom: 0.625rem;
            @include font-main(1rem, rgba(25, 75, 91, 0.6), $regular, normal);
          }
          td {
            padding-bottom: 1.5rem;
            @include font-main(1.25rem, $second-color, $regular, normal);
            b {
              @include font-main(1.25rem, $second-color, $bold, normal);
            }
            span {
              display: block;
              @include font-main(1rem, rgba(25, 75, 91, 0.8), $regular, normal);
            }
          }
        }
      }
    }
  }
}
</style>
