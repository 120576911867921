var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = { section: true, "section-main": true }),
        (_obj["section" + _vm.orderNo] = true),
        _obj),
      style: { "min-height": _vm.windowHeight + "px" }
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c(
        "div",
        {
          staticClass: "background-image-element",
          style: {
            "background-image": "url(" + _vm.resourceUrl(_vm.data.image) + ")"
          }
        },
        [_c("div", { staticClass: "background-color-element" })]
      ),
      _c(
        "div",
        { staticClass: "content-center" },
        [
          _c("AtomTitle", {
            attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle }
          }),
          _c(
            "router-link",
            {
              staticClass: "btn-general btn-general-white",
              attrs: { tag: "a", to: { name: "static-page-map" } }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.data.buttonText))]),
              _c("AtomIcon", { attrs: { icon: "arrow-right" } })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-bottom" },
        [_c("OrganismMainBoxes", { attrs: { "boxes-data": _vm.data.boxes } })],
        1
      ),
      _c(
        "span",
        { staticClass: "icon-scroll-down", on: { click: _vm.goToNextSection } },
        [
          _c("AtomIcon", {
            attrs: { icon: "angle-down", size: 17, color: "#fff" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }