<template>
  <div :class="{section: true, 'section-parking': true, [`section${orderNo}`]: true, topPadding: data.heading || data.description}">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="top-title" v-if="data.heading || data.description">
      <AtomTitle :title="data.heading" :subtitle="data.description" />
    </div>
    <OrganismParkingAccess :parking-access="data.items" :image-url="data.image" />
  </div>
</template>

<script>
import OrganismParkingAccess from "../../../components/organisms/common/OrganismParkingAccess";
import AtomTitle from "../../../components/atoms/common/AtomTitle";
export default {
  name: "SectionParking",
  components: {AtomTitle, OrganismParkingAccess},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
  },
  computed: {
  },
  data() {
    return {};
  },
  methods: {
  }
}
</script>

<style lang="scss">
  .topPadding{
    padding: 4rem 0 0;
    @media only screen and (max-width: 767px) {
      padding: 40px 0 0;
    }
  }
  .top-title {
    .atom-title {
      //padding: 0 3.75rem;
      //margin-bottom: 3.125rem;
      //@media only screen and (max-width: 767px) {
      //  margin-bottom: 30px;
      //  padding: 0 10px;
      //}
      h2 {
        color: $blueDark !important;
        font-weight: 400;
        font-size: 3.25rem;
        line-height: 3.875rem;
        text-transform: none;
        @media only screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
        }
        b {
          font-size: 3.25rem;
          line-height: 3.875rem;
          @media only screen and (max-width: 767px) {
            font-size: 22px;
            line-height: 26px;
          }
        }
      }
      h3 {
        color: $blueGreen !important;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 19px;
        }
      }
    }
  }
  .section-parking {
    @media only screen and (max-width: 767px) {
      margin-top: 120px;
    }
    img {
      width: 100%;
    }
  }
</style>