var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "atom-list" },
    _vm._l(_vm.lines, function(line, index) {
      return _c("li", { key: index }, [
        line.link
          ? _c(
              "a",
              { class: { active: line.active }, attrs: { href: line.link } },
              [
                line.icon
                  ? _c("AtomIcon", { attrs: { icon: line.icon } })
                  : _vm._e(),
                line.text
                  ? _c("span", { domProps: { innerHTML: _vm._s(line.text) } })
                  : _vm._e()
              ],
              1
            )
          : _c(
              "p",
              [
                line.icon
                  ? _c("AtomIcon", { attrs: { icon: line.icon } })
                  : _vm._e(),
                line.text
                  ? _c("span", { domProps: { innerHTML: _vm._s(line.text) } })
                  : _vm._e()
              ],
              1
            )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }