<template>
  <div v-if="title" class="atom-title-line">
    <p class="title" v-html="title"></p>
  </div>
</template>

<script>
export default {
  name: "AtomTitleLine",
  props: {
    title: {
      required: false,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.atom-title-line {
  position: relative;
  margin: 3rem 0;
  text-align: center;
  @media only screen and (max-width: 767px) {
    margin: 2rem 0;
  }
  .title {
    position: relative;
    background: $white;
    min-width: 36.563rem;
    max-width: 100%;
    text-align: center;
    display: inline-block;
    letter-spacing: -0.96px;
    z-index: 5;
    @include font-main(3rem, $second-color, $light, normal);
    @media only screen and (max-width: 767px) {
      min-width: 0;
      padding: 0 1rem;
      @include font-main(2.5rem, $second-color, $light, normal);
    }
    b {
      letter-spacing: -0.96px;
      @include font-main(3rem, $second-color, $bold, normal);
      @media only screen and (max-width: 767px) {
        @include font-main(2.5rem, $second-color, $bold, normal);
      }
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.063rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(25, 73, 95, 0.5);
  }
}
</style>
