<template>
  <div v-if="!section || !enabled"></div>
  <SectionHero v-else-if="section === 'Hero'" :hash="hash" :data="data" :order-no="orderNo" :extra="extra" />
  <SectionMain v-else-if="section === 'Main'" :hash="hash" :data="data" :order-no="orderNo" />
  <SectionProjects v-else-if="section === 'Projects'" :hash="hash" :data="data" :order-no="orderNo" />
  <SectionLeasing v-else-if="section === 'Leasing'" :hash="hash" :data="data" :order-no="orderNo" />
  <SectionPortfolioMap v-else-if="section === 'PortfolioMap'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionVision v-else-if="section === 'Vision'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionWhy v-else-if="section === 'Why'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionCreators v-else-if="section === 'Creators'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionTeam v-else-if="section === 'Team'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionPartners v-else-if="section === 'Partners'" :hash="hash" :data="data" :order-no="orderNo" :title="title" :subtitle="subtitle" />
  <SectionContact v-else-if="section === 'Contact'" :hash="hash" :data="data" :order-no="orderNo" :variant="variant" />
</template>

<script>
import SectionHero from './section/SectionHero';
import SectionPortfolioMap from './section/SectionPortfolioMap';
import SectionVision from './section/SectionVision';
import SectionWhy from './section/SectionWhy';
import SectionCreators from './section/SectionCreators';
import SectionTeam from './section/SectionTeam';
import SectionPartners from './section/SectionPartners';
import SectionMain from "@/views/portfolio/section/SectionMain";
import SectionProjects from "@/views/portfolio/section/SectionProjects";
import SectionLeasing from "@/views/portfolio/section/SectionLeasing";
import SectionContact from "@/views/project/section/SectionContact";

export default {
  name: "SectionSelector",
  components: {
    SectionContact,
    SectionLeasing,
    SectionProjects,
    SectionMain,
    SectionHero,
    SectionPortfolioMap,
    SectionVision,
    SectionWhy,
    SectionCreators,
    SectionTeam,
    SectionPartners,
  },
  props: {
    section: {
      required: true,
      type: String,
      default: "",
    },
    data: {
      required: true,
      type: Object | Array,
      default: function () {
        return {}
      },
    },
    extra: {
      required: false,
      type: Object,
      default: function () {
        return {}
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    variant: {
      required: true,
      type: String,
      default: ""
    },
    enabled: {
      required: false,
      type: Boolean,
      default: false
    },
    hash: {
      required: true,
      type: String,
      default: ""
    },
    title: {
      required: false,
      type: String,
      default: ""
    },
    subtitle: {
      required: false,
      type: String,
      default: ""
    }
  }
}
</script>