var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-shortlist-placeholder" }, [
    _c("img", { attrs: { src: _vm.url, alt: "" } }),
    _vm._m(0),
    _c(
      "span",
      { staticClass: "molecule-shortlist-placeholder_button" },
      [
        _c("AtomButton", {
          staticClass: "btn-black",
          attrs: {
            label: "See Promotional Items list",
            "on-click": _vm.triggerOpenAvailability
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", [
      _vm._v("\n    At the moment there are "),
      _c("br"),
      _c("span", [_vm._v(" no promotional items shortlisted")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }