var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-option" },
    [
      _c("AtomButton", {
        ref: "optionBtn",
        class: { "option-button": true, active: _vm.internalValue },
        attrs: {
          label: _vm.option.text,
          icon: _vm.internalValue ? "minus" : "plus",
          "on-click": _vm.handleClick
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }