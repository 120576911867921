<template>
  <div class="molecule-physical-filters">
    <div class="filters clearfix " :class="{'visible': showFilters}">
      <div class="row">
        <div v-if="Object.keys(areasOptions).length >= 2" class="col col-12">
          <div class="select-style">
            <AtomDropDownFilter
                placeholder="Area(s)"
                :options="areasOptions"
                :value="filters.physical.areas"
                @input="$event => handleFilterChange($event, 'areas')"
                @clearFilter="handleClearMultipleSelectFilter"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12">
          <MoleculeMultipleOptions
              ref="devicesOptions"
              :options="devicesOptions"
              title="Device(s)"
              :value="filters.physical.devices"
              @input="($event) => handleFilterChange($event, 'devices')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoleculeMultipleOptions from "./MoleculeMultipleOptions";
import AtomDropDownFilter from "../../../atoms/common/AtomDropdownFilter";

export default {
  name: "MoleculePhysicalFilters",
  components: { AtomDropDownFilter, MoleculeMultipleOptions },
  props: {
    showFilters: {
      type: Boolean,
      required: true,
      default: false
    },
    reInitFilters: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      areasOptions: [],
      devicesOptions: [],
    }
  },
  mounted() {
    this.initFilterOptions();
  },
  computed: {
    filters() {
      return this.$store.state.project?.filters || null;
    },
    buildings() {
      return this.$store.state.project?.project?.buildings || [];
    },
    areas() {
      const areas = [];
      if (this.buildings.length === 0) return;
      for (const b of this.buildings) {
        if (!b.areas || b.areas.length === 0) return;
        for (const a of b.areas) {
          areas.push(a);
        }
      }
      return areas;
    },
    uniqueDevices() {
      const physicalItems = [];
      if (this.buildings.length === 0) return;
      for (const b of this.buildings) {
        if (!b.physicalItems || b.physicalItems.length === 0) return;
        for (const ph of b.physicalItems) {
          physicalItems.push({ ...ph.physicalItem });
        }
      }
      return [...new Set(physicalItems.map((ph) => ph.type))]; // unique devices
    },
  },
  watch: {
    reInitFilters(val, old) {
      if (val !== old) {
        this.initFilterOptions();
      }
    },
    areas: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.initFilterOptions();
        }
      }
    }
  },
  methods: {
    initFilterOptions() {
      this.setAreasOptions();
      this.setDevicesOptions();
    },
    setAreasOptions() {
      const areasOptions = [];
      const areasComp = [];
      if (this.areas && this.areas.length > 0) {
        for (const area of this.areas) {
          if (areasComp.indexOf(area.name) === -1) {
            areasOptions.push({
              text: area.name,
              value: area.name,
            });
            areasComp.push(area.name);
          }
        }
      }
      areasOptions.sort((a, b) => this.compare(a.text, b.text));
      this.areasOptions = areasOptions;
    },
    setDevicesOptions() {
      let devicesOptions = [];
      if (this.uniqueDevices && this.uniqueDevices.length > 0) {
        devicesOptions = this.uniqueDevices.map((d) => ({
          text: d.charAt(0).toUpperCase() + d.slice(1),
          id: d
        }));
      }
      devicesOptions.sort((a, b) => this.compare(a.text, b.text));
      this.devicesOptions = devicesOptions;
    },
    handleFilterChange(value, type, parentKey = 'physical') {
      this.$emit('filterChange', { value, type, parentKey });
    },
    handleClearMultipleSelectFilter(value) {
      if (value) {
        const projectConstants = this.$store.getters.constants.project;
        this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_MULTIPLE_SELECT_FILTER), { parentKey: 'physical' });
      }
    },
    compare(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
  }
}
</script>

<style lang="scss">
.molecule-physical-filters {
  .filters {
    background: $white;
    display: none;
    @media only screen and (max-width: 767px) {
      padding: 0.5rem 0;
    }
    .col-padding-4px {
      padding: 0 0.25rem;
    }
    &.visible {
      display: block;
    }
    .atom-check-box {
      label {
        z-index: 0;
        font-size: 0.9375rem;
        line-height: 1.125rem;
      }
      .check-box {
        .icon {
          color: #23CD9A !important;
        }
      }
    }
    .select-style {
      background: #F5F7F8;
      width: 100%;
      border-radius: 0.125rem;
      margin-bottom: 0.5rem;
      label {
        display: block;
        height: auto;
        min-width: 0;
        border: none;
        background: transparent;
        margin: 0;
        font-weight: $medium;
        font-size: 0.688rem;
        line-height: 0.813rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.6);
        padding: 0.5rem 0.75rem 0.188rem;
      }
      .select2-container {
        .selection {
          .select2-selection--single {
            padding: 0.188rem 0.75rem 0.625rem;
            font-size: 1rem;
            line-height: 1.188rem;
            .select2-selection__rendered {
              font-size: 1rem;
              line-height: 1.188rem;
            }
            .select2-selection__arrow {
              height: 2rem;
              width: 2rem;
              top: -0.188rem;
              right: 0.375rem;
              b {
                margin-left: 0;
                margin-top: 0;
                transform: translate(-50%, -50%);
                border-color: $black transparent transparent transparent;
                border-width: 0.375rem 0.313rem 0 0.313rem;
              }
            }
          }
        }
      }
      .select2-container--default {
        &.select2-container--open {
          .select2-selection--single {
            .select2-selection__arrow {
              b {
                border-color: transparent transparent $black transparent;
                border-width: 0 0.313rem 0.375rem 0.313rem;
              }
            }
          }
        }
      }
      .select-inline-block {
        display: block;
      }
    }
  }
}
</style>