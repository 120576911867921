<template>
  <div class="atom-floor-selector" v-if="isActiveVirtualTour">
    <span
      v-for="(floor, id) in buildings.floors"
      :class="{
        active: isActive(String(floor.no)),
      }"
      @click="moveCameraToItem(floor)"
      :key="id"
      >{{ floor.no }}</span
    >
  </div>
</template>

<script>
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
export default {
  name: "AtomFloorSelector",
  computed: {
    activeProject() {
      return this.$store.state.project?.activeProject;
    },
    buildings() {
      return (
        this.$store.state.project?.project?.buildings.find(
          (bl) => bl.code === this.activeProject
        ) || []
      );
    },
    isActiveVirtualTour() {
      return this.$store.state?.base?.isActiveVirtualTour || false;
    },
    selectedFloorView() {
      return this.$store.state?.base?.virtualTourLastFloorClicked || "";
    },
  },
  methods: {
    isActive(floorNo) {
      return this.selectedFloorView === floorNo;
    },
    clearVirtualTourPopupState() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.CLEAR_VIRTUAL_TOUR_POPUP
        )
      );
    },
    setVirtualLastItemClicked(item = null) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED
        ),
        {
          lastItemClicked: item,
        }
      );
    },
    setVirtualLastFloorClicked(floorNo) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_FLOOR_CLICKED
        ),
        {
          lastFloorClicked: floorNo,
        }
      );
    },
    moveCameraToItem(item) {
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.moveFirstPersonCamera(
          item.position_coordinates,
          item.target_coordinates
        );
        this.clearVirtualTourPopupState();
        this.setVirtualLastItemClicked();
        setTimeout(() => {
          this.setVirtualLastFloorClicked(manager.getCameraFloor());
          manager.scene.getEngine().getRenderingCanvas().focus();
        }, 1200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-floor-selector {
  display: block;
  margin-right: 1.625rem;
  @media only screen and (max-width: 767px) {
    margin-right: 26px;
  }
  span {
    display: inline-block;
    vertical-align: middle;
    width: 2rem;
    height: 2rem;
    border-radius: 2rem;
    text-align: center;
    margin-right: 0.25rem;
    cursor: pointer;
    @include font-main(1.125rem, $blackOpacity05, $regular, 2rem);
    transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      margin-right: 4px;
      @include font-main(18px, $blackOpacity05, $regular, 32px);
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &.active {
      background: $black;
      color: $white;
    }
  }
}
</style>
