<template>
  <table
    class="organism-table-general"
    :class="{ 'text-center': tableData.centerText }"
  >
    <thead>
      <MoleculeTableHeaderGeneral
        v-for="(trHeader, index) in tableData.header"
        :key="index"
      >
        <AtomTableHeaderGeneral
          v-for="(thHeader, index) in trHeader.th"
          :uppercase-text="thHeader.uppercaseText"
          :rowspan="thHeader.rowspan"
          :colspan="thHeader.colspan"
          :font-size="thHeader.fontSize"
          :background-color="thHeader.backgroundColor"
          :text-color="thHeader.textColor"
          :text="thHeader.text"
          :key="index"
        />
      </MoleculeTableHeaderGeneral>
    </thead>
    <tbody>
      <MoleculeTableBodyGeneral
        v-for="(trBody, index) in tableData.body"
        :key="index"
      >
        <AtomTableBodyGeneral
          v-for="(tdBody, index) in trBody.td"
          :uppercase-text="tdBody.uppercaseText"
          :rowspan="tdBody.rowspan"
          :colspan="tdBody.colspan"
          :font-size="tdBody.fontSize"
          :background-color="tdBody.backgroundColor"
          :text-color="tdBody.textColor"
          :text="tdBody.text"
          :key="index"
        />
      </MoleculeTableBodyGeneral>
    </tbody>
    <tfoot>
      <MoleculeTableFooterGeneral
        v-for="(trFooter, index) in tableData.footer"
        :key="index"
      >
        <AtomTableFooterGeneral
          v-for="(tdFooter, index) in trFooter.td"
          :uppercase-text="tdFooter.uppercaseText"
          :rowspan="tdFooter.rowspan"
          :colspan="tdFooter.colspan"
          :font-size="tdFooter.fontSize"
          :background-color="tdFooter.backgroundColor"
          :text-color="tdFooter.textColor"
          :text="tdFooter.text"
          :key="index"
        />
      </MoleculeTableFooterGeneral>
    </tfoot>
  </table>
</template>

<script>
import MoleculeTableHeaderGeneral from "../../../molecules/common/table/MoleculeTableHeaderGeneral";
import AtomTableHeaderGeneral from "../../../atoms/common/table/AtomTableHeaderGeneral";
import MoleculeTableBodyGeneral from "../../../molecules/common/table/MoleculeTableBodyGeneral";
import AtomTableBodyGeneral from "../../../atoms/common/table/AtomTableBodyGeneral";
import MoleculeTableFooterGeneral from "../../../molecules/common/table/MoleculeTableFooterGeneral";
import AtomTableFooterGeneral from "../../../atoms/common/table/AtomTableFooterGeneral";

export default {
  name: "OrganismTableGeneral",
  components: {
    AtomTableFooterGeneral,
    MoleculeTableFooterGeneral,
    AtomTableBodyGeneral,
    MoleculeTableBodyGeneral,
    AtomTableHeaderGeneral,
    MoleculeTableHeaderGeneral,
  },
  props: {
    tableData: {
      required: true,
      type: Object || Array,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.organism-table-general {
  border-spacing: 0.25rem;
  &.text-center {
    text-align: center;
  }
  td,
  th {
    padding: 0.375rem 1rem;
    color: $second-color;
    &.text-uppercase {
      text-transform: uppercase;
    }
    b {
      color: $second-color;
    }
    span {
      color: $second-color;
    }
  }
  tbody,
  tfoot {
    td {
      @include font-main(1.125rem, $second-color, $regular, normal);
      &:first-child {
        @include font-main(1.25rem, $second-color, $regular, normal);
      }
      b {
        @include font-main(1.125rem, $second-color, $bold, normal);
      }
      span {
        @include font-main(0.875rem, $second-color, $regular, normal);
      }
    }
  }
}
</style>
