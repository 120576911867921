<template>
  <div v-if="right" class="atom-arrow-button-slider-right">
    <button  @mouseover="hovered" @mouseout="unhovered" @click="clickedNext"> › </button>
  </div>
  <div v-else class="atom-arrow-button-slider-left">
    <button  @mouseover="hovered" @mouseout="unhovered" @click="clickedPrevious"> ‹ </button>
  </div>

  <!--
  <transition appear name="hover">

  <button v-if="right" class="atom-arrow-button-slider-right" v-on:mouseover="hovered" v-on:mouseout="unhovered"> > </button>
  <button v-else class="atom-arrow-button-slider-left" v-on:mouseover="hovered" v-on:mouseout="unhovered"> < </button>


  </transition>
  -->
  <!--
<div>
  <button @click="show = !show">Toggle</button>

  <transition appear name="fade-in" :duration="999">
      <h1>Root fading in</h1>
  </transition>
</div>
-->
  <!--
  <div>
    <transition name="fade">
      <div v-show="showMessage">
        <p >This is Header</p>
        <span >This is Body</span>
      </div>
    </transition>

    <div class="block" @click="showMessage = !showMessage">
      <a class="button is-primary">Primary</a>
    </div>

  </div>
  -->
  <!--
  <div>
  <button @click="show = !show">Toggle show</button>
  <transition appear name="bounce">
    <p v-if="show">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris facilisis enim libero, at lacinia diam fermentum id. Pellentesque habitant morbi tristique senectus et netus.</p>
  </transition>
  </div>
  -->
</template>

<script>
export default {
  name: "AtomArrowButtonSlider",
  props: {
    right: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      bHovered: false,
      show: false,
      showMessage: false
    }
  },
  methods: {
    hovered: function(){
      this.bHovered = true;
      this.$el.classList.add('hovered');
    },
    unhovered: function(){
      this.bHovered = false;
      this.$el.classList.remove('hovered');
    },
    clickedPrevious: function (){
      this.$emit('Previous');
    },
    clickedNext: function (){
      this.$emit('Next');
    }
  }
}
</script>

<style lang="scss">
.atom-arrow-button-slider-right, .atom-arrow-button-slider-left{
  button{
    width: 62px;
    height: 62px;
    background-color: hsla(0,0%,100%,.7);
    @include font-main(55px, $black, $medium, 58px);
  }
  margin: auto;
  z-index: 10;


  @media only screen and (max-width: 1000px) {
    button {
      width: 30px;
    }
  }
  @media only screen and (max-width: 660px) {
    button {
      font-size: 40px;
    }
  }
}
.atom-arrow-button-slider-right.hovered, .atom-arrow-button-slider-left.hovered{
  button {
    background-color: hsla(0, 0%, 100%, 1);
  }
}

.atom-arrow-button-slider-left{
  margin-left: 0;
}
.atom-arrow-button-slider-right{
  margin-right: 0;
}
.bounce-enter-active {
  animation: bounce-in 2s;
}
.bounce-leave-active {
  animation: bounce-in 2s reverse;
}
@keyframes bounce-in {
  0% {
    background-color: hsla(0,0%,100%,.7);
  }
  100% {
    background-color: hsla(0,0%,100%,1);
  }
}
</style>