var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-media-details clearfix",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c(
        "div",
        { staticClass: "header-elements" },
        [
          _c(
            "div",
            { staticClass: "title-padding" },
            [_c("AtomTitleLine", { attrs: { title: _vm.data.title } })],
            1
          ),
          _c("AtomList", { attrs: { lines: _vm.data.lines } })
        ],
        1
      ),
      _c("OrganismTabsPackages", { attrs: { tabs: _vm.data.tabs } }),
      _vm.data.pinLabel
        ? _c(
            "span",
            {
              staticClass: "tooltip-element",
              on: {
                click: function($event) {
                  return _vm.handleInfoTrigger(_vm.data.infoPopup)
                }
              }
            },
            [
              _c("AtomIcon", { attrs: { icon: "info" } }),
              _vm._v("\n    " + _vm._s(_vm.data.pinLabel) + "\n  ")
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }