<template>
  <div class="organism-sidebar-campaign">
    <MoleculeSidebarCampaign :class="{'active': open}">
      <div class="row row-header">
        <h3>Start a Campaign</h3>
        <div class="close-i" @click="handleClose">
            Close
            <span class="close-button">
              <AtomIcon icon="xmark" />
            </span>
        </div>
      </div>
      <div class="row row-steps align-items-center">
        <div class="col col-adaptive">
          <span class="step-number step-active">1</span>
        </div>
        <div class="col col-adaptive">
          <span class="line"></span>
        </div>
        <div class="col col-adaptive">
          <span class="step-number step-done"><AtomIcon icon="ok" /></span>
        </div>
        <div class="col col-adaptive">
          <span class="line"></span>
        </div>
        <div class="col col-adaptive">
          <span class="step-number">3</span>
        </div>
        <div class="col col-adaptive">
          <span class="line"></span>
        </div>
        <div class="col col-adaptive">
          <span class="step-number">4</span>
        </div>
      </div>
      <div class="row row-title-steps">
        <div class="col col-xs-12">
          <div class="title-and-description">
            <span class="step">Step 1</span>
            <p class="title">Choose type of campaign</p>
            <p class="subtitle">You can select more than one</p>
          </div>
        </div>
      </div>
    </MoleculeSidebarCampaign>
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import MoleculeSidebarCampaign from "@/components/molecules/MoleculeSidebarCampaign";

export default {
  name: "OrganismSidebarCampaign",
  components: {MoleculeSidebarCampaign, AtomIcon},
  computed: {
    open() {
      return this.$store.state?.base?.campaignSidebar?.open || false;
    }
  },
  methods: {
    handleClose() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CAMPAIGN_SIDEBAR), {
        open: !this.open,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .organism-sidebar-campaign {
    .row-header {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 25px;
      }
      h3 {
        font-weight: $regular;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: $black;
        @media only screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    .row-steps {
      background: #F5F7F8;
      border-radius: 100px;
      padding: 6px;
      text-align: center;
      margin-bottom: 32px;
      @media only screen and (max-width: 767px) {
        border-radius: 100px;
        padding: 6px;
        margin-bottom: 32px;
      }
      .col {
        align-items: center;
      }
      .step-number {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: $bold;
        color: $black;
        font-size: 21px;
        line-height: 24px;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: $white;
        @media only screen and (max-width: 767px) {
          font-size: 21px;
          line-height: 24px;
          width: 32px;
          height: 32px;
          border-radius: 32px;
        }
        .icon {
          font-size: 1rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 16px !important;
          }
        }
        &.step-active {
          background: $black;
          color: $white;
        }
        &.step-done {
          background: #23CD9A;
          .icon {
            color: $white !important;
          }
        }
      }
      .line {
        display: inline-block;
        width: 20px;
        height: 1px;
        background: rgba(0,0,0,0.2);
        @media only screen and (max-width: 767px) {
          width: 20px;
        }
      }
    }
    .row-title-steps {
      justify-content: center;
      .title-and-description {
        text-align: center;
        max-width: 300px;
        @media only screen and (max-width: 767px) {
          max-width: 300px;
        }
        .step {
          font-weight: $bold;
          font-size: 14px;
          line-height: 17px;
          text-transform: uppercase;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 17px;
          }
        }
        .title {
          font-size: 32px;
          line-height: 38px;
          margin-bottom: 12px;
          @media only screen and (max-width: 767px) {
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 12px;
          }
        }
        .subtitle {
          font-size: 16px;
          line-height: 19px;
          color: $blackOpacity06;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
    .molecule-sidebar-campaign {
      position: fixed;
      top: 0;
      bottom: 0;
      background: $white;
      width: 25rem;
      right: -30rem;
      z-index: 99999;
      transition: all 0.5s ease-in-out 0s;
      padding: 1.25rem 1.5rem;
      box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.05);
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding: 20px 24px;
        box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.05);
      }
      &.active {
        right: 0;
      }
    }
    .close-i {
      cursor: pointer;
      .text {
        display: inline-block;
        vertical-align: middle;
        font-weight: $regular;
        font-size: 1rem;
        line-height: 1.188rem;
        color: $blackOpacity06;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .close-button {
        display: inline-block;
        vertical-align: middle;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        line-height: 3rem;
        text-align: center;
        background: rgba(2, 2, 2, 0.08);
        margin-left: 1.25rem;
        @media only screen and (max-width: 767px) {
          width: 48px;
          height: 48px;
          border-radius: 48px;
          line-height: 48px;
          margin-left: 20px;
        }
        .icon {
          font-size: 1.125rem !important;
          @media only screen and (max-width: 767px) {
            font-size: 18px !important;
          }
        }
      }
    }
  }
</style>