<template>
  <div class="row atom-facts">
    <div class="col col-xs-12 col-4" v-for="(fact, index) in facts" :key="index">
      <div class="box-fact">
        <h5 v-html="fact.title"></h5>
        <p v-html="fact.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomFacts",
  props: {
    facts: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    },
  }
}
</script>

<style lang="scss">
  .atom-facts {
    margin: 0 -1rem;
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
    & > .col {
      padding: 0 1rem;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
    .box-fact {
      max-width: 20.625rem;
      margin: 0 auto 1.25rem;
      @media only screen and (max-width: 767px) {
        margin: 0 auto 10px;
        max-width: 166px;
      }
      h5 {
        color: $blue;
        font-weight: 700;
        font-size: 5.125rem;
        line-height: 6.063rem;
        @media only screen and (max-width: 767px) {
          font-size: 40px;
          line-height: 60px;
        }
      }
      p {
        font-weight: 300;
        font-size: 2rem;
        line-height: 2.375rem;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 22px;
        }
        span {
          display: block;
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
</style>