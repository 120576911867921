var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-packages" },
    _vm._l(_vm.packages, function(packageInfo, index) {
      return _c("div", { key: index, staticClass: "package-box" }, [
        _c("div", { staticClass: "header-package" }, [
          _c("div", { staticClass: "left-text" }, [
            _c("p", { domProps: { innerHTML: _vm._s(packageInfo.name) } })
          ]),
          _c(
            "div",
            { staticClass: "right-text" },
            [
              _c("AtomIcon", { attrs: { icon: "timer-v2" } }),
              _c("p", { domProps: { innerHTML: _vm._s(packageInfo.info) } })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "body-package" },
          [
            _c("OrganismTableGeneral", {
              attrs: { "table-data": packageInfo.tableData }
            })
          ],
          1
        ),
        _c("div", { staticClass: "footer-package" }, [
          _c("div", { staticClass: "left-text" }, [
            _c("p", { domProps: { innerHTML: _vm._s(packageInfo.priceText) } })
          ]),
          _c("div", { staticClass: "right-text" }, [
            _c("p", { domProps: { innerHTML: _vm._s(packageInfo.price) } })
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }