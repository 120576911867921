<template>
  <div class="atom-check-box" @click="handleClick">
    <input
      :id="id"
      ref="checkbox"
      type="checkbox"
      :name="name"
      v-model="inputVal"
    />
    <label :for="id">{{ label }}</label>
    <span :class="{ 'check-box': true, checked }">
      <AtomIcon v-if="checked" icon="square-check" :size="size" />
      <AtomIcon v-else icon="square" :size="size" />
    </span>
    <span class="error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";

export default {
  name: "AtomCheck",
  components: { AtomIcon },
  props: {
    id: {
      required: false,
      type: String,
      default: "",
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    name: {
      required: false,
      type: String,
      default: "checkbox",
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
    size: {
      required: false,
      type: Number,
      default: 22,
    },
    error: {
      required: false,
      type: String,
    },
    isChecked: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.isChecked,
    };
  },
  computed: {
    inputVal: {
      get() {
        return this.checked;
      },
      set(val) {
        this.checked = val;
      },
    },
  },
  watch: {
    inputVal: function (newValue, oldValue) {
      this.$emit("input", newValue);
    },
    isChecked: function (newValue, oldValue) {
      if (typeof newValue !== "undefined") this.inputVal = newValue;
    },
  },
  beforeMount() {
    if (typeof this.isChecked !== "undefined") this.inputVal = this.isChecked;
  },
  methods: {
    handleClick() {
      this.inputVal = !this.inputVal;
    },
    setStatus(status) {
      this.inputVal = status;
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-check-box {
  position: relative;
  padding-left: 1.875rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
    @media only screen and (max-width: 767px) {
      padding: 0.313rem 0;
      font-size: 1rem;
      line-height: 1.188rem;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 2.3rem;
  }
  input[type="checkbox"] {
    display: none;
  }
  label {
    font-weight: $regular;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: #0a0a0a;
    cursor: pointer;
    z-index: -1;
    position: relative;
    @media only screen and (max-width: 767px) {
      font-size: 1.125rem;
      line-height: 1.313rem;
    }
  }
  .check-box {
    position: absolute;
    left: -0.375rem;
    top: -0.3rem;
    @media only screen and (max-width: 767px) {
      top: -0.15rem;
    }
    i {
      font-size: 1.375rem !important;
      color: $black !important;
      @media only screen and (max-width: 767px) {
        font-size: 1.54rem !important;
      }
    }
    &.checked {
      i {
        color: $black !important;
      }
    }
  }
}
</style>
