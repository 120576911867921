<template>
  <div class="pinInfo" :data-pin="pinInfo.highlightAnchor" :id="pinInfo.highlightAnchor">
    <AtomPinInfoContent :pinInfo="pinInfo" ></AtomPinInfoContent>
    <AtomPinFitoutButtons v-if="pinInfo.fitouts" :fitouts="pinInfo.fitouts" />
  </div>
</template>

<script>
import AtomPinFitoutButtons from "../../../atoms/project/building/AtomPinFitoutButtons";
import AtomPinInfoContent from "../../../atoms/project/building/AtomPinInfoContent";
export default {
  name: "MoleculePinInfoContent",
  components: {AtomPinInfoContent, AtomPinFitoutButtons},
  props: {
    pinInfo: {
      required: false,
      type: Object,
      default: function (){
        return {
          highlightAnchor: "",
          pinTitle: "",
          pinDescription: ""
        }
      }
    },
  },
}
</script>

<style lang="scss">
.pinInfo {
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  color: black;
  font-size: 12px;
  border-radius: 5px;
  padding: 0;
  z-index: 99;
  max-width: 280px;
  box-shadow: 0 0 10px $blackOpacity012;
  .simple-wrapper {
    padding: 15px 15px 10px;
    @media only screen and (max-width: 1200px) {
      padding: 0;
      max-width: 200px;
    }
  }
  p {
    margin-top: 5px;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  h4 {
    text-transform: uppercase;
    margin: 0;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
}
</style>