var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-contact-form" },
    [
      _c("AtomTitle", {
        attrs: {
          color: "#2F3030",
          color2: "rgba(67,54,135,0.4)",
          title: "Leave us a message",
          subtitle: "We will get back to you as soon as possible"
        }
      }),
      _c("MoleculeContactForm")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }