<template>
  <div class="atom-dropdown" v-click-outside="handleClickOutside" >
    <div class="selected-value" :class="{'active': isMenuOpen}" @click="toggleMenu">
      <p class="value">
        <AtomIcon icon="location-v2"/>
        {{ activeCity }}
      </p>
    </div>
    <div v-if="isMenuOpen" class="menu-options">
      <div class="row row-elements" v-if="mappedProjects.length > 0">
        <div class="col col-6 left-sidebar">
          <p>City</p>
          <span
              v-for="(p, idx) in mappedProjects" :key="idx"
              class="option"
              :class="{'active': isActive(p.city) && p.is_available, 'disabled': !p.is_available}"
              @click="filterProjectsByCity(p.city)"
          >
            {{ p.city }}
            <b v-if="!p.is_available">Coming soon</b>
          </span>
        </div>
        <div class="col col-6 right-sidebar">
          <p>Location / Mall</p>
          <span class="option" :class="{'active': activeProject === project.slug }"
                v-for="(project, idx) in filteredProjects" :key="idx"
                @click="handleProjectChange(project)">{{ project.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";
import ClickOutside from 'vue-click-outside';

export default {
  name: "AtomDropdown",
  components: {AtomIcon},
  directives: {
    ClickOutside
  },
  props: {},
  data() {
    return {
      isMenuOpen: false,
      filteredProjects: [],
      clickedItem: null,
    }
  },
  mounted() {
    if (this.activeCity) {
      this.filterProjectsByCity(this.activeCity);
    }
  },
  watch: {
    activeProject(newVal, oldVal){
      if(oldVal !== newVal){
        if (this.activeCity) {
          this.filterProjectsByCity(this.activeCity);
        }
      }
    }
  },
  computed: {
    activeProject() {
      return this.$store.state.project?.activeProject || null;
    },
    activeProjectObject() {
      return this.projects?.find((p) => p.slug === this.activeProject) || null;
    },
    activeCity() {
      return this.activeProjectObject?.city || null;
    },
    projects() {
      return this.$store.state?.project?.projects || [];
    },
    uniqueCities() {
      return [...new Set(this.projects?.map((p) => p.city))] || []
    },
    mappedProjects() {
      const projects = [...this.projects];
      for (const p of projects) {
        const buildings = this.$store.state?.project?.buildings?.filter((b) => b.project_id === p.id) || [];
        if (buildings.length === 0) return;
        if (!p.city) p.city = "";
        for (const b of buildings) {
          p.is_available = b.is_available;
        }
      }
      return [...new Map(projects?.map((p) => [p["city"], p])).values()] || [];
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleClickOutside() {
      this.isMenuOpen = false;
      this.resetComponent();
    },
    isActive(city) {
      if(this.clickedItem) return city === this.clickedItem;
      return city === this.activeCity;
    },
    filterProjectsByCity(city) {
      this.filteredProjects = this.projects?.filter((p) => p.city === city) || [];
      this.clickedItem = city;
    },
    handleProjectChange(option) {
      if (this.activeProjectObject.slug === option.slug) return;
      const projectConstants = this.$store.getters?.constants?.project;
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.CHANGE_ACTIVE_PROJECT), option.slug);
      this.$router.push({name: 'SpecificProject', params: {projectId: option.slug}});
      this.isMenuOpen = false;
    },
    resetComponent(){
      this.filterProjectsByCity(this.activeCity)
    }
  },
}
</script>

<style lang="scss" scoped>
.atom-dropdown {
  position: relative;
  margin-right: 1.5rem;
  @media only screen and (max-width: 767px) {
    margin-right: 24px;
  }

  .selected-value {
    position: relative;
    cursor: pointer;

    .value {
      padding: 0.375rem 1rem;
      @include font-main(1rem, $black, $medium, 1.188rem);
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        padding: 6px 16px;
        @include font-main(16px, $black, $medium, 19px);
      }

      .icon {
        display: inline-block;
        vertical-align: middle;
        font-size: 1rem !important;
        margin-top: -0.125rem;
        margin-right: 0.5rem;
        transition: all 0.2s ease-in-out 0s;
        @media only screen and (max-width: 767px) {
          font-size: 16px !important;
          margin-top: -2px;
          margin-right: 8px;
        }
      }
    }

    &:hover, &.active {
      .value {
        background: rgba(242, 149, 45, 0.1);

        .icon {
          color: $main-color !important;
        }
      }
    }
  }

  .menu-options {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 0.5rem;
    background: $white;
    box-shadow: 0 0 3.125rem 0.625rem rgba(0, 0, 0, 0.12);
    padding: 1rem;
    width: 22rem;
    @media only screen and (max-width: 767px) {
      margin-top: 8px;
      box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.12);
      padding: 16px;
      width: 320px;
    }

    .row {
      margin: 0 -0.688rem;
      @media only screen and (max-width: 767px) {
        margin: 0 -11px;
      }

      .col {
        padding: 0 0.688rem;
        @media only screen and (max-width: 767px) {
          padding: 0 11px;
        }
      }
    }

    p {
      letter-spacing: 0.01em;
      margin-bottom: 1rem;
      @include font-main(1.125rem, $black, $regular, 1.375rem);
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
        @include font-main(1rem, $black, $regular, 1.188rem);
      }
    }

    .option {
      display: block;
      width: 100%;
      padding: 0.438rem 0.625rem;
      letter-spacing: 0.01em;
      text-align: center;
      margin-bottom: 0.5rem;
      cursor: pointer;
      background: #E9EDEF;
      @include font-main(1rem, $black, $regular, 1.188rem);
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        padding: 7px 10px;
        margin-bottom: 8px;
        @include font-main(16px, $black, $regular, 19px);
      }
      b {
        display: block;
        @include font-main(1rem, $black, $bold, 1.188rem);
        @media only screen and (max-width: 767px) {
          @include font-main(16px, $black, $bold, 19px);
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:hover, &.active {
        background: rgba(242, 149, 45, 0.1);
        color: $main-color;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
}
</style>