<template>
  <table class="organsim-table">
    <thead>
      <tr>
        <AtomTableHeader
          v-for="(header, index) in tableData.header"
          :text="header.text"
          :key="index"
        />
      </tr>
    </thead>
    <tbody>
      <MoleculeTableBody
        v-for="(rowLines, index) in tableData.body"
        :key="index"
      >
        <AtomTableBody
          v-for="(body, index) in rowLines.textes"
          :text="body.text"
          :key="index"
        />
      </MoleculeTableBody>
    </tbody>
  </table>
</template>

<script>
import AtomTableHeader from "@/components/atoms/common/AtomTableHeader";
import AtomTableBody from "@/components/atoms/common/AtomTableBody";
import MoleculeTableBody from "@/components/molecules/common/MoleculeTableBody";

export default {
  name: "OrganismTable",
  components: { MoleculeTableBody, AtomTableBody, AtomTableHeader },
  props: {
    tableData: {
      required: true,
      type: Object || Array,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style lang="scss">
.organsim-table {
  margin-bottom: 3.75rem;
  max-width: 31.25rem;
  width: 100%;
  @media only screen and (max-width: 767px) {
    margin-bottom: 2rem;
  }
  thead {
    th {
      font-weight: $regular;
      font-size: 1rem;
      line-height: 1.188rem;
      padding-bottom: 1.375rem;
      letter-spacing: 0.01em;
      color: $second-color;
      opacity: 0.6;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
  tbody {
    td {
      font-weight: $regular;
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-bottom: 0.875rem;
      letter-spacing: 0.01em;
      text-align: center;
      color: $second-color;
      b {
        font-size: 1.25rem;
        line-height: 1.5rem;
        letter-spacing: 0.01em;
        color: $second-color;
      }
      span {
        display: block;
        font-weight: $regular;
        font-size: 1rem;
        line-height: 1.188rem;
        letter-spacing: 0.01em;
        opacity: 0.8;
      }
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}
</style>
