<template>
  <div class="atom-number-list">
    <div class="atom-number-list-wrapper">
      <ul class="list-wrapper">
        <li v-for="(line, index) in lines" :key="index">
          <p v-html="line.number"></p>
          <span v-html="line.text"></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "AtomNumberList",
  components: {},
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-number-list {
  position: relative;
  z-index: 5;
  text-align: center;
  .atom-number-list-wrapper {
    display: inline-block;
    padding: 2.063rem 2.5rem;
    background: $white;
    box-shadow: 0 0.5rem 1.25rem 0 #0000000d;
    border-radius: 0.625rem;
    margin-top: -50%;
    @media only screen and (max-width: 767px) {
      margin-top: 2rem;
      display: block;
      padding: 2.063rem 2rem 0;
    }
  }
  .list-wrapper {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 3.75rem;
    @media only screen and (max-width: 767px) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 0;
    }
    li {
      display: flex;
      align-items: end;
      gap: 0.5rem;
      @media only screen and (max-width: 767px) {
        align-items: center;
        flex-direction: column;
        flex-grow: 0;
        min-width: 0;
        flex-basis: calc(100% / 12 * 6);
        margin-bottom: 2.063rem;
        gap: 1rem;
      }
      p {
        letter-spacing: -0.08rem;
        @include font-main(4rem, $main-color, $bold, 2.6rem);
        @media only screen and (max-width: 767px) {
          @include font-main(3rem, $main-color, $bold, 2rem);
        }
      }
      span {
        display: block;
        letter-spacing: -0.028rem;
        text-align: left;
        @include font-main(1.375rem, $black, $regular, 1.4rem);
        @media only screen and (max-width: 767px) {
          text-align: center;
          @include font-main(1.1rem, $black, $regular, 1.3rem);
        }
      }
      &:after {
        content: "";
        display: block;
        height: 2.875rem;
        width: 0.063rem;
        background: rgba(0, 0, 0, 0.1);
        margin-left: 3.25rem;
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
