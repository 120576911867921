<template>
  <div
    class="section section-media-contact"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="header-contact-email">
      <p class="title" v-html="data.title"></p>
      <a :href="`mailto:${data.email}`">
        <AtomIcon icon="mail-alt" /> {{ data.email }}
      </a>
    </div>
    <div class="row align-items-center media-contact">
      <div class="col col-xs-12 col-6">
        <div class="image-contact">
          <img :src="resourceUrl(data.image)" alt="Image Contact" />
        </div>
      </div>
      <div class="col col-xs-12 col-6">
        <div class="media-contact-form">
          <div class="media-contact-form-wrapper">
            <div class="title-contact">
              <p class="title" v-html="data.titleForm"></p>
              <p class="subtitle" v-html="data.subtitleForm"></p>
            </div>
            <OrganismMediaContactForm />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../components/atoms/common/AtomIcon";
import OrganismMediaContactForm from "../../components/organisms/common/OrganismMediaContactForm";
export default {
  name: "SectionMediaContact",
  components: { OrganismMediaContactForm, AtomIcon },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
  },
};
</script>

<style lang="scss">
.section-media-contact {
  position: relative;
  padding: 0 2rem;
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
  .header-contact-email {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 2rem;
    }
    .title {
      margin-right: 4rem;
      @include font-main(3rem, $black, $regular, normal);
      @media only screen and (max-width: 767px) {
        margin: 0 0 1rem;
        @include font-main(2.5rem, $black, $regular, normal);
      }
    }
    a {
      letter-spacing: -0.03rem;
      padding: 1.25rem 5rem;
      border: 0.063rem solid $main-color;
      border-radius: 0.25rem;
      @include font-main(1.5rem, $second-color, $bold, normal);
      @media only screen and (max-width: 767px) {
        padding: 1rem 2rem;
        @include font-main(1.2rem, $second-color, $bold, normal);
      }
      .icon {
        font-size: 1.3rem !important;
        color: $second-color !important;
        margin-right: 0.25rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.1rem !important;
        }
      }
      &:hover {
        background: $main-color;
        color: $white;
        .icon {
          color: $white !important;
        }
      }
    }
  }
  .image-contact {
    @media only screen and (max-width: 767px) {
      margin-bottom: 2rem;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .media-contact-form-wrapper {
    width: 20.375rem;
    margin: 0 auto;
    max-width: 100%;
    .title-contact {
      text-align: center;
      margin-bottom: 3rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 2rem;
      }
      .title {
        text-transform: uppercase;
        opacity: 0.5;
        margin-bottom: 0.5rem;
        @include font-main(1.25rem, $second-color, $bold, normal);
      }
      .subtitle {
        @include font-main(3rem, $second-color, $regular, normal);
        @media only screen and (max-width: 767px) {
          @include font-main(2.5rem, $second-color, $regular, normal);
        }
      }
    }
  }
}
</style>
