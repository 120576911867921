<template>
  <div
    class="organism-virtual-tour-popup"
    :class="{ open: virtualTourPopupState.isActive, expand: expandGallery }"
    v-if="virtualTourPopupState.isActive"
  >
    <div class="header-element">
      <h5 v-if="!expandGallery">Info</h5>
      <h5 v-else>Gallery</h5>
      <div class="close-element" @click="clearVirtualTourPopupState">
        <AtomIcon icon="xmark" />
      </div>
    </div>
    <div class="body-element">
      <h2 v-if="virtualTourPopupState.title">
        {{ virtualTourPopupState.title }}
      </h2>
      <MoleculeVirtualTourImages
        ref="virtualTourSliderImages"
        v-if="virtualTourPopupState.assets.length > 0"
      >
        <template v-slot:slider-images>
          <AtomVirtualTourImages
            v-for="(image, assets) in virtualTourPopupState.assets"
            :key="assets"
          >
            <div class="image">
              <img
                :src="`${cdnBase}/images/projects/${landlordSlug}/${activeProjectSlug}/images/${image.file_name}.jpg`"
                :alt="`${virtualTourPopupState.title}-${image.id}`"
              />
            </div>
          </AtomVirtualTourImages>
        </template>
      </MoleculeVirtualTourImages>
      <p v-if="virtualTourPopupState.description && !expandGallery">
        {{ virtualTourPopupState.description }}
      </p>
      <a
        v-if="!expandGallery"
        class="btn-general btn-black font-medium"
        href="javascript:void(0)"
        ><span>Download</span></a
      >
    </div>
  </div>
</template>
<script>
import AtomIcon from "../atoms/common/AtomIcon";
import MoleculeVirtualTourImages from "../molecules/common/MoleculeVirtualTourImages";
import AtomVirtualTourImages from "../atoms/common/slider/AtomVirtualTourImages";

export default {
  name: "OrganismVirtualTourPopup",
  components: {
    AtomVirtualTourImages,
    MoleculeVirtualTourImages,
    AtomIcon,
  },
  computed: {
    virtualTourPopupState() {
      return this.$store.state?.base?.virtualTourPopup || null;
    },
    activeProjectSlug() {
      return this.$store.state.project?.activeProject || null;
    },
    landlordSlug() {
      return this.$store.state.base?.landlordDetails?.landlord?.slug || null;
    },
    cdnBase() {
      return this.$store.getters?.cdnBase || null;
    },
    expandGallery() {
      return this.$store.state?.project?.expandGallery?.open || false;
    },
  },
  methods: {
    clearVirtualTourPopupState() {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.CLEAR_VIRTUAL_TOUR_POPUP
        )
      );
      this.setVirtualLastItemClicked();
    },
    setVirtualLastItemClicked(item = null) {
      const baseConstants = this.$store.getters.constants.base;
      this.$store.dispatch(
        baseConstants.withNamespace(
          baseConstants.action.UPDATE_VIRTUAL_TOUR_LAST_ITEM_CLICKED
        ),
        {
          lastItemClicked: item,
        }
      );
    },
    handleThumbChange(index) {
      this.$refs.virtualTourSliderImages.instance.trigger("to.owl.carousel", [
        index,
        300,
      ]);
    },
  },
};
</script>

<style lang="scss">
.organism-virtual-tour-popup {
  display: block;
  position: absolute;
  top: 50%;
  left: -19rem;
  background: $white;
  box-shadow: 0 0 2.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0.75rem 1rem;
  width: 14.5rem;
  z-index: 9;
  transform: translateY(-50%);
  @media only screen and (max-width: 767px) {
    top: 5.4rem;
    transform: none;
  }

  &.open {
    left: 2rem;
  }

  &.expand {
    width: 41rem;
    .body-element {
      margin-top: -2.23rem;
      h2 {
        margin: 0.5rem 0 1.5rem;
      }
      .molecule-virtual-tour-images {
        .owl-nav {
          button {
            width: 3rem;
            height: 3rem;
            span {
              font-size: 2.5rem;
            }
          }
        }
        .expand {
          width: 3rem;
          height: 3rem;
          .icon {
            font-size: 1.2rem !important;
          }
        }
      }
    }
  }

  .header-element {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h5 {
      letter-spacing: 0.01em;
      @include font-main(0.875rem, rgba(0, 0, 0, 0.6), $regular, 1.063rem);
    }

    .close-element {
      width: 2rem;
      height: 2rem;
      background: rgba(2, 2, 2, 0.04);
      border-radius: 2rem;
      cursor: pointer;
      transition: all 0.2s ease-in-out 0s;

      .icon {
        font-size: 1.125rem !important;
        width: 2rem;
        height: 2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        background: #e5e5e5;
      }
    }
  }

  .body-element {
    h2 {
      text-align: center;
      letter-spacing: 0.01em;
      margin: 0.5rem 0 0.75rem;
      @include font-main(1.25rem, $black, $medium, 1.5rem);
    }

    img {
      display: block;
      border-radius: 0.25rem;
    }

    p {
      text-align: center;
      letter-spacing: 0.01em;
      margin-bottom: 1.25rem;
      @include font-main(0.875rem, $black, $regular, 1.063rem);
    }

    .btn-general {
      border-radius: 6.25rem;
      width: 100%;
      padding: 0.438rem 0.5rem;

      span {
        font-size: 0.875rem;
        line-height: 1.063rem;
      }
    }
  }
}
</style>
