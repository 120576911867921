<template>
  <div class="molecule-virtual-tour-images">
    <div class="molecule-carousel">
      <div class="owl-carousel owl-theme" ref="carousel">
        <slot name="slider-images"></slot>
      </div>
      <div class="expand" @click="handleExpandGalleryTrigger">
        <AtomIcon :icon="expandGallery ? 'minimize' : 'expand'" :size="20" />
      </div>
    </div>
    <slot name="thumb-images"></slot>
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
export default {
  name: "MoleculeVirtualTourImages",
  components: { AtomIcon },
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      instance: null,
    };
  },
  computed: {
    expandGallery() {
      return this.$store.state?.project?.expandGallery?.open || false;
    },
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        nav: true,
        dots: false,
        loop: false,
        margin: 20,
        ...this.carouselOpts,
      });
    },
    destroyCarousel() {
      this.instance.trigger("destroy.owl.carousel");
      this.instance = null;
    },
    handleExpandGalleryTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_EXPAND_GALLERY
        ),
        {
          open: !this.expandGallery,
        }
      );
      this.instance.trigger("refresh.owl.carousel");
    },
  },
};
</script>

<style lang="scss">
.molecule-virtual-tour-images {
  margin-bottom: 0.75rem;
  .molecule-carousel {
    position: relative;
  }
  .expand {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    z-index: 9;
    cursor: pointer;
    .icon {
      color: $white !important;
      font-size: 0.8rem !important;
    }
    &:hover {
      background: $black;
    }
  }
  .owl-nav {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    button {
      border: none !important;
      background: rgba(0, 0, 0, 0.5) !important;
      width: 2rem;
      height: 2rem;
      line-height: 0 !important;
      margin-right: 0;
      span {
        font-size: 2rem;
        font-weight: 300;
      }
      &.disabled {
        opacity: 0.7;
      }
      &:hover {
        background: $black !important;
        span {
          color: $white;
        }
      }
    }
  }
}
</style>
