<template>
  <td
    :class="{ 'text-uppercase': uppercaseText }"
    :rowspan="rowspan"
    :colspan="colspan"
    :style="{
      'font-size': fontSize,
      'background-color': backgroundColor,
      color: textColor,
    }"
    v-html="text"
  ></td>
</template>

<script>
export default {
  name: "AtomTableFooterGeneral",
  props: {
    text: {
      required: false,
      type: String,
      default: "",
    },
    uppercaseText: {
      required: false,
      type: Boolean,
      default: false,
    },
    rowspan: {
      required: false,
      type: String,
      default: "",
    },
    colspan: {
      required: false,
      type: String,
      default: "",
    },
    fontSize: {
      required: false,
      type: String,
      default: "",
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "rgba(0,0,0,0.03)",
    },
    textColor: {
      required: false,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss"></style>
