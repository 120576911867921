var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col col-xs-12 col-3 atom-list-circle" },
    [
      _vm._m(0),
      _c("h4", [_vm._v(_vm._s(_vm.title))]),
      _c("AtomVerticalLine"),
      _c("AtomContentText", { attrs: { color: "#fff", text: "" + _vm.text } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "circle" }, [_c("span")])
  }
]
render._withStripped = true

export { render, staticRenderFns }