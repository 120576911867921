<template>
  <div class="organism-loader-gradient">
    <AtomLoaderProject v-for="(content, index) in contentLoader" :key="index">
      <div class="title-project">
        <h2>{{ content.project }}</h2>
      </div>
      <div class="content-loader">
        <h3>{{ content.title }}</h3>
        <h4>{{ content.subtitle }}</h4>
        <p v-html="content.text"></p>
      </div>
    </AtomLoaderProject>
    <AtomLoaderLoadingBar />
  </div>
</template>

<script>
import AtomLoaderLoadingBar from "../../atoms/common/AtomLoaderLoadingBar";
import AtomLoaderProject from "../../atoms/common/AtomLoaderProject";
export default {
  name: "OrganismLoader",
  components: {AtomLoaderProject, AtomLoaderLoadingBar},
  props: {
    contentLoader: {
      required: true,
      type: Array,
      default: function () {
        return [];
      }
    }
  },

}
</script>

<style lang="scss">
  .organism-loader-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    .title-project {
      position: absolute;
      left: 3.75rem;
      top: 3.438rem;
      @media only screen and (max-width: 767px) {
        left: 0;
        top: 15%;
        padding: 0 2rem;
      }
      h2 {
        @include font-main(2.125rem, $white, $medium, 2.5rem);
        text-transform: uppercase;
        @media only screen and (max-width: 767px) {
          font-size: 0.875rem;
          line-height: 1.125rem;
        }
      }
    }
    .content-loader {
      position: absolute;
      left: 3.75rem;
      bottom: 10rem;
      @media only screen and (max-width: 767px) {
        left: 0;
        padding: 0 2rem;
        bottom: 7%;
        max-width: 100%;
      }
      h3 {
        @include font-main(3.375rem, $white, $bold, 4rem);
        text-transform: uppercase;
        @media only screen and (max-width: 767px) {
          font-size: 1rem;
          line-height: 1.25rem;
          margin-bottom: 3px;
        }
      }
      h4 {
        @include font-main(2.125rem, $white, $regular, 2.5rem);
        margin-bottom: 1.875rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.25rem;
          line-height: 1.563rem;
          margin-bottom: 0.313rem;
        }
      }
      p {
        width: 44.688rem;
        max-width: 100%;
        @include font-main(1rem, $white, $regular, 1.625rem);
        @media only screen and (max-width: 767px) {
          width: 46.875rem;
          max-width: 100%;
          font-size: 0.75rem;
          line-height: 1.125rem;
        }
      }
    }
  }
</style>