<template>
  <router-link v-if="to" tag="div" class="menu-item-atom" :to="to">
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
    <span>{{ label }}</span>
  </router-link>
  <div v-else class="menu-item-atom" @click="onClick(tabId)">
    <AtomIcon v-if="icon" :icon="icon" :size="14"></AtomIcon>
    <span>{{ label }}</span>
  </div>
</template>

<script>
import AtomIcon from "../../common/AtomIcon";

export default {
  name: "AtomMenuItem",
  components: { AtomIcon },
  props: {
    label: {
      required: false,
      type: String,
      default: "Menu Item",
    },
    icon: {
      required: false,
      type: String,
      default: "",
    },
    tabId: {
      required: false,
      type: String,
      default: "",
    },
    to: {
      required: false,
      type: Object,
      default: null,
    },
    onClick: {
      required: false,
      type: Function,
      default: () => 1,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item-atom {
  @include font-main(15px, $black, 500, 24px);
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 21px 20px;
  border-radius: 3px;
  margin-right: 4px;

  cursor: pointer;
  span {
    white-space: nowrap;
  }
  .icon {
    margin-right: 10px;
    font-size: 16px !important;
  }
  &.router-link-exact-active,
  &.active {
    span {
      white-space: nowrap;
      color: white !important;
    }
    background-color: #020202 !important;
    i {
      color: white !important;
    }
  }

  &:hover {
    background-color: rgba(2, 2, 2, 0.08);
  }
}
</style>
