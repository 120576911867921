<template>
  <div class="molecule-content-slider3 carousel-box">
    <div class="owl-carousel owl-theme" ref="carousel">
      <slot></slot>
    </div>
    <div class="counter-style" :id="`counter${id}`"></div>
  </div>
</template>

<script>
export default {
  name: "MoleculeContentSlider3",
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    },
    id: {
      required: false,
      type: String,
      default: ""
    },
  },
  data() {
    return {
      instance: null,
    }
  },
  mounted() {
    this.initCarousel();
  },
  beforeUpdate() {
    this.destroyCarousel();
  },
  updated() {
    this.initCarousel();
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        nav: true,
        navText: ["<span>prev</span>","<span>next</span>"],
        dots: false,
        margin: 20,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        onInitialized: this.counter,
        onChanged: this.counter,
        ...this.carouselOpts
      });
    },
    destroyCarousel() {
      this.instance.trigger('destroy.owl.carousel');
      this.instance = null;
    },
    counter(event) {
      let items     = event.item.count;
      let item      = event.item.index + 1;

      if(item > items) {
        item = item - items
      }
      document.getElementById("counter"+this.id).innerHTML = "<span>"+item+"</span> / "+items;
    }
  }
}
</script>

<style lang="scss">
@import '../../../../scss/carousel';

  .molecule-content-slider3 {
    .owl-nav {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: 6.25rem;
      @media only screen and (max-width: 767px) {
        left: 0;
        margin: 0;
        width: 100%;
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
      }
      button {
        position: relative;
        border: none !important;
        width: auto;
        height: auto;
        margin-right: 1rem;
        font-weight: $regular !important;
        font-size: 1.25rem !important;
        line-height: 1.5rem !important;
        letter-spacing: -0.02em;
        color: $second-color !important;
        @media only screen and (max-width: 767px) {
          margin-right: 0;
        }
        span {
          position: relative;
          z-index: 9;
          font-weight: $regular !important;
          font-size: 1.25rem !important;
          line-height: 1.5rem !important;
          letter-spacing: -0.02em;
          color: $second-color !important;
          @media only screen and (max-width: 767px) {
            margin-right: 0;
          }
        }
        &:after {
          opacity: 0;
          content: '';
          display: block;
          width: 106%;
          height: 0.25rem;
          background: $main-color;
          position: absolute;
          left: -3%;
          bottom: 0.188rem;
          transition: all 0.3s ease-in-out 0s;
          z-index: 5;
        }
        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
    }
    .counter-style {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: 2rem;
      font-size: 1.25rem;
      line-height: 1.25rem;
      letter-spacing: -0.02em;
      color: $second-color;
      @media only screen and (max-width: 767px) {
        margin-left: 0;
        transform: translateX(-50%);
      }
      span {
        font-size: 1.25rem;
        line-height: 1.25rem;
        letter-spacing: -0.02em;
        color: $second-color;
        opacity: 0.5;
      }
    }
  }
</style>