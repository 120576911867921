<template>
  <div class="atom-title">
    <h2 :style="(color ? {'color': color} : {})" v-html="title"></h2>
    <h3 :style="color2 ? {'color': color2} : {}" v-html="subtitle"></h3>
  </div>
</template>

<script>
export default {
  name: "AtomTitle",
  props: {
    title: {
      required: false,
      type: String,
      default: ''
    },
    subtitle: {
      required: false,
      type: String,
      default: ''
    },
    color: {
      required: false,
      type: String,
      default: ''
    },
    color2: {
      required: false,
      type: String,
      default: ''
    },
    disableColorProps: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .atom-title {
    h2 {
      font-weight: $light;
      font-size: 4rem;
      line-height: 4.125rem;
      text-transform: uppercase;
      color: $second-color;
      @media only screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 39px;
      }
    }
    h3 {
      font-weight: $medium;
      font-size: 4rem;
      line-height: 4.125rem;
      color: $second-color;
      @media only screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
</style>