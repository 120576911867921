var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "organism-table-general",
      class: { "text-center": _vm.tableData.centerText }
    },
    [
      _c(
        "thead",
        _vm._l(_vm.tableData.header, function(trHeader, index) {
          return _c(
            "MoleculeTableHeaderGeneral",
            { key: index },
            _vm._l(trHeader.th, function(thHeader, index) {
              return _c("AtomTableHeaderGeneral", {
                key: index,
                attrs: {
                  "uppercase-text": thHeader.uppercaseText,
                  rowspan: thHeader.rowspan,
                  colspan: thHeader.colspan,
                  "font-size": thHeader.fontSize,
                  "background-color": thHeader.backgroundColor,
                  "text-color": thHeader.textColor,
                  text: thHeader.text
                }
              })
            }),
            1
          )
        }),
        1
      ),
      _c(
        "tbody",
        _vm._l(_vm.tableData.body, function(trBody, index) {
          return _c(
            "MoleculeTableBodyGeneral",
            { key: index },
            _vm._l(trBody.td, function(tdBody, index) {
              return _c("AtomTableBodyGeneral", {
                key: index,
                attrs: {
                  "uppercase-text": tdBody.uppercaseText,
                  rowspan: tdBody.rowspan,
                  colspan: tdBody.colspan,
                  "font-size": tdBody.fontSize,
                  "background-color": tdBody.backgroundColor,
                  "text-color": tdBody.textColor,
                  text: tdBody.text
                }
              })
            }),
            1
          )
        }),
        1
      ),
      _c(
        "tfoot",
        _vm._l(_vm.tableData.footer, function(trFooter, index) {
          return _c(
            "MoleculeTableFooterGeneral",
            { key: index },
            _vm._l(trFooter.td, function(tdFooter, index) {
              return _c("AtomTableFooterGeneral", {
                key: index,
                attrs: {
                  "uppercase-text": tdFooter.uppercaseText,
                  rowspan: tdFooter.rowspan,
                  colspan: tdFooter.colspan,
                  "font-size": tdFooter.fontSize,
                  "background-color": tdFooter.backgroundColor,
                  "text-color": tdFooter.textColor,
                  text: tdFooter.text
                }
              })
            }),
            1
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }