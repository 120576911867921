<template>
  <li :class="{'atom-location-type': true}">
    <span class="change-info-boxes" :data-category="category" @click="toggleSection">
      {{ categoryLabel }}
      <span class="buttons-tabs">
        <span class="walking-tab common-tab" :class="{'active': defaultMode === 'walking'}" data-transportation="walking" @click="selectWalkingSliderOption">
          <AtomIcon icon="walking" :size="14"/>
        </span>
        <span class="driving-tab common-tab" :class="{'active': defaultMode === 'driving'}" data-transportation="driving" @click="selectDrivingSliderOption">
          <AtomIcon icon="driving" :size="14"/>
        </span>
      </span>
      <span class="circle">
        <AtomIcon class="plus-icon" color="#423687" :size="18" icon="plus"/>
        <AtomIcon class="minus-icon" color="#423687" :size="18" icon="minus"/>
      </span>
    </span>
    <slot></slot>
  </li>
</template>

<script>
import AtomIcon from "../common/AtomIcon";

export default {
  name: "AtomLocationType",
  components: {AtomIcon},
  props: {
    category: {
      required: false,
      type: String,
      default: "",
    },
    categoryLabel: {
      required: false,
      type: String,
      default: "",
    },
    totalNumber: {
      required: false,
      type: Number,
      default: 0,
    },
    defaultMode: {
      required: false,
      type: String,
      default: "walking",
    },
  },
  data() {
    return {
      isActive: false,
      filters: {},
    };
  },
  methods: {
    toggleSection() {
      this.filters = {
        selectedCategory: this.$el.querySelectorAll('.change-info-boxes')[0].getAttribute('data-category'),
        transportationType: this.$el.querySelector('.common-tab.active').getAttribute('data-transportation'),
      }
      this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters);

      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem;
        });
      };
      const target = this.$el;
      target.classList.toggle('active');

      this.$emit('category-toggle', target);
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('active');
      });
    },
    selectWalkingSliderOption(e) {
      e.stopPropagation();
      const walkingButton = this.$el.querySelector('.walking-tab');
      const drivingButton = this.$el.querySelector('.driving-tab');
      if (!walkingButton.classList.contains('active')) {
        this.$emit('category-toggle', this.$el);
        walkingButton.classList.add('active');
        this.filters.transportationType = 'walking';
        this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters);
        drivingButton.classList.remove('active');
      }
    },
    selectDrivingSliderOption(e) {
      e.stopPropagation();
      const drivingButton = this.$el.querySelector('.driving-tab');
      const walkingButton = this.$el.querySelector('.walking-tab');
      if (!drivingButton.classList.contains('active')) {
        this.$emit('category-toggle', this.$el);
        drivingButton.classList.add('active');
        this.filters.transportationType = 'driving';
        this.$parent.$parent.$refs.map.displayMarkersByCategory(this.filters);
        walkingButton.classList.remove('active');
      }
    },
  }
}
</script>

<style lang="scss">
.atom-location-type {
  .plus-icon {
    display: block;
  }
  .minus-icon {
    display: none;
  }
  &.active {
    .plus-icon {
      display: none;
    }
    .minus-icon {
      display: block;
    }
  }
  .change-info-boxes {
    position: relative;
    display: block;
    padding: 1.25rem 9.688rem 1.25rem 1.899rem;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #272626;
    border-bottom: 1px solid $blackOpacity005;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      padding: 0.938rem 6.25rem 0.938rem 3.125rem;
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    .total-nr {
      position: absolute;
      left: 1.25rem;
      top: 0.900rem;
      width: 1.875rem;
      height: 1.875rem;
      line-height: 2rem;
      background: #F7F7FC;
      text-align: center;
      border-radius: 0.938rem;
      display: inline-block;
      vertical-align: middle;
      font-weight: $medium;
      font-size: 1rem;
      color: #40367B;
      @media only screen and (max-width: 767px) {
        width: 1.563rem;
        height: 1.563rem;
        line-height: 1.563rem;
        border-radius: 1.563rem;
        font-size: 0.75rem;
        top: 0.625rem;
        left: 0.625rem;
      }
    }
    .buttons-tabs {
      display: none;
      position: absolute;
      right: 4.375rem;
      top: 0.900rem;
      @media only screen and (max-width: 767px) {
        top: 0.625rem;
        right: 2.5rem;
      }
      span {
        width: 1.875rem;
        height: 1.875rem;
        line-height: 1.875rem;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.625rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s;
        @media only screen and (max-width: 767px) {
          width: 1.563rem;
          height: 1.563rem;
          line-height: 1.563rem;
          margin-right: 0.313rem;
        }
        &:last-child {
          margin-right: 0;
        }
        .icon {
          font-size: 0.875rem !important;
          color: $main-color !important;
          @media only screen and (max-width: 767px) {
            font-size: 0.75rem !important;
          }
        }
        &:hover, &.active {
          background: $second-color;

          .icon {
            color: $white !important;
          }
        }
      }
    }
    .circle {
      position: absolute;
      right: 1.563rem;
      top: 0.900rem;
      width: 1.875rem;
      height: 1.875rem;
      text-align: center;
      line-height: 1.875rem;
      cursor: pointer;
      font-size: 1.125rem;
      color: $main-color;
      @media only screen and (max-width: 767px) {
        width: 1.563rem;
        height: 1.563rem;
        line-height: 1.563rem;
        top: 0.625rem;
        right: 0.625rem;
      }
      .icon {
        font-size: 1.125rem !important;
      }
    }
  }
  &.active {
    background: #FAFAFA;
    .change-info-boxes {
      .buttons-tabs {
        display: block;
      }
    }
    .street-show {
      display: block !important;
    }
  }
}
</style>