<template>
  <div :class="{section: true, 'section-projects': true, [`section-${hash}`]: true, [`section${orderNo}`]: true }">
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <OrganismContentSlider3 :slider-content-data="data.slider" :id="data.id" />
  </div>
</template>

<script>
import OrganismContentSlider3 from "@/components/organisms/common/slider3/OrganismContentSlider3";

export default {
  name: "SectionProjects",
  components: {OrganismContentSlider3},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          slider: [],
        };
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    }
  }
};
</script>

<style lang="scss">
  .section-projects {
    margin-top: 5rem;
    @media only screen and (max-width: 767px) {
      margin-top: 1rem;
    }
    &.section-advertising {
      @media only screen and (max-width: 767px) {
        margin-top: 2.5rem;
      }
      .atom-content-slider3 {
        .row-slider-info {
          .col {
            &:first-child {
              padding-right: 0;
              padding-left: 2rem;
              @media only screen and (max-width: 767px) {
                padding-left: 0;
              }
            }
            &:last-child {
              -webkit-box-ordinal-group: 0;
              -ms-flex-order: -1;
              order: -1;
              padding-left: 6.875rem;
              @media only screen and (max-width: 767px) {
                -webkit-box-ordinal-group: 0;
                -ms-flex-order: 0;
                order: 0;
                padding-left: 0;
              }
            }
          }
        }
      }
      .molecule-content-slider3 {
        .counter-style, .owl-nav {
          left: 4.875rem;
          @media only screen and (max-width: 767px) {
            left: 0;
          }
        }
        .counter-style {
          @media only screen and (max-width: 767px) {
            left: 50%;
          }
        }
      }
    }
  }
</style>