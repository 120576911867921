<template>
  <div
    class="section section-media-devices"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <AtomTitleLine :title="data.title" />
    <OrganismImageAndTable :content-data="data.rowImageText" />
  </div>
</template>

<script>
import AtomTitleLine from "../../components/atoms/common/AtomTitleLine";
import OrganismImageAndTable from "../../components/organisms/common/OrganismImageAndTable";
export default {
  name: "SectionMediaDevices",
  components: { OrganismImageAndTable, AtomTitleLine },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-media-devices {
  position: relative;
  padding: 0 2rem;
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
}
</style>
