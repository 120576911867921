<template>
  <tr class="molecule-table-body">
    <slot></slot>
  </tr>
</template>

<script>

export default {
  name: "MoleculeTableBody",
  components: {}
}
</script>

<style lang="scss" scoped>
  .molecule-table-body {

  }
</style>