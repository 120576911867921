<template>
  <div class="organism-media-contact-form">
    <MoleculeDynamicModal />
    <form ref="contactForm" @submit="submitForm" action="javascript:void(0);">
      <div class="row">
        <div class="col col-12">
          <AtomInput
            :type="inputTypes.TEXT"
            :value="fullName"
            @input="fullName = $event"
            label="Full Name*"
            placeholder="Enter your first and last name"
            :error="errors.fullName"
          />
        </div>
        <div class="col col-12">
          <AtomInput
            :type="inputTypes.TEXT"
            :value="email"
            @input="email = $event"
            label="Email address*"
            placeholder="Enter email address"
            :error="errors.email"
          />
        </div>
        <div class="col col-12">
          <AtomInput
            :type="inputTypes.TEXT"
            :value="phone"
            @input="phone = $event"
            label="Phone Number*"
            placeholder="Enter phone number"
            :error="errors.phone"
          />
        </div>
        <div class="col col-12">
          <AtomButton class="btn-general-second-color" label="Submit" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AtomInput, { EnumInputTypes } from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";
import config from "../../../config";
import MoleculeDynamicModal from "@/components/molecules/common/MoleculeDynamicModal.vue";

export default {
  name: "OrganismMediaContactForm",
  components: {
    MoleculeDynamicModal,
    AtomButton,
    AtomInput,
  },
  data() {
    return {
      errors: {},
      fullName: "",
      phone: "",
      email: "",
      inputTypes: EnumInputTypes,
    };
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      if (this.validateInputs() !== null) return;
      if (window && "dataLayer" in window) {
        window.dataLayer.push({
          event: "contact_request_submit",
          category: "contact_request",
          label: "contact_request",
        });
      }

      const payload = {
        fullName: this.fullName,
        phoneNumber: this.phone,
        email: this.email,
      };
      const {
        error: serverError,
        result: serverResult,
      } = await this.$request.POST(this.$urls.landlord.contact(), payload);
      if (serverError) {
        alert("Something wrong happened! Please try again!");
        return;
      }
      this.$refs.contactForm.reset();

      // show pop-up after request is submitted
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "dynamic",
          modalData: {
            title: "Thank you!",
            text:
              "Your message has been sent and we will get back to you shortly.",
          },
        }
      );
    },
    validateInputs() {
      this.errors = {};
      if (this.fullName === "") {
        this.errors.fullName = "Full Name is required.";
      }
      if (this.phone === "" || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = "Phone Number is required.";
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (Object.keys(this.errors).length === 0) {
        return null;
      }
      return this.errors;
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss">
.organism-media-contact-form {
  .error-message {
    padding: 0.625rem 0 0.938rem;
    width: 100%;
    @include font-main(0.875rem, red, $regular, 1.063rem);
  }
  .input-atom-wrapper {
    margin-bottom: 0.75rem;
    label {
      margin-bottom: 0.5rem;
      @include font-main(1.125rem, $second-color, $regular, normal);
    }
    input {
      border-color: rgba(25, 75, 91, 0.5);
      &:focus-visible {
        border-color: $main-color;
      }
    }
  }
  .btn-general-second-color {
    margin-top: 1.75rem;
  }
}
</style>
