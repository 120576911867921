var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-digital-filters" }, [
    _c(
      "div",
      { staticClass: "filters clearfix ", class: { visible: _vm.showFilters } },
      [
        _c("div", { staticClass: "row" }, [
          Object.keys(_vm.areasOptions).length >= 2
            ? _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "select-style" },
                  [
                    _c("AtomDropDownFilter", {
                      attrs: {
                        placeholder: "Area(s)",
                        options: _vm.areasOptions,
                        value: _vm.filters.digital.areas
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleFilterChange($event, "areas")
                        },
                        clearFilter: _vm.handleClearMultipleSelectFilter
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "row" }, [
          Object.keys(_vm.packagesOptions).length >= 2
            ? _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "select-style" },
                  [
                    _c("AtomSelect", {
                      attrs: {
                        id: "package-select",
                        options: _vm.packagesOptions,
                        "select-props": {
                          search: false,
                          placeholder: "Select a package"
                        },
                        hasLabel: "",
                        labelText: "Type Of Package",
                        value: _vm.filters.digital.typeOfPackage
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleFilterChange($event, "typeOfPackage")
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "row" }, [
          Object.keys(_vm.advertisingOptions).length >= 2
            ? _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "select-style" },
                  [
                    _c("AtomSelect", {
                      attrs: {
                        id: "advertising-select",
                        options: _vm.advertisingOptions,
                        "select-props": {
                          search: false,
                          placeholder: "Select an advertising timing"
                        },
                        hasLabel: "",
                        labelText: "Advertising Timing",
                        value: _vm.filters.digital.advertisingTiming
                      },
                      on: {
                        change: function($event) {
                          return _vm.handleFilterChange(
                            $event,
                            "advertisingTiming"
                          )
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        false
          ? _c("div", { staticClass: "row my-10 mx-2" }, [
              _c(
                "div",
                { staticClass: "col col-12" },
                [
                  _c("AtomCheck", {
                    attrs: {
                      name: "showAllGroupOptions",
                      label: "Show all Iulius Group options",
                      "is-checked": _vm.filters.digital.showAllGroupOptions
                    },
                    on: {
                      input: function($event) {
                        return _vm.handleFilterChange(
                          $event,
                          "showAllGroupOptions"
                        )
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }