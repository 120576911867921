var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "organsim-main-boxes" }, [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.boxesData, function(box, index) {
        return _c(
          "div",
          { key: index, staticClass: "col col-xs-12 col-auto" },
          [
            _c("AtomNumberAndText", {
              attrs: { title: box.title, subtitle: box.subtitle }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }