var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-media-contact",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("div", { staticClass: "header-contact-email" }, [
        _c("p", {
          staticClass: "title",
          domProps: { innerHTML: _vm._s(_vm.data.title) }
        }),
        _c(
          "a",
          { attrs: { href: "mailto:" + _vm.data.email } },
          [
            _c("AtomIcon", { attrs: { icon: "mail-alt" } }),
            _vm._v(" " + _vm._s(_vm.data.email) + "\n    ")
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row align-items-center media-contact" }, [
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "image-contact" }, [
            _c("img", {
              attrs: {
                src: _vm.resourceUrl(_vm.data.image),
                alt: "Image Contact"
              }
            })
          ])
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "media-contact-form" }, [
            _c(
              "div",
              { staticClass: "media-contact-form-wrapper" },
              [
                _c("div", { staticClass: "title-contact" }, [
                  _c("p", {
                    staticClass: "title",
                    domProps: { innerHTML: _vm._s(_vm.data.titleForm) }
                  }),
                  _c("p", {
                    staticClass: "subtitle",
                    domProps: { innerHTML: _vm._s(_vm.data.subtitleForm) }
                  })
                ]),
                _c("OrganismMediaContactForm")
              ],
              1
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }