var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.handleClickOutside,
          expression: "handleClickOutside"
        }
      ],
      staticClass: "atom-dropdown"
    },
    [
      _c(
        "div",
        {
          staticClass: "selected-value",
          class: { active: _vm.isMenuOpen },
          on: { click: _vm.toggleMenu }
        },
        [
          _c(
            "p",
            { staticClass: "value" },
            [
              _c("AtomIcon", { attrs: { icon: "location-v2" } }),
              _vm._v("\n      " + _vm._s(_vm.activeCity) + "\n    ")
            ],
            1
          )
        ]
      ),
      _vm.isMenuOpen
        ? _c("div", { staticClass: "menu-options" }, [
            _vm.mappedProjects.length > 0
              ? _c("div", { staticClass: "row row-elements" }, [
                  _c(
                    "div",
                    { staticClass: "col col-6 left-sidebar" },
                    [
                      _c("p", [_vm._v("City")]),
                      _vm._l(_vm.mappedProjects, function(p, idx) {
                        return _c(
                          "span",
                          {
                            key: idx,
                            staticClass: "option",
                            class: {
                              active: _vm.isActive(p.city) && p.is_available,
                              disabled: !p.is_available
                            },
                            on: {
                              click: function($event) {
                                return _vm.filterProjectsByCity(p.city)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(p.city) + "\n          "
                            ),
                            !p.is_available
                              ? _c("b", [_vm._v("Coming soon")])
                              : _vm._e()
                          ]
                        )
                      })
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-6 right-sidebar" },
                    [
                      _c("p", [_vm._v("Location / Mall")]),
                      _vm._l(_vm.filteredProjects, function(project, idx) {
                        return _c(
                          "span",
                          {
                            key: idx,
                            staticClass: "option",
                            class: {
                              active: _vm.activeProject === project.slug
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleProjectChange(project)
                              }
                            }
                          },
                          [_vm._v(_vm._s(project.name))]
                        )
                      })
                    ],
                    2
                  )
                ])
              : _vm._e()
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }