<template>
  <div :class="{section: true, 'section-brochure': true, [`section${orderNo}`]: true}" :style="{'min-height': `${windowHeight}px`}">
    <div class="carousel-content portfolio-carousel" :style="{'min-height': `${windowHeight}px`}">
      <div class="background-image" :style="{'background-image': `url(${resourceUrl(data.backgroundImage)})`}"></div>
      <div class="left-slide">
        <div class="background-purple"></div>
        <h2>{{ data.title }}</h2>
        <div class="certifications">
          <img v-for="(certification, i) in data.certifications" :key="i" :src="resourceUrl(certification.image)" :alt="certification.alt">
        </div>
        <span v-if="data.sold" class="sold-info">Divested</span>
        <span v-if="data.dataDelivery" class="data-delivery">{{ data.dataDelivery }}</span>
        <div class="horizontally-line"></div>
        <OrganismContentSliderBrochure :slider-content-data="data.slider" />
      </div>
      <div class="row buttons-bottom-absolute text-center">
        <div v-if="data.urlBookMeATour" class="col col-auto">
          <a class="button-style" :href="`${data.urlBookMeATour}`">Book me a tour</a>
        </div>
        <div v-if="data.urlBrochure" class="col col-auto">
          <a class="button-style" target="_blank" :href="`${resourceUrl(data.urlBrochure)}`">Brochure</a>
        </div>
        <div class="col col-auto">
          <a class="button-style" href="mailto:leasing@one.ro">Contact</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrganismContentSliderBrochure from "../../../components/organisms/common/OrganismContentSliderBrochure";
export default {
  name: "SectionBrochure",
  components: {OrganismContentSliderBrochure},
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          backgroundImage: '',
          title: '',
          dataDelivery: '',
          sold: false,
          urlBookMeATour: '',
          urlBrochure: '',
          slider: []
        }
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener);
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
    resizeListener(e) {
      this.windowHeight = window.innerHeight;
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-brochure {
    position: relative;
    .carousel-content {
      position: relative;
      overflow: hidden;
      .background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        @include prefix(animation, 30s ease 0s normal none infinite running zoomEffect, ('webkit', 'moz', 'ms', 'o'));
        @media only screen and (max-width: 767px) {
          height: 60%;
        }
      }
      .buttons-bottom-absolute {
        position: absolute;
        left: 4.813rem;
        bottom: 10.5rem;
        z-index: 1;
        width: 48rem;
        padding: 0 4.25rem;
        @include prefix(transition, all 0.5s ease-in-out 0s, ('webkit', 'moz', 'ms', 'o'));
        @media only screen and (max-width: 767px) {
          left: 0;
          bottom: 27%;
          width: 100%;
        }
        .col {
          padding: 0.125rem 0.25rem;
        }
      }
      .left-slide {
        position: absolute;
        left: 4.813rem;
        bottom: 14.563rem;
        z-index: 1;
        width: 48rem;
        height: 24rem;
        @media only screen and (max-width: 767px) {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 40%;
        }
        .sold-info {
          display: block;
          box-shadow: inset 0 0 0 2vh transparent;
          padding: 0.625rem 0.25rem;
          text-align: center;
          position: absolute;
          left: 50%;
          top: 2.1rem;
          width: 11rem;
          text-transform: uppercase;
          transition: all 0.1s ease-in-out 0s;
          z-index: 9;
          background: #4CC66D;
          border: 1px solid $white;
          transform: translateX(-50%);
          @include font-main(1.063rem, $white, $bold, 1.188rem);
          @media only screen and (max-width: 767px) {
            font-size: 11px;
            padding: 2px;
            line-height: 14px;
            width: 100px;
            top: 18px;
          }
        }
        .certifications {
          display: inline-block;
          position: absolute;
          left: 4.313rem;
          top: 5.375rem;
          height: 3.563rem;
          z-index: 5;
          @media only screen and (max-width: 767px) {
            top: 35px;
            height: 30px
          }
          img {
            display: inline-block;
            height: 100%;
            &:first-child {
              margin-right: 2.688rem;
              @media only screen and (max-width: 767px) {
                margin-right: 30px;
              }
            }
          }
        }
        .background-purple {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: #2d2750;
        }
        h2 {
          position: absolute;
          left: 0;
          top: -2.5rem;
          display: block;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          z-index: 5;
          @include font-main(4.563rem, $white, $semiBold, 4.563rem);
          @media only screen and (max-width: 767px) {
            font-size: 35px;
            top: -8px;
          }
        }
        .data-delivery {
          display: block;
          position: absolute;
          top: 6.875rem;
          right: 4.563rem;
          z-index: 5;
          @include font-main(1.188rem, $white, $light, 1.188rem);
          @media only screen and (max-width: 767px) {
            top: 49px;
            font-size: 14px;
          }
        }
        .horizontally-line {
          position: absolute;
          left: -4.813rem;
          top: 10.813rem;
          z-index: 5;
          width: 48rem;
          height: 1px;
          background-color: $white;
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }
      .button-style {
        display: block;
        border: 1px solid $white;
        background-color: #2d2750;
        box-shadow: inset 0 0 0 2vh transparent;
        cursor: pointer;
        padding: 0.625rem 0.25rem;
        text-align: center;
        @include font-main(1.063rem, $white, $light, 1.188rem);
        @include prefix(transition, box-shadow 500ms cubic-bezier(.165, .84, .44, 1), ('webkit', 'moz', 'ms', 'o'));
        &:hover {
          box-shadow: inset 0 0 0 0 $white;
        }
        @media only screen and (max-width: 767px) {
          font-size: 11px;
          padding: 5px;
          line-height: 14px;
        }
      }
    }
    @keyframes zoomEffect {
      0% {transform: scale(1, 1)}
      50% {transform: scale(1.1, 1.1)}
      100% {transform: scale(1, 1)}
    }
  }
</style>