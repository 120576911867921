<template>
  <td v-html="text"></td>
</template>

<script>

export default {
  name: "AtomTableBody",
  props: {
    text: {
      required: false,
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">

</style>