<template>
  <MoleculeContentSlider3 :id="id">
    <AtomContentSlider3
      v-for="(sliderContent, index) in sliderContentData"
      :key="index"
    >
      <div class="row row-slider-info align-items-center">
        <div class="col col-xs-12 col-6">
          <img
            :src="resourceUrl(sliderContent.image)"
            :alt="sliderContent.title"
          />
        </div>
        <div class="col col-xs-12 col-6">
          <div class="content-slider">
            <p
              class="title"
              v-if="sliderContent.title"
              v-html="sliderContent.title"
            ></p>
            <div class="subtitle-info" v-if="sliderContent.lines">
              <p
                class="subtitle"
                v-for="(line, index) in sliderContent.lines"
                :key="index"
                v-html="line"
              ></p>
            </div>
            <div class="statistics-info" v-if="sliderContent.statisticsLines">
              <AtomNumberAndText
                v-for="(statistic, index) in sliderContent.statisticsLines"
                :title="statistic.numberText"
                :subtitle="statistic.text"
                :key="index"
              />
            </div>
            <OrganismTable
              v-if="sliderContent.table"
              :table-data="sliderContent.table"
            />
            <div class="buttons-bottom">
              <div class="display-inline" v-if="sliderContent.buttonUrl">
                <AtomButtonLink
                  :label="`${sliderContent.buttonText}`"
                  :href="`${sliderContent.buttonUrl}`"
                />
              </div>
              <div class="display-inline" v-else>
                <AtomButton
                  class="btn-disabled"
                  :label="`${sliderContent.buttonText}`"
                />
              </div>
              <div
                class="display-inline"
                v-if="sliderContent.buttonBrochureText"
              >
                <AtomButtonLink
                  class="btn-general-all-black"
                  :label="`${sliderContent.buttonBrochureText}`"
                  :href="`${sliderContent.buttonBrochureUrl}`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AtomContentSlider3>
  </MoleculeContentSlider3>
</template>

<script>
import MoleculeContentSlider3 from "@/components/molecules/common/slider3/MoleculeContentSlider3";
import AtomContentSlider3 from "@/components/atoms/common/slider3/AtomContentSlider3";
import AtomNumberAndText from "@/components/atoms/common/AtomNumberAndText";
import OrganismTable from "@/components/organisms/common/OrganismTable";
import AtomButtonLink from "../../../atoms/common/AtomButtonLink";
import AtomButton from "../../../atoms/common/AtomButton";

export default {
  name: "OrganismContentSlider3",
  components: {
    AtomButton,
    AtomButtonLink,
    OrganismTable,
    AtomNumberAndText,
    AtomContentSlider3,
    MoleculeContentSlider3,
  },
  props: {
    sliderContentData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
    id: {
      required: false,
      type: String,
      default: "",
    },
  },
  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
};
</script>

<style lang="scss"></style>
