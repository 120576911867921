var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "technical-specs-info" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "lazyload",
            rawName: "v-lazyload",
            value: { handle: _vm.handleLazyLoad, url: _vm.resourceUrl },
            expression: "{ handle: handleLazyLoad, url: resourceUrl }"
          }
        ],
        staticClass: "image-technical-specs"
      },
      [
        !_vm.imageLoaded
          ? _c("AtomLazyLoader", { attrs: { height: 450 } })
          : _vm._e(),
        _c("picture", [
          _c("source", { attrs: { srcset: _vm.resourceUrl } }),
          _c("img", {
            attrs: { src: _vm.resourceUrl, alt: "Technical Specifications" }
          })
        ]),
        _c(
          "div",
          { staticClass: "organism-technical-specs" },
          _vm._l(_vm.bulletTechnicalData, function(bullet, index) {
            return _c("AtomBulletSpecsNoCategory", {
              key: index,
              attrs: {
                "show-top": bullet.showTop,
                "show-bottom": bullet.showBottom,
                "show-right": bullet.showRight,
                "show-left": bullet.showLeft,
                "show-center": bullet.showCenter,
                image: bullet.image,
                category: bullet.category.label,
                text: bullet.text,
                "top-postion": bullet.top,
                "right-postion": bullet.right,
                "bottom-postion": bullet.bottom,
                "left-postion": bullet.left,
                "active-bullet": _vm.activeBullet,
                "bullet-id": "item-" + bullet.id
              },
              on: {
                change: function($event) {
                  return _vm.handleBulletChange($event, bullet.category)
                }
              }
            })
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info-mobile" },
      _vm._l(_vm.specsByCategory, function(category, i) {
        return _c(
          "AtomCategorySpecs",
          {
            key: i,
            attrs: {
              "category-id": "id-" + category.slug,
              "active-category": _vm.activeCategory,
              "category-name": category.label
            },
            on: { change: _vm.handleCategoryChange }
          },
          _vm._l(category.items, function(bullet, index) {
            return _c("AtomSpecsInfo", {
              key: index,
              ref: "item-" + bullet.id,
              refInFor: true,
              attrs: {
                id: bullet.id,
                text: bullet.text,
                "active-info": _vm.activeBullet,
                "info-id": "item-" + bullet.id
              },
              on: { change: _vm.handleBulletChange }
            })
          }),
          1
        )
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }