<template>
  <div class="molecule-main-locations">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeMainLocations"
}
</script>

<style lang="scss" scoped>
  .molecule-main-locations {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    padding: 0 86px 30px;
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
      padding: 0 2rem 1rem;
      margin-bottom: 0;
    }
  }
</style>