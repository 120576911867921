<template>
  <div :class="{'organism-sidebar': true, 'open': isOpen}">
    <slot></slot>
  </div>
</template>

<script>
import {baseConstants} from '../../store/modules'
export default {
  name: "OrganismSidebar",
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].sidebarOpen;
    }
  }
}
</script>

<style lang="scss">
  .organism-sidebar {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    background: $white;
    padding: 0.938rem;
    width: 36rem;
    left: -38rem;
    z-index: 9;
    transition: all 0.5s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      left: -101%;
      width: 100%;
      padding: 15px 20px;
    }
    &.open {
      left: 0;
      z-index: 999;
    }
    .subtitle {
      margin-bottom: 1.563rem;
      opacity: 0.5;
      @include font-main(1.5rem, $second-color, $regular, 1.813rem);
      @media only screen and (max-width: 767px) {
        margin-bottom: 0.5rem;
      }
    }
    .separator {
      margin: 2.5rem 0;
      border: 1px solid #E9EDEF;
      @media only screen and (max-width: 767px) {
        margin: 1rem 0;
      }
    }
    .menu {
      position: absolute;
      top: 50%;
      left: 2.4rem;
      transform: translateY(-50%);
      @media only screen and (max-width: 767px) {
        left: 0;
        width: 100%;
        padding: 0 20px;
        text-align: center;
      }
      .hidden-lg {
        @media only screen and (max-width: 767px) {
          margin-top: 5px;
        }
      }
      .sidebar-link {
        position: relative;
        display: block;
        margin-top: 0.5rem;
        text-decoration: none;
        letter-spacing: 0.02em;
        @include font-main(2rem, $second-color, $regular, 2.313rem);
        @media only screen and (max-width: 767px) {
          text-align: center;
          margin-top: 0.2rem;
          font-size: 1.5rem;
          line-height: 1.813rem;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.3;
        }
        &:hover {
          color: $main-color;
        }
      }
    }
    .social-links {
      position: absolute;
      bottom: 2rem;
      left: 2.25rem;
      @media only screen and (max-width: 767px) {
        left: 0;
        width: 100%;
        text-align: center;
      }
      a {
        position: relative;
        text-decoration: none;
        @include font-main(0.875rem, $black, $regular, 1.3rem);
        @media only screen and (max-width: 767px) {
          line-height: 16px;
          font-size: 12px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #909090;
        }
      }
    }
    .locale-switch{
      position: absolute;
      bottom: 4rem;
      left: 3rem;
      @media only screen and (max-width: 767px) {
        bottom: 20px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
</style>