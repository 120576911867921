<template>
  <OrganismInfoPopup v-show="open">
    <div class="close-i" @click="handleInfoTrigger">
      <AtomIcon icon="xmark" />
    </div>
    <p class="title" v-if="data.header" v-html="data.header"></p>
    <OrganismTableGeneral
      class="media-info-table"
      :table-data="data.tableData"
    />
  </OrganismInfoPopup>
</template>
<script>
import OrganismInfoPopup from "@/components/organisms/OrganismInfoPopup";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import OrganismTableGeneral from "@/components/organisms/common/table/OrganismTableGeneral.vue";

export default {
  name: "OrganismMediaInfoPopup",
  components: {
    OrganismTableGeneral,
    AtomIcon,
    OrganismInfoPopup,
  },
  computed: {
    open() {
      return this.$store.state?.project?.mediaInfoBox?.open || false;
    },
    data() {
      return this.$store.state?.project?.mediaInfoBox?.data || {};
    },
  },
  methods: {
    handleInfoTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_MEDIA_INFO_BOX
        ),
        {
          open: !this.open,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.organism-info {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.8);
  padding: 1.25rem 2.5rem 2rem;
  width: 50rem;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  transition: all 0.5s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    max-width: 90%;
    width: 90%;
    max-height: 80%;
    overflow: auto;
    padding: 3rem 1rem 1rem;
  }
  .title {
    margin-bottom: 1.5rem;
    letter-spacing: -0.065rem;
    @include font-main(3.25rem, $darkerWhite, $light, normal);
    @media only screen and (max-width: 767px) {
      @include font-main(2.5rem, $darkerWhite, $light, normal);
    }
    b {
      @include font-main(3.25rem, $darkerWhite, $medium, normal);
      @media only screen and (max-width: 767px) {
        @include font-main(2.5rem, $darkerWhite, $medium, normal);
      }
    }
    span {
      display: block;
      opacity: 0.3;
      @include font-main(2rem, $darkerWhite, $light, normal);
      @media only screen and (max-width: 767px) {
        @include font-main(1.7rem, $darkerWhite, $light, normal);
      }
    }
  }
  .media-info-table {
    width: 45rem;
    max-width: 100%;
    border-spacing: 0;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    tr {
      th,
      td {
        padding: 0 0.625rem;
        background: transparent !important;
        &:first-child {
          text-align: left;
          padding-left: 0;
        }
        &:last-child {
          text-align: right;
          padding-right: 0;
        }
      }
      th {
        padding-bottom: 0.625rem;
        @include font-main(1rem, $darkerWhite, $regular, normal);
      }
      td {
        padding-bottom: 1.5rem;
        @include font-main(1.25rem, $darkerWhite, $regular, normal);
        b {
          @include font-main(1.25rem, $darkerWhite, $bold, normal);
        }
        span {
          display: block;
          @include font-main(1rem, $darkerWhite, $regular, normal);
          max-width: 20rem;
        }
      }
    }
  }
  &.open {
    display: block;
  }
  .close-i {
    position: absolute;
    right: 1.875rem;
    top: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background: rgba(255, 255, 255, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
    transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      right: 0.5rem;
      top: 0.5rem;
    }
    &:hover {
      background: $black;
    }
    .icon {
      font-size: 1.25rem !important;
      color: $white !important;
    }
  }
  .content-info {
    @media only screen and (max-width: 767px) {
      margin-top: 1.25rem;
    }
  }
  .row {
    margin: 0 -1.25rem;
    .col {
      padding: 0 1.25rem;
    }
  }
  h4 {
    font-size: 2rem;
    line-height: 2.375rem;
    color: $white;
    margin-bottom: 1.563rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
    }
    b {
      font-size: 2rem;
      line-height: 2.375rem;
    }
  }
  h6 {
    color: $white;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.188rem;
    margin-bottom: 1.25rem;
  }
  p {
    font-weight: $light;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $white;
    @media only screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.263rem;
    }
  }
  .icon-atom-custom {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0.125rem;
      width: 1.313rem;
      &.orientation-i,
      &.esc-i {
        top: -0.125rem;
      }
      &.mouse-i,
      &.cursor-i,
      &.hand-i,
      &.circle-plus-i,
      &.home-i {
        width: 1rem;
      }
      &.arrow-left-i {
        width: 0.8rem;
      }
      &.fitout-i {
        width: 1rem;
        margin-top: -0.15rem;
      }
    }
  }
  .icon-bottom-custom {
    p {
      margin-bottom: 1rem;
    }
    .row-images {
      .image {
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin: -0.438rem 1rem 0;
      }
      img {
        width: 3.5rem;
      }
    }
  }
}
</style>
