var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-building": true
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("MoleculeShortlistRulesModal"),
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _vm.loadingScreenActive
        ? _c(
            "div",
            {
              staticClass: "organism-loader-background",
              style: {
                "background-image":
                  "url(" + _vm.resourceUrl(_vm.backgroundLoaderUrl) + ")"
              }
            },
            [
              _c("OrganismLoader", {
                attrs: { "content-loader": _vm.contentLoaderData }
              })
            ],
            1
          )
        : _vm._e(),
      _c("OrganismMenuRight", {
        attrs: { "phone-header": _vm.phoneNumberHeader }
      }),
      _c("OrganismBuilding", {
        ref: "building",
        class: {
          active: _vm.drawerAvailabilityOpen || _vm.drawerShortlistOpen
        }
      }),
      _c(
        "div",
        {
          class: {
            "drawer-buttons": true,
            active: _vm.drawerAvailabilityOpen || _vm.drawerShortlistOpen
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "drawer-button drawer-button-black custom-tooltip",
              on: { click: _vm.handleExpandAvailability }
            },
            [
              _c("AtomIcon", {
                attrs: {
                  icon: _vm.drawerAvailabilityOpen ? "xmark" : "filter-list",
                  color: "#fff"
                }
              }),
              _c("p", { staticClass: "hidden-mobile-elements" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.drawerAvailabilityOpen
                        ? "Close"
                        : "Filters & Portfolio"
                    ) +
                    "\n      "
                )
              ])
            ],
            1
          ),
          _vm.shortlistState
            ? _c(
                "div",
                {
                  staticClass:
                    "drawer-button drawer-button-white custom-tooltip",
                  on: { click: _vm.handleExpandShortlist }
                },
                [
                  _c("AtomIcon", {
                    attrs: {
                      icon: _vm.drawerShortlistOpen
                        ? "xmark"
                        : "shortlist-icon",
                      color: "#000"
                    }
                  }),
                  _vm.shortlistItemsLength > 0
                    ? _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(_vm.shortlistItemsLength))
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "hidden-mobile-elements" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.drawerShortlistOpen ? "Close" : "Shortlist"
                        ) +
                        "\n      "
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("OrganismAvailabilityDrawer", {
        ref: "availability-drawer",
        attrs: { "default-open": _vm.drawerAvailabilityOpen },
        on: { "drawer:close": _vm.handleCloseAvailability }
      }),
      _c("OrganismShortlistDrawer", {
        ref: "shortlist-drawer",
        attrs: {
          "default-open": _vm.drawerShortlistOpen,
          "trigger-open-availability": _vm.handleExpandAvailability
        },
        on: {
          toggleAvailability: _vm.handleExpandAvailability,
          "drawer:close": _vm.handleCloseShortlist
        }
      }),
      _c("OrganismRequestOffer"),
      _c("OrganismTutorial"),
      _c("OrganismVirtualTourPopup"),
      _c(
        "div",
        { staticClass: "orientation-nav" },
        [_c("MoleculeBuildingOrientationBar")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "footer-bright",
          attrs: { href: "https://brightspaces.tech/", target: "_blank" }
        },
        [_vm._v("\n    Created by ©BrightSpaces")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }