var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-image-and-table" },
    _vm._l(_vm.contentData, function(rowElement, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "row row-image-and-table align-items-center",
          class: { "flex-row-reverse": rowElement.reverseColumn }
        },
        [
          _c("div", { staticClass: "col col-xs-12 col-6" }, [
            _c("img", {
              attrs: {
                src: _vm.resourceUrl(rowElement.image),
                alt: rowElement.title
              }
            })
          ]),
          _c("div", { staticClass: "col col-xs-12 col-6" }, [
            _c(
              "div",
              { staticClass: "content-slider" },
              [
                rowElement.title
                  ? _c("p", {
                      staticClass: "title",
                      domProps: { innerHTML: _vm._s(rowElement.title) }
                    })
                  : _vm._e(),
                rowElement.tableData
                  ? _c("OrganismTableGeneral", {
                      attrs: { "table-data": rowElement.tableData }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }