<template>
  <div class="title-and-info">
    <div class="filters-title-and-button">
      <div class="filters-title">
        <p>
          <AtomIcon icon="filter-solid" :size="26" />
          Filters
          <span v-if="activeFiltersLength > 0" class="number"
            >/ {{ activeFiltersLength }}</span
          >
        </p>
        <AtomButton
          class="rounded-button"
          v-show="activeFiltersLength > 0"
          label="Clear All"
          :on-click="handleClearAllFilters"
        />
      </div>
      <div class="filters-trigger" @click="toggleShowFilters">
        <p v-if="!showFilters">
          expand
          <AtomIcon icon="sort-down" :size="26" />
        </p>
        <p class="inverse-arrow" v-else>
          minimize
          <AtomIcon icon="sort-down" :size="26" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../../atoms/common/AtomIcon";
import AtomButton from "../../../atoms/common/AtomButton";

export default {
  name: "MoleculeFilterHeader",
  components: { AtomButton, AtomIcon },
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "digital",
    },
  },
  data() {
    return {
      showFilters: true,
    };
  },
  computed: {
    filters() {
      return this.$store.state.project?.filters || null;
    },
    activeFiltersLength() {
      let count = 0;
      if (!this.filters || !this.filters[this.activeTab]) return count;
      for (const type in this.filters[this.activeTab]) {
        const val = this.filters[this.activeTab][type];
        if (
          ![
            "advertisingTiming", // string
            "typeOfPackage", // string
            "showAllGroupOptions", // boolean
            "areas", // array
            "devices", // array
          ].includes(type)
        ) {
          continue;
        }
        if (type === "showAllGroupOptions") {
          if (val !== false) {
            count++;
          }
        } else if (Array.isArray(val)) {
          if (val.length !== 0) {
            count++;
          }
        } else {
          if (val !== "all") {
            count++;
          }
        }
      }
      return count;
    },
  },
  methods: {
    toggleShowFilters() {
      this.showFilters = !this.showFilters;
      this.$emit("toggle-filters", this.showFilters);
    },
    handleClearAllFilters() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.RESET_FILTERS)
      );
      this.$emit("re-init-filters", true);
    },
  },
};
</script>

<style lang="scss">
.filters-title-and-button {
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 1.5rem;
  @media only screen and (max-width: 767px) {
    padding: 1rem 0 0;
  }
  .filters-title {
    display: flex;
    align-items: center;
    .rounded-button {
      margin-left: 1.25rem;
      padding: 0.125rem 0.75rem;
      gap: 0.625rem;
      border: none;
      border-radius: 18.75rem;
      background: $darkerWhite;
      transition: all 0.5s ease-in-out;
      span {
        text-transform: none;
        font-weight: $regular;
        color: $black;
      }
      &:hover {
        background: $black;
        span {
          color: $white;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.188rem;
    }
    .icon {
      font-size: 1.125rem !important;
      display: inline-block;
      vertical-align: middle;
      margin-top: -0.063rem;
      margin-right: 0.313rem;
      &:before {
        margin: 0;
      }
    }
  }
  .filters-trigger {
    cursor: pointer;
    p {
      font-weight: $regular;
      font-size: 0.875rem;
      line-height: 1.063rem;
      color: rgba(2, 2, 2, 0.3);
      .icon {
        font-size: 1rem !important;
        display: inline-block;
        vertical-align: middle;
        margin-top: -1.563rem;
        margin-left: 0.313rem;
        height: 0;
        &:before {
          margin: 0;
          height: 0;
        }
      }
      &.inverse-arrow {
        .icon {
          transform: rotate(180deg);
          margin-top: 1.25rem;
        }
      }
    }
  }
}
</style>
