<template>
  <ul class="changeFitout">
    <li
      v-for="(fitout, fitName) in fitouts"
      @click="changeFitout(fitout.name)"
      :key="fitName"
    >
      <AtomButton :label="fitout.buttonText" />
    </li>
  </ul>
</template>

<script>
import AtomButton from "../../common/AtomButton";

export default {
  name: "AtomPinFitoutButtons",
  components: { AtomButton },
  props: {
    fitouts: {
      required: false,
      type: Array,
      default: function () {
        return [
          {
            name: "",
            buttonText: "",
          },
        ];
      },
    },
  },
  methods: {
    changeFitout(value) {
      console.log("old code legacy ", value);
    },
  },
};
</script>

<style scoped></style>
