var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", {
    class: { "text-uppercase": _vm.uppercaseText },
    style: {
      "font-size": _vm.fontSize,
      "background-color": _vm.backgroundColor,
      color: _vm.textColor
    },
    attrs: { rowspan: _vm.rowspan, colspan: _vm.colspan },
    domProps: { innerHTML: _vm._s(_vm.text) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }