var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "menu",
      class: { "organism-menu-bar": true, scrolled: _vm.isScrolled },
      attrs: { id: "header-project" }
    },
    [
      _c("div", { staticClass: "row align-items-center menu-bar-content" }, [
        _c("div", { staticClass: "block-element" }, [
          _c(
            "div",
            { staticClass: "inline-elements" },
            [_c("MoleculeMenuTrigger")],
            1
          ),
          _c("div", { staticClass: "inline-elements" }, [
            _c(
              "div",
              {
                staticClass: "logo-header",
                on: { click: _vm.scrollTopMethod }
              },
              [
                _c(
                  "router-link",
                  { attrs: { tag: "div", to: { name: "Home" } } },
                  [_c("AtomLogo")],
                  1
                )
              ],
              1
            )
          ]),
          _vm.showProjectSelector
            ? _c(
                "div",
                { staticClass: "inline-elements hidden-xs" },
                [_c("AtomDropdown")],
                1
              )
            : _vm._e(),
          _vm.showProjectSelector
            ? _c(
                "div",
                { staticClass: "inline-elements hidden-xs" },
                [_c("AtomFloorSelector")],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "block-element" }, [
          _c("div", { staticClass: "inline-elements" }, [
            _c("div", { staticClass: "menu" }, [_vm._t("default")], 2)
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }