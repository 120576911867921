<template>
  <ul class="atom-list">
    <li v-for="(line, index) in lines" :key="index">
      <a v-if="line.link" :href="line.link" :class="{ active: line.active }">
        <AtomIcon v-if="line.icon" :icon="line.icon" />
        <span v-if="line.text" v-html="line.text"></span>
      </a>
      <p v-else>
        <AtomIcon v-if="line.icon" :icon="line.icon" />
        <span v-if="line.text" v-html="line.text"></span>
      </p>
    </li>
  </ul>
</template>

<script>
import AtomIcon from "./AtomIcon";
export default {
  name: "AtomList",
  components: { AtomIcon },
  props: {
    lines: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.atom-list {
  position: relative;
  z-index: 5;
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  @media only screen and (max-width: 767px) {
    justify-content: initial;
    padding: 0 2rem 0.5rem;
    overflow: hidden;
    overflow-x: auto;
    gap: 1rem;
  }
  li {
    a {
      display: block;
      padding: 0.5rem 1rem;
      border-radius: 0.214rem;
      @media only screen and (max-width: 767px) {
        white-space: nowrap;
      }
      .icon {
        font-size: 1.5rem !important;
        color: $white !important;
        margin-right: 0.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 1.2rem !important;
        }
        &:before {
          margin: 0 !important;
        }
      }
      span {
        transition: all 0.2s ease-in-out 0s;
        @include font-main(1.5rem, $white, $medium, 1.5rem);
        @media only screen and (max-width: 767px) {
          @include font-main(1.2rem, $white, $medium, 1.2rem);
        }
      }
      &:hover,
      &.active {
        background: $main-color !important;
        .icon {
          color: $white !important;
        }
        span {
          color: $white !important;
        }
      }
    }
  }
}
</style>
