<template>
  <div class="organism-building" :style="{ height: `${windowHeight}px` }">
    <div
      id="canvas-wrapper"
      :class="{ 'in-floor': isFloorView }"
      :style="{ height: `${windowHeight}px` }"
    >
      <canvas ref="canvas" id="project-canvas" />
    </div>
  </div>
</template>

<script>
import buildingStore, { CurrentView } from "./store";
import config from "../../../../config";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";

export default {
  name: "OrganismBuilding",
  components: {},
  props: {
    customModel: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    isInitialized() {
      return buildingStore.state.isInitialized;
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    isFloorView() {
      return this.buildingStore.state.currentView === CurrentView.FLOOR;
    },
    spaces() {
      return this.$store.state.project.project.spaces;
    },
    buildings() {
      return this.$store.state.project.project.buildings;
    },
    floors() {
      return this.$store.state.project.project.floors;
    },
    generalConfig() {
      return this.$store.state?.base?.meta?.general;
    },
  },
  watch: {
    isInitialized: function (value) {
      if (value) {
        window.addEventListener("resize", this.resizeListener);
        this.sceneManager.store = this.$store;
        if (this.$route.query.v === "debug") {
          this.sceneManager.scene.debugLayer.show();
        }
      }
    },
  },
  data() {
    return {
      windowHeight: typeof window !== "undefined" ? window.innerHeight : 0,
      pinData: [],
    };
  },
  beforeDestroy() {
    this.buildingStore.dispatch("destroy");
    this.sceneManager.destroy();
    window.removeEventListener("resize", this.resizeListener);
  },
  methods: {
    initializeBuilding3D() {
      if (this.$store.getters.getActiveProjectSettings.building.pinsData) {
        this.pinData = this.$store.getters.getActiveProjectSettings.building.pinsData;
      }
      const payload = {
        pinsData: this.pinData,
        client: config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
      };
      this.buildingStore.dispatch("destroy");
      this.buildingStore.dispatch("initBuilding", payload);
      ////Init scene
      const client = Utils.convertToCamel(config.CLIENT);
      if (typeof BabylonClientManager[client] === "function") {
        this.sceneManager = BabylonClientManager[client](this.$refs.canvas);
      } else {
        this.sceneManager = BabylonClientManager["default"](this.$refs.canvas);
      }

      this.sceneManager.initScene(
        payload,
        this.buildingStore,
        this.$refs.canvas
      );
      this.sceneManager.loadBuilding();
    },
    initializeFloor3D(spaceId) {
      const space = this.spaces.find((s) => s.id === spaceId);
      if (!space) return;

      if (this.$store.getters.getActiveProjectSettings.floor.pinsData) {
        this.pinData = this.$store.getters.getActiveProjectSettings.floor.pinsData;
      }

      const building = this.buildings.find((b) => b.id === space.building_id);
      const floor = this.floors.find((f) => f.id === space.floor_id);
      const payload = {
        pinsData: this.pinData,
        space: space.space_code,
        building: building.code,
        floor: floor.code,
        floorType: floor.floor_type.model,
        spaceData: space,
        client: config.CLIENT,
        project: this.$store.state.project.project,
        cdnBase: this.cdnBase,
        activeProject: this.activeProject,
        settings: this.$store.getters.getActiveProjectSettings,
      };
      this.buildingStore.dispatch("destroy");
      this.buildingStore.dispatch("viewSpace", payload);
      this.buildingStore.dispatch("initFloor", payload);
      ////Init scene
      const client = Utils.convertToCamel(config.CLIENT);
      if (typeof BabylonClientManager[client] === "function") {
        this.sceneManager = BabylonClientManager[client](this.$refs.canvas);
      } else {
        this.sceneManager = BabylonClientManager["default"](this.$refs.canvas);
      }
      this.sceneManager.initScene(
        payload,
        this.buildingStore,
        this.$refs.canvas
      );
      this.sceneManager.loadFloor();
    },
    resizeListener() {
      this.windowHeight = window.innerHeight;
      this.$nextTick(() => {
        this.sceneManager.resize();
      });
    },
  },
};
</script>

<style lang="scss">
.organism-building {
  width: 100%;
  #canvas-wrapper {
    width: 100%;
  }

  #project-canvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
  }
}

/*vor fi sterse*/

#inspector-host {
  position: absolute !important;
  left: 0;
  bottom: auto !important;
  width: 100% !important;
  z-index: auto !important;
}
#sceneExplorer {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  left: 0 !important;
}
#actionTabs,
#inspector-host {
  position: fixed !important;
  z-index: 999999;
  top: 55px;
  right: 0;
}

.btn-wrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 5rem;
}

.row-floor-active-test {
  padding: 0.313rem;
  border: 1px solid gold;
  cursor: pointer;
  margin: 0 0.313rem;
}

.pinInfo {
  left: 0;
  top: 0;
  position: absolute;
  background: white;
  color: black;
  font-size: 12px;
  border-radius: 5px;
  padding: 0;
  z-index: 99;
  max-width: 280px;
  box-shadow: 0 0 10px $blackOpacity012;
  .simple-wrapper {
    padding: 15px 15px 10px;
    @media only screen and (max-width: 1200px) {
      padding: 0;
      max-width: 200px;
    }
  }
  p {
    margin-top: 5px;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  a {
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
  h4 {
    text-transform: uppercase;
    margin: 0;
    @media only screen and (max-width: 1200px) {
      font-size: 12px;
    }
  }
}

.pinText {
  padding: 0px 20px 16px;
}

.pinTextTitle {
  padding: 20px 20px 16px;
}

.pinWrapper {
  padding: 10px 15px;
}
</style>
