var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results-info" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.activeFiltersLength > 0,
            expression: "activeFiltersLength > 0"
          }
        ]
      },
      [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.numberOfResults === 0
                ? "No results found"
                : _vm.numberOfResults === 1
                ? _vm.numberOfResults + " result found"
                : _vm.numberOfResults + " results found"
            )
          }
        })
      ]
    ),
    _vm._v("\n  Scroll Down\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }