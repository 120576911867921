<template>
  <div
    class="section section-media-exposure"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="title-padding">
      <AtomTitleLine :title="data.title" />
    </div>
    <div class="table-overflow">
      <OrganismTableGeneral :table-data="data.tableData" />
    </div>
  </div>
</template>

<script>
import AtomTitleLine from "../../components/atoms/common/AtomTitleLine";
import OrganismTableGeneral from "../../components/organisms/common/table/OrganismTableGeneral";
export default {
  name: "SectionMediaExposure",
  components: { OrganismTableGeneral, AtomTitleLine },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.section-media-exposure {
  position: relative;
  padding: 0 2rem;
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 4rem;
  }
  .table-overflow {
    @media only screen and (max-width: 767px) {
      overflow-x: auto;
      padding: 0 2rem 0.25rem;
    }
  }
  .title-padding {
    @media only screen and (max-width: 767px) {
      padding: 0 2rem;
    }
  }
  .organism-table-general {
    margin: 0 auto;
    @media only screen and (max-width: 767px) {
      min-width: 80rem;
    }
  }
  .atom-title-line {
    .title {
      @media only screen and (max-width: 767px) {
        width: 18rem;
        max-width: 100%;
      }
    }
  }
}
</style>
