<template>
  <div
    class="section section-media-details clearfix"
    :class="{ [`section${orderNo}`]: true }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="header-elements">
      <div class="title-padding">
        <AtomTitleLine :title="data.title" />
      </div>
      <AtomList :lines="data.lines" />
    </div>
    <OrganismTabsPackages :tabs="data.tabs" />
    <span
      v-if="data.pinLabel"
      class="tooltip-element"
      @click="handleInfoTrigger(data.infoPopup)"
    >
      <AtomIcon icon="info" />
      {{ data.pinLabel }}
    </span>
  </div>
</template>

<script>
import AtomTitleLine from "../../components/atoms/common/AtomTitleLine";
import AtomList from "../../components/atoms/common/AtomList";
import OrganismTabsPackages from "../../components/organisms/common/OrganismTabsPackages";
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
export default {
  name: "SectionMediaDetails",
  components: { AtomIcon, OrganismTabsPackages, AtomList, AtomTitleLine },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    handleInfoTrigger(infoPopup) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_MEDIA_INFO_BOX
        ),
        {
          open: !this.open,
          data: infoPopup,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.section-media-details {
  position: relative;
  background: rgba(217, 217, 217, 0.2);
  margin-bottom: 6.25rem;
  @media only screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
  .header-elements {
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .title-padding {
    @media only screen and (max-width: 767px) {
      padding: 0 2rem;
    }
  }
  .atom-title-line {
    .title {
      background: rgb(247, 247, 247);
    }
  }
  .atom-list {
    li {
      a {
        .icon {
          color: #1a617f !important;
        }
        span {
          color: #1a617f;
        }
      }
    }
  }
  .tooltip-element {
    position: absolute;
    bottom: 1.125rem;
    right: 3.125rem;
    padding: 0.375rem 0.625rem 0.475rem;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.125rem;
    cursor: pointer;
    z-index: 8;
    transition: all 0.2s ease-in-out 0s;
    @include font-main(1rem, $white, $regular, normal);
    .icon {
      font-size: 1rem !important;
      color: $white !important;
      margin-right: 0.1rem;
    }
    &:hover,
    &.active {
      background: $black;
    }
  }
}
</style>
