var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-sidebar-results" }, [
    _vm.activeProject && _vm.activeTab === "digital"
      ? _c(
          "div",
          { staticClass: "bottom-content" },
          _vm._l(_vm.listData.digitalItems, function(building) {
            return _c("AtomCollapsibleItem", {
              key: "building_" + building.id,
              ref: "building_" + building.id,
              refInFor: true,
              staticClass: "parent-box",
              attrs: {
                item: building,
                "is-default-open": true,
                "show-icon-text": true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "title-box" }, [
                          _c("p", { staticClass: "title-text" }, [
                            _vm._v(_vm._s(building.name))
                          ]),
                          _vm.shortlistItemsLength &&
                          _vm.shortlistType === "digital"
                            ? _c(
                                "span",
                                {
                                  staticClass: "building-selected-options-count"
                                },
                                [_vm._v(_vm._s(_vm.shortlistItemsLength))]
                              )
                            : _vm._e()
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return _vm._l(building.packs, function(pack, name) {
                        return _c("AtomCollapsibleItem", {
                          key: "pack_" + name,
                          ref: "pack_" + name,
                          refInFor: true,
                          staticClass: "packs",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "package-container" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "package-title" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(name) +
                                                "\n                Package\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "package-title-length"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(pack.length) +
                                                "\n                " +
                                                _vm._s(
                                                  pack.length > 1
                                                    ? "Options"
                                                    : "Option"
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm.countSelectedPacks(
                                          pack,
                                          _vm.shortlistType
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "package-selected-options-count"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.countSelectedPacks(
                                                      pack,
                                                      _vm.shortlistType
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "location-info" },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "You can view the locations of the screens by tapping /\n                clicking on them"
                                          )
                                        ])
                                      ]
                                    ),
                                    _vm._l(pack, function(packOption) {
                                      return _c("AtomCollapsibleItem", {
                                        key: "packOption_" + packOption.id,
                                        ref: "packOption_" + packOption.id,
                                        refInFor: true,
                                        staticClass: "pack-options",
                                        attrs: { item: packOption },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "title",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "div",
                                                    {},
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "package-option-title"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                packOption.duration
                                                              ) +
                                                              "\n                    Seconds\n                  "
                                                          )
                                                        ]
                                                      ),
                                                      _c("AtomIcon", {
                                                        staticClass:
                                                          "time-icon",
                                                        attrs: {
                                                          icon: "timer-v2"
                                                        }
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "package-broadcasts"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                packOption.hourly_broadcasts
                                                              ) +
                                                              " broadcasts/hour\n                  "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "content",
                                              fn: function() {
                                                return [
                                                  _c("MoleculeTablePackage", {
                                                    attrs: {
                                                      "pack-id": packOption.id,
                                                      "digital-items":
                                                        building.digitalItems
                                                    }
                                                  }),
                                                  _c("AtomItemsButtons", {
                                                    attrs: {
                                                      "digital-items":
                                                        building.digitalItems,
                                                      "pack-option": packOption,
                                                      "building-id":
                                                        building.id,
                                                      "item-id": packOption.id,
                                                      type: _vm.activeTab
                                                    }
                                                  })
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      })
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          }),
          1
        )
      : _vm._e(),
    _vm.activeProject && _vm.activeTab === "physical"
      ? _c(
          "div",
          { staticClass: "physical-supports" },
          _vm._l(_vm.listData.physicalItems, function(building) {
            return _c("AtomCollapsibleItem", {
              key: "building_" + building.id,
              ref: "building_" + building.id,
              refInFor: true,
              staticClass: "parent-box",
              attrs: {
                item: building,
                "is-default-open": true,
                "show-icon-text": true
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "title-box" }, [
                          _c("p", { staticClass: "title-text" }, [
                            _vm._v(_vm._s(building.name))
                          ]),
                          _vm.shortlistItemsLength &&
                          _vm.shortlistType === "physical"
                            ? _c(
                                "span",
                                {
                                  staticClass: "building-selected-options-count"
                                },
                                [_vm._v(_vm._s(_vm.shortlistItemsLength))]
                              )
                            : _vm._e()
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return _vm._l(building.physicalItems, function(
                        physicalItem,
                        type
                      ) {
                        return _c("AtomCollapsibleItem", {
                          key: "physical_" + type,
                          ref: "physical_" + type,
                          refInFor: true,
                          staticClass: "physical-items",
                          attrs: { item: physicalItem },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "physical-item-container"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "physical-item-title"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  type.charAt(0).toUpperCase() +
                                                    type.slice(1)
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "physical-item-title-length"
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.physicalItemTotalCount(
                                                    physicalItem
                                                  )
                                                ) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm.countSelectedPacks(
                                          type,
                                          _vm.shortlistType
                                        )
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "physical-selected-options-count"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.countSelectedPacks(
                                                      type,
                                                      _vm.shortlistType
                                                    )
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return _vm._l(physicalItem, function(
                                    area,
                                    name
                                  ) {
                                    return _c("AtomCollapsibleItem", {
                                      key: "area_" + name,
                                      ref: "area_" + name,
                                      refInFor: true,
                                      staticClass: "area-options",
                                      attrs: { item: { area: area } },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function() {
                                              return [
                                                _c("div", {}, [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "area-option-title"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(name) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "content",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "margin-05" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "area-items"
                                                      },
                                                      _vm._l(area, function(
                                                        item
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key:
                                                              "item_" + item.id,
                                                            staticClass:
                                                              "area-item-option",
                                                            class: {
                                                              active: _vm.isActive(
                                                                item.code
                                                              )
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      item.coordinates_id,
                                                                    expression:
                                                                      "item.coordinates_id"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "hover-element",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.moveCameraToItem(
                                                                      item
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("AtomIcon", {
                                                                  attrs: {
                                                                    icon:
                                                                      "act-ethically"
                                                                  }
                                                                }),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.getButtonText(
                                                                          item.code
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      }),
                                                      0
                                                    ),
                                                    _c("AtomItemsButtons", {
                                                      attrs: {
                                                        "building-id":
                                                          building.id,
                                                        "item-id":
                                                          "areaId:" +
                                                          area[0].area_id +
                                                          "_type:" +
                                                          area[0].type,
                                                        "physical-item-type":
                                                          area[0].type,
                                                        type: _vm.activeTab
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  })
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      })
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }