<template>
  <div class="atom-content-slider3 slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomContentSlider3",
  props: {}
}
</script>

<style lang="scss">
  .row-slider-info {
    .col {
      &:first-child {
        padding-right: 2rem;
        @media only screen and (max-width: 767px) {
          padding-right: 0;
        }
      }
      &:last-child {
        padding-left: 2rem;
        @media only screen and (max-width: 767px) {
          padding-left: 0;
        }
      }
    }
    .content-slider {
      max-width: 39.25rem;
      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        padding: 1.5rem 2rem 3rem;
      }
    }
    .title {
      font-weight: $light;
      font-size: 3.25rem;
      line-height: 3.875rem;
      letter-spacing: -0.02em;
      margin-bottom: 1.563rem;
      color: $second-color;
      @media only screen and (max-width: 767px) {
        font-size: 2.5rem;
        line-height: 3rem;
        margin-bottom: 0.5rem;
      }
      b {
        font-weight: $bold;
        font-size: 3.25rem;
        line-height: 3.875rem;
        color: $second-color;
        @media only screen and (max-width: 767px) {
          font-size: 2.5rem;
          line-height: 3rem;
          margin-bottom: 0.5rem;
        }
      }
      span {
        display: block;
        font-weight: $light;
        font-size: 2rem;
        line-height: 3.063rem;
        @media only screen and (max-width: 767px) {
          font-size: 2rem;
          line-height: 3.063rem;
        }
      }
    }
    .subtitle-info {
      margin-bottom: 1.25rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
      }
      .subtitle {
        margin-bottom: 0.313rem;
        font-size: 1.375rem;
        line-height: 2rem;
        letter-spacing: -0.02em;
        color: $second-color;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.313rem;
          font-size: 1.125rem;
          line-height: 1.313rem;
        }
      }
    }
    .statistics-info {
      margin-bottom: 1.625rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 2.5rem;
      }
      .atom-number-and-text {
        display: inline-block;
        margin-right: 4.563rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 1.25rem;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
    .buttons-bottom {
      .display-inline {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1.25rem;
        @media only screen and (max-width: 767px) {
          margin-right: 1rem;
        }
        &:last-child {
          margin-right: 0;
        }
        .btn-general {
          padding: 0.75rem 1rem;
        }
      }
    }
  }
  .section2 {
    .row-slider-info {
      .buttons-bottom {
        .display-inline {
          display: block;
          margin: 0;
          .btn-general {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
</style>