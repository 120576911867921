<template>
  <div class="atom-option">
    <AtomButton
      ref="optionBtn"
      :class="{ 'option-button': true, active: internalValue }"
      :label="option.text"
      :icon="internalValue ? 'minus' : 'plus'"
      :on-click="handleClick"
    />
  </div>
</template>

<script>
import AtomButton from "./AtomButton";
export default {
  name: "AtomOption",
  components: { AtomButton },
  props: {
    option: {
      required: true,
      type: Object,
      default: () => ({
        text: "option-name",
        id: "",
      }),
    },
    value: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lazyVal: this.value,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyVal;
      },
      set(val) {
        this.lazyVal = val;
        this.$emit("input", val);
      },
    },
  },
  watch: {
    value(val) {
      this.lazyVal = val;
    },
  },
  methods: {
    handleClick(o) {
      this.internalValue = !this.internalValue;
    },
  },
};
</script>

<style lang="scss">
.atom-option {
  .option-button {
    margin: 0.188rem 0.375rem 0.188rem 0;
    height: 1.5rem;
    padding: 0 0.5rem 0 0;
    gap: 0.5rem;
    border: none;
    border-radius: 0.063rem;
    background: $darkerWhite;
    &:hover {
      background: #e9eef0;
      span {
        color: $black;
      }
      .icon {
        background: $black;
        color: $white !important;
      }
    }
    &.active {
      background: $black;
      span {
        color: $white;
      }
      .icon {
        color: $white !important;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    span {
      display: inline-block;
      vertical-align: middle;
      color: $black;
      text-transform: none;
      font-weight: $regular;
      transition: all 0.2s ease-in-out 0s;
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      color: $black !important;
      font-size: 1.1rem !important;
      margin: 0 0.5rem 0 0;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
}
</style>
