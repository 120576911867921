<template>
  <div class="organism-request-offer-packages">
    <MoleculeDynamicModal />
    <div class="row-package">
      <div
        class="items-info"
        v-for="[parentKey, typeOfItems] in Object.entries(
          shortlistGroupedItems
        )"
        :key="`type-${parentKey}`"
      >
        <h2 class="center-category" v-if="typeOfItems.buildings.length > 0">
          {{ parentKey.charAt(0).toUpperCase() + parentKey.slice(1) }} items
        </h2>
        <div
          v-for="(building, idx) in typeOfItems.buildings"
          :key="`building_${idx}`"
          :ref="`building_${idx}`"
        >
          <div class="row-project-name">
            <p>
              {{ buildings.find((build) => build.id === building.id).name }}
            </p>
          </div>
          <!-- DIGITAL ITEMS -->
          <div
            class="items-info-wrapper"
            v-if="
              shortlistGroupedItems.digital.buildings.length > 0 &&
              parentKey === ItemTypes.DIGITAL
            "
          >
            <div
              class="digital-option-margin"
              v-for="(opt, index) in building.options"
              :key="index"
            >
              <div class="row-packages-tabs">
                <div class="tabs-packages">
                  <div
                    class="tags-wrapper"
                    v-for="(tag, tagIdx) in tags"
                    :key="`tag-${tagIdx}`"
                    @click="
                      handleChangePackageType(
                        tag.key.toLowerCase(),
                        opt,
                        building,
                        parentKey
                      )
                    "
                  >
                    <span
                      class="tag-name"
                      :class="{ active: tag.key.toLowerCase() === opt.type }"
                      >{{ tag.key }}</span
                    >
                    <span class="vertical-line" v-if="tagIdx < tags.length - 1"
                      >|</span
                    >
                  </div>
                </div>
                <button
                  class="btn-trash-item btn-trash-item-digital"
                  @click="removeFromRequestDigital(building.id, opt.unique_key)"
                >
                  <AtomIcon icon="trash-can" />
                </button>
              </div>
              <MoleculeCollapsiblePackages>
                <template v-slot:header>
                  <span class="text"
                    ><b>{{ opt.duration }} Seconds</b></span
                  >
                  <span class="text"
                    ><AtomIcon icon="timer-v2" />
                    <b>{{ opt.hourly_broadcasts }} </b> broadcasts/hour</span
                  >
                </template>
                <template v-slot:body>
                  <MoleculeTablePackage
                    :digital-items="opt.items"
                    :pack-id="opt.pack_id"
                  />
                </template>
              </MoleculeCollapsiblePackages>
              <div class="row-date-select">
                <p>Enter the period you want your campaign to run:</p>
                <div class="row row-date-inputs">
                  <div class="col col-6">
                    <AtomInput
                      :type="inputTypes.DATE"
                      label="Start Date"
                      :value="
                        physicalItemTypeDate(
                          building.id,
                          opt.pack_id,
                          parentKey
                        ).startDate || null
                      "
                      @input="
                        handleChangeStartDate(
                          building.id,
                          opt.pack_id,
                          $event,
                          parentKey
                        )
                      "
                    />
                  </div>
                  <div class="col col-6">
                    <AtomInput
                      :type="inputTypes.DATE"
                      label="End Date"
                      :value="
                        physicalItemTypeDate(
                          building.id,
                          opt.pack_id,
                          parentKey
                        ).endDate || null
                      "
                      @input="
                        handleChangeEndDate(
                          building.id,
                          opt.pack_id,
                          $event,
                          parentKey
                        )
                      "
                    />
                  </div>
                </div>
                <AtomCheck
                  id="available-soon"
                  ref="check"
                  class="row-checkbox"
                  label="As soon as it’s available"
                  :is-checked="
                    physicalItemTypeDate(building.id, opt.pack_id, parentKey)
                      .checkbox || false
                  "
                  @input="
                    handleCheckbox(building.id, opt.pack_id, $event, parentKey)
                  "
                />
              </div>
              <span class="horizontal-line"></span>
            </div>
          </div>

          <!-- PHYSICAL ITEMS -->
          <div
            class="items-info-wrapper"
            v-if="
              shortlistGroupedItems.physical.buildings.length > 0 &&
              parentKey === ItemTypes.PHYSICAL
            "
          >
            <div
              v-for="(option, optionIdx) in building.options"
              :key="optionIdx"
            >
              <MoleculeCollapsiblePackages>
                <template v-slot:header>
                  <span class="text"
                    ><b>{{ capitalize(optionIdx) }}</b></span
                  >
                  <br />
                  <span class="text">
                    {{ countTotalOfPhysicalItems(option) }} items
                  </span>
                  <button
                    class="btn-trash-item btn-trash-item-physical"
                    @click="
                      removeFromRequestPhysicalByType(building.id, optionIdx)
                    "
                  >
                    <AtomIcon icon="trash-can" />
                  </button>
                </template>
                <template v-slot:body>
                  <div class="table-header">
                    <div class="row-table-header">
                      <div class="columns">
                        <span>Area</span>
                      </div>
                      <div class="columns">
                        <span>No. of physical supports</span>
                      </div>
                    </div>
                  </div>
                  <MoleculeTableArea
                    v-for="areaItem in option"
                    :key="areaItem.unique_key"
                    :area="areaItem.area"
                    :items="areaItem.items"
                    :pkey="areaItem.unique_key"
                    :building-id="building.id"
                  />
                </template>
              </MoleculeCollapsiblePackages>
              <div class="row-date-select">
                <p>Campaign Period: <span>(not required)</span></p>
                <div class="row row-date-inputs">
                  <div class="col col-6">
                    <AtomInput
                      :type="inputTypes.DATE"
                      :value="
                        physicalItemTypeDate(building.id, optionIdx, parentKey)
                          .startDate || null
                      "
                      label="Start Date"
                      @input="
                        handleChangeStartDate(
                          building.id,
                          optionIdx,
                          $event,
                          parentKey
                        )
                      "
                    />
                  </div>
                  <div class="col col-6">
                    <AtomInput
                      :type="inputTypes.DATE"
                      :value="
                        physicalItemTypeDate(building.id, optionIdx, parentKey)
                          .endDate || null
                      "
                      label="End Date"
                      @input="
                        handleChangeEndDate(
                          building.id,
                          optionIdx,
                          $event,
                          parentKey
                        )
                      "
                    />
                  </div>
                </div>
                <AtomCheck
                  :id="`checkbox_${optionIdx}`"
                  class="row-checkbox"
                  label="As soon as it’s available"
                  :is-checked="
                    physicalItemTypeDate(building.id, optionIdx, parentKey)
                      .checkbox || false
                  "
                  @input="
                    handleCheckbox(building.id, optionIdx, $event, parentKey)
                  "
                />
              </div>
              <span class="horizontal-line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import MoleculeCollapsiblePackages from "../molecules/MoleculeCollapsiblePackages";
import AtomInput, { EnumInputTypes } from "../atoms/common/AtomInput";
import MoleculeTablePackage from "../molecules/MoleculeTablePackage";
import AtomCheck from "../atoms/common/AtomCheck";
import MoleculeDynamicModal from "../molecules/common/MoleculeDynamicModal.vue";
import MoleculeTableArea from "../molecules/MoleculeTableArea.vue";
import { ItemTypes } from "@/store/modules/shortlist/types";

export default {
  name: "OrganismRequestOfferPackages",
  components: {
    MoleculeDynamicModal,
    AtomCheck,
    MoleculeTablePackage,
    MoleculeTableArea,
    AtomInput,
    MoleculeCollapsiblePackages,
    AtomIcon,
  },
  data() {
    return {
      inputTypes: EnumInputTypes,
      tags: [
        {
          key: "Bronze",
        },
        {
          key: "Silver",
        },
        {
          key: "Gold",
        },
        {
          key: "Platinum",
        },
      ],
    };
  },
  computed: {
    ItemTypes() {
      return ItemTypes;
    },
    buildings() {
      return this.$store.state.project?.buildings || [];
    },
    digitalItems() {
      return this.building?.digitalItems || [];
    },
    baseConstants() {
      return this.$store.getters.constants.base;
    },
    shortlistItems() {
      return this.$store.state.shortlist || null;
    },
    requestOfferItems() {
      return this.$store.state.requestOffer || null;
    },
    type() {
      let shortlistType = null;
      if (this.shortlistItems?.digital?.buildings?.length > 0) {
        shortlistType = ItemTypes.DIGITAL;
      } else if (this.shortlistItems?.physical?.buildings?.length > 0) {
        shortlistType = ItemTypes.PHYSICAL;
      }
      return shortlistType;
    },
    shortlistGroupedItems() {
      const items = {
        digital: {
          buildings: [],
        },
        physical: {
          buildings: [],
        },
      };
      if (!items) return null;
      Object.entries(this.requestOfferItems).forEach(([key, shortlistType]) => {
        switch (key) {
          case ItemTypes.DIGITAL: {
            shortlistType.buildings?.forEach((b, idx) => {
              items.digital.buildings[idx] = { ...b };
              if (!b.options || b.options.length === 0) return;
              for (const opt of b.options) {
                opt.unique_key = opt.pack_id;
              }
            });
            break;
          }
          case ItemTypes.PHYSICAL: {
            shortlistType.buildings?.forEach((b, idx) => {
              items.physical.buildings[idx] = { ...b };
              if (!b.options || b.options.length === 0) return;
              for (const opt of b.options) {
                opt.unique_key = `areaId:${opt.area.id}_type:${opt.type}`;
              }
              items.physical.buildings[idx].options = this.groupBy(
                b.options,
                (opt) => opt.type
              );
            });
            break;
          }
          default:
            break;
        }
      });
      return items;
    },
    shortlistConstants() {
      return this.$store.getters.constants.shortlist;
    },
  },
  methods: {
    groupBy(arr, keyGetter) {
      const out = {};
      for (let item of arr) {
        const key = keyGetter(item);
        out[key] ??= [];
        out[key].push(item);
      }
      return out;
    },
    handleChangePackageType(tag, option, building, type) {
      const requestOfferType = this.requestOfferItems.requestOfferType;
      const existingOptions = building?.options || [];
      if (type === ItemTypes.DIGITAL && existingOptions.length > 0) {
        const foundOption =
          existingOptions?.find(
            (o) => o.type === tag && o.duration === option.duration
          ) || null;
        if (foundOption) {
          const modal = this.$store.getters.constants.modal;
          this.$store.dispatch(
            modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
            {
              showModal: true,
              modalType: "dynamic",
              modalData: {
                title: "Warning!",
                text:
                  "You already have a package selected of type " +
                  tag.toLocaleUpperCase(),
              },
            }
          );
          document.body.classList.add("disable-scroll");
          return;
        }
        // it means we don't have the new selected option so we have to update the options
        const shortlist = this.$store.getters.constants.shortlist;
        const requestOffer = this.$store.getters.constants.requestOffer;
        if (requestOfferType === "shortlist") {
          this.$store.dispatch(
            shortlist.withNamespace(
              shortlist.action.EDIT_DIGITAL_FROM_SHORTLIST
            ),
            {
              oldOption: option,
              buildingId: building.id,
              newType: tag,
            }
          );
        } else if (requestOfferType === "single") {
          this.$store.dispatch(
            requestOffer.withNamespace(
              requestOffer.action.EDIT_DIGITAL_FROM_REQUEST_OFFER
            ),
            {
              oldOption: option,
              buildingId: building.id,
              newType: tag,
            }
          );
        }
      }
    },
    handleChangeStartDate(building_id, optionIdx, selectedDate, itemType) {
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.SELECT_CAMPAIGN_OPTION),
        {
          buildingId: building_id,
          optionIdx: optionIdx,
          startDate: selectedDate,
          itemType,
        }
      );
    },
    handleChangeEndDate(building_id, optionIdx, selectedDate, itemType) {
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.SELECT_CAMPAIGN_OPTION),
        {
          buildingId: building_id,
          optionIdx: optionIdx,
          endDate: selectedDate,
          itemType,
        }
      );
    },
    handleCheckbox(building_id, optionIdx, event, itemType) {
      const requestOffer = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
        requestOffer.withNamespace(requestOffer.action.SELECT_CAMPAIGN_OPTION),
        {
          buildingId: building_id,
          optionIdx: optionIdx,
          checkbox: event,
          itemType,
        }
      );
    },
    physicalItemTypeDate(buildingId, optionIdx, itemType) {
      let building;
      if (itemType === ItemTypes.DIGITAL) {
        building = this.requestOfferItems.digital.buildings.find(
          (building) => building.id === buildingId
        );
        return (
          building?.itemsCampaignOptions.find(
            (typeDate) => typeDate.packId === optionIdx
          ) || {}
        );
      } else if (itemType === ItemTypes.PHYSICAL) {
        building = this.requestOfferItems.physical.buildings.find(
          (building) => building.id === buildingId
        );
        return (
          building?.itemsCampaignOptions.find(
            (typeDate) => typeDate.type === optionIdx
          ) || {}
        );
      } else {
        return {};
      }
    },
    async removeFromRequestDigital(building_id, unique_key) {
      const requestOfferConstants = this.$store.getters.constants.requestOffer;
      const requestOfferType = this.requestOfferItems.requestOfferType;
      if (requestOfferType === "single") {
        this.$store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.CLEAR_REQUEST
          )
        );
        this.$store.dispatch(
          this.baseConstants.withNamespace(
            this.baseConstants.action.TOGGLE_REQUEST_OFFER
          ),
          { open: false }
        );
      } else {
        await this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_DIGITAL_FROM_SHORTLIST
          ),
          {
            building_id: building_id,
            item_id: unique_key,
          }
        );
        await this.$store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.MULTIPLE_REQUEST_OFFER
          )
        );
        if (
          this.requestOfferItems.digital.buildings.length === 0 &&
          this.requestOfferItems.physical.buildings.length === 0
        ) {
          this.$store.dispatch(
            this.baseConstants.withNamespace(
              this.baseConstants.action.TOGGLE_REQUEST_OFFER
            ),
            { open: false }
          );
        }
      }
    },
    async removeFromRequestPhysicalByType(building_id, item_type) {
      const requestOfferConstants = this.$store.getters.constants.requestOffer;
      const requestOfferType = this.requestOfferItems.requestOfferType;
      if (requestOfferType === "single") {
        this.$store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.CLEAR_REQUEST
          )
        );
        this.$store.dispatch(
          this.baseConstants.withNamespace(
            this.baseConstants.action.TOGGLE_REQUEST_OFFER
          ),
          { open: false }
        );
      } else {
        await this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action
              .REMOVE_PHYSICAL_BY_TYPE_FROM_SHORTLIST
          ),
          {
            building_id: building_id,
            item_type: item_type,
          }
        );
        await this.$store.dispatch(
          requestOfferConstants.withNamespace(
            requestOfferConstants.action.MULTIPLE_REQUEST_OFFER
          )
        );
        if (
          this.requestOfferItems.digital.buildings.length === 0 &&
          this.requestOfferItems.physical.buildings.length === 0
        ) {
          this.$store.dispatch(
            this.baseConstants.withNamespace(
              this.baseConstants.action.TOGGLE_REQUEST_OFFER
            ),
            { open: false }
          );
        }
      }
    },
    countTotalOfPhysicalItems: (options) => {
      return options.reduce(
        (total, option) => (total += option.items.length),
        0
      );
    },
    capitalize: (str) =>
      str
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(" "),
  },
};
</script>

<style lang="scss">
.organism-request-offer-packages {
  .row-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.188rem;
    margin-left: 0.626rem;
  }
  .digital-option-margin {
    margin-bottom: 1rem;
  }
  .center-category {
    margin: 1rem 0 0.8rem;
    padding: 0.5rem;
    background: $black;
    @include font-main(1rem, $white, $bold, 150%);
  }
  .btn-trash-item {
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1rem !important;
      margin: 0 !important;
    }
    &:hover {
      background: rgba(239, 48, 117, 0.1);
      .icon {
        color: #ef3075 !important;
      }
    }
  }
  .row-package {
    margin-bottom: 1.25rem;
    .items-info {
      &:last-child {
        .items-info-wrapper {
          & > div:last-child {
            .horizontal-line {
              display: none;
            }
          }
        }
      }
    }
  }
  .row-project-name {
    margin-bottom: 1.5rem;
    p {
      @include font-main(1.25rem, $black, $regular, 1.5rem);
    }
  }
  .row-packages-tabs {
    display: flex;
    margin-bottom: 0.8rem;
    justify-content: space-between;
    &.center-element {
      justify-content: center;
    }
    .tabs-packages {
      display: flex;
      width: fit-content;
      padding: 0.25rem;
      border: 1px solid #d5e0e6;
      border-radius: 0.313rem;
      .tags-wrapper {
        .tag-name {
          display: inline-block;
          vertical-align: middle;
          letter-spacing: 0.01em;
          padding: 0.313rem 0.5rem;
          border-radius: 0.188rem;
          cursor: pointer;
          transition: all 0.2s ease-in-out 0s;
          @include font-main(1rem, $black, $medium, 1.188rem);
          &:hover,
          &.active {
            background: #020202;
            color: $white;
          }
        }
        .vertical-line {
          display: inline-block;
          vertical-align: middle;
          margin: 0 0.313rem;
          @include font-main(0.938rem, #d5e0e5, $regular, 1.5rem);
        }
      }
    }
  }
  .row-package-info {
    margin-bottom: 0.5rem;
    .header-info {
      position: relative;
      cursor: pointer;
      background: #f5f7f8;
      padding: 0.938rem 1.25rem;
      transition: all 0.2s ease-in-out 0s;
      .text {
        display: inline-block;
        vertical-align: middle;
        letter-spacing: 0.01em;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(0.875rem, $black, $regular, 1.063rem);
        b {
          transition: all 0.2s ease-in-out 0s;
          @include font-main(1rem, $black, $medium, 1.188rem);
        }
        &:first-child {
          margin-right: 1rem;
        }
        .icon {
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem !important;
          margin-top: -0.188rem;
          margin-right: 0.25rem;
          transition: all 0.2s ease-in-out 0s;
        }
      }
      .btn-trash-item {
        position: absolute;
        right: 3.3rem;
        top: 50%;
        transform: translateY(-50%);
        .icon {
          color: $white !important;
        }
        &:hover {
          .icon {
            color: #ef3075 !important;
          }
        }
      }
      .icon-ok {
        position: absolute;
        top: 50%;
        right: 1.25rem;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem !important;
        color: $black !important;
      }
      &:hover {
        background: #23cd9a;
        .text {
          color: $white;
          b {
            color: $white;
          }
          .icon {
            color: $white !important;
          }
        }
        .icon-ok {
          color: $white !important;
        }
      }
    }
    .body-info {
      margin: 1rem 0 1.438rem;
    }
    &.active {
      .header-info {
        background: #23cd9a;
        .text {
          color: $white;
          b {
            color: $white;
          }
          .icon {
            color: $white !important;
          }
        }
        .icon-ok {
          color: $white !important;
        }
      }
    }
  }
  .horizontal-line {
    display: block;
    height: 0.063rem;
    background: rgba(0, 0, 0, 0.2);
    margin: 2rem -1.5rem 1.5rem;
  }
  .row-date-select {
    p {
      letter-spacing: 0.01em;
      margin-bottom: 0.5rem;
      @include font-main(0.875rem, $black, $regular, 1.063rem);
      b {
        @include font-main(1rem, $black, $medium, 1.188rem);
      }
      span {
        @include font-main(0.75rem, #888888, $regular, 1rem);
      }
    }
    .row-date-inputs {
      margin: 0 -0.625rem 0.875rem;
      .col {
        padding: 0 0.625rem;
      }
      .input-atom-wrapper {
        &.date-style {
          label {
            letter-spacing: 0.1em;
            text-transform: uppercase;
            display: block;
            padding: 0.5rem 0.5rem 0 1rem;
            background: rgba(244, 246, 247, 0.8);
            border-radius: 0.25rem 0.25rem 0 0;
            margin-bottom: 0;
            @include font-main(0.688rem, rgba(0, 0, 0, 0.2), $bold, 0.813rem);
          }
          input {
            height: auto;
            padding: 0.25rem 0.5rem 0.5rem 1rem;
            background: rgba(244, 246, 247, 0.8);
            border-radius: 0 0 0.25rem 0.25rem;
            @include font-main(1rem, $black, $regular, 1.188rem);
            border: none;
          }
        }
      }
    }
  }
}
</style>
