var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-request-offer-packages" },
    [
      _c("MoleculeDynamicModal"),
      _c(
        "div",
        { staticClass: "row-package" },
        _vm._l(Object.entries(_vm.shortlistGroupedItems), function(ref) {
          var parentKey = ref[0]
          var typeOfItems = ref[1]
          return _c(
            "div",
            { key: "type-" + parentKey, staticClass: "items-info" },
            [
              typeOfItems.buildings.length > 0
                ? _c("h2", { staticClass: "center-category" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          parentKey.charAt(0).toUpperCase() + parentKey.slice(1)
                        ) +
                        " items\n      "
                    )
                  ])
                : _vm._e(),
              _vm._l(typeOfItems.buildings, function(building, idx) {
                return _c(
                  "div",
                  {
                    key: "building_" + idx,
                    ref: "building_" + idx,
                    refInFor: true
                  },
                  [
                    _c("div", { staticClass: "row-project-name" }, [
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.buildings.find(function(build) {
                                return build.id === building.id
                              }).name
                            ) +
                            "\n          "
                        )
                      ])
                    ]),
                    _vm.shortlistGroupedItems.digital.buildings.length > 0 &&
                    parentKey === _vm.ItemTypes.DIGITAL
                      ? _c(
                          "div",
                          { staticClass: "items-info-wrapper" },
                          _vm._l(building.options, function(opt, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "digital-option-margin"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "row-packages-tabs" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tabs-packages" },
                                      _vm._l(_vm.tags, function(tag, tagIdx) {
                                        return _c(
                                          "div",
                                          {
                                            key: "tag-" + tagIdx,
                                            staticClass: "tags-wrapper",
                                            on: {
                                              click: function($event) {
                                                _vm.handleChangePackageType(
                                                  tag.key.toLowerCase(),
                                                  opt,
                                                  building,
                                                  parentKey
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "tag-name",
                                                class: {
                                                  active:
                                                    tag.key.toLowerCase() ===
                                                    opt.type
                                                }
                                              },
                                              [_vm._v(_vm._s(tag.key))]
                                            ),
                                            tagIdx < _vm.tags.length - 1
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass: "vertical-line"
                                                  },
                                                  [_vm._v("|")]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn-trash-item btn-trash-item-digital",
                                        on: {
                                          click: function($event) {
                                            return _vm.removeFromRequestDigital(
                                              building.id,
                                              opt.unique_key
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("AtomIcon", {
                                          attrs: { icon: "trash-can" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c("MoleculeCollapsiblePackages", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(opt.duration) +
                                                      " Seconds"
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [
                                                _c("AtomIcon", {
                                                  attrs: { icon: "timer-v2" }
                                                }),
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      opt.hourly_broadcasts
                                                    ) + " "
                                                  )
                                                ]),
                                                _vm._v(" broadcasts/hour")
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "body",
                                        fn: function() {
                                          return [
                                            _c("MoleculeTablePackage", {
                                              attrs: {
                                                "digital-items": opt.items,
                                                "pack-id": opt.pack_id
                                              }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c(
                                  "div",
                                  { staticClass: "row-date-select" },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "Enter the period you want your campaign to run:"
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "row row-date-inputs" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("AtomInput", {
                                              attrs: {
                                                type: _vm.inputTypes.DATE,
                                                label: "Start Date",
                                                value:
                                                  _vm.physicalItemTypeDate(
                                                    building.id,
                                                    opt.pack_id,
                                                    parentKey
                                                  ).startDate || null
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.handleChangeStartDate(
                                                    building.id,
                                                    opt.pack_id,
                                                    $event,
                                                    parentKey
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("AtomInput", {
                                              attrs: {
                                                type: _vm.inputTypes.DATE,
                                                label: "End Date",
                                                value:
                                                  _vm.physicalItemTypeDate(
                                                    building.id,
                                                    opt.pack_id,
                                                    parentKey
                                                  ).endDate || null
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.handleChangeEndDate(
                                                    building.id,
                                                    opt.pack_id,
                                                    $event,
                                                    parentKey
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("AtomCheck", {
                                      ref: "check",
                                      refInFor: true,
                                      staticClass: "row-checkbox",
                                      attrs: {
                                        id: "available-soon",
                                        label: "As soon as it’s available",
                                        "is-checked":
                                          _vm.physicalItemTypeDate(
                                            building.id,
                                            opt.pack_id,
                                            parentKey
                                          ).checkbox || false
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.handleCheckbox(
                                            building.id,
                                            opt.pack_id,
                                            $event,
                                            parentKey
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "horizontal-line" })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _vm.shortlistGroupedItems.physical.buildings.length > 0 &&
                    parentKey === _vm.ItemTypes.PHYSICAL
                      ? _c(
                          "div",
                          { staticClass: "items-info-wrapper" },
                          _vm._l(building.options, function(option, optionIdx) {
                            return _c(
                              "div",
                              { key: optionIdx },
                              [
                                _c("MoleculeCollapsiblePackages", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.capitalize(optionIdx)
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _c("br"),
                                            _c(
                                              "span",
                                              { staticClass: "text" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.countTotalOfPhysicalItems(
                                                        option
                                                      )
                                                    ) +
                                                    " items\n                "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-trash-item btn-trash-item-physical",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.removeFromRequestPhysicalByType(
                                                      building.id,
                                                      optionIdx
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("AtomIcon", {
                                                  attrs: { icon: "trash-can" }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "body",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "table-header" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row-table-header"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "columns"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Area")
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "columns"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "No. of physical supports"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._l(option, function(areaItem) {
                                              return _c("MoleculeTableArea", {
                                                key: areaItem.unique_key,
                                                attrs: {
                                                  area: areaItem.area,
                                                  items: areaItem.items,
                                                  pkey: areaItem.unique_key,
                                                  "building-id": building.id
                                                }
                                              })
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                }),
                                _c(
                                  "div",
                                  { staticClass: "row-date-select" },
                                  [
                                    _vm._m(0, true),
                                    _c(
                                      "div",
                                      { staticClass: "row row-date-inputs" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("AtomInput", {
                                              attrs: {
                                                type: _vm.inputTypes.DATE,
                                                value:
                                                  _vm.physicalItemTypeDate(
                                                    building.id,
                                                    optionIdx,
                                                    parentKey
                                                  ).startDate || null,
                                                label: "Start Date"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.handleChangeStartDate(
                                                    building.id,
                                                    optionIdx,
                                                    $event,
                                                    parentKey
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col col-6" },
                                          [
                                            _c("AtomInput", {
                                              attrs: {
                                                type: _vm.inputTypes.DATE,
                                                value:
                                                  _vm.physicalItemTypeDate(
                                                    building.id,
                                                    optionIdx,
                                                    parentKey
                                                  ).endDate || null,
                                                label: "End Date"
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.handleChangeEndDate(
                                                    building.id,
                                                    optionIdx,
                                                    $event,
                                                    parentKey
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c("AtomCheck", {
                                      staticClass: "row-checkbox",
                                      attrs: {
                                        id: "checkbox_" + optionIdx,
                                        label: "As soon as it’s available",
                                        "is-checked":
                                          _vm.physicalItemTypeDate(
                                            building.id,
                                            optionIdx,
                                            parentKey
                                          ).checkbox || false
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.handleCheckbox(
                                            building.id,
                                            optionIdx,
                                            $event,
                                            parentKey
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("span", { staticClass: "horizontal-line" })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Campaign Period: "),
      _c("span", [_vm._v("(not required)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }