<template>
  <div class="organsim-main-boxes">
    <div class="row">
      <div class="col col-xs-12 col-auto" v-for="(box, index) in boxesData" :key="index">
        <AtomNumberAndText :title="box.title" :subtitle="box.subtitle" />
      </div>
    </div>
  </div>
</template>

<script>
import AtomNumberAndText from "@/components/atoms/common/AtomNumberAndText";

export default {
  name: "OrganismMainBoxes",
  components: {AtomNumberAndText},
  props: {
    boxesData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .organsim-main-boxes {

  }
</style>