<template>
  <div class="organism-menu-right" :class="{'scrolled': isScrolled}">
      <div class="phone-header-parent">
        <a class="phone-header" :href="`tel:${phoneHeader}`"><AtomIcon icon="phone-solid" /></a>
        <span class="hover-phone">Call us: <b>{{ phoneHeader }}</b></span>
      </div>
<!--      <MoleculeStartCampaignButton />-->
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon";

export default {
  name: "OrganismMenuRight",
  components: {AtomIcon},
  props: {
    phoneHeader: {
      required: false,
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isScrolled: false,
    }
  },
  mounted() {
    this.isScrolled = window.scrollY > 40;
    document.addEventListener('scroll', this.initScrollListener)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.initScrollListener)
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    }
  }
}
</script>

<style lang="scss">
  .organism-menu-right {
    position: fixed;
    top: 2.05rem;
    right: 2rem;
    z-index: 9999;
    display: flex;
    &.scrolled {
      .btn-general {
        background: transparent;
        color: $black;
        border: 1px solid $main-color;
        &:hover, &.active {
          background: $main-color;
          color: $white;
        }
      }
      .phone-header-parent {
        .phone-header {
          border-color: $main-color-opacity-01;
          .icon {
            color: $black !important;
          }
        }
        &:hover {
          .phone-header {
            .icon {
              color: $white !important;
            }
          }
        }
      }
    }
    .phone-header-parent {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      //margin-right: 1.5rem;
      .phone-header {
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 6.25rem;
        .icon {
          font-size: 1rem !important;
          color: $white !important;
        }
      }
      .hover-phone {
        letter-spacing: 0.01em;
        padding: 0.938rem 1.25rem;
        border-radius: 0.25rem;
        margin-top: 1.25rem;
        display: none;
        background: $white;
        position: absolute;
        white-space: nowrap;
        right: 0;
        top: 100%;
        @include font-main(1.25rem, $black, $regular, 1.5rem);
        b {
          @include font-main(1.25rem, $black, $bold, 1.5rem);
        }
        &:after {
          bottom: 100%;
          right: 0.875rem;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: $white;
          border-width: 0.625rem;
        }
      }
      &:hover {
        .phone-header {
          background: $main-color;
          border-color: $main-color;
          .icon {
            color: $white !important;
          }
        }
        .hover-phone {
          display: block;
          @media only screen and (max-width: 1100px) {
            display: none;
          }
        }
      }
    }
  }
</style>