var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-media-gallery" },
    [
      _c(
        "MoleculeMediaGallery",
        _vm._l(_vm.sliderGalleryData, function(sliderData, index) {
          return _c("div", { key: index, staticClass: "slide" }, [
            _c("img", {
              attrs: {
                src: _vm.resourceUrl(
                  "images/projects/iulius/advertising/gallery/placeholder.jpg"
                ),
                alt: "Placeholder"
              }
            }),
            sliderData.pinLabel
              ? _c("span", {
                  staticClass: "pulsating-circle",
                  style: {
                    top: sliderData.top,
                    right: sliderData.right,
                    bottom: sliderData.bottom,
                    left: sliderData.left
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleInfoTrigger(sliderData.infoPopup)
                    }
                  }
                })
              : _vm._e(),
            sliderData.pinLabel
              ? _c(
                  "span",
                  {
                    staticClass: "tooltip-element",
                    on: {
                      click: function($event) {
                        return _vm.handleInfoTrigger(sliderData.infoPopup)
                      }
                    }
                  },
                  [
                    _c("AtomIcon", { attrs: { icon: "info" } }),
                    _vm._v(
                      "\n        " + _vm._s(sliderData.pinLabel) + "\n      "
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("div", {
              staticClass: "background-image",
              style: {
                "background-image":
                  "url(" + _vm.resourceUrl(sliderData.image) + ")"
              }
            })
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }