var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.projects.length > 1
    ? _c(
        "div",
        [
          _c("div", { staticClass: "separator" }),
          _c("div", { staticClass: "subtitle" }, [_vm._v("Projects")]),
          _vm._l(_vm.projects, function(project, i) {
            return _c(
              "a",
              {
                key: i,
                staticClass: "sidebar-link",
                class: { disabled: _vm.isProjectDisabled(project) },
                attrs: { href: "/project/" + project.slug }
              },
              [_vm._v("\n    " + _vm._s(project.name) + "\n  ")]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }