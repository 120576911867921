var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row-package-info active" }, [
    _c(
      "div",
      { staticClass: "header-info" },
      [_vm._t("header"), _c("AtomIcon", { attrs: { icon: "ok" } })],
      2
    ),
    _c("div", { staticClass: "body-info" }, [_vm._t("body")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }