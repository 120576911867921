var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "section section-media-devices",
      class: ((_obj = {}), (_obj["section" + _vm.orderNo] = true), _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("AtomTitleLine", { attrs: { title: _vm.data.title } }),
      _c("OrganismImageAndTable", {
        attrs: { "content-data": _vm.data.rowImageText }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }