<template>
  <div class="results-info">
    <div v-show="activeFiltersLength > 0">
      <span
        v-html="
          numberOfResults === 0
            ? `No results found`
            : numberOfResults === 1
            ? `${numberOfResults} result found`
            : `${numberOfResults} results found`
        "
      ></span>
    </div>
    Scroll Down
  </div>
</template>

<script>
export default {
  name: "AtomFilterResult",
  props: {
    activeTab: {
      type: String,
      required: true,
      default: "digital",
    },
  },
  data() {
    return {
      filteredItems: [],
    };
  },
  computed: {
    activeProjectSlug() {
      return this.$store.state.project.activeProject;
    },
    activeProject() {
      return (
        this.$store.state.base.landlordDetails?.projects.find(
          (p) => p.slug === this.activeProjectSlug
        ) || null
      );
    },
    filters() {
      return this.$store.state.project?.filters || null;
    },
    activeFiltersLength() {
      let count = 0;
      if (!this.filters || !this.filters[this.activeTab]) return count;
      for (const type in this.filters[this.activeTab]) {
        const val = this.filters[this.activeTab][type];
        if (
          ![
            "advertisingTiming", // string
            "typeOfPackage", // string
            "showAllGroupOptions", // boolean
            "areas", // array
            "devices", // array
          ].includes(type)
        ) {
          continue;
        }
        if (type === "showAllGroupOptions") {
          if (val !== false) {
            count++;
          }
        } else if (Array.isArray(val)) {
          if (val.length !== 0) {
            count++;
          }
        } else {
          if (val !== "all") {
            count++;
          }
        }
      }
      return count;
    },
    numberOfResults() {
      return (
        this.$store.state.project?.filtersResults?.[this.activeTab]
          ?.totalResults || 0
      );
    },
  },
};
</script>

<style lang="scss">
.results-info {
  background: black;
  margin: 0 -1.25rem;
  color: $white;
  padding: 0.625rem 1.25rem;
  font-size: 1em;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    margin: 0 -1rem;
    padding: 0.625rem 1rem;
    div {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
  .number {
    font-size: 1.1em;
    font-weight: 500;
    @media only screen and (max-width: 767px) {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}
</style>
