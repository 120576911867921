var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "molecule-contact-form" },
    [
      _c("MoleculeDynamicModal"),
      _c(
        "form",
        {
          ref: "contactForm",
          attrs: { action: "javascript:void(0);" },
          on: { submit: _vm.submitForm }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col col-xs-12 col-6" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.companyNumber,
                        placeholder: "Company Registration Number"
                      },
                      on: {
                        input: function($event) {
                          _vm.companyNumber = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.firstName,
                        placeholder: "First Name*",
                        error: _vm.errors.firstName
                      },
                      on: {
                        input: function($event) {
                          _vm.firstName = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.companyName,
                        placeholder: "Company Name*",
                        error: _vm.errors.companyName
                      },
                      on: {
                        input: function($event) {
                          _vm.companyName = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.lastName,
                        placeholder: "Last Name*",
                        error: _vm.errors.lastName
                      },
                      on: {
                        input: function($event) {
                          _vm.lastName = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-12" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.companyAddress,
                        placeholder: "Company Address"
                      },
                      on: {
                        input: function($event) {
                          _vm.companyAddress = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _c("div", { staticClass: "col col-xs-12 col-6" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.phone,
                        placeholder: "Phone Number*",
                        error: _vm.errors.phone
                      },
                      on: {
                        input: function($event) {
                          _vm.phone = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col col-xs-12 col-6" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXT,
                        value: _vm.email,
                        placeholder: "Email*",
                        error: _vm.errors.email
                      },
                      on: {
                        input: function($event) {
                          _vm.email = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col col-12" },
                  [
                    _c("AtomInput", {
                      attrs: {
                        type: _vm.inputTypes.TEXTAREA,
                        value: _vm.message,
                        placeholder: "Message"
                      },
                      on: {
                        input: function($event) {
                          _vm.message = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "row bottom-form align-items-center" }, [
            _c(
              "div",
              { staticClass: "col col-xs-12 col-6" },
              [
                _c("AtomCheck", {
                  ref: "check",
                  staticClass: "row-checkbox",
                  attrs: {
                    id: "checkbox-contact-footer",
                    label:
                      "I agree to the Terms & Conditions and Privacy Policy (*)",
                    error: _vm.errors.agree
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "col col-xs-12 col-6" }, [
              _c(
                "div",
                { staticClass: "button-contact-form text-right" },
                [_c("AtomButton", { attrs: { label: "Send" } })],
                1
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }