<template>
  <div class="molecule-contact-form">
    <MoleculeDynamicModal />
    <form ref="contactForm" @submit="submitForm" action="javascript:void(0);">
      <div class="row">
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyNumber"
                @input="companyNumber = $event"
                placeholder="Company Registration Number"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="firstName"
                @input="firstName = $event"
                placeholder="First Name*"
                :error="errors.firstName"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyName"
                @input="companyName = $event"
                placeholder="Company Name*"
                :error="errors.companyName"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="lastName"
                @input="lastName = $event"
                placeholder="Last Name*"
                :error="errors.lastName"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="companyAddress"
                @input="companyAddress = $event"
                placeholder="Company Address"
              />
            </div>
          </div>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="row">
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="phone"
                @input="phone = $event"
                placeholder="Phone Number*"
                :error="errors.phone"
              />
            </div>
            <div class="col col-xs-12 col-6">
              <AtomInput
                :type="inputTypes.TEXT"
                :value="email"
                @input="email = $event"
                placeholder="Email*"
                :error="errors.email"
              />
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <AtomInput
                :type="inputTypes.TEXTAREA"
                :value="message"
                @input="message = $event"
                placeholder="Message"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row bottom-form align-items-center">
        <div class="col col-xs-12 col-6">
          <AtomCheck
            id="checkbox-contact-footer"
            ref="check"
            class="row-checkbox"
            label="I agree to the Terms & Conditions and Privacy Policy (*)"
            :error="errors.agree"
          />
        </div>
        <div class="col col-xs-12 col-6">
          <div class="button-contact-form text-right">
            <AtomButton label="Send" />
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import AtomInput, { EnumInputTypes } from "../../atoms/common/AtomInput";
import AtomButton from "../../atoms/common/AtomButton";
import AtomCheck from "../../atoms/common/AtomCheck";
import config from "../../../config";
import MoleculeDynamicModal from "@/components/molecules/common/MoleculeDynamicModal.vue";

export default {
  name: "MoleculeContactForm",
  components: {
    MoleculeDynamicModal,
    AtomCheck,
    AtomButton,
    AtomInput,
  },
  data() {
    return {
      errors: {},
      companyNumber: "",
      companyName: "",
      firstName: "",
      lastName: "",
      companyAddress: "",
      phone: "",
      email: "",
      message: "",
      inputTypes: EnumInputTypes,
    };
  },
  computed: {
    client() {
      return config.CLIENT;
    },
  },
  methods: {
    async submitForm(e) {
      e.preventDefault();
      if (this.validateInputs() !== null) return;
      if (window && "dataLayer" in window) {
        window.dataLayer.push({
          event: "contact_request_submit",
          category: "contact_request",
          label: "contact_request",
        });
      }

      const payload = {
        companyRegNo: this.companyNumber === "" ? null : this.companyNumber,
        companyName: this.companyName,
        companyAddress: this.companyAddress === "" ? null : this.companyAddress,
        fullName: `${this.firstName} ${this.lastName}`,
        phoneNumber: this.phone,
        email: this.email,
        message: this.message,
      };
      const {
        error: serverError,
        result: serverResult,
      } = await this.$request.POST(this.$urls.landlord.contact(), payload);
      if (serverError) {
        alert("Something wrong happened! Please try again!");
        return;
      }
      this.$refs.contactForm.reset();
      this.$refs.check.checked = false;

      // show pop-up after request is submitted
      const modal = this.$store.getters.constants.modal;
      this.$store.dispatch(
        modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
        {
          showModal: true,
          modalType: "dynamic",
          modalData: {
            title: "Thank you!",
            text:
              "Your message has been sent and we will get back to you shortly.",
          },
        }
      );
    },
    validateInputs() {
      this.errors = {};
      if (this.companyName === "") {
        this.errors.companyName = "Company name is required.";
      }
      if (this.firstName === "") {
        this.errors.firstName = "First Name is required.";
      }
      if (this.lastName === "") {
        this.errors.lastName = "Last Name is required.";
      }
      if (this.phone === "" || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = "Phone is required.";
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = "Email is not valid.";
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = "Please agree to the Terms and Conditions first.";
      }
      if (Object.keys(this.errors).length === 0) {
        return null;
      }
      return this.errors;
    },
    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
  },
};
</script>

<style lang="scss">
.molecule-contact-form {
  margin-top: 1.875rem;
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
  }
  .row {
    margin: 0 -0.75rem;
    @media only screen and (max-width: 767px) {
      margin: 0 -0.625rem;
    }
    .col {
      padding: 0 0.75rem;
      @media only screen and (max-width: 767px) {
        padding: 0 0.625rem;
      }
    }
  }
  .bottom-form {
    margin-top: 1.25rem;
    @media only screen and (max-width: 767px) {
      margin-top: 0.625rem;
    }
  }
  .input-atom-wrapper {
    margin-bottom: 0.938rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0.625rem;
    }
    input,
    textarea {
      margin-bottom: 0.313rem;
    }
  }
  .button-atom {
    width: 7.5rem;
    max-width: 100%;
    @media only screen and (max-width: 767px) {
      width: auto;
    }
  }
}
</style>
