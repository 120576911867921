<template>
  <div class="organism-shortlist-drawer">
    <OrganismDrawer
      ref="drawer"
      :default-open="defaultOpen"
      @drawer:close="$emit('drawer:close')"
    >
      <div class="header">
        <div class="title">
          <h3>Your Shortlist</h3>
          <span v-if="shortlistItemsLength > 0" class="shortlist-length">
            {{ shortlistItemsLength }}
          </span>
        </div>
        <AtomButton
          class="rounded-button"
          v-show="shortlistItemsLength > 0"
          label="Clear All"
          :on-click="clearAll"
        />
      </div>
      <div class="body" v-if="shortlistItemsLength > 0">
        <MoleculeSidebarShortlistItems />
      </div>
      <div v-else>
        <MoleculeShortlistPlaceholder
          :trigger-open-availability="triggerOpenAvailability"
        />
      </div>
    </OrganismDrawer>
  </div>
</template>

<script>
import OrganismDrawer from "../OrganismDrawer";
import MoleculeShortlistPlaceholder from "../../../molecules/shortlist/MoleculeShortlistPlaceholder";
import MoleculeSidebarShortlistItems from "../../../molecules/shortlist/MoleculeSidebarShortlistItems";
import AtomButton from "@/components/atoms/common/AtomButton.vue";
import constants from "../../../../store/modules/shortlist/constants";

export default {
  name: "OrganismShortlistDrawer",
  components: {
    AtomButton,
    OrganismDrawer,
    MoleculeShortlistPlaceholder,
    MoleculeSidebarShortlistItems,
  },
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    triggerOpenAvailability: {
      required: false,
      type: Function,
      default: () => {},
    },
  },
  computed: {
    shortlistItemsLength() {
      return this.$store.state.shortlist.total || 0;
    },
  },
  methods: {
    toggleDrawer() {
      this.$refs["drawer"]?.toggleFilter();
    },
    closeDrawer() {
      this.$refs["drawer"]?.close();
    },
    openDrawer() {
      this.$refs["drawer"]?.open();
    },
    clearAll() {
      const shortlistConstants = this.$store.getters.constants.shortlist;
      this.$store.dispatch(
        shortlistConstants.withNamespace(
          shortlistConstants.action.CLEAR_SHORTLIST
        )
      );
    },
  },
};
</script>

<style lang="scss">
.organism-shortlist-drawer {
  z-index: 99999;

  .body {
    height: 100%;
  }

  .rounded-button {
    margin-left: 1.25rem;
    padding: 0.125rem 0.75rem;
    gap: 0.625rem;
    border: none;
    border-radius: 18.75rem;
    background: $darkerWhite;
    transition: all 0.5s ease-in-out;
    span {
      text-transform: none;
      font-weight: $regular;
      color: $black;
    }
    &:hover {
      background: $black;
      span {
        color: $white;
      }
    }
  }

  .drawer-content {
    overflow-y: auto;
  }

  &::v-deep {
    .drawer-content {
      .drawer-content-wrapper {
        overflow-y: auto;

        .header {
          display: flex;
          align-items: center;
          .title {
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .main-content {
      flex: 1;
      overflow-y: auto;
      .atom-collapsible-item {
        &.parent-box {
          background: $darkerWhite;
          margin-bottom: 0.75rem;
          .item-header {
            padding: 0 0.75rem 0 0.75rem;
            .header-wrapper {
              display: flex;
              flex-direction: column;
            }
            .title-box {
              display: flex;
              align-items: center;
              font-weight: $regular;
              font-size: 1.375rem;
              line-height: 1.625rem;
              .icon {
                background: rgba(100, 100, 100, 0.1);
                border-radius: 50%;
                padding: 0.25rem;
                font-size: 1rem !important;
                margin-right: 0.625rem;
                &:hover {
                  color: #ef3075 !important;
                  background: rgba(239, 48, 117, 0.1);
                }
              }
              .shortlist-length {
                font-weight: $bold;
                border-radius: 1.875rem;
                color: $white;
                background: #23cd9a;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 0.875rem;
                margin-left: 0.625rem;
                width: 1.25rem;
                height: 1.25rem;
              }
            }
            .info {
              margin: 0.625rem 0.625rem 1rem;
              font-weight: 500;
              display: flex;
              justify-content: space-between;
              .item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;

                .icon {
                  font-size: 1rem !important;
                }

                .item-text {
                  white-space: nowrap;
                  .superior-text {
                    font-size: 0.625rem;
                  }
                }
              }
              span {
                font-size: 1rem;
              }
            }
          }
          .item-content {
            padding: 0 0.75rem 0.5rem 0.75rem;
          }
          &.active {
            background: $lightGrey;
          }
        }
        &.child-box {
          background: $white;
          .item-header {
            padding: 0;
            .title-box {
              .title {
                background: $black;
                color: $white;
                padding: 0.313rem 0.5rem;
                gap: 0.25rem;
                border-radius: 0.125rem;
                margin-right: 0.625rem;
              }
            }
            .icon-button {
              margin-right: 0.5rem;
              .expand-text {
                display: none;
              }
            }
          }
          .item-content {
            padding: 0.5rem 0;
          }
        }
        &.subchild-box {
          background: $white;
          margin-bottom: 0.25rem;
          .item-header {
            &:hover {
              .title-box {
                .title {
                  background: $black;
                  color: $white;
                  transition: all 0.2s ease-in-out 0s;
                }
              }
            }
            .title-box {
              .title {
                background: $white;
                color: $black;
                padding: 0.313rem 0.5rem;
                gap: 0.25rem;
                border-radius: 0.125rem;
                margin-right: 0.625rem;
              }
              .price {
                margin-left: 0.5rem;

                .price-value {
                  color: #020202;
                  font-weight: 500;
                  font-size: 1.25rem;
                  line-height: 1.5rem;
                  letter-spacing: 0.01em;
                  margin-left: 0.5rem;
                }
              }
              .surface {
                margin-right: 0.625rem;
                .icon {
                  font-size: 0.875rem !important;
                }
              }
              .cardinal-point {
                margin-right: 0.125rem;
                .icon {
                  font-size: 0.875rem !important;
                }
              }
            }
          }
          .item-content {
            .content-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex-wrap: wrap;
              .space-info {
                display: flex;
                justify-content: space-between;
                margin: 0 0.625rem;
                span {
                  display: flex;
                  flex-direction: column;
                  background: #f5f7f8;
                  padding: 0.75rem;
                  align-items: center;
                  min-width: 5.188rem;
                  min-height: 4.063rem;
                  div {
                    font-weight: 500;
                  }
                  .icon {
                    font-size: 1rem !important;
                  }
                }
              }

              .space-box {
                display: flex;
                align-items: center;
                margin: 0.5rem 0.625rem;
                .space-image-box {
                  img {
                    width: 5.938rem;
                  }
                }
                .space-content-box {
                  display: flex;
                  flex-direction: column;
                  flex-wrap: wrap;
                  align-items: flex-start;
                  margin-left: 0.625rem;
                  .space-description {
                    text-align: justify;
                  }
                  .space-price {
                    margin-top: 0.625rem;
                    text-align: justify;
                  }
                }
              }
              .content-buttons {
                display: flex;
                justify-content: space-between;
                margin: 0.5rem 0.625rem;
                .button-atom {
                  padding: 0.25rem 0.45rem;
                  height: 2.5rem;
                  text-transform: none;
                  flex: 1;
                  margin: 0.125rem;
                  span {
                    text-transform: none;
                  }
                }
                .explore {
                  border: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 0.125rem;
                  .icon {
                    display: none;
                    color: $white !important;
                    margin-right: 0.5rem;
                  }

                  &:hover {
                    background: #23cd9a;

                    .icon {
                      display: inline-block;
                    }

                    span {
                      color: $white;
                    }
                  }
                }

                .shortlist-btn {
                  background: $white;
                  border: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 0.125rem;
                  .icon {
                    color: $black !important;
                    margin-right: 0.5rem;
                  }

                  span {
                    color: $black;
                  }

                  &:hover {
                    background: #ef3075;

                    .icon {
                      color: $white !important;
                    }

                    span {
                      color: $white;
                    }
                  }
                }
              }
            }
            .surface-details {
              padding: 0.875rem;
              .title {
                font-weight: 500;
                font-size: 1rem;
              }

              .unit-wrapper {
                border-bottom: 1px solid #e9edef;
                padding: 0.125rem 0;
                margin: 0.313rem 0;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                * {
                  font-size: 1rem;
                }

                .unit-surface {
                  font-weight: 500;
                }
              }
            }
          }
          &.active {
            .item-header {
              .title-box {
                .title {
                  background: $black;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1rem;
      width: 100%;
      .request-offer {
        width: 100%;
        border: none;
        &:hover {
          background: #23cd9a;
        }
        span {
          text-transform: capitalize;
        }
      }
    }
    .placeholder-container {
      padding: 1rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .portfolio-button {
        width: 100%;
        border: none;
        &:hover {
          background: #23cd9a;
        }
        span {
          text-transform: capitalize;
        }
      }
      .placeholder-text {
        padding-top: 0.625rem;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 1.063rem;
      }
    }
  }

  .header {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
      padding: 0 1rem;
      margin-bottom: 1rem;
    }
    .title {
      display: flex;
      h3 {
        @media only screen and (max-width: 767px) {
          @include font-main(1.25rem, $black, $bold, 1.5rem);
        }
      }
      .shortlist-length {
        font-weight: $bold;
        border-radius: 1.875rem;
        color: $white;
        background: #23cd9a;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        margin-left: 0.625rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    .clear-button {
      all: unset;
      cursor: pointer;
      background: #f5f7f8;
      border-radius: 18.75rem;
      padding: 0.125rem 0.75rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      &::v-deep {
        span {
          color: $black !important;
          text-transform: capitalize;
          font-style: normal;
          font-weight: 400;
        }
        .icon {
          display: none;
          color: $white !important;
          background: $black;
          border-radius: 50%;
          margin-right: 0.25rem;
          width: 1.25rem;
          height: 1.25rem;
          padding: 0.125rem 0.063rem;
        }
        &:hover {
          background: transparent;
          .icon {
            display: inline-block;
          }
          span {
            color: $black;
          }
        }
      }
    }
  }
}
</style>
