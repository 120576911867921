var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.section || !_vm.enabled
    ? _c("div")
    : _vm.section === "Hero"
    ? _c("SectionHero", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          extra: _vm.extra
        }
      })
    : _vm.section === "Main"
    ? _c("SectionMain", {
        attrs: { hash: _vm.hash, data: _vm.data, "order-no": _vm.orderNo }
      })
    : _vm.section === "Projects"
    ? _c("SectionProjects", {
        attrs: { hash: _vm.hash, data: _vm.data, "order-no": _vm.orderNo }
      })
    : _vm.section === "Leasing"
    ? _c("SectionLeasing", {
        attrs: { hash: _vm.hash, data: _vm.data, "order-no": _vm.orderNo }
      })
    : _vm.section === "PortfolioMap"
    ? _c("SectionPortfolioMap", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Vision"
    ? _c("SectionVision", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Why"
    ? _c("SectionWhy", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Creators"
    ? _c("SectionCreators", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Team"
    ? _c("SectionTeam", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Partners"
    ? _c("SectionPartners", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          title: _vm.title,
          subtitle: _vm.subtitle
        }
      })
    : _vm.section === "Contact"
    ? _c("SectionContact", {
        attrs: {
          hash: _vm.hash,
          data: _vm.data,
          "order-no": _vm.orderNo,
          variant: _vm.variant
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }