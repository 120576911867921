<template>
  <div class="organism-packages">
    <div
      class="package-box"
      v-for="(packageInfo, index) in packages"
      :key="index"
    >
      <div class="header-package">
        <div class="left-text">
          <p v-html="packageInfo.name"></p>
        </div>
        <div class="right-text">
          <AtomIcon icon="timer-v2" />
          <p v-html="packageInfo.info"></p>
        </div>
      </div>
      <div class="body-package">
        <OrganismTableGeneral :table-data="packageInfo.tableData" />
      </div>
      <div class="footer-package">
        <div class="left-text">
          <p v-html="packageInfo.priceText"></p>
        </div>
        <div class="right-text">
          <p v-html="packageInfo.price"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
import OrganismTableGeneral from "./table/OrganismTableGeneral";
export default {
  name: "OrganismPackages",
  components: { OrganismTableGeneral, AtomIcon },
  props: {
    packages: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
};
</script>

<style lang="scss">
.organism-packages {
  display: flex;
  justify-content: center;
  gap: 1.063rem;
  padding: 0 3.75rem 7.813rem;
  overflow-x: auto;
  @media only screen and (max-width: 1200px) {
    justify-content: initial;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 2rem 7rem;
  }
  .package-box {
    background: $white;
    box-shadow: 0 0.5rem 0.875rem 0.375rem #0000000a;
    border-radius: 0.5rem;
    padding: 1.375rem 1rem;
    position: relative;
    .header-package {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 2rem;
      background: #1a617f;
      border-radius: 0.25rem;
      margin-bottom: 1.5rem;
      .left-text {
        p {
          letter-spacing: 0.01rem;
          @include font-main(1rem, $white, $medium, normal);
        }
      }
      .right-text {
        display: flex;
        .icon {
          font-size: 0.875rem !important;
          color: $white !important;
          margin-right: 0.25rem;
          margin-top: 0.1rem;
        }
        p {
          @include font-main(0.875rem, $white, $regular, normal);
          b {
            @include font-main(1rem, $white, $medium, normal);
          }
        }
      }
    }
    .body-package {
      .organism-table-general {
        border-spacing: 0;
        width: 22rem;
        max-width: 100%;
        @media only screen and (max-width: 1000px) {
          width: 25rem;
        }
        @media only screen and (max-width: 767px) {
          width: 21rem;
        }
        tr {
          th,
          td {
            padding: 0 0.625rem;
            background: transparent !important;
            &:first-child {
              text-align: left;
              padding-left: 0;
            }
            &:last-child {
              text-align: right;
              padding-right: 0;
            }
          }
          th {
            padding-bottom: 0.5rem;
            @include font-main(0.875rem, $black, $regular, normal);
          }
          td {
            padding-top: 0.688rem;
            padding-bottom: 0.688rem;
            border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1);
            @include font-main(1rem, $black, $regular, normal);
            b {
              @include font-main(1rem, $black, $medium, normal);
            }
            span {
              display: block;
              @include font-main(0.75rem, $black, $regular, normal);
            }
          }
        }
        tbody {
          tr {
            &:last-child {
              td {
                border-color: #1a617f;
              }
            }
          }
        }
        tfoot {
          tr {
            td {
              vertical-align: top;
              color: #1a617f;
              b {
                color: #1a617f;
              }
              span {
                color: #1a617f;
              }
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .footer-package {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.25rem 1rem 0.75rem;
      margin-bottom: -4rem;
      background: $main-color;
      p {
        letter-spacing: 0.01rem;
        text-transform: uppercase;
        @include font-main(1rem, $white, $bold, 2.25rem);
        span {
          display: block;
          letter-spacing: -0.015rem;
          text-transform: initial;
          @include font-main(0.75rem, $white, $regular, normal);
        }
      }
      .right-text {
        text-align: right;
        p {
          @include font-main(1.5rem, $white, $bold, 2.25rem);
        }
      }
    }
  }
}
</style>
