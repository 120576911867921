var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "molecule-virtual-tour-images" },
    [
      _c("div", { staticClass: "molecule-carousel" }, [
        _c(
          "div",
          { ref: "carousel", staticClass: "owl-carousel owl-theme" },
          [_vm._t("slider-images")],
          2
        ),
        _c(
          "div",
          {
            staticClass: "expand",
            on: { click: _vm.handleExpandGalleryTrigger }
          },
          [
            _c("AtomIcon", {
              attrs: {
                icon: _vm.expandGallery ? "minimize" : "expand",
                size: 20
              }
            })
          ],
          1
        )
      ]),
      _vm._t("thumb-images")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }