var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title-and-info" }, [
    _c("div", { staticClass: "filters-title-and-button" }, [
      _c(
        "div",
        { staticClass: "filters-title" },
        [
          _c(
            "p",
            [
              _c("AtomIcon", { attrs: { icon: "filter-solid", size: 26 } }),
              _vm._v("\n        Filters\n        "),
              _vm.activeFiltersLength > 0
                ? _c("span", { staticClass: "number" }, [
                    _vm._v("/ " + _vm._s(_vm.activeFiltersLength))
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("AtomButton", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.activeFiltersLength > 0,
                expression: "activeFiltersLength > 0"
              }
            ],
            staticClass: "rounded-button",
            attrs: { label: "Clear All", "on-click": _vm.handleClearAllFilters }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "filters-trigger",
          on: { click: _vm.toggleShowFilters }
        },
        [
          !_vm.showFilters
            ? _c(
                "p",
                [
                  _vm._v("\n        expand\n        "),
                  _c("AtomIcon", { attrs: { icon: "sort-down", size: 26 } })
                ],
                1
              )
            : _c(
                "p",
                { staticClass: "inverse-arrow" },
                [
                  _vm._v("\n        minimize\n        "),
                  _c("AtomIcon", { attrs: { icon: "sort-down", size: 26 } })
                ],
                1
              )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }