<template>
  <div class="molecule-sidebar-results">
    <div class="title-list">
      <h3>{{ sidebarTitle }}</h3>
    </div>
    <div class="building-list">
      <div
        v-for="(pinSection, i) in pinsInfo"
        :key="i"
        class="floor-info-parent"
      >
        <div>
          <div
            class="parent-floor row-floor"
            v-bind:class="'activated-floor'"
            v-on:click="showSpecificPins(pinSection)"
          >
            <div class="clearfix" v-if="pinSection.category">
              <div class="column1">
                <span class="when-text db">{{ pinSection.category }}</span>
                <span
                  class="arrow-custom"
                  v-bind:class="{ 'rotate-icon': isSpaceListExpanded }"
                  ><AtomIcon icon="sort-down" :size="15"
                /></span>
              </div>
            </div>
          </div>
          <div class="show-all-spaces">
            <div
              v-for="pin in pinSection.pins"
              :key="pin.pinId"
              :id="pin.pinId"
              data-viewmode="defaultPin"
              class="row-floor row-floor-active"
              v-bind:class="'active'"
            >
              <span
                class="trigger-click-view-space"
                v-on:click="activateSpecificPin(pin)"
              ></span>
              <div class="parent-general-row-floor">
                <div class="general-dow-floor">
                  <div class="clearfix">
                    <div class="column1">
                      <span class="line-element line-checked"
                        ><AtomIcon icon="ok"
                      /></span>
                      <span class="get-sqm db"
                        ><b class="nr-sqm"></b> {{ pin.pinDescription }}</span
                      >
                      <!--                    <span class="icon view-space icon-lock"><AtomIcon icon="eye" :size="15" /> view space</span>-->
                      <!--                    <span class="icon hide-space icon-lock"><AtomIcon icon="eye-slash" :size="15" /> hide space</span>-->
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>

            <div
              v-for="virtualTourPin in pinSection.virtualTourPins"
              data-viewmode="virtualTourPin"
              :key="virtualTourPin.pinId"
              :id="virtualTourPin.pinId"
              class="row-floor row-floor-active virtual-tour-row"
              v-bind:class="'active'"
            >
              <span
                class="trigger-click-view-space"
                v-on:click="goToPin(virtualTourPin)"
              ></span>
              <div class="parent-general-row-floor">
                <div class="general-dow-floor">
                  <div class="clearfix">
                    <div class="column1">
                      <span class="line-element line-checked"
                        ><AtomIcon icon="ok"
                      /></span>
                      <span class="get-sqm db"
                        ><b class="nr-sqm"></b>
                        {{ virtualTourPin.pinDescription }}</span
                      >
                      <!--                    <span class="icon view-space icon-lock"><AtomIcon icon="eye" :size="15" /> view space</span>-->
                      <!--                    <span class="icon hide-space icon-lock"><AtomIcon icon="eye-slash" :size="15" /> hide space</span>-->
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
            <!-- end floor-space -->
          </div>
        </div>
      </div>
    </div>
    <!-- end floor-description -->
  </div>
</template>

<script>
import AtomIcon from "../../atoms/common/AtomIcon";
import buildingStore from "../../organisms/project/building/store";
import { isMobile, isTablet } from "../../../helpers/mobile/DeviceType";

export default {
  name: "MoleculeSidebarAccordionResults",
  data() {
    return {
      isSpaceListExpanded: false,
      listData: [],
      filters: {},
    };
  },
  components: { AtomIcon },
  props: {
    pinsInfo: [],
    sidebarTitle: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    buildingStore() {
      return buildingStore;
    },
    projectData() {
      return this.$store.state.project.project;
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    cdnBase() {
      return this.$store.getters.cdnBase;
    },
    unitOfMeasure() {
      return this.$store.state.base.meta.general.unitOfMeasure;
    },
    unitOfMeasureString() {
      return this.$store.state.base.meta.general.unitOfMeasure === 1
        ? "sqft"
        : "sqm";
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces;
    },
  },
  watch: {
    requestedSpaces: {
      deep: true,
      handler: function () {
        this.removeEventListeners();
        this.addEventListeners();
      },
    },
    "$store.state.project.project.buildings": function () {
      this.removeEventListeners();
      this.addEventListeners();
    },
    "$store.state.project.activeProject": function (value) {
      this.removeEventListeners();
      this.addEventListeners();
    },
    "$store.state.project.filters": {
      deep: true,
      handler: function (filters) {
        this.filters = filters;
        this.removeEventListeners();
        this.addEventListeners();
      },
    },
  },
  beforeUpdate() {
    this.removeEventListeners();
  },
  updated() {
    this.addEventListeners();
  },
  mounted() {
    this.removeEventListeners();
    this.addEventListeners();
  },
  methods: {
    removeEventListeners() {
      const parentFloorNodes = document.querySelectorAll(".parent-floor");
      parentFloorNodes.forEach((node) => {
        node.removeEventListener(
          "click",
          this.itemClickEventListener.bind(null, node)
        );
      });
    },
    addEventListeners() {
      const parentFloorNodes = document.querySelectorAll(".parent-floor");
      parentFloorNodes.forEach((node) => {
        node.addEventListener(
          "click",
          this.itemClickEventListener.bind(null, node)
        );
      });
    },
    resetView() {
      if (isMobile() || isTablet()) {
        this.buildingStore.dispatch("clearSpaceData");
      }
      console.log("old code legacy ");
    },
    activateSpecificPin(pinObject) {
      const getSiblings = function (elem) {
        let descendants = elem.parentNode.children;
        return Array.prototype.filter.call(descendants, function (sibling) {
          return sibling !== elem;
        });
      };
      const target = event.target;
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove("activated");
      });
      target.classList.toggle("activated");
      const closestFloorRow = event.target.closest(".row-floor");
      getSiblings(closestFloorRow).forEach((sibling) => {
        sibling.classList.remove("activation");
      });
      closestFloorRow.classList.add("activation");
      console.log("old code legacy");
      const buildingList = document.querySelector(".building-list");
      buildingList.querySelectorAll(".row-floor").forEach((childRow) => {
        if (childRow !== closestFloorRow) {
          childRow.classList.remove("activation");
        }
      });
    },
    showSpecificPins(pinSection) {
      console.log("old code legacy ", pinSection);
    },

    goToPin(pinObject) {
      document.querySelectorAll(".parent-floor").forEach((item) => {
        item.classList.remove("active");
      });
      console.log("old code legacy ", pinObject);
    },

    itemClickEventListener(node, e) {
      // if (!e.target.classList.contains('icon')) {
      const floorInfoList = document.querySelectorAll(".floor-info-parent");
      floorInfoList.forEach((floorItem) => {
        floorItem.querySelectorAll(".row-floor").forEach((rowFloor) => {
          rowFloor.classList.remove("activation");
        });
        floorItem
          .querySelectorAll(".trigger-click-view-space")
          .forEach((elemView) => {
            elemView.classList.remove("activated");
          });

        if (floorItem.querySelector(".parent-floor") !== node) {
          floorItem.querySelector(".parent-floor").classList.remove("active");
          floorItem.querySelector(".show-all-spaces").style.display = "none";
        }
      });

      node.classList.toggle("active");
      const siblingSpacesContainer = node.parentNode.querySelector(
        ".show-all-spaces"
      );
      if (siblingSpacesContainer) {
        siblingSpacesContainer.style.display =
          siblingSpacesContainer.style.display === "block" ? "none" : "block";
      }
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-sidebar-results {
  .title-list {
    padding: 12px;
  }
  .row-padding-11px {
    padding: 0 11px;
  }
  .col-padding-4px {
    padding: 0 4px;
  }
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .trigger-click-view-space {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    cursor: pointer;
  }
  .floor-info-parent {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .row-floor {
    position: relative;
    display: block;
    vertical-align: middle;
    width: 100%;
    padding: 12px 16px;
    cursor: pointer;
    background: #f7f7f7;

    &.virtual-tour-row {
      display: none;
    }
    &.action-disabled {
      pointer-events: none;
      background: none;
    }
    .clearfix {
      position: relative;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .hover-buttons {
      display: none;
      text-align: center;
      padding-bottom: 10px;
      .wrapper-btn {
        display: block;
      }
      .icon-edit {
        display: block;
        background: $white;
        border-radius: 2px;
        padding: 8px 10px;
        font-size: 14px;
        color: $black;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;
        .icon {
          margin-right: 5px;
        }
        &:hover {
          background: $black;
          color: $white;
          .icon {
            color: $white !important;
          }
        }
      }
      .icon-select {
        display: block;
        border-radius: 2px;
        background: #4cc66d;
        padding: 8px 10px;
        font-size: 14px;
        color: $white;
        font-weight: $regular;
        line-height: 17px;
        text-decoration: none;
        svg {
          margin-right: 5px;
        }
        &.select-show {
          background: #4cc66d;
          &:hover {
            background: #3ade66;
          }
          .icon {
            margin-right: 5px;
            font-size: 15px !important;
            color: $white !important;
          }
        }
        &.btn-remove-item {
          background: #fcdfdf;
          color: #f93d3d;
          &:hover {
            background: #f93d3d;
            color: $white;
          }
        }
      }
    }
    .topTitle {
      position: relative;
      padding: 8px 239px 8px 0;
      margin-bottom: 10px;
    }
    h6 {
      display: block;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: $black;
      font-weight: $regular;
      line-height: 17px;
      b {
        font-size: 14px;
        line-height: 17px;
      }
      &.floor-number {
        color: #7e7e7e;
        font-weight: $light;
        font-size: 14px;
        line-height: 17px;
        span {
          font-weight: $medium;
          margin: -1px 0 0 4px;
          font-size: 14px;
          line-height: 17px;
        }
      }
      &.when-text.db {
        color: $black;
        font-weight: $regular;
      }
    }
    .sqm-gla-total {
      color: #7e7e7e;
      font-weight: $light;
      position: absolute;
      top: 2px;
      right: 30px;
      b {
        color: $black;
        font-weight: $semiBold;
      }
    }
    .column3 {
      width: 32%;
    }
    .column2 {
      width: 49%;
    }
    .column3,
    .column2 {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
  .show-all-spaces {
    display: none;
    .row-floor {
      border: none;
      background: #f7f7f7;
      border-radius: 0;
      margin-top: 2px;
      padding: 12px 16px 2px 16px;
      cursor: initial;
      transition: all 0.2s ease-in-out 0s;
      &.leased-element-style {
        padding: 12px 16px;
        pointer-events: none;
        background: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent 2px,
          #f7f7f7 1px,
          #f7f7f7 4px
        );
        .clearfix {
          margin: 0;
        }
        .get-space-floor {
          opacity: 0.3;
        }
        .leased-overlay {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;
          font-size: 14px;
          color: $black;
          font-weight: $regular;
          line-height: 17px;
        }
      }
      .clearfix {
        position: relative;
        .icon-lock {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 2px;
          cursor: pointer;
          font-size: 14px;
          color: $black;
          font-weight: $light;
          line-height: 17px;
          transition: all 0.2s ease-in-out 0s;
          &.hide-space {
            display: none;
          }
        }
      }
      .line-element {
        margin-right: 12px;
        .icon {
          font-size: 14px !important;
        }
        &.line-checked {
          .icon {
            color: #4cc66d !important;
          }
        }
      }
      .textInfo {
        font-size: 14px;
        color: $black;
        font-weight: $light;
        line-height: 20px;
        margin-bottom: 9px;
      }
      .get-sqm {
        display: inline;
        margin-right: 16px;
      }
      .get-sqm,
      .get-nr-people {
        font-weight: $light;
      }
      &:hover {
        background: #eaeaea;
        .clearfix {
          .icon-lock {
            opacity: 1;
          }
        }
      }
      &.activation {
        background: #eaeaea;
        .hover-buttons {
          display: block;
        }
        .icon-lock {
          &.view-space {
            display: none;
          }
          &.hide-space {
            display: block;
            opacity: 1;
          }
        }
      }
    }
  }
  .parent-floor {
    margin-bottom: 0;
    transition: all 0.2s ease-in-out 0s;
    .arrow-custom {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out 0s;
      margin-top: -3px;

      &.rotate-icon {
        top: 30%;
        transform: rotate(180deg);
      }
    }
    &:hover {
      background: #eaeaea;
    }
    &.active {
      background: #eaeaea;
    }
  }
  .row-floor-active {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid $main-color;
    &:last-child {
      border-bottom: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .clearfix {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
  .nr-cube {
    opacity: 0;
    width: 0;
    height: 22px;
    line-height: 23px !important;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background: $black;
    color: $white !important;
    border-radius: 4px;
    margin-top: -2px;
    margin-right: 5px;
    font-weight: $bold !important;
    &.nrCubeWhite {
      color: $black !important;
      background: $white;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hiddenMobileElements {
    display: none !important;
  }
  .hiddenDesktopElements {
    display: block !important;
  }
  .mobileTextLeft {
    text-align: left !important;
  }

  .organism-building {
    #canvas-wrapper {
      width: 100vw;
    }
  }
  .molecule-sidebar-results {
    .row-floor {
      &.action-disabled {
        .leased-overlay {
          font-size: 13px;
        }
      }
      h6 {
        font-size: 13px;
      }
      span {
        font-size: 13px;
        b {
          font-size: 13px;
        }
        &.floor-number {
          font-size: 13px;
          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  .project {
    .organism-sidebar-accordion {
      display: block;
      right: 50% !important;
      top: auto;
      width: 362px;
      bottom: 79px !important;
      border-radius: 4px;
      height: calc(100vh - 204px) !important;
      @include prefix(transform, translateX(50%), ("webkit", "moz", "ms", "o"));
      @include prefix(transition, none, ("webkit", "moz", "ms", "o"));
      .header-box {
        .info-building {
          z-index: 99999;
          height: calc(100vh - 254px);
        }
      }
      .show-all-spaces {
        .row-floor {
          .line-element {
            margin-right: 5px;
          }
          .get-sqm {
            margin-right: 8px;
          }
          .clearfix {
            .icon-lock {
              top: 0;
              opacity: 1 !important;
              font-size: 13px;
              .icon {
                font-size: 12px !important;
              }
            }
          }
          span {
            font-size: 13px;
            b {
              font-size: 13px;
            }
          }
          .hover-buttons {
            a {
              padding: 6px 5px;
              font-size: 12px;
              .icon {
                font-size: 13px !important;
              }
            }
          }
          &:hover {
            background: #f7f7f7;
          }
          &.active {
            background: #eaeaea !important;
          }
          &.leased-element-style {
            .clearfix {
              .icon-lock {
                opacity: 0 !important;
              }
            }
            .leased-overlay {
              font-size: 13px;
            }
          }
        }
      }
    }
    .button-back {
      left: 0;
      .btn-cube {
        width: 55px;
        height: 55px;
        &:hover {
          @include prefix(transform, scale(1), ("webkit", "moz", "ms", "o"));
        }
      }
      &.active {
        top: 0;
      }
    }
  }
}
</style>
