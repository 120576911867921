<template>
  <div
    :class="{
      section: true,
      'section-leasing': true,
      [`section${orderNo}`]: true,
    }"
  >
    <a :id="hash" class="navigation-target" href="javascript:void(0);"></a>
    <div class="row row-slider-info align-items-center">
      <div class="col col-xs-12 col-6">
        <img :src="resourceUrl(data.image)" :alt="data.title" />
      </div>
      <div class="col col-xs-12 col-6">
        <div class="content-slider">
          <p class="title" v-if="data.title" v-html="data.title"></p>
          <div class="subtitle-info" v-if="data.lines">
            <p
              class="subtitle"
              v-for="(line, index) in data.lines"
              :key="index"
              v-html="line"
            ></p>
          </div>
          <div class="statistics-info" v-if="data.statisticsLines">
            <AtomNumberAndText
              v-for="(statistic, index) in data.statisticsLines"
              :title="statistic.numberText"
              :subtitle="statistic.text"
              :key="index"
            />
          </div>
          <div class="buttons-bottom">
            <div class="display-inline" v-if="data.buttonText">
              <AtomButtonLink
                :label="`${data.buttonText}`"
                :href="`${data.buttonUrl}`"
              />
            </div>
            <div class="display-inline" v-if="data.buttonBrochureText">
              <AtomButtonLink
                class="btn-general-all-black"
                :label="`${data.buttonBrochureText}`"
                :href="`${resourceUrl(data.buttonBrochureUrl)}`"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomNumberAndText from "@/components/atoms/common/AtomNumberAndText";
import AtomButtonLink from "../../../components/atoms/common/AtomButtonLink";

export default {
  name: "SectionLeasing",
  components: { AtomButtonLink, AtomNumberAndText },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {};
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    },
    hash: {
      required: true,
      type: String,
      default: "",
    },
  },

  methods: {
    resourceUrl(url) {
      if (url.includes("://")) {
        return url;
      }
      return `${this.$store.getters.cdnBase}/${url}`;
    },
  },
};
</script>

<style lang="scss">
.section-leasing {
  margin-top: 5rem;
  @media only screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
</style>
