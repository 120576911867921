import { PinContentManager } from "@/components/organisms/project/building/3D/core/builders/PinContentManager";
import { AbstractMesh, Mesh, MeshBuilder, Scene } from "babylonjs";
import Constants, {
  PIN_ICON,
} from "@/components/organisms/project/building/3D/core/builders/Constants";
import { Utils } from "@/components/organisms/project/building/3D/core/builders/Utils";

import {
  TextBlock,
  Image,
  Control,
  StackPanel,
  Rectangle,
} from "babylonjs-gui";
import { PinManager } from "../managers/PinManager";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";
import { isDesktop } from "@/helpers/mobile/DeviceType";

export class Pin {
  mesh: Mesh;
  isActive = false;
  clicked = false;
  scene: Scene;
  pinData: {
    text: string;
    title?: string;
    pinImageSrc?: string;
    pinVideoSrc?: string;
  };
  pinContentManager: PinContentManager;
  pinContentType: string;
  pinManager?: PinManager;
  category = "";
  sceneManager = BabylonClientManager.getSceneManager();
  options?: Record<string, unknown>;
  constructor(
    scene: Scene,
    pinData: {
      text: string;
      title?: string;
      pinImageSrc?: string;
      pinVideoSrc?: string;
    },
    pinContentManager: PinContentManager,
    pinContentType = "default"
  ) {
    this.scene = scene;
    this.pinData = pinData;
    this.pinContentManager = pinContentManager;
    this.pinContentType = pinContentType;
    this.mesh = this.createMeshUI(pinContentType);

    return this;
  }

  createMeshUI(category: string) {
    this.category = category;
    const mesh = MeshBuilder.CreatePlane(
      Constants.PIN_NAME,
      { size: 2 },
      this.scene
    );
    mesh.occlusionQueryAlgorithmType =
      AbstractMesh.OCCLUSION_ALGORITHM_TYPE_CONSERVATIVE;
    mesh.isOccluded = true;
    mesh.occlusionType = AbstractMesh.OCCLUSION_TYPE_STRICT;
    mesh.visibility = Constants.DEBUG_PINS ? 1 : 0.0001;
    if (Constants.DEBUG_PINS) {
      mesh.showBoundingBox = true;
    }
    mesh.rotation.x = Math.PI / 4;
    mesh.position.y = 0.5;
    mesh.bakeCurrentTransformIntoVertices();
    mesh.freezeWorldMatrix();
    mesh.isPickable = false;
    const title = this.pinData.title ? this.pinData.title : this.pinData.text;

    const ui = new StackPanel("anchor");
    ui.adaptHeightToChildren = true;
    ui.adaptWidthToChildren = true;
    ui.isHitTestVisible = false;
    ui.isVertical = false;
    ui.background = "transparent";

    this.sceneManager?.gui?.addControl(ui);

    const left = new Rectangle("left");
    left.height = "44px";
    left.width = "28px";
    left.thickness = 0;
    left.background = "transparent";

    ui.addControl(left);

    const url0 = "data:image/svg+xml," + encodeURIComponent(PIN_ICON.line);
    const img0 = new Image("image", url0);
    img0.width = "26px";
    img0.height = "26px";
    img0.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    img0.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    left.addControl(img0);

    const anchor = new StackPanel("anchor");
    anchor.adaptHeightToChildren = true;
    anchor.adaptWidthToChildren = true;
    anchor.isPointerBlocker = true;
    anchor.paddingLeft = "-2px";
    anchor.isVertical = false;
    anchor.color = Constants.PIN_STYLES.color;
    anchor.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    anchor.background = Constants.PIN_STYLES.backgroundColor;

    anchor.onPointerEnterObservable.add(() => {
      this.pointerOver();
      anchor.background = Constants.PIN_STYLES.backgroundColorActive;
    });
    anchor.onPointerOutObservable.add(() => {
      this.pointerOut();
      anchor.background = Constants.PIN_STYLES.backgroundColor;
    });
    anchor.onPointerDownObservable.add(() => {
      this.pointerDown();
    });
    ui.addControl(anchor);

    if (category != "default") {
      const url =
        "data:image/svg+xml," +
        encodeURIComponent((PIN_ICON as { [key: string]: string })[category]);
      const img = new Image("image", url);
      img.width = "20px";
      img.height = "20px";
      img.paddingBottom = "5px";
      img.paddingLeft = "5px";
      img.paddingTop = "5px";
      img.paddingRight = "5px";
      img.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
      anchor.addControl(img);
    }

    const text = new TextBlock();
    text.fontSize = Constants.PIN_STYLES.fontSize;
    text.fontFamily = Constants.PIN_STYLES.fontFamily;
    text.fontWeight = Constants.PIN_STYLES.fontWeight;
    text.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    text.resizeToFit = true;
    text.forceResizeWidth = true;
    text.text = title;
    text.paddingLeft = category == "default" ? "4px" : "0px";
    text.color = Constants.PIN_STYLES.color;
    anchor.addControl(text);

    const url2 = "data:image/svg+xml," + encodeURIComponent(PIN_ICON.plus);
    const img2 = new Image("image", url2);
    img2.width = "20px";
    img2.height = "20px";
    img2.paddingBottom = "5px";
    img2.paddingLeft = "5px";
    img2.paddingTop = "5px";
    img2.paddingRight = "5px";
    img2.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    anchor.addControl(img2);

    this.sceneManager?.scene.onAfterRenderObservable.addOnce(() => {
      ui.linkOffsetY = -Math.round(ui.heightInPixels / 2);
      ui.linkOffsetX = Math.round(ui.widthInPixels / 2);
    });

    ui.linkWithMesh(mesh);
    mesh.metadata = { tooltip: anchor, tooltipLine: ui };

    return mesh;
  }

  toggle(isActive: boolean) {
    this.sceneManager?.renderScene();
    this.isActive = isActive;
    if (this.isActive) {
      this.showContent();
      this.pinHighlight(true);
      if (isDesktop()) {
        this.mesh.metadata.tooltip.alpha = 0;
      } else {
        this.mesh.metadata.tooltipLine.alpha = 0;
      }
    } else {
      this.pinContentManager.dispose();
      this.pinHighlight(false);
      this.mesh.metadata.tooltip.background =
        Constants.PIN_STYLES.backgroundColor;
      if (isDesktop()) {
        this.mesh.metadata.tooltip.alpha = 1;
      } else {
        this.mesh.metadata.tooltipLine.alpha = 1;
      }
    }
  }

  reset() {
    this.toggle(false);
  }

  updateProperties(options: unknown) {
    Object.assign(this.mesh, options);
  }

  generateTextBox(
    data: {
      pinClasses: string[];
      imgClasses: string[];
      videoClasses: string[];
      titleClasses: string[];
      textClasses: string[];
      id: string | number;
    },
    yOffset: number[]
  ) {
    this.pinContentManager.generateBox(...data.pinClasses);
    this.pinContentManager.addInfoHeader(this.category, this.category);
    if (this.pinData.title && this.pinData.title != "") {
      this.pinContentManager.addTitle(this.pinData.title, ...data.titleClasses);
    }
    //// Add image if it exits
    if (this.pinData.pinImageSrc) {
      this.pinContentManager.addImg(
        this.pinData.pinImageSrc,
        this.mesh,
        yOffset,
        [...data.imgClasses]
      );
    }
    if (this.pinData.pinVideoSrc) {
      this.pinContentManager.addVideo(
        this.pinData.pinVideoSrc,
        this.mesh,
        yOffset,
        [...data.videoClasses]
      );
    }
    if (this.pinData.text) {
      this.pinContentManager.addText(this.pinData.text, ...data.textClasses);
    }

    if (!this.pinData.pinImageSrc) {
      this.pinContentManager.show(this.mesh, yOffset);
    }

    //// Add close event
    this.pinContentManager.xButton?.addEventListener(
      "pointerdown",
      this.closePopUp.bind(this)
    );
  }

  showContent() {
    this.generateTextBox(
      {
        pinClasses: ["pinInfo", "pin-info-wrapper"],
        imgClasses: ["pin-img-wrapper"],
        videoClasses: ["video-pin"],
        titleClasses: ["simple-wrapper"],
        textClasses: ["simple-wrapper"],
        id: -1,
      },
      Constants.PIN_POP_UP_OFFSET
    );
  }

  dispose() {
    if (this.mesh.metadata && this.mesh.metadata.tooltip) {
      this.mesh.metadata.tooltip.onPointerOutObservable.clear();
      this.mesh.metadata.tooltip.onPointerDownObservable.clear();
      this.mesh.metadata.tooltip.onPointerEnterObservable.clear();
    }
    this.mesh.dispose();
    this.pinContentManager.dispose();
  }

  closePopUp() {
    this.pinContentManager.dispose();
    this.pinContentManager.xButton?.removeEventListener(
      "pointerdown",
      this.closePopUp
    );
    this.reset();
    this.sceneManager?.renderScene();
  }

  hide(isVisible: boolean) {
    this.mesh.setEnabled(isVisible);
    this.mesh.metadata.tooltipLine.isVisible = isVisible;
    this.mesh.metadata.tooltipLine.isHitTestVisible = isVisible;
    this.sceneManager?.renderScene();
  }

  pointerOut() {
    this.pinHighlight(false);
    this.sceneManager?.renderScene(100);
    document.body.style.cursor = "default";
  }

  pointerOver() {
    this.pinHighlight(true);
    this.sceneManager?.renderScene(-1);
    document.body.style.cursor = "pointer";
  }

  pointerDown() {
    if (this.pinManager != null) {
      this.pinManager.unselectPin();
      this.pinManager.selectedPin = this;
    }
    this.isActive = !this.isActive;
    this.toggle(this.isActive);
    document.body.style.cursor = "default";
  }

  pinHighlight(isEnabled: boolean) {
    if (this.options?.highlightMesh) {
      const highlightNode = Utils.findNode(
        this.options.highlightMesh as string,
        this.scene
      );
      if (!highlightNode) {
        return console.error(
          "Pin highlight not found ",
          this.options.highlightMesh
        );
      }
      highlightNode.setEnabled(isEnabled);
    }
  }
}
