<template>
  <div :class="{ 'atom-collapsible-item': true, active: isOpen }">
    <div class="item-header" @click="toggle">
      <div class="title-wrapper">
        <slot name="title"></slot>
        <div class="icon-button" :aria-expanded="isOpen">
          <span class="expand-text" v-if="showIconText">{{
            computedText
          }}</span>
          <span class="icon-wrapper" :class="{ active: isOpen }">
            <AtomIcon icon="sort-down" :size="14" color="#000000" />
          </span>
        </div>
      </div>
      <slot name="extra-header-info"></slot>
    </div>
    <div class="item-content" v-show="isOpen">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import AtomIcon from "./AtomIcon";

export default {
  name: "AtomCollapsibleItem",
  components: { AtomIcon },
  props: {
    item: {
      required: false,
      type: Object,
      default: () => {},
    },
    isDefaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
    showIconText: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.$props.isDefaultOpen,
    };
  },
  computed: {
    computedText() {
      return this.isOpen ? "minimize" : "expand";
    },
  },
  methods: {
    toggle(emit = true) {
      this.isOpen = !this.isOpen;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
    open(emit = true) {
      this.isOpen = true;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
    close(emit = true) {
      this.isOpen = false;
      if (emit) {
        this.$emit("toggle", this.isOpen);
      }
    },
  },
};
</script>

<style lang="scss">
.atom-collapsible-item {
  &.parent-box {
    background: $darkerWhite;
    margin-bottom: 0.25rem;
    .item-header {
      padding: 0.5rem 0.75rem 0.5rem 1.25rem;
      @media only screen and (max-width: 767px) {
        padding: 0.5rem 1rem;
      }
      .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .title-text {
          font-weight: $regular;
          font-size: 1.375rem;
          line-height: 1.625rem;
        }
        .circle-sum {
          font-weight: $bold;
          border-radius: 1.875rem;
          color: $white;
          background: $black;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.875rem;
          margin-left: 0.625rem;
          padding: 0.125rem 0.5rem;
        }
      }
    }
    .item-content {
      margin: 0 0.5rem;
      @media only screen and (max-width: 767px) {
        margin: 0;
      }
    }
    &.active {
      background: $lightGrey;
    }
  }
  .item-header {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .title-wrapper {
      padding: 0.5rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon {
        font-size: 1rem !important;
        display: inline-block;
        vertical-align: middle;
        margin-top: -0.188rem;
        margin-right: 0.188rem;
      }
      .icon-button {
        cursor: pointer;
        display: flex;
        .expand-text {
          @include font-main(0.875rem, rgba(2, 2, 2, 0.3), $regular, 1.063rem);
          margin-right: 0.875rem;
        }
        .icon-wrapper {
          display: flex;
          transition: all 0.2s ease-in-out 0s;
          height: fit-content;
          top: -0.063rem;
          position: relative;
          .icon {
            font-size: 0.875rem !important;
          }
          &.active {
            transform: rotate(-180deg);
            top: 0.25rem;
          }
        }
        &:hover {
          span {
            font-weight: $semiBold;
            transition: all 0.3s ease-in-out 0s;
          }
        }
      }
    }
  }
  .item-content {
    padding: 0.5rem;
  }
}
</style>
