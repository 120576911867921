var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "locations-map" },
    [
      _c(
        "div",
        { staticClass: "organism-main-locations" },
        [
          _c(
            "MoleculeMainLocations",
            _vm._l(_vm.locationsData.projects, function(location, index) {
              return _c("AtomMainLocations", {
                key: index,
                attrs: {
                  "active-project": _vm.activeProject,
                  "tab-id": "item-" + index,
                  "project-id": location.id,
                  image: location.image,
                  title: location.title,
                  "image-text": location.imageText,
                  "button-text": location.buttonText,
                  "button-url": location.buttonUrl
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _c("MoleculeGoogleMap", {
        ref: "map",
        attrs: { "map-config": _vm.locationsData.map }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }