<template>
  <li v-if="transportationType === mode" class="atom-route" :data-marker="markerId" :data-mode="mode"
      @click="drawRouteOnItemClicked">
    <b>{{ orderDisplay }}</b>
    {{ name }}
    <span class="add-time">
        <AtomIcon :icon="mode" :size="14"/>
        {{ time }} &nbsp; {{ distance }}
      </span>
  </li>
</template>

<script>
import AtomIcon from "../common/AtomIcon";
import {isMobile} from "../../../helpers/mobile/DeviceType";

export default {
  name: "AtomRoute",
  components: { AtomIcon },
  props: {
    markerId: {
      required: false,
      type: String,
      default: "",
    },
    mode: {
      required: false,
      type: String,
      default: "",
    },
    order: {
      required: false,
      type: Number,
      default: 0,
    },
    name: {
      required: false,
      type: String,
      default: "",
    },
    time: {
      required: false,
      type: String,
      default: "0s",
    },
    distance: {
      required: false,
      type: String,
      default: "0km",
    }
  },
  computed: {
    orderDisplay() {
      return this.order.toString().padStart(2, "0");
      // return this.order.toString();
    },
    transportationType() {
      return this.$parent.filters.transportationType
    }
  },
  methods: {
    drawRouteOnItemClicked() {

      const getSiblings = function (elem) {
        return Array.prototype.filter.call(elem.parentNode.children, function (sibling) {
          return sibling !== elem;
        });
      };
      const target = this.$el;
      target.classList.toggle('atom-route-active');
      getSiblings(target).forEach((sibling) => {
        sibling.classList.remove('atom-route-active');
      });

      this.$parent.$parent.$parent.$refs.map.calcRoute(this.markerId, this.mode);
      if(isMobile()) {
        this.$parent.$parent.$parent.toggleSidebar();
      }
    }
  }
}
</script>

<style lang="scss">
  .atom-route-active {
    background: $second-color;
    color: $white;
    .add-time {
      color: $white !important;
    }
  }
  .atom-route {
    padding: 0.75rem 13.438rem 0.75rem 6.563rem;
    position: relative;
    border-bottom: 1px solid $blackOpacity005;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s;
    font-size: 0.85rem;
    line-height: 1.188rem;
    &.active {
      display: none;
    }
    @media only screen and (max-width: 767px) {
      padding: 0.625rem 7.938rem 0.625rem 3.125rem;
      font-size: 0.75rem;
      line-height: 1rem;
    }
    b {
      font-weight: $regular;
      font-size: 1rem;
      line-height: 1.188rem;
      color: $main-color;
      position: absolute;
      left: 4.688rem;
      top: 0.75rem;
      @media only screen and (max-width: 767px) {
        font-size: 0.75rem;
        line-height: 1rem;
        left: 0.938rem;
        top: 0.625rem;
      }
    }
    .add-time {
      position: absolute;
      top: 0.75rem;
      right: 4.688rem;
      font-weight: $regular;
      font-size: 1rem;
      line-height: 1.188rem;
      color: $black;
      transition: all 0.2s ease-in-out 0s;
      @media only screen and (max-width: 767px) {
        font-size: 0.75rem;
        line-height: 1rem;
        right: 0.625rem;
        top: 0.625rem;
      }
      .icon {
        font-size: 0.875rem !important;
        color: $main-color !important;
        display: inline-block;
        vertical-align: middle;
        margin-top: -0.125rem;
        margin-right: 0.625rem;
        @media only screen and (max-width: 767px) {
          font-size: 0.75rem !important;
          margin-right: 0.313rem;
          top: -0.188rem;
        }
      }
    }
    &:hover, &.active {
      background: $second-color;
      color: $white;
      .add-time {
        color: $white;
      }
    }
  }
</style>