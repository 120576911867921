<template>
  <div class="input-atom-wrapper" :class="{'date-style': type === types.DATE}">
    <label :for="name" v-if="label">{{ label }}</label>
    <input ref="input" v-if="type !== types.TEXTAREA" :type="type" v-model="inputVal" :placeholder="placeholder" :name="name"/>
    <textarea ref="textarea" v-else v-model="inputVal" placeholder="Message"></textarea>
    <span class="error-message" v-if="error">{{ error }}</span>
  </div>
</template>

<script>
export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
};

export default {
  name: "AtomInput",
  props: {
    value: {
      required: false,
      type: String,
      default: "",
    },
    type: {
      required: false,
      type: String,
      default: EnumInputTypes.TEXT,
    },
    placeholder: {
      required: false,
      type: String,
      default: "Input value...",
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
    name: {
      required: false,
      type: String,
    },
    error: {
      required: false,
      type: String,
    }
  },
  data() {
    return {
      types: EnumInputTypes,
      // err: this.error,
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    resetInput() {
      this.inputVal = '';
    },
    focus(opts = {}) {
      if (this.type === this.types.TEXTAREA) {
        this.$refs.textarea.focus(opts);
        return;
      }
      return this.$refs.input.focus(opts);
    }
  }
}
</script>

<style lang="scss" scoped>
.input-atom-wrapper {
  margin: 0;
  flex: 1;
  width: 100%;
  .error-message {
    color: red;
    padding: 0.625rem 0 0.938rem;
    font-size: 0.875rem;
    line-height: 1.063rem;
    font-weight: 400;
    width: 100%;
    @media only screen and (max-width: 767px) {
      padding: 0.313rem 0;
      font-size: 1rem;
      line-height: 1.188rem;
    }
  }
  label {
    display: block;
    margin-bottom: 0.5rem;
    @include font-main(0.75rem, rgba(0, 0, 0, 0.6), $regular, 0.875rem);
    @media only screen and (max-width: 767px) {
      color: $black;
      font-size: 1rem;
      line-height: 1.213rem;
    }
  }
  input, textarea {
    background: transparent;
    padding: 0.625rem 0.938rem;
    width: 100%;
    border: 1px solid #E5EAED;
    @include font-main(0.875rem, $black, $regular, 1.063rem);
    &:focus-visible {
      box-shadow: none !important;
      outline-width: 0 !important;
      border-color: $black;
    }
    &::placeholder {
      color: rgba(0,0,0,0.2);
    }
  }

  input {
    height: 2.125rem;
    @media only screen and (max-width: 767px) {
      height: 2.5rem;
      font-size: 1.125rem;
      line-height: 1.313rem;
    }
  }

  textarea {
    height: 6.25rem;
    min-height: 6.25rem;
    max-height: 6.25rem;
    max-width: 100%;
    min-width: 100%;
    @media only screen and (max-width: 767px) {
      font-size: 1.125rem;
      line-height: 1.313rem;
    }
  }
}
</style>