import { Mesh, Scene, Vector3 } from "babylonjs";
import { PIN_ICON } from "@/components/organisms/project/building/3D/core/builders/Constants";
import { isDesktop } from "@/helpers/mobile/DeviceType";

export class PinContentManager {
  activeDiv?: HTMLDivElement;
  cdnBase;
  scene: Scene;
  xButton?: HTMLDivElement;

  constructor(cdnBase: string, scene: Scene) {
    this.cdnBase = cdnBase;
    this.scene = scene;

    return this;
  }

  generateBox(...cssClass: string[]) {
    if (this.activeDiv) this.dispose();
    this.activeDiv = document.createElement("div");
    this.activeDiv.style.position = "absolute";
    this.activeDiv.style.zIndex = "10";
    this.activeDiv.style.visibility = "hidden";
    //// Add css
    this.activeDiv.classList.add(...cssClass);
    const t = document.getElementsByClassName("organism-building")[0];
    t.appendChild(this.activeDiv);
  }

  addTitle(title: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const titleHTML = document.createElement("h4");
    titleHTML.innerText = title;
    //// Add css
    titleHTML.classList.add(...cssClass);
    this.activeDiv.appendChild(titleHTML);
  }

  addText(text: string, ...cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const textHtlm = document.createElement("p");
    textHtlm.innerHTML = text;
    //// Add css
    textHtlm.classList.add(...cssClass);
    this.activeDiv.appendChild(textHtlm);
  }

  addImg(imgSrc: string, mesh: Mesh, yOffset: number[], cssClass: string[]) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const imgHtml = document.createElement("img");
    imgHtml.src = this.cdnBase + "/" + imgSrc;
    imgHtml.alt = "pin image";
    //// Add css
    imgHtml.classList.add(...cssClass);
    this.activeDiv.appendChild(imgHtml);
    imgHtml.onload = () => {
      this.show(mesh, yOffset);
    };
  }

  addVideo(
    videoSrc: string,
    mesh: Mesh,
    yOffset: number[],
    cssClass: string[]
  ) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const wrapper = document.createElement("div");
    //// Add css
    wrapper.classList.add(...cssClass);
    const videoHtml = document.createElement("iframe");
    videoHtml.src = videoSrc;
    wrapper.appendChild(videoHtml);
    this.activeDiv.appendChild(wrapper);
    videoHtml.onload = () => {
      this.show(mesh, yOffset);
    };
  }

  reposition(mesh: Mesh, yOffset: number[]) {
    if (!this.activeDiv || !this.scene.activeCameras)
      return console.error("No pin info div found");
    const engine = this.scene.getEngine();
    const hardwareScaling = engine.getHardwareScalingLevel();
    const coordinates = Vector3.Project(
      Vector3.Zero(),
      mesh.getWorldMatrix(),
      this.scene.getTransformMatrix(),
      this.scene.activeCameras[0].viewport.toGlobal(
        engine.getRenderWidth(true) * hardwareScaling,
        engine.getRenderHeight(true) * hardwareScaling
      )
    );
    const pinTop = coordinates.y;
    const pinLeft = coordinates.x;
    const rect = this.activeDiv.getBoundingClientRect();

    if (isDesktop()) {
      this.activeDiv.style.top = pinTop - yOffset[1] - rect.height / 2 + "px";
      this.activeDiv.style.left = pinLeft + yOffset[0] + "px";
    } else {
      this.activeDiv.style.left = window.innerWidth - rect.width - 5 + "px";
      this.activeDiv.style.top = "50%";
      this.activeDiv.style.transform = "translateY(-50%)";
      this.activeDiv.style.marginTop = "-51px";
    }
    //// Pin box info z-index
    this.activeDiv.style.zIndex = "90909";
  }

  dispose() {
    if (!this.activeDiv || !this.xButton) return;
    this.xButton.removeEventListener("pointerdown", this.dispose);
    this.activeDiv.remove();
    delete this.activeDiv;
  }

  show(mesh: Mesh, yOffset: number[]) {
    if (!this.activeDiv) return;
    this.reposition(mesh, yOffset);
    this.activeDiv.style.visibility = "visible";
  }

  addInfoHeader(icon: string, headerText: string) {
    if (!this.activeDiv) return console.error("No pin info div found");
    const wrapper = document.createElement("div");
    wrapper.classList.add("pin-info-header");

    const leftWrapper = document.createElement("div");
    leftWrapper.classList.add("left-header");
    if (icon != "" && icon != "default") {
      const headerImg = document.createElement("img");
      headerImg.src =
        "data:image/svg+xml," +
        encodeURIComponent((PIN_ICON as { [key: string]: string })[icon]);
      leftWrapper.appendChild(headerImg);
    }

    if (headerText != "default") {
      const headerTextElem = document.createElement("h3");
      headerTextElem.innerHTML =
        headerText.charAt(0).toUpperCase() + headerText.slice(1);
      leftWrapper.appendChild(headerTextElem);
    }

    const rightWrapper = document.createElement("div");
    rightWrapper.classList.add("right-header");
    this.xButton = document.createElement("div");
    this.xButton.classList.add("close-element");
    this.xButton.innerHTML = "X";
    rightWrapper.appendChild(this.xButton);

    wrapper.appendChild(leftWrapper);
    wrapper.appendChild(rightWrapper);
    this.activeDiv.appendChild(wrapper);
  }
}
