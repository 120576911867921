var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { class: { "atom-location-type": true } },
    [
      _c(
        "span",
        {
          staticClass: "change-info-boxes",
          attrs: { "data-category": _vm.category },
          on: { click: _vm.toggleSection }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.categoryLabel) + "\n    "),
          _c("span", { staticClass: "buttons-tabs" }, [
            _c(
              "span",
              {
                staticClass: "walking-tab common-tab",
                class: { active: _vm.defaultMode === "walking" },
                attrs: { "data-transportation": "walking" },
                on: { click: _vm.selectWalkingSliderOption }
              },
              [_c("AtomIcon", { attrs: { icon: "walking", size: 14 } })],
              1
            ),
            _c(
              "span",
              {
                staticClass: "driving-tab common-tab",
                class: { active: _vm.defaultMode === "driving" },
                attrs: { "data-transportation": "driving" },
                on: { click: _vm.selectDrivingSliderOption }
              },
              [_c("AtomIcon", { attrs: { icon: "driving", size: 14 } })],
              1
            )
          ]),
          _c(
            "span",
            { staticClass: "circle" },
            [
              _c("AtomIcon", {
                staticClass: "plus-icon",
                attrs: { color: "#423687", size: 18, icon: "plus" }
              }),
              _c("AtomIcon", {
                staticClass: "minus-icon",
                attrs: { color: "#423687", size: 18, icon: "minus" }
              })
            ],
            1
          )
        ]
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }