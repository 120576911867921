<template>
  <v-popover @show="handleWidth">
    <div class="label-popover">{{ placeholder }}</div>
    <div class="drop-down-filter-container" ref="input-show">
      <div v-if="inputVal.length">
        {{ placeholder }}
        <span class="indicator">{{ inputVal.length }}</span>
        <span class="clear-all" @click="clearFilter"><AtomIcon icon="xmark"/></span>
      </div>
      <span v-else>
        All
      </span>
      <span class="arrow-filter"><b></b></span>
    </div>
    <div slot="popover">
      <div class="popover-wrapper" ref="popover-wrapper">
        <div class="dropdown-option" v-for="option in options" :key="option.value">
          <AtomCheck
              @input="handleInput(option.value, $event)"
              :label="option.text"
              :is-checked="inputVal.includes(option.value)"
          />
        </div>
      </div>
    </div>
  </v-popover>
</template>

<script>
import Vue from "vue";
import AtomCheck from "./AtomCheck";
import AtomIcon from "./AtomIcon";

export default Vue.extend({
  name: "AtomDropDownFilter",
  components: { AtomIcon, AtomCheck },
  props: {
    options: {
      required: true,
      type: Array,
      default: () => [],
    },
    value: {
      required: false,
      type: Array,
      default: () => [],
    },
    placeholder: {
      required: false,
      type: String,
      default: "Filter",
    },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    clearFilter(e) {
      e.stopPropagation();
      this.$emit('clearFilter', true);
    },
    handleWidth() {
      const input = this.$refs["input-show"];
      const wrapper = this.$refs["popover-wrapper"];
      const { width } = input.getBoundingClientRect()
      wrapper.style.width = `${ width }px`;
    },
    handleInput(id, val) {
      let values = [...this.inputVal];
      if (val) {
        values.push(id);
      } else {
        values = values.filter((v) => v !== id);
      }
      this.inputVal = values;
    },
  },
});
</script>

<style lang="scss">
.v-popover {
  .trigger {
    position: relative;
    display: block !important;
  }
  .label-popover {
    display: block;
    height: auto;
    min-width: 0;
    border: none;
    background: transparent;
    margin: 0;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 0.813rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    padding: 0.5rem 0.75rem 0.188rem;
  }
  .drop-down-filter-container {
    padding: 0.188rem 0.75rem 0.625rem;
    font-size: 1rem;
    line-height: 1.188rem;
    background: transparent;
    cursor: pointer;
    .indicator {
      color: $blue;
      margin-left: 0.5rem;
      border-left: 0.125rem solid $blue;
      padding-left: 0.25rem;
      font-variant-numeric: tabular-nums;
    }
  }
  .dropdown-option::v-deep {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    z-index: 999999;
    &:hover {
      background-color: #edf6ff;
    }
  }
  .clear-all {
    display: block;
    position: absolute;
    height: 2rem;
    width: 1.6rem;
    bottom: 0.2rem;
    right: 2.4rem;
    .icon {
      font-size: 1.313rem !important;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  .arrow-filter {
    display: block;
    position: absolute;
    height: 2rem;
    width: 2rem;
    bottom: 0.2rem;
    right: 0.375rem;
    b {
      border-style: solid;
      height: 0;
      left: 50%;
      top: 50%;
      width: 0;
      position: absolute;
      transform: translate(-50%, -50%);
      border-color: $black transparent transparent transparent;
      border-width: 0.375rem 0.313rem 0 0.313rem;
    }
  }
  &.open {
    .arrow-filter {
      b {
        border-color: transparent transparent #000000 transparent;
        border-width: 0 0.313rem 0.375rem 0.313rem;
      }
    }
  }
}
.popover-wrapper {
  height: 12.5rem;
  overflow-y: auto;
  .dropdown-option {
    padding: 0.313rem 0.938rem;
  }
}
.vue-popover-theme {
  z-index: 999999999999999999;
  background: #F7F7F7;
  border-radius: 0.25rem;
  .icon {
    transition: none !important;
  }
}
</style>