<template>
  <div class="molecule-table-package">
    <div class="table-body">
      <template>
        <div class="row-table-body">
          <div class="columns">
            <button
              class="btn-trash-item btn-trash-item-physical"
              @click="removeFromRequestPhysicalByArea(buildingId, pkey)"
            >
              <AtomIcon icon="trash-can" />
            </button>
            <span
              ><b>{{ area.name }}</b></span
            >
          </div>
          <div class="columns">
            <span
              ><b>{{ items.length }}</b></span
            >
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";

export default {
  name: "MoleculeTableArea",
  components: { AtomIcon },
  props: {
    pkey: {
      required: true,
      type: String,
      default: function () {
        return "";
      },
    },
    buildingId: {
      required: true,
      type: Number,
      default: function () {
        return 0;
      },
    },
    area: {
      required: false,
      type: Object,
      default: function () {
        return [];
      },
    },
    items: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    shortlistConstants() {
      return this.$store.getters.constants.shortlist;
    },
    requestOfferConstants() {
      return this.$store.getters.constants.requestOffer;
    },
    baseConstants() {
      return this.$store.getters.constants.base;
    },
    requestOfferItems() {
      return this.$store.state.requestOffer;
    },
  },
  methods: {
    async removeFromRequestPhysicalByArea(building_id, item_id) {
      const requestOfferType = this.requestOfferItems.requestOfferType;
      if (requestOfferType === "single") {
        this.$store.dispatch(
          this.requestOfferConstants.withNamespace(
            this.requestOfferConstants.action.CLEAR_REQUEST
          )
        );
        this.$store.dispatch(
          this.baseConstants.withNamespace(
            this.baseConstants.action.TOGGLE_REQUEST_OFFER
          ),
          { open: false }
        );
      } else {
        await this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_PHYSICAL_FROM_SHORTLIST
          ),
          {
            building_id: building_id,
            item_id: item_id,
          }
        );
        await this.$store.dispatch(
          this.requestOfferConstants.withNamespace(
            this.requestOfferConstants.action.MULTIPLE_REQUEST_OFFER
          )
        );
        if (
          this.requestOfferItems.digital.buildings.length === 0 &&
          this.requestOfferItems.physical.buildings.length === 0
        ) {
          this.$store.dispatch(
            this.baseConstants.withNamespace(
              this.baseConstants.action.TOGGLE_REQUEST_OFFER
            ),
            { open: false }
          );
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.molecule-table-package {
  .row-table-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.188rem;
  }
  .row-table-body {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.625rem;
    margin-bottom: 0.688rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-bottom: 0.625rem;
    }
  }
  .row-table-footer {
    display: flex;
    justify-content: space-between;
  }
  span {
    letter-spacing: 0.01em;
    @include font-main(0.875rem, $black, $regular, 1.063rem);
    b {
      @include font-main(1rem, $black, $medium, 1.188rem);
    }
  }
  .columns {
    display: flex;
    align-items: center;
  }
  .btn-trash-item {
    float: left;
    width: 2.125rem;
    height: 2.125rem;
    border-radius: 2.125rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1rem !important;
      margin: 0 !important;
    }
    &:hover {
      background: rgba(239, 48, 117, 0.1);
      .icon {
        color: #ef3075 !important;
      }
    }
  }
}
</style>
