var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-sidebar-header" }, [
    _c(
      "div",
      { staticClass: "menu-items sm" },
      [
        !["Digital Items", "Physical Items"].length
          ? _vm._t("default")
          : _vm._l(
              [
                { label: "Digital Items", tabId: "digital" },
                { label: "Physical Supports", tabId: "physical" }
              ],
              function(tab, index) {
                return _c("AtomMenuItem", {
                  key: index,
                  class: { active: _vm.selectedTab === tab.tabId },
                  attrs: {
                    label: tab.label,
                    icon: tab.icon,
                    "tab-id": tab.tabId,
                    "on-click": _vm.handleTabClick
                  }
                })
              }
            )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }