var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-availability-drawer" },
    [
      _c(
        "OrganismDrawer",
        {
          ref: "drawer",
          attrs: { "default-open": _vm.defaultOpen },
          on: {
            "drawer:close": function($event) {
              return _vm.$emit("drawer:close")
            }
          }
        },
        [
          _c("MoleculeSidebarHeader", {
            ref: "sidebarHeader",
            attrs: { "selected-option": _vm.activeTab },
            on: { "tab-change": _vm.handleTabChange }
          }),
          _c(
            "div",
            { staticClass: "top-content" },
            [_c("MoleculeFilters", { attrs: { "active-tab": _vm.activeTab } })],
            1
          ),
          _c("div", { staticClass: "line-break" }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab === "digital",
                  expression: "activeTab === 'digital'"
                }
              ]
            },
            [
              _c("div", { staticClass: "menu-info" }, [
                _c("div", { staticClass: "menu-info-content" }, [
                  _c("h1", [_vm._v("Digital Media")]),
                  _c("div", { staticClass: "first-content" }, [
                    _vm._v(
                      "\n            You’ll find a wide variety of options, all packages have options\n            of 10s and 7s ads.\n          "
                    )
                  ]),
                  _c("div", { staticClass: "second-content" }, [
                    _vm._v(
                      "\n            Select one or more packages from the list bellow, you can\n            shortlist them or request offer individually.\n          "
                    )
                  ])
                ])
              ])
            ]
          ),
          _vm.activeTab === "physical"
            ? _c("div", { staticClass: "project-info" }, [
                _c("div", { staticClass: "menu-info" }, [
                  _c("div", { staticClass: "menu-info-content" }, [
                    _c("h1", [_vm._v("Physical supports")]),
                    _c("div", { staticClass: "first-content" }, [
                      _vm._v(
                        "\n            All physical supports have different technical specifications\n            listed in the list below.\n          "
                      )
                    ]),
                    _c("div", { staticClass: "second-content" }, [
                      _vm._v(
                        "\n            Select one or more supports from the list bellow, you can\n            shortlist them or request offer individually.\n          "
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "top-content content-results" }, [
            _c(
              "div",
              { staticClass: "space-results" },
              [
                _c("MoleculeSidebarResults", {
                  attrs: { "active-tab": _vm.activeTab }
                })
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }