<template>
  <div class="molecule-digital-filters">
    <div class="filters clearfix " :class="{'visible': showFilters}">
      <div class="row">
        <div v-if="Object.keys(areasOptions).length >= 2" class="col col-12">
          <div class="select-style">
            <AtomDropDownFilter
                placeholder="Area(s)"
                :options="areasOptions"
                :value="filters.digital.areas"
                @input="$event => handleFilterChange($event, 'areas')"
                @clearFilter="handleClearMultipleSelectFilter"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="Object.keys(packagesOptions).length >= 2" class="col col-12">
          <div class="select-style">
            <AtomSelect
                id="package-select"
                :options="packagesOptions"
                :select-props="{
                    search: false,
                    placeholder: 'Select a package',
                  }"
                hasLabel
                labelText="Type Of Package"
                :value="filters.digital.typeOfPackage"
                @change="$event => handleFilterChange($event,  'typeOfPackage')"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div v-if="Object.keys(advertisingOptions).length >= 2" class="col col-12">
          <div class="select-style">
            <AtomSelect
                id="advertising-select"
                :options="advertisingOptions"
                :select-props="{
                    search: false,
                    placeholder: 'Select an advertising timing',
                  }"
                hasLabel
                labelText="Advertising Timing"
                :value="filters.digital.advertisingTiming"
                @change="$event => handleFilterChange($event, 'advertisingTiming')"
            />
          </div>
        </div>
      </div>
      <div class="row my-10 mx-2" v-if="false">
        <div class="col col-12">
          <AtomCheck
              name="showAllGroupOptions"
              label="Show all Iulius Group options"
              :is-checked="filters.digital.showAllGroupOptions"
              @input="handleFilterChange($event, 'showAllGroupOptions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomSelect from "../../../atoms/common/AtomSelect";
import AtomDropDownFilter from "../../../atoms/common/AtomDropdownFilter";
import AtomCheck from "../../../atoms/common/AtomCheck";

export default {
  name: "MoleculeDigitalFilters",
  components: { AtomCheck, AtomDropDownFilter, AtomSelect },
  props: {
    showFilters: {
      type: Boolean,
      required: true,
      default: false
    },
    reInitFilters: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      packagesOptions: [],
      advertisingOptions: [],
      areasOptions: [],
    }
  },
  mounted() {
    this.initFilterOptions();
  },
  computed: {
    filters() {
      return this.$store.state.project?.filters || null;
    },
    buildings() {
      return this.$store.state.project?.project?.buildings || [];
    },
    areas() {
      const areas = [];
      if (this.buildings.length === 0) return;
      for (const b of this.buildings) {
        if (!b.areas || b.areas.length === 0) return;
        for (const a of b.areas) {
          areas.push(a);
        }
      }
      return areas;
    },
    packs() {
      const packs = [];
      if (this.buildings.length === 0) return;
      for (const b of this.buildings) {
        if (!b.packs || b.packs.length === 0) return;
        for (const a of b.packs) {
          packs.push(a);
        }
      }
      return packs;
    }
  },
  watch: {
    reInitFilters(val, old) {
      if (val !== old) {
        this.initFilterOptions();
      }
    },
    areas: {
      deep: true,
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.initFilterOptions();
        }
      }
    }
  },
  methods: {
    initFilterOptions() {
      this.setAreasOptions();
      this.setPackagesOptions();
      this.setAdvertisingOptions();
    },
    setAreasOptions() {
      const areasOptions = [];
      const areasComp = [];
      if (this.areas && this.areas.length > 0) {
        for (const area of this.areas) {
          if (areasComp.indexOf(area.name) === -1) {
            areasOptions.push({
              text: area.name,
              value: area.name,
            });
            areasComp.push(area.name);
          }
        }
      }
      areasOptions.sort((a, b) => this.compare(a.text, b.text));
      this.areasOptions = areasOptions;
    },
    setPackagesOptions() {
      const packagesOptions = [
        { text: 'All', id: 'all' },
      ];
      const packsComp = [];
      if (this.packs && this.packs.length > 0) {
        for (const pack of this.packs) {
          if (packsComp.indexOf(pack.type) === -1) {
            packagesOptions.push({
              text: pack.type.charAt(0).toUpperCase() + pack.type.slice(1),
              id: pack.type,
            });
            packsComp.push(pack.type);
          }
        }
      }
      packagesOptions.slice(1).sort((a, b) => this.compare(a.text, b.text));
      this.packagesOptions = packagesOptions;
    },
    setAdvertisingOptions() {
      const advertisingOptions = [
        { text: 'All', id: "all" },
      ];
      const advertisingComp = [];
      if (this.packs && this.packs.length > 0) {
        for (const pack of this.packs) {
          if (advertisingComp.indexOf(pack.duration) === -1) {
            advertisingOptions.push({
              text: `${ pack.duration }s`,
              id: pack.duration,
            });
            advertisingComp.push(pack.duration);
          }
        }
      }
      advertisingOptions.slice(1).sort((a, b) => this.compare(a.text, b.text));
      this.advertisingOptions = advertisingOptions;
    },
    handleFilterChange(value, type, parentKey = 'digital') {
      this.$emit('filterChange', { value, type, parentKey });
    },
    handleClearMultipleSelectFilter(value) {
      if (value) {
        const projectConstants = this.$store.getters.constants.project;
        this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_MULTIPLE_SELECT_FILTER), { parentKey: 'digital' });
      }
    },
    compare(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
  }
}

</script>

<style lang="scss">
.molecule-digital-filters {
  .filters {
    background: $white;
    display: none;
    @media only screen and (max-width: 767px) {
      padding: 0.5rem 0;
    }
    .col-padding-4px {
      padding: 0 0.25rem;
    }
    &.visible {
      display: block;
    }
    .my-10 {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    }
    .mx-2 {
      margin-left: 0.125rem;
      margin-right: 0.125rem;
    }
    .atom-check-box {
      label {
        z-index: 0;
        font-size: 0.9375rem;
        line-height: 1.125rem;
      }
      .check-box {
        .icon {
          color: #23CD9A !important;
        }
      }
    }
    .select-style {
      background: #F5F7F8;
      width: 100%;
      border-radius: 0.125rem;
      margin-bottom: 0.5rem;
      label {
        display: block;
        height: auto;
        min-width: 0;
        border: none;
        background: transparent;
        margin: 0;
        font-weight: $medium;
        font-size: 0.688rem;
        line-height: 0.813rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.6);
        padding: 0.5rem 0.75rem 0.188rem;
      }
      .select2-container {
        .selection {
          .select2-selection--single {
            padding: 0.188rem 0.75rem 0.625rem;
            font-size: 1rem;
            line-height: 1.188rem;
            .select2-selection__rendered {
              font-size: 1rem;
              line-height: 1.188rem;
            }
            .select2-selection__arrow {
              height: 2rem;
              width: 2rem;
              top: -0.188rem;
              right: 0.375rem;
              b {
                margin-left: 0;
                margin-top: 0;
                transform: translate(-50%, -50%);
                border-color: $black transparent transparent transparent;
                border-width: 0.375rem 0.313rem 0 0.313rem;
              }
            }
          }
        }
      }
      .select2-container--default {
        &.select2-container--open {
          .select2-selection--single {
            .select2-selection__arrow {
              b {
                border-color: transparent transparent $black transparent;
                border-width: 0 0.313rem 0.375rem 0.313rem;
              }
            }
          }
        }
      }
      .select-inline-block {
        display: block;
      }
    }
  }
}
</style>