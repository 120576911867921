<template>
  <div class="organism-tabs-packages">
    <div class="row-tabs">
      <div class="tabs-wrapper">
        <div
          class="tab-trigger"
          v-for="(tab, index) in tabs"
          :class="{ active: activeTab === tab.tabId }"
          @click="handleTabChange(tab.tabId)"
          :key="index"
        >
          {{ tab.tabLabel }}
        </div>
      </div>
    </div>
    <div v-for="(tab, index) in tabs" :key="index">
      <div v-if="activeTab === tab.tabId">
        <OrganismPackages :packages="tab.packages" />
      </div>
    </div>
  </div>
</template>

<script>
import OrganismPackages from "./OrganismPackages";
export default {
  name: "OrganismTabsPackages",
  components: { OrganismPackages },
  props: {
    tabs: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    let activeTab = "";
    if (this.tabs && this.tabs.length) {
      activeTab = this.tabs[0].tabId;
    }
    return {
      activeTab,
    };
  },
  beforeUpdate() {
    if (this.tabs && this.tabs.length && !this.activeTab) {
      this.activeTab = this.tabs[0].tabId;
    }
  },
  methods: {
    handleTabChange(tabId) {
      this.activeTab = tabId;
    },
  },
};
</script>

<style lang="scss" scoped>
.organism-tabs-packages {
  .row-tabs {
    margin: 2.5rem 0;
    padding: 0 2rem;
    text-align: center;
    .tabs-wrapper {
      display: inline-block;
      background: $white;
      border-radius: 0.188rem;
      .tab-trigger {
        display: inline-block;
        vertical-align: middle;
        padding: 0.5rem 1rem;
        margin-right: 0.5rem;
        border-radius: 0.188rem;
        background: $white;
        cursor: pointer;
        transition: all 0.2s ease-in-out 0s;
        @include font-main(1.25rem, $black, $regular, 1.5rem);
        &:last-child {
          margin-right: 0;
        }
        &:hover,
        &.active {
          background: $second-color;
          color: $white;
        }
      }
    }
  }
}
</style>
