<template>
  <div class="atom-virtual-tour-images slide">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AtomVirtualTourImages",
  props: {},
};
</script>

<style lang="scss" scoped>
.atom-virtual-tour-images {
  position: relative;
}
</style>
