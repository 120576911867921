var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "atom-main-locations-padding" }, [
    _c(
      "div",
      { staticClass: "atom-main-locations", attrs: { id: "" + _vm.projectId } },
      [
        _vm.image
          ? _c(
              "div",
              {
                staticClass: "image-location",
                style: {
                  "background-image": "url(" + _vm.resourceUrl(_vm.image) + ")"
                }
              },
              [
                _c("div", { staticClass: "background-transparent" }),
                _vm.imageText
                  ? _c("span", {
                      staticClass: "image-text",
                      domProps: { innerHTML: _vm._s(_vm.imageText) }
                    })
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "title-and-button" },
          [
            _vm.title
              ? _c("p", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(_vm.title) }
                })
              : _vm._e(),
            _vm.buttonUrl
              ? _c("AtomButtonLink", {
                  attrs: {
                    label: "" + _vm.buttonText,
                    href: "" + _vm.buttonUrl
                  }
                })
              : _c("AtomButton", {
                  staticClass: "btn-disabled",
                  attrs: { label: _vm.buttonText }
                })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }