var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isActiveVirtualTour
    ? _c(
        "div",
        { staticClass: "atom-floor-selector" },
        _vm._l(_vm.buildings.floors, function(floor, id) {
          return _c(
            "span",
            {
              key: id,
              class: {
                active: _vm.isActive(String(floor.no))
              },
              on: {
                click: function($event) {
                  return _vm.moveCameraToItem(floor)
                }
              }
            },
            [_vm._v(_vm._s(floor.no))]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }