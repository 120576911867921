var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organism-menu-right", class: { scrolled: _vm.isScrolled } },
    [
      _c("div", { staticClass: "phone-header-parent" }, [
        _c(
          "a",
          {
            staticClass: "phone-header",
            attrs: { href: "tel:" + _vm.phoneHeader }
          },
          [_c("AtomIcon", { attrs: { icon: "phone-solid" } })],
          1
        ),
        _c("span", { staticClass: "hover-phone" }, [
          _vm._v("Call us: "),
          _c("b", [_vm._v(_vm._s(_vm.phoneHeader))])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }