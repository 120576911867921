<template>
  <div class="section-spaces canvas-buttons-wrapper active">
    <div class="col-12 background-gray">
      <div class="text-center">
        <div class="inline-element text-left select-style">
          <label><AtomIcon icon="chair-office" :size="14" /></label>
          <div class="select-inline-block">
            <AtomSelect id="fit-out" @change="changeFitout"  :options="[
                  { text: 'Select one of the fit-outs to see how the space will look if fitted in certain ways or none to see it empty.', value: '', disabled: 'disabled' },
                  { text: 'None', value: 'none' },
                  ...fitoutData.fitoutOptions.map((opt) => ({text: opt.label, value: opt.name}))
                ]" :select-props="{search: false, dropdownCssClass: 'fitout-dropdown-list', containerCssClass: 'fitout-dropdown-container'}" />
            <div class="info-fit-out">
              <span class="fit-out-text">Fitout</span>
              <span class="select-text hidden-mobile-elements">Select</span>
              <div class="custom-tooltip">
                <span class="info-icon" data-fitout=""><AtomIcon icon="info" :size="16" /></span>
              </div>
              <div class="legends legends-space"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomSelect from "../../atoms/common/AtomSelect";
import AtomIcon from "../../atoms/common/AtomIcon";
export default {
  name: "MoleculeFitoutSelector",
  components: {AtomIcon, AtomSelect},
  props: {
    fitoutData: {
      required: true,
      type: Object,
      default: function () {
        return {
          fitoutOptions: []
        }
      }
    },
    changeFitout: {
      required: true,
      type: Function,
      default: (value) => {}
    }
  }
}
</script>

<style scoped>

</style>