<template>
  <div class="organism-availability-drawer">
    <OrganismDrawer
      ref="drawer"
      :default-open="defaultOpen"
      @drawer:close="$emit('drawer:close')"
    >
      <MoleculeSidebarHeader
        :selected-option="activeTab"
        @tab-change="handleTabChange"
        ref="sidebarHeader"
      />
      <div class="top-content">
        <MoleculeFilters :active-tab="activeTab" />
      </div>
      <div class="line-break"></div>
      <div v-show="activeTab === 'digital'">
        <div class="menu-info">
          <div class="menu-info-content">
            <h1>Digital Media</h1>
            <div class="first-content">
              You’ll find a wide variety of options, all packages have options
              of 10s and 7s ads.
            </div>
            <div class="second-content">
              Select one or more packages from the list bellow, you can
              shortlist them or request offer individually.
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'physical'" class="project-info">
        <div class="menu-info">
          <div class="menu-info-content">
            <h1>Physical supports</h1>
            <div class="first-content">
              All physical supports have different technical specifications
              listed in the list below.
            </div>
            <div class="second-content">
              Select one or more supports from the list bellow, you can
              shortlist them or request offer individually.
            </div>
          </div>
        </div>
      </div>
      <div class="top-content content-results">
        <div class="space-results">
          <MoleculeSidebarResults :active-tab="activeTab" />
        </div>
      </div>
    </OrganismDrawer>
  </div>
</template>

<script>
import OrganismDrawer from "../OrganismDrawer";
import MoleculeSidebarResults from "@/components/molecules/project/building/MoleculeSidebarResults.vue";
import MoleculeSidebarHeader from "@/components/molecules/project/building/MoleculeSidebarHeader.vue";
import MoleculeFilters from "../../../molecules/common/MoleculeFilters";

export default {
  name: "OrganismAvailabilityDrawer",
  components: {
    MoleculeFilters,
    MoleculeSidebarHeader,
    MoleculeSidebarResults,
    OrganismDrawer,
  },
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerShortlistOpen: false,
      activeTab: "digital",
      selectedAmenities: [],
      drawerFiltersOpen: true,
      clickedHighlight: "",
    };
  },
  computed: {},
  methods: {
    toggleDrawer() {
      this.$refs["drawer"]?.toggleFilter();
    },
    closeDrawer() {
      this.$refs["drawer"]?.close();
    },
    openDrawer() {
      this.$refs["drawer"]?.open();
    },
    handleTabChange(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

<style lang="scss">
.organism-availability-drawer {
  z-index: 99999;
  .drawer-content {
    overflow-y: auto;
  }
  .menu-info {
    padding: 0.625rem 0.5rem;
  }
  .menu-info-content {
    padding: 0.75rem;
    background: #1a617f1a;
    @media only screen and (max-width: 767px) {
      padding: 0.75rem 0.5rem;
    }
    h1 {
      font-size: 1.25rem;
      font-weight: 400;
      margin-bottom: 0.75rem;
    }
    .first-content {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    .second-content {
      font-size: 0.875rem;
    }
  }
  &::v-deep {
    .atom-collapsible-item {
      &.parent-box {
        background: $darkerWhite;
        margin-bottom: 0.25rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.25rem;
        }

        .item-header {
          padding: 0.5rem 0.75rem 0.5rem 1.25rem;
          @media only screen and (max-width: 767px) {
            padding: 0 0 0 0.5rem;
          }

          .title-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;

            .title-text {
              font-weight: $regular;
              font-size: 1.375rem;
              line-height: 1.625rem;
              @media only screen and (max-width: 767px) {
                font-size: 1.125rem;
                line-height: 1.375rem;
              }
            }

            .circle-sum {
              font-weight: $bold;
              border-radius: 1.875rem;
              color: $white;
              background: $black;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 0.875rem;
              margin-left: 0.625rem;
              padding: 0.125rem 0.5rem;
              @media only screen and (max-width: 767px) {
                font-size: 0.875rem;
                margin-left: 0.625rem;
                padding: 0.125rem 0.5rem;
                border-radius: 1.875rem;
              }
            }
          }
        }

        .item-content {
          margin: 0 0.5rem;
          @media only screen and (max-width: 767px) {
            margin: 0;
          }
        }

        &.active {
          background: $lightGrey;
        }
      }
      &.child-box {
        background: $white;
        margin-bottom: 0.25rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.25rem;
        }
        .category-title {
          font-weight: 500;
          display: block;
          margin: 0.375rem;
          @media only screen and (max-width: 767px) {
            font-size: 0.875rem;
            margin: 0.375rem;
          }
        }
        &.active {
          .item-header {
            .title-box {
              .title-child {
                color: $white;
                background: $black;
              }
            }
          }
        }

        .item-header {
          padding: 0;

          &:hover {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }

          .title-box {
            .title {
              background: $white;
              color: $black;
              padding: 0.313rem 0.5rem;
              gap: 0.25rem;
              border-radius: 0.125rem;
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                padding: 0.313rem 0.5rem;
                border-radius: 0.125rem;
                margin-right: 0.625rem;
                font-size: 0.875rem;
              }
            }
          }

          .icon-button {
            margin-right: 0.5rem;
            @media only screen and (max-width: 767px) {
              margin-right: 0.5rem;
            }

            .expand-text {
              display: none;
            }
          }
        }

        .item-content {
          padding: 0.5rem 0;
          @media only screen and (max-width: 767px) {
            padding: 0.5rem 0;
          }
        }

        &.active {
        }
      }
      &.subchild-box {
        background: $darkerWhite;
        margin-bottom: 0.25rem;
        @media only screen and (max-width: 767px) {
          margin-bottom: 0.25rem;
        }
        .item-header {
          &:hover {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }

          .title-box {
            .title {
              background: $white;
              color: $black;
              padding: 0.313rem 0.5rem;
              gap: 4px;
              border-radius: 0.125rem;
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                font-size: 12px;
                padding: 5px 8px;
                border-radius: 2px;
                margin-right: 10px;
              }
            }
            span {
              @media only screen and (max-width: 767px) {
                font-size: 12px;
              }
            }
            .surface {
              margin-right: 0.625rem;
              @media only screen and (max-width: 767px) {
                margin-right: 10px;
                font-size: 12px;
              }
              .icon {
                font-size: 0.875rem !important;
                @media only screen and (max-width: 767px) {
                  font-size: 14px !important;
                }
              }
            }
            .cardinal-point {
              margin-right: 0.125rem;
              @media only screen and (max-width: 767px) {
                margin-right: 2px;
                font-size: 12px;
              }
              .icon {
                font-size: 0.875rem !important;
                @media only screen and (max-width: 767px) {
                  font-size: 14px !important;
                }
              }
            }
          }
        }

        .item-content {
          .content-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            //background: $lightGrey;

            .space-box {
              display: flex;
              align-items: center;
              margin: 0.5rem 0.625rem;
              @media only screen and (max-width: 767px) {
                margin: 8px 0;
              }

              .space-image-box {
                img {
                  width: 5.938rem;
                  @media only screen and (max-width: 767px) {
                    width: 95px;
                  }
                }
              }

              .space-content-box {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                margin-left: 10px;

                .space-description {
                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 14px;
                  }
                }
                .space-price {
                  margin-top: 0.625rem;
                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 14px;
                    margin-top: 10px;
                    strong {
                      font-size: 12px;
                      line-height: 14px;
                    }
                  }
                }
              }
            }

            .content-buttons {
              display: flex;
              justify-content: space-between;
              margin: 0.5rem 0.625rem;
              @media only screen and (max-width: 767px) {
                margin: 5px 0 0;
              }
              .button-atom {
                padding: 0.25rem 0.45rem;
                height: 2.5rem;
                text-transform: none;
                flex: 1;
                margin: 0.125rem;
                @media only screen and (max-width: 767px) {
                  padding: 4px 7px;
                  height: 30px;
                  margin: 2px;
                }
                .icon {
                  @media only screen and (max-width: 767px) {
                    font-size: 12px !important;
                  }
                }
                span {
                  text-transform: none;
                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }
            }
          }

          .content-buttons {
            .explore-btn {
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 4px;
              .icon {
                display: none;
                color: $white !important;
                margin-right: 0.5rem;
                @media only screen and (max-width: 767px) {
                  margin-right: 8px;
                }
              }
              &:hover {
                background: #23cd9a;
                .icon {
                  display: inline-block;
                }
                span {
                  color: $white;
                }
              }
            }
            .shortlist-btn {
              border-radius: 4px;
              background: $white;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                color: $black !important;
                margin-right: 0.5rem;
                @media only screen and (max-width: 767px) {
                  margin-right: 8px;
                }
              }
              span {
                color: $black;
              }
              &:hover {
                background: #23cd9a;
                .icon {
                  color: $white !important;
                }
                span {
                  color: $white;
                }
              }
            }
            .remove-shortlist-btn {
              border-radius: 4px;
              background: $white;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              .icon {
                color: $black !important;
                margin-right: 0.5rem;
                @media only screen and (max-width: 767px) {
                  margin-right: 8px;
                }
              }
              span {
                color: $black;
              }
              &:hover {
                background: #ef3075;
                .icon {
                  color: $white !important;
                }
                span {
                  color: $white;
                }
              }
            }
            .request-offer-btn {
              border-radius: 4px;
              &.button-green {
                border: none;
                background: #23cd9a;
                .icon {
                  display: none;
                  margin-right: 0.5rem;
                }
                span {
                  color: $white;
                }
                &:hover {
                  background: $black;
                  .icon {
                    display: inline-block;
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }

        &.active {
          background: $lightGrey;

          .item-header {
            .title-box {
              .title {
                background: $black;
                color: $white;
              }
            }
          }
        }
      }
      &.spaces-leased {
        opacity: 0.7;
        background: repeating-linear-gradient(
          -55deg,
          #e5eaed,
          #e5eaed 2px,
          #ffffff 2px,
          #ffffff 5px
        );
      }
      &.space-sold {
        opacity: 0.7;
        background: repeating-linear-gradient(
          -55deg,
          #ffffff,
          #ffffff 2px,
          #e5eaed 2px,
          #e5eaed 5px
        );
      }
    }
    .project-info {
      .project-title-and-address.action-disabled {
        display: none;
      }
      .project-spaces .btn-black {
        display: none;
        background: red;
      }
    }
  }
  .horizontal-menu {
    padding: 8px 8px 0 8px;
    margin-bottom: 1.125rem;
    height: auto !important;
    @media only screen and (max-width: 767px) {
      margin-bottom: 18px;
    }
  }
  .top-content {
    background: $white;
    padding: 0 1.25rem;
    @media only screen and (max-width: 767px) {
      padding: 0 1rem;
    }

    &.content-results {
      padding: 0;
    }
  }
  .not-found,
  .no-result {
    padding: 10px;
  }
  .fully-leased {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 767px) {
      font-size: 0.875rem;
    }
  }
}
</style>
