var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-table-item" }, [
    _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "row-table-header" }, [
        _vm._m(0),
        _vm.totalDailyBroadcasts !== 0
          ? _c("div", { staticClass: "columns" }, [
              _c("span", [_vm._v("Total No. of broadcasts")])
            ])
          : _vm._e()
      ])
    ]),
    _c(
      "div",
      { staticClass: "table-body" },
      [
        _vm._l(_vm.items, function(item) {
          return [
            _c(
              "div",
              { key: "item_" + item.id, staticClass: "row-table-body" },
              [
                _c(
                  "div",
                  {
                    staticClass: "row-state-element",
                    class: { active: _vm.isActive(item.code) }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.coordinates_id,
                            expression: "item.coordinates_id"
                          }
                        ],
                        staticClass: "hover-element",
                        on: {
                          click: function($event) {
                            return _vm.moveCameraToItem(item)
                          }
                        }
                      },
                      [
                        _c("AtomIcon", { attrs: { icon: "act-ethically" } }),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.getButtonText(item.code)) + " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "columns" }, [
                      _c("span", [_c("b", [_vm._v(_vm._s(item.name))])])
                    ]),
                    _vm.dailyBroadcasts(item)
                      ? _c("div", { staticClass: "columns" }, [
                          _c("span", [
                            _c("b", [
                              _vm._v(_vm._s(_vm.dailyBroadcasts(item)))
                            ]),
                            _vm._v(" / day\n            ")
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ]
            )
          ]
        })
      ],
      2
    ),
    _c("div", { staticClass: "table-footer" }, [
      _c("div", { staticClass: "row-table-footer" }, [
        _vm._m(1),
        _vm.totalDailyBroadcasts !== 0
          ? _c("div", { staticClass: "columns" }, [
              _c("span", [
                _c("b", [_vm._v(_vm._s(_vm.totalDailyBroadcasts))]),
                _vm._v(" / day")
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("span", [_vm._v("Type of item")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("span", [_vm._v("Total")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }