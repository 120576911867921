var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-leasing": true
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("div", { staticClass: "row row-slider-info align-items-center" }, [
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("img", {
            attrs: { src: _vm.resourceUrl(_vm.data.image), alt: _vm.data.title }
          })
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          _c("div", { staticClass: "content-slider" }, [
            _vm.data.title
              ? _c("p", {
                  staticClass: "title",
                  domProps: { innerHTML: _vm._s(_vm.data.title) }
                })
              : _vm._e(),
            _vm.data.lines
              ? _c(
                  "div",
                  { staticClass: "subtitle-info" },
                  _vm._l(_vm.data.lines, function(line, index) {
                    return _c("p", {
                      key: index,
                      staticClass: "subtitle",
                      domProps: { innerHTML: _vm._s(line) }
                    })
                  }),
                  0
                )
              : _vm._e(),
            _vm.data.statisticsLines
              ? _c(
                  "div",
                  { staticClass: "statistics-info" },
                  _vm._l(_vm.data.statisticsLines, function(statistic, index) {
                    return _c("AtomNumberAndText", {
                      key: index,
                      attrs: {
                        title: statistic.numberText,
                        subtitle: statistic.text
                      }
                    })
                  }),
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "buttons-bottom" }, [
              _vm.data.buttonText
                ? _c(
                    "div",
                    { staticClass: "display-inline" },
                    [
                      _c("AtomButtonLink", {
                        attrs: {
                          label: "" + _vm.data.buttonText,
                          href: "" + _vm.data.buttonUrl
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.data.buttonBrochureText
                ? _c(
                    "div",
                    { staticClass: "display-inline" },
                    [
                      _c("AtomButtonLink", {
                        staticClass: "btn-general-all-black",
                        attrs: {
                          label: "" + _vm.data.buttonBrochureText,
                          href: "" + _vm.resourceUrl(_vm.data.buttonBrochureUrl)
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }