<template>
  <div :class="{section: true, 'section-custom-model': true, [`section${orderNo}`]: true}">
    <OrganismSidebarAccordion :pins-info="data.pinsInfo" :sidebar-title="data.sidebarTitle"/>
    <div class="canvas-model-wrapper">
      <organism-building ref="buildingRef" :custom-model="data.customModel" ></organism-building>
      <OrganismPinInfoBox :pin-data="pinBoxInfo"/>
    </div>
    <Organism3DModelInteraction/>
  </div>
</template>

<script>

import OrganismBuilding from "../../../components/organisms/project/building/OrganismBuilding";
import buildingStore from "../../../components/organisms/project/building/store";
import OrganismSidebarAccordion from "../../../components/organisms/common/OrganismSidebarAccordion";
import OrganismPinInfoBox from "../../../components/organisms/project/building/OrganismPinInfoBox";
import Organism3DModelInteraction from "../../../components/organisms/project/building/Organism3DModelInteraction";
export default {
  name: "SectionCustomModel",
  components: {
    Organism3DModelInteraction,
    OrganismPinInfoBox,
    OrganismSidebarAccordion,
    OrganismBuilding
  },
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {
          customModel: {
            required: true,
            type: Object,
            default: function () {
              return {}
            }
          },
          pinsInfo:{
            required: false,
            type: Array,
            default: function () {
              return [
                {
                  category: {
                    required: false,
                    type: String,
                    default: function () {
                      return ''
                    }
                  },
                  pins: {
                    required: false,
                    type: Array,
                    default: function () {
                      return [
                        {
                          pinId: {
                            required: false,
                            type: String,
                            default: function () {
                              return ''
                            }
                          },
                          pinDescription: {
                            required: false,
                            type: String,
                            default: function () {
                              return ''
                            }
                          }
                        }
                      ]
                    }
                  },
                  virtualTourPins: {
                    required: false,
                    type: Array,
                    default: function () {
                      return [
                        {
                          pinId: {
                            required: false,
                            type: String,
                            default: function () {
                              return ''
                            }
                          },
                          pinDescription: {
                            required: false,
                            type: String,
                            default: function () {
                              return ''
                            }
                          }
                        }
                      ]
                    }
                  }
                }
              ]
            }
          },

          sidebarTitle:{
            required: false,
            type: String,
            default: function () {
              return ""
            }
          }

        }
      },
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0,
    }
  },
  computed: {
    pinBoxInfo() {
      const pinsArr = [];

      if (this.data.pinsInfo){
        this.data.pinsInfo.forEach(pinData=>{
          if (pinData.pins){
            pinData.pins.forEach(pin=>{
              pinsArr.push({
                'highlightAnchor': pin.pinId,
                'pinTitle': pin.pinDescription,
                'imageSrc':pin.imageSrc,
                'videoSrc':pin.videoSrc,
                'iframeSrc':pin.iframeSrc,
                'type':pin.type
              });
            })
          }
          if (pinData.virtualTourPins){
            pinData.virtualTourPins.forEach(pin=>{
              pinsArr.push({
                'highlightAnchor': pin.pinId,
                'pinTitle': pin.pinDescription,
                'imageSrc':pin.imageSrc,
                'fitouts':pin.fitouts,
                'videoSrc':pin.videoSrc,
                'iframeSrc':pin.iframeSrc,
                'type':pin.type
              });
            })
          }
        });
      }
      return pinsArr
    },
    buildingStore() {
      return buildingStore;
    },
    spaces() {
      return this.$store.state.project.project.spaces
    },

  },
  methods: {
    removePageLoader() {
      if (this.$store.state.base.documentIsReady) {
        this.$store.dispatch(this.baseConstants.withNamespace(this.baseConstants.action.CHANGE_PAGE_LOADING_STATE), false);
      }
    }
  },
  watch: {
    spaces: {
      deep: true,
      handler: function (value, old) {
        this.removePageLoader();
        this.$refs.buildingRef.initializeCustomModel3D(this.data.pinsInfo);
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.disableArrowScroll, false);
     //this.$refs.buildingRef.initializeCustomModel3D();
  }
}
</script>

<style lang="scss">
  .section-custom-model{
    display: flex;
    position: relative;
    .organism-building {
      #canvas-wrapper{
        //justify-content: flex-end;
        //margin-left: auto;
      }
    }

    .canvas-model-wrapper{
      position: relative;
    }

    }
</style>