<template>
  <div class="molecule-sidebar-campaign">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MoleculeSidebarCampaign",
}
</script>

<style lang="scss">

</style>