var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pinInfo",
      attrs: {
        "data-pin": _vm.pinInfo.highlightAnchor,
        id: _vm.pinInfo.highlightAnchor
      }
    },
    [
      _c("AtomPinInfoContent", { attrs: { pinInfo: _vm.pinInfo } }),
      _vm.pinInfo.fitouts
        ? _c("AtomPinFitoutButtons", {
            attrs: { fitouts: _vm.pinInfo.fitouts }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }