<template>
  <div class="atoms-items-buttons">
    <AtomButton
      class="btn-black icon-left font-medium"
      label="Request Offer"
      custom-label="Request"
      :on-click="requestOffer"
      activate-hover-event
    />
    <AtomButton
      v-if="!shortlistButtonState"
      class="btn-white icon-left font-medium"
      icon="shortlist-icon"
      label="Shortlist"
      custom-label="Add"
      :on-click="addToShortlist"
      activate-hover-event
    />
    <AtomButton
      v-else
      class="btn-white hover-red icon-left font-medium"
      icon="remove-shortlist"
      label="Remove Item"
      custom-label="Remove"
      :on-click="removeFromShortlist"
      activate-hover-event
    />
  </div>
</template>

<script>
import AtomButton from "../common/AtomButton";
import { ItemTypes } from "../../../store/modules/shortlist/types";
export default {
  name: "AtomItemsButtons",
  components: { AtomButton },
  props: {
    buildingId: {
      required: true,
      type: Number,
    },
    itemId: {
      required: true,
      type: [Number, String],
    },
    type: {
      required: true,
      type: String,
    },
    physicalItemType: {
      required: false,
      type: String,
      default: "",
    },
    digitalItems: {
      required: false,
      type: Array,
      default: function () {
        return [];
      },
    },
    packOption: {
      required: false,
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    projectConstants() {
      return this.$store.getters.constants.project;
    },
    shortlistConstants() {
      return this.$store.getters.constants.shortlist;
    },
    shortlistItemsIds() {
      return this.$store.state.project?.shortlistRegistry?.items_ids || [];
    },
    isInShortlist() {
      const shortlistRegistry = this.$store.state.project?.shortlistRegistry;
      if (shortlistRegistry.type === "physical") {
        return shortlistRegistry.typesAreas.includes(
          `${this.physicalItemType}_${this.itemId}`
        );
      }
      return (
        this.shortlistItemsIds.includes(this.itemId) && this.type === "digital"
      );
    },
    area() {
      return this.$store.state.project.project.areas.find((area) => area.id === this.itemId) || {}
    },
    shortlistButtonState() {
      const building =
        this.$store.state?.shortlist?.[this.type]?.buildings?.find(
          (b) => b.id === this.buildingId
        ) || null;
      if (!building) return;
      if (this.type === ItemTypes.DIGITAL) {
        const option =
          building?.options?.find((o) => o.pack_id === this.itemId) || null;
        if (!option) return;
        return true;
      }
      if (this.type === ItemTypes.PHYSICAL) {
        const identifiers = this.itemId.split("_"); // "areaId:1_type:tv"
        const areaId = identifiers[0].split(":")[1];
        const typeOfItem = identifiers[1].split(":")[1];
        const option =
          building?.options?.find(
            (o) => o.area.id === Number(areaId) && o.type === typeOfItem
          ) || null;
        if (!option) return;
        return true;
      }
      return false;
    },
  },
  methods: {
    addToShortlist() {
      const typeOfShortlist = this.$store.state.project.shortlistRegistry.type;
      const payload = {
        building_id: this.buildingId,
        item_id: this.itemId,
        type: this.type,
      };

      if (this.type === "physical") {
        payload.physical_item_type = this.physicalItemType;
      }

      if (typeOfShortlist && typeOfShortlist !== this.type) {
        const modal = this.$store.getters.constants.modal;
        this.$store.dispatch(
          modal.withNamespace(modal.action.CHANGE_MODAL_STATE),
          {
            showModal: true,
            modalType: "shortlist-rules",
            modalData: payload,
          }
        );
        document.body.classList.add("disable-scroll");
        return;
      }

      // this.$store.dispatch(
      //   this.projectConstants.withNamespace(
      //     this.projectConstants.action.ADD_TO_SHORTLIST
      //   ),
      //   payload
      // );

      if (this.type === ItemTypes.DIGITAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.ADD_DIGITAL_TO_SHORTLIST
          ),
          {
            building_id: this.buildingId,
            item_id: this.itemId,
          }
        );
      }
      if (this.type === ItemTypes.PHYSICAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.ADD_PHYSICAL_TO_SHORTLIST
          ),
          {
            building_id: this.buildingId,
            item_id: this.itemId,
          }
        );
      }

      // if (this.type === "digital") {
      // this.$store.dispatch(
      //   this.shortlistConstants.withNamespace(
      //     this.shortlistConstants.action.ADD_DIGITAL_ITEM_TO_SHORTLIST
      //   ),
      //   this.digitalItems.map((di) => ({ ...di, ...payload }))
      // );
      // }
    },
    removeFromShortlist() {
      // const payload = {
      //   building_id: this.buildingId,
      //   item_id: this.itemId,
      // };
      // if (this.type === "physical") {
      //   payload.physical_item_type = this.physicalItemType;
      // }
      // this.$store.dispatch(
      //   this.projectConstants.withNamespace(
      //     this.projectConstants.action.REMOVE_FROM_SHORTLIST
      //   ),
      //   payload
      // );

      if (this.type === ItemTypes.DIGITAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_DIGITAL_FROM_SHORTLIST
          ),
          {
            building_id: this.buildingId,
            item_id: this.itemId,
          }
        );
      }
      if (this.type === ItemTypes.PHYSICAL) {
        this.$store.dispatch(
          this.shortlistConstants.withNamespace(
            this.shortlistConstants.action.REMOVE_PHYSICAL_FROM_SHORTLIST
          ),
          {
            building_id: this.buildingId,
            item_id: this.itemId,
          }
        );
      }
    },
    requestOffer() {
      const requestOfferConstants = this.$store.getters.constants.requestOffer;
      this.$store.dispatch(
          requestOfferConstants.withNamespace(
              requestOfferConstants.action.CHANGE_MODAL_REQUEST_TYPE
          ),
          "single"
      );
      let payloadItems = {
        building_id: this.buildingId,
        item_id: this.itemId,
        type: this.type
      };
      this.$store.dispatch(
        requestOfferConstants.withNamespace(
          requestOfferConstants.action.SINGLE_REQUEST_OFFER
        ),
        payloadItems
      );
      this.$store.dispatch("base/toggleRequestOffer", { open: true, requestOfferType: "single" });
    },
  },
};
</script>

<style lang="scss" scoped>
.atoms-items-buttons {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  @media only screen and (max-width: 767px) {
    gap: 0.5rem;
    padding: 0.5rem 0;
  }
  .btn-general {
    width: 100%;
    padding: 0.75rem 0.625rem;
  }
}
</style>
