var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "atom-check-box", on: { click: _vm.handleClick } },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputVal,
            expression: "inputVal"
          }
        ],
        ref: "checkbox",
        attrs: { id: _vm.id, type: "checkbox", name: _vm.name },
        domProps: {
          checked: Array.isArray(_vm.inputVal)
            ? _vm._i(_vm.inputVal, null) > -1
            : _vm.inputVal
        },
        on: {
          change: function($event) {
            var $$a = _vm.inputVal,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputVal = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputVal = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputVal = $$c
            }
          }
        }
      }),
      _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))]),
      _c(
        "span",
        { class: { "check-box": true, checked: _vm.checked } },
        [
          _vm.checked
            ? _c("AtomIcon", {
                attrs: { icon: "square-check", size: _vm.size }
              })
            : _c("AtomIcon", { attrs: { icon: "square", size: _vm.size } })
        ],
        1
      ),
      _vm.error
        ? _c("span", { staticClass: "error-message" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }