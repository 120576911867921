var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "molecule-physical-filters" }, [
    _c(
      "div",
      { staticClass: "filters clearfix ", class: { visible: _vm.showFilters } },
      [
        _c("div", { staticClass: "row" }, [
          Object.keys(_vm.areasOptions).length >= 2
            ? _c("div", { staticClass: "col col-12" }, [
                _c(
                  "div",
                  { staticClass: "select-style" },
                  [
                    _c("AtomDropDownFilter", {
                      attrs: {
                        placeholder: "Area(s)",
                        options: _vm.areasOptions,
                        value: _vm.filters.physical.areas
                      },
                      on: {
                        input: function($event) {
                          return _vm.handleFilterChange($event, "areas")
                        },
                        clearFilter: _vm.handleClearMultipleSelectFilter
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col col-12" },
            [
              _c("MoleculeMultipleOptions", {
                ref: "devicesOptions",
                attrs: {
                  options: _vm.devicesOptions,
                  title: "Device(s)",
                  value: _vm.filters.physical.devices
                },
                on: {
                  input: function($event) {
                    return _vm.handleFilterChange($event, "devices")
                  }
                }
              })
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }