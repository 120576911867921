<template>
  <OrganismInfoPopup v-show="open">
    <div class="close-i" @click="handleInfoTrigger">
      <AtomIcon icon="xmark" />
    </div>
    <div class="row info-i">
      <div class="col col-12">
        <h4>Easy Experience</h4>
      </div>
      <div class="col col-xs-12 col-6">
        <div class="content-info">
          <div class="icon-atom-custom">
            <img
              class="mouse-i"
              src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/mouse-i.svg"
              alt="Mouse"
            />
            <p>Press and drag to explore or scroll down for more information</p>
          </div>
          <div class="icon-atom-custom">
            <img
              class="cursor-i"
              src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/cursor-i.svg"
              alt="Cursor"
            />
            <p>Click and select an Area to navigate to another location</p>
          </div>
          <div class="icon-atom-custom">
            <img
              class="filter-i"
              src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/filter-i.svg"
              alt="Filter"
            />
            <p>
              Use filters to filter and explore other digital or physical items
            </p>
          </div>
        </div>
      </div>
      <div class="col col-xs-12 col-6">
        <div class="content-info">
          <div class="icon-atom-custom">
            <img
              class="menu-i"
              src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/menu-i.svg"
              alt="Menu"
            />
            <p>Use the menu to navigate through projects</p>
          </div>
          <div class="icon-atom-custom">
            <img
              class="esc-i"
              src="https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/esc-i.svg"
              alt="Esc"
            />
            <p>Press Escape to exit the selected area</p>
          </div>
        </div>
      </div>
    </div>
  </OrganismInfoPopup>
</template>
<script>
import OrganismInfoPopup from "@/components/organisms/OrganismInfoPopup";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import { BabylonClientManager } from "@/components/organisms/project/building/3D/ClientManager";

export default {
  name: "OrganismTutorial",
  components: {
    AtomIcon,
    OrganismInfoPopup,
  },
  computed: {
    open() {
      return this.$store.state?.project?.infoBox?.open || false;
    },
  },
  methods: {
    handleInfoTrigger() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.TOGGLE_INFO_BOX),
        {
          open: !this.open,
        }
      );
      const manager = BabylonClientManager.getSceneManager();
      if (manager) {
        manager.scene.getEngine().getRenderingCanvas().focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.organism-info {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.8);
  padding: 1.25rem 2.5rem 2rem;
  width: 38.75rem;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  transition: all 0.5s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    max-width: 90%;
    width: 90%;
    max-height: 80%;
    overflow: auto;
  }
  &.open {
    display: block;
  }
  .close-i {
    position: absolute;
    right: 1.875rem;
    top: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background: rgba(255, 255, 255, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
    transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      right: 0.5rem;
      top: 0.5rem;
    }
    &:hover {
      background: $black;
    }
    .icon {
      font-size: 1.25rem !important;
      color: $white !important;
    }
  }
  .content-info {
    @media only screen and (max-width: 767px) {
      margin-top: 1.25rem;
    }
  }
  .row {
    margin: 0 -1.25rem;
    .col {
      padding: 0 1.25rem;
    }
  }
  h4 {
    font-size: 2rem;
    line-height: 2.375rem;
    color: $white;
    margin-bottom: 1.563rem;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
    }
    b {
      font-size: 2rem;
      line-height: 2.375rem;
    }
  }
  h6 {
    color: $white;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.188rem;
    margin-bottom: 1.25rem;
  }
  p {
    font-weight: $light;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $white;
    @media only screen and (max-width: 767px) {
      font-size: 1rem;
      line-height: 1.263rem;
    }
  }
  .icon-atom-custom {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0.125rem;
      width: 1.313rem;
      &.orientation-i,
      &.esc-i {
        top: -0.125rem;
      }
      &.mouse-i,
      &.cursor-i,
      &.hand-i,
      &.circle-plus-i,
      &.home-i {
        width: 1rem;
      }
      &.arrow-left-i {
        width: 0.8rem;
      }
      &.fitout-i {
        width: 1rem;
        margin-top: -0.15rem;
      }
    }
  }
  .icon-bottom-custom {
    p {
      margin-bottom: 1rem;
    }
    .row-images {
      .image {
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin: -0.438rem 1rem 0;
      }
      img {
        width: 3.5rem;
      }
    }
  }
}
</style>
