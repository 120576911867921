<template>
  <div class="organism-media-gallery">
    <MoleculeMediaGallery>
      <div
        class="slide"
        v-for="(sliderData, index) in sliderGalleryData"
        :key="index"
      >
        <img
          :src="
            resourceUrl(
              'images/projects/iulius/advertising/gallery/placeholder.jpg'
            )
          "
          alt="Placeholder"
        />
        <span
          v-if="sliderData.pinLabel"
          class="pulsating-circle"
          :style="{
            top: sliderData.top,
            right: sliderData.right,
            bottom: sliderData.bottom,
            left: sliderData.left,
          }"
          @click="handleInfoTrigger(sliderData.infoPopup)"
        ></span>
        <span
          v-if="sliderData.pinLabel"
          class="tooltip-element"
          @click="handleInfoTrigger(sliderData.infoPopup)"
        >
          <AtomIcon icon="info" />
          {{ sliderData.pinLabel }}
        </span>
        <div
          class="background-image"
          :style="{
            'background-image': `url(${resourceUrl(sliderData.image)})`,
          }"
        ></div>
      </div>
    </MoleculeMediaGallery>
  </div>
</template>

<script>
import MoleculeMediaGallery from "../../molecules/common/MoleculeMediaGallery";
import AtomIcon from "../../atoms/common/AtomIcon";

export default {
  name: "OrganismMediaGallery",
  components: { AtomIcon, MoleculeMediaGallery },
  props: {
    sliderGalleryData: {
      required: true,
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    open() {
      return this.$store.state?.project?.mediaInfoBox?.open || false;
    },
  },
  methods: {
    resourceUrl(img) {
      if (img.includes("://")) {
        return img;
      }
      return `${this.$store.getters.cdnBase}/${img}`;
    },
    handleInfoTrigger(infoPopup) {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_MEDIA_INFO_BOX
        ),
        {
          open: !this.open,
          data: infoPopup,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.organism-media-gallery {
  position: relative;
  .slide {
    position: relative;
    img {
      display: block;
      width: 100%;
      opacity: 0;
    }
    .background-image {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .tooltip-element {
      position: absolute;
      top: 1.125rem;
      right: 3.125rem;
      padding: 0.375rem 0.625rem 0.475rem;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.125rem;
      cursor: pointer;
      z-index: 8;
      transition: all 0.2s ease-in-out 0s;
      @include font-main(1rem, $white, $regular, normal);
      .icon {
        font-size: 1rem !important;
        color: $white !important;
        margin-right: 0.1rem;
      }
      &:hover,
      &.active {
        background: $black;
      }
    }
    .pulsating-circle {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      z-index: 8;
      &:before {
        content: "";
        position: relative;
        display: block;
        width: 250%;
        height: 250%;
        box-sizing: border-box;
        margin-left: -75%;
        margin-top: -75%;
        border-radius: 2.813rem;
        background-color: $white;
        animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
      }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $main-color;
        border-radius: 0.938rem;
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
        animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s
          infinite;
      }
    }
    @keyframes pulse-ring {
      0% {
        transform: scale(0.33);
      }
      80%,
      100% {
        opacity: 0;
      }
    }
    @keyframes pulse-dot {
      0% {
        transform: scale(0.8);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.8);
      }
    }
  }
  .owl-nav {
    button {
      position: absolute;
      top: 0;
      height: 100%;
      border: none !important;
      background: rgba(0, 0, 0, 0.5) !important;
      width: 2.5rem;
      margin: 0;
      &.owl-prev {
        left: 0;
      }
      &.owl-next {
        right: 0;
      }
      &:hover {
        background: $black !important;
        span {
          color: $white !important;
        }
      }
    }
  }
}
</style>
