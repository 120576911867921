var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        ((_obj = {
          section: true,
          "section-gallery": true,
          "section-gallery-v2": true
        }),
        (_obj["section" + _vm.orderNo] = true),
        _obj)
    },
    [
      _c("a", {
        staticClass: "navigation-target",
        attrs: { id: _vm.hash, href: "javascript:void(0);" }
      }),
      _c("div", { staticClass: "top-title" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col col-xs-12 col-9" },
            [
              _c("AtomTitle", {
                attrs: { title: _vm.data.title, subtitle: _vm.data.subtitle }
              })
            ],
            1
          ),
          _c("div", { staticClass: "col col-xs-12 col-3" }, [
            _c("div", { staticClass: "text-right" }, [
              _vm.data.brochure
                ? _c(
                    "a",
                    {
                      staticClass: "button-atom",
                      attrs: {
                        href: "" + _vm.resourceUrl(_vm.data.brochure),
                        target: "_blank"
                      }
                    },
                    [_c("span", [_vm._v("Download Brochure")])]
                  )
                : _vm._e(),
              !_vm.matterport &&
              _vm.currentSpace &&
              _vm.spaceFloor.floor_plan_url
                ? _c(
                    "div",
                    {
                      staticClass: "button-atom",
                      on: {
                        click: function($event) {
                          return _vm.download(
                            _vm.spaceFloor.floor_plan_url,
                            "floorPlan.jpg"
                          )
                        }
                      }
                    },
                    [_c("span", [_vm._v("Download Floor Plan")])]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _c("OrganismImageSliderV2", { attrs: { "slider-data": _vm.content } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }